export const careerRoutes = {
  root: '/career',
  position: '/career/:id',
  checklist: '/career/apply/:id/:name',
  firstStep: '/career/apply/:id/position/first-step',
  secondStep: '/career/apply/:id/position/second-step',
  thirdStep: '/career/apply/:id/position/third-step',
  fourthStep: '/career/apply/:id/position/fourth-step',
  fifthStep: '/career/apply/:id/position/fifth-step',
  sixStep: '/career/apply/:id/position/six-step',
  sevenStep: '/career/apply/:id/position/seven-step',
  eighthStep: '/career/apply/:id/position/eight-step',
  finishPage: '/career/apply/:id/position/finish',
  stepFn: (id,step) => `/career/apply/${id}/position/${step}`
};
