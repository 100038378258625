import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { aboutRoutes } from './constants';
//pages
import AboutPage from 'modules/about/pages/AboutPage';

const AboutRoutes = () => {
  return (
    <Switch>
      <Route path={aboutRoutes.root} component={AboutPage} exact />
    </Switch>
  );
};

export default AboutRoutes;
