import {
  EDIT,
  SET,
  SET_EDITING,
  SET_LOADING,
  DELETE,
  CREATE,
  SET_CURRENT_LESSON_NUMBER,
  EDIT_LESSON,
  INSERT_LESSON,
  CANCEL,
  CLEAR_TRAININGS,
  SET_ERROR,
  REFRESH_TRAININGS,
  UPDATE_PROGRESSING,
  UPDATE_TRAININGS,
} from 'modules/core/store/actions/trainingsActions';

import { LOGOUT, LOGIN } from 'modules/auth/store/actions/authActions';

const emptyInitialState = {
  trainings: [],
  loading: false,
  trainingsTotalItems: 9999,
  // training that is currently editing in admin training editor
  editing: undefined,
  // training that is currently being completing by user
  progressing: undefined,
};

const trainingsReducer = (state = emptyInitialState, action) => {
  switch (action.type) {
    case SET:
      return {
        ...state,
        trainings: [...(action.clear ? [] : state.trainings), ...action.data],
        trainingsTotalItems: action.trainingsTotalItems,
      };
    case EDIT:
      return {
        ...state,
        trainings: state.trainings.map((training) => {
          if (training.id === action.id) {
            return action.training;
          }
          return training;
        }),
      };
    case DELETE:
      return {
        ...state,
        trainings: [],
        trainingsTotalItems: 9999,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: action.value,
      };
    case SET_EDITING:
      return {
        ...state,
        editing: action.training,
      };
    case SET_CURRENT_LESSON_NUMBER:
      return {
        ...state,
        currentLessonNumber: action.number,
      };
    case CREATE:
      return {
        ...state,
        trainings: [],
        trainingsTotalItems: 9999,
        editing: undefined,
        currentLessonNumber: undefined,
        error: undefined,
      };
    case CLEAR_TRAININGS:
      return {
        ...state,
        trainings: [],
        trainingsTotalItems: 9999,
      };
    case CANCEL:
      return {
        ...state,
        editing: undefined,
        currentLessonNumber: undefined,
        error: undefined,
      };
    case LOGOUT:
      return emptyInitialState;
    case LOGIN:
      return emptyInitialState;
    case EDIT_LESSON:
      let updated = false;
      let timeChanged = false;
      let mappedLessons = state.editing.lessons.map((lesson, i) => {
        if (i + 1 === action.orderNumber) {
          updated = true;
          /* eslint-disable */
          if (lesson.timeInMin != action.lesson.timeInMin) {
            timeChanged = true;
          }
          return action.lesson;
        }
        return lesson;
      });
      if (!updated) {
        mappedLessons.splice(action.orderNumber - 1, 0, action.lesson);
      }
      let timeInMin = state.editing.timeInMin;

      if (!updated || timeChanged) {
        timeInMin = mappedLessons.reduce(
          (acc, lesson) => acc + Number(lesson.timeInMin),
          0
        );
      }

      return {
        ...state,

        editing: { ...state.editing, timeInMin, lessons: mappedLessons },
      };
    case INSERT_LESSON:
      const newLessons = state.editing.lessons.map((l) => {
        if (l.orderNumber > action.orderNumber) {
          l.orderNumber = l.orderNumber + 1;
        }
        return l;
      });
      newLessons.splice(action.orderNumber, 0, {
        name: '',
        timeInMin: 0,
        orderNumber: action.orderNumber + 1,
        lessonTopics: [{ lessonContentParts: [], timeInMin: 0, name: '' }],
      });
      return { ...state, editing: { ...state.editing, lessons: newLessons } };
    case SET_ERROR:
      return {
        ...state,
        error: action.error,
      };
    case REFRESH_TRAININGS:
      return {
        ...state,
        trainings: [] /* state.trainings.map((t) =>
          t.id === action.id ? { ...t, paid: true } : t
        ),*/,
        trainingsTotalItems: 9999,
      };
    case UPDATE_PROGRESSING:
      return {
        ...state,
        progressing: { ...state.progressing, ...action.progressing },
      };
    case UPDATE_TRAININGS:
      return {
        ...state,
        trainings: action.trainings,
      };

    default:
      return state;
  }
};

export default trainingsReducer;
