import ApiClient from 'modules/core/utils/ApiClient';

export const SET_PRODUCTS = 'SET_PRODUCTS';
export const SET_PRODUCTS_LOADING = 'SET_PRODUCTS_LOADING';
export const EDIT_PRODUCT = 'EDIT_PRODUCT';
export const CREATE_PRODUCT = 'CREATE_PRODUCT';
export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const SET_PRODUCT = 'SET_PRODUCT';

const setProducts = (data, totalItems, clear, categoryId) => ({
  type: SET_PRODUCTS,
  data,
  totalItemsInCategory: totalItems,
  categoryId,
  clear,
});

export const editProduct = (id, product, categoryId) => ({
  type: EDIT_PRODUCT,
  id,
  product,
  categoryId,
});
export const setProduct = (id, product, categoryId) => ({
  type: SET_PRODUCT,
  id,
  product,
  categoryId,
});

export const fetchProducts = (pageSize, categoryId) => {
  return async (dispatch, getState) => {
    const state = getState();
    if (
      state.products.loading ||
      (state.products.products[categoryId] &&
        state.products.products[categoryId].length >=
          state.products.totalItemsInCategory[categoryId])
    ) {
      return;
    }
    dispatch({ type: SET_PRODUCTS_LOADING, value: true });
    const length = state.products.products[categoryId]?.length ?? 0;

    const shouldClear = length < pageSize;
    const page = shouldClear ? 0 : (length / pageSize) ^ 0;
    const response = await ApiClient.fetchProducts(
      page + 1,
      pageSize,
      categoryId
    );
    dispatch(
      setProducts(
        response.entities,
        response.totalItems,
        shouldClear,
        categoryId
      )
    );
    dispatch({ type: SET_PRODUCTS_LOADING, value: false });
  };
};

export const fetchFullProduct = (productId, categoryId) => {
  return async (dispatch, getState) => {
    const response = await ApiClient.fetchFullProduct(productId);
    // console.log('action', productId, categoryId, response);
    dispatch(setProduct(productId, response, categoryId));
    return response;
  };
};

export const startEditProduct = (product, categoryId) => {
  return async (dispatch) => {
    const response = await ApiClient.updateProduct(product);
    dispatch(editProduct(product.id, product, categoryId));
    return response;
  };
};

export const startDeleteProduct = (id, categoryId) => {
  return async (dispatch) => {
    dispatch({ type: SET_PRODUCTS_LOADING, value: true });
    try {
      await ApiClient.deleteProduct(id);

      dispatch({ type: DELETE_PRODUCT, id, categoryId });
    } catch (err) {
      console.log(err, err.response);
    } finally {
      dispatch({ type: SET_PRODUCTS_LOADING, value: false });
    }
  };
};

export const startCreateProduct = (product, categoryId) => {
  return async (dispatch) => {
    await ApiClient.createProduct(product);
    dispatch({ type: CREATE_PRODUCT, categoryId });
  };
};
