import React from 'react';
import PropTypes from 'prop-types';
import { NavLink, Spinner } from 'react-bootstrap';
import moment from 'moment';
import { useDispatch } from 'react-redux';

import { fetchFullPost } from 'modules/core/store/actions/postsActions';
import { postRouts } from 'modules/posts/routs/constants';
import Share from 'modules/core/components/Share';
import ContentItem from 'modules/core/components/ContentItem';
import AuthImage from 'modules/core/components/AuthImage';

import './styles.scss';
import noPhotoImg from 'assets/images/no_photo.png';

const LargePost = ({ post, showPostsLink }) => {
  const [loading, setLoading] = React.useState(false);

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!post.postItems && !loading) {
      console.log('posts is', post);
      setLoading(true);
      dispatch(fetchFullPost(post.id)).then(() => {
        setLoading(false);
      });
    }
  }, [post, dispatch, loading]);

  const shareLink = React.useMemo(() => {
    return `${window.location.protocol}//${window.location.host}/posts/${post.id}`;
  }, [post]);

  const postItems = React.useMemo(
    () =>
      post.postItems
        ?.sort((a, b) => a.orderNumber - b.orderNumber)
        .map((postItem, i) => (
          <div key={postItem.id}>
            <ContentItem {...postItem} />
            {postItem.pictureUrl && i === post.postItems.length - 1 && (
              <div className="LargePost__bottom" />
            )}
          </div>
        )),
    [post]
  );

  const controls = React.useMemo(
    () => (
      <div className="LargePost__postControls">
        <span className="LargePost__date">
          {moment(new Date(post.createdAt)).format('L')}
        </span>
        <Share
          shareLink={shareLink}
          invertColor
          scaleable
          shareText={post.heading}
        />
      </div>
    ),
    [shareLink, post]
  );

  return (
    <div className="LargePost__root">
      {post.image ? (
        <AuthImage controllerPath="posts" hash={post.image}>
          <img className="LargePost__image" alt="postimage" />
        </AuthImage>
      ) : (
        <img className="LargePost__noImage" src={noPhotoImg} alt="postimage" />
      )}

      <div className="LargePost__contents">
        <h1 className="LargePost__title">{post.heading}</h1>
        {controls}

        {showPostsLink && (
          <NavLink className="LargePost__showMorePosts" href={postRouts.root}>
            Show more posts
          </NavLink>
        )}
        <p className="LargePost__text">{post.postText}</p>
      </div>

      {postItems}
      {loading && (
        <div className="LargePost__spinnerContainer">
          <Spinner animation="border" />
        </div>
      )}

      {post?.postItems?.length > 0 && (
        <div className="LargePost__bottomControls">{controls}</div>
      )}
    </div>
  );
};

LargePost.propTypes = {
  post: PropTypes.object.isRequired,
};

export default LargePost;
