export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
export const SET_IS_READ = 'SET_IS_READ';

export const setNotifications = (notifications) => {
  return {
    type: SET_NOTIFICATIONS,
    notifications,
  };
};

export const setIsRead = (id) => {
  return {
    type: SET_IS_READ,
    id
  };
};
