import Compressor from 'compressorjs';

class ImageCompressor {
  static async compress(file) {
    return new Promise((resolve, reject) => {
      new Compressor(file, {
        quality: 0.8,
        success(result) {
          resolve(
            new File([result], file.name, {
              type: file.type,
              lastModified: file.lastModified,
            })
          );
        },
        error(err) {
          reject(err);
        },
      });
    });
  }
}

export default ImageCompressor;
