import React from 'react';
import Container from "../../../core/components/Container";
import './styles.scss';
import {Button} from "react-bootstrap";
import {Link, useParams} from "react-router-dom";
import {careerRoutes} from "../../routes/constants";
import {stepsPath} from "../../constants";

const checklistArray = [
    'Application',
    'Obtaining and acknowledging the Forstter Youth Housing Staff Handbook',
    'Corporal Punishment Policy',
    'Abuse/Neglect Reporting Policy',
    'Health Assessment Form (if you do not have a health assessment done by a doctor, make an appointment).',
    'Current TB skin test',
    'Driving license (copy)',
    'First aid/CPR (If you do not have current first aid/CPR, check one of the following.',
]

const CareerViewChecklist = () => {
    const {id} = useParams()
    return (
        <Container className={'CareerViewChecklist__root'}>
            <div className={'CareerViewChecklist__container'}>
                <h2>File Completion Checklist </h2>
                <p>
                    To complete your file, I need the following items. Please return this folder along with the required
                    items. Thank you for your prompt response.
                </p>
                <p>
                    Filling out the form may take 20-30 minutes.
                </p>
                {/*    Checklist Box*/}
                <div className={'CareerViewChecklist__checklist-container'}>
                    {/*    List*/}
                    <ul>
                        {checklistArray.map((it, index) => (
                            <li key={index.toString()}>
                                {`${index + 1}. ${it}`}
                            </li>
                        ))}
                    </ul>

                    <Button
                        replace
                        to={{
                            pathname: careerRoutes.stepFn(id, stepsPath.firstStep),
                            state: {activeStep: 0}
                        }}
                        as={Link}
                        className={'CareerViewChecklist__button'}>
                        Start
                    </Button>

                </div>
            </div>
        </Container>
    );
};

export default CareerViewChecklist;
