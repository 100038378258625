import React from 'react';
import { Spinner } from 'react-bootstrap';

import './styles.scss';

const WithLoading = ({ children, loading, className = '', style, ...rest }) => {
  return !loading ? (
    <>{children}</>
  ) : (
    <div className={'WithLoading__root ' + className} style={style}>
      <Spinner animation="border" size="lg" />
    </div>
  );
};

export default WithLoading;
