import isNumeric from 'validator/lib/isNumeric';

class StripeHelper {
  _self = null;

  // constructor() {}

  get self() {
    if (!this._self) {
      /*eslint no-undef: 0*/
      this._self = Stripe(process.env.STRIPE_KEY);
    }
    return this._self;
  }

  async verifyPayment(paymentMethodId, clientSecret) {
    return await this.self.confirmCardPayment(clientSecret, {
      payment_method: paymentMethodId,
    });
  }
}

export const stripe = new StripeHelper();

export const isCardDate = (value) => {
  const split = value.split('/');
  const m = Number(split[0]);
  return m < 13 && m > 0;
};

export const createExpDateHandler = (value, setValue) => {
  return (e) => {
    if (!isNumeric(e.target.value.replaceAll('/', '')) && e.target.value !== '')
      return;

    if (e.target.value.length === 2 && value.length < e.target.value.length) {
      setValue(e.target.value + '/');
    } else {
      setValue(e.target.value);
    }
  };
};

export const createCardInputHandler = (setValue) => {
  return (e) => {
    const entered = e.target.value.trim();
    if (entered !== '' && !isNumeric(entered)) return;

    setValue(entered);
  };
};
