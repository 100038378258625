import React from 'react';

export const prettifyError = (err, fallback) => {
  console.log(err, err.response);
  const calculated = err?.response?.data?.errors
    ? Object.values(err?.response?.data?.errors).join(' ')
    : err?.response?.statusText;
  if (calculated !== '[object Object]') return calculated;

  return (
    Object.values(err.response.data.errors)
      .map((e) => e.message)
      .join(' ') ||
    fallback ||
    'Form filled out incorrectly.'
  );
};

export const prettifyErrorToJSX = (err, { startFromNewLine = false } = {}) => {
  console.log(err, err.response);

  return (
    <>
      {Object.values(err.response.data.errors).map((e) => (
        <>
          {e.message ? (
            <>
              {e.message} {startFromNewLine && <br />}
            </>
          ) : (
            e?.map((message) => (
              <>
                <p>{message + ' '}</p> {startFromNewLine && <br />}
              </>
            )) ?? 'Unexpected server error.'
          )}
        </>
      ))}
    </>
  );
};
