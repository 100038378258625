import { postEditorRoutes } from 'modules/posts-editor/routes/constants';
import { donationsTabelRoutes } from 'modules/donations-table/routes/constants';
import { settingsRoutes } from 'modules/settings/routes/constants';
import { storeOrdersRoutes } from 'modules/store-orders/routes/constants';
import { adminCareerRoutes } from 'modules/admin-career/routes/constants';
import { adminTrainingsRoutes } from 'modules/admin-trainings/routes/constants';
import { adminStaffRoutes } from 'modules/admin-staff/routes/constants';
import { adminResidentsRoutes } from 'modules/admin-residents/routes/constants';
import { adminFacilityRoutes } from 'modules/admin-facility/routes/constants';

class SecurityGroupService {
  groupsPermissionsMap;
  permissionsPagesMap;

  static getGroupPermissions(group) {
    let groupName;
    if (typeof group === 'object') {
      groupName = group.name;
    } else groupName = group;

    return SecurityGroupService.groupsPermissionsMap.get(groupName);
  }

  static getGroupsPermissions(groups) {
    if (!Array.isArray(groups)) {
      throw new Error('Groups must be an array.');
    }
    return groups.reduce(
      (acc, group) => [
        ...acc,
        ...SecurityGroupService.getGroupPermissions(group),
      ],
      []
    );
  }

  /**
   * Checks does security group/groups contains specified permission
   * (P.S. Superadmin has "Superadmin" permission)
   * @param {Array<string> | string} group security group or array of them
   * @param {string} permission
   */
  static containsPermission(group, permission) {
    if (Array.isArray(group)) {
      return !!SecurityGroupService.getGroupsPermissions(group)?.find(
        (p) => p === permission
      );
    }
    return !!SecurityGroupService.groupsPermissionsMap
      .get(group)?.find((p) => p === permission);
  }

  /**
   * Checks is facility required to create a user with this security group
   * @param {string} group
   */
  static needsFacility(group) {
    return !!this.facilityRequiredMap.get(group);
  }

  static getPagePathByPermission(permission) {
    return this.permissionsPagesMap.get(permission);
  }

  static getFirstGroupsPermissionPagePath(groups) {
    const permissions = this.getGroupsPermissions(groups);
    const pagePath = this.getPagePathByPermission(permissions?.[0]);

    if (!pagePath) {
      return settingsRoutes.root;
    }
    return pagePath;
  }
}
SecurityGroupService.groupsPermissionsMap = new Map([
  [
    'Superadmin',
    [
      'Posts',
      'Donation',
      'Store',
      'Trainings',
      'Career',
      'Residents',
      'Staff',
      'Reports',
      'Facilities',
      'Superadmin',
    ],
  ],
  ['Posts', ['Posts']],
  ['Donation', ['Donation']],
  ['Store', ['Store']],
  ['Trainings', ['Trainings']],
  ['Career', ['Career']],
  ['Residents', ['Residents']],
  ['Staff', ['Staff']],
  ['Facilities', ['Facilities']],
]);

SecurityGroupService.permissionsPagesMap = new Map([
  ['Posts', postEditorRoutes.root],
  ['Donation', donationsTabelRoutes.root],
  ['Store', storeOrdersRoutes.root],
  ['Trainings', adminTrainingsRoutes.root],
  ['Career', adminCareerRoutes.root],
  ['Residents', adminResidentsRoutes.root],
  ['Staff', adminStaffRoutes.root],
  ['Facilities', adminFacilityRoutes.root],
]);

SecurityGroupService.facilityRequiredMap = new Map([
  ['Residents', true],
  ['Facilities', true],
  ['Staff', true],
]);

export default SecurityGroupService;
