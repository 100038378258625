import * as yup from 'yup'
import {ErrorMessages} from "./messages";
import {checkYear} from "../utils/checkYear";
import moment from "moment";

export const fourthStepSchema = yup.object().shape({
    fullName: yup.string().required(ErrorMessages.REQUIRED),
    contactPerson: yup.string().required(ErrorMessages.REQUIRED),
    phone: yup.string().required(ErrorMessages.REQUIRED),
    agencyName: yup.string().required(ErrorMessages.REQUIRED),
    agencyMailingAddress: yup.string().required(ErrorMessages.REQUIRED),
    emailAddress: yup.string().email(ErrorMessages.EMAIL_INVALID).required(ErrorMessages.REQUIRED),
    maidenName: yup.string().required(ErrorMessages.REQUIRED),
    streetAddress: yup.string().required(ErrorMessages.REQUIRED),
    city: yup.string().required(ErrorMessages.REQUIRED),
    state: yup.string().required(ErrorMessages.REQUIRED),
    zipCode: yup.number().required(ErrorMessages.REQUIRED).typeError(ErrorMessages.ONLY_DIGITS)
        .test('len', ErrorMessages.MAX_LENGTH, val => val && val.toString().length <= 10)
    ,
    dateOfBirth: yup.string().required(ErrorMessages.REQUIRED).test('DOB', ErrorMessages.DATE_INVALID, (value) => {
        return checkYear(value)
    }),
    ss: yup.string().required(ErrorMessages.REQUIRED),
    isMale: yup.boolean(ErrorMessages.REQUIRED).required(ErrorMessages.REQUIRED),
    confidentialUseInformation: yup.boolean().oneOf([true], ErrorMessages.REQUIRED),
    releaseAnyInformation: yup.boolean().oneOf([true], ErrorMessages.REQUIRED),
    signature: yup.string().required(ErrorMessages.REQUIRED),
    signatureDate: yup.string().required(ErrorMessages.REQUIRED),
    officialUserProhibited: yup.string().required(ErrorMessages.REQUIRED),
    officialUseCleaned: yup.string().required(ErrorMessages.REQUIRED),
    abusePrintedName: yup.string().required(ErrorMessages.REQUIRED),
    abusePosition: yup.string().required(ErrorMessages.REQUIRED),
    abuseSignature: yup.string().required(ErrorMessages.REQUIRED),
    abuseDate: yup.string().required(ErrorMessages.REQUIRED),
    abilityRestraintApproach: yup.boolean().oneOf([true], ErrorMessages.REQUIRED),
    moreRestraintTechniques: yup.boolean().oneOf([true], ErrorMessages.REQUIRED),
    disciplineFirstName: yup.string().required(ErrorMessages.REQUIRED),
    disciplineFirstPosition: yup.string().required(ErrorMessages.REQUIRED),
    disciplineFirstSignature: yup.string().required(ErrorMessages.REQUIRED),
    disciplineFirstDate: yup.string().required(ErrorMessages.REQUIRED).test('checkDate', ErrorMessages.INVALID_DATE, (value) => {
        return moment(value).isSameOrAfter(new Date().toDateString())
    }),
    disciplineSecondName: yup.string().required(ErrorMessages.REQUIRED),
    disciplineSecondPosition: yup.string().required(ErrorMessages.REQUIRED),
    disciplineSecondSignature: yup.string().required(ErrorMessages.REQUIRED),
    disciplineSecondDate: yup.string().required(ErrorMessages.REQUIRED),

})
