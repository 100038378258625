import React from 'react';
import {
  Container as BootstrapContainer,
  Row,
  Col,
  Carousel,
} from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import ApiClient from 'modules/core/utils/ApiClient';
import { fetchPosts } from 'modules/core/store/actions/postsActions';
import { postRouts } from 'modules/posts/routs/constants';
import { BreakpointContextConsumer } from 'modules/core/contexts/BreakpointContext';
import Container from 'modules/core/components/Container';
import LandscapeImage from 'modules/core/components/LandscapeImage';
import DonationProgressbar from 'modules/donate/components/DonationProgressbar';
import Post from 'modules/core/components/Post';
import DonationPopup from 'modules/donate/components/DonationPopup';
import { stripe } from 'modules/core/utils/cardHelpers';

import Carousel_1Img from 'assets/images/carousel/DonateCarousel_1.jpeg';
import Carousel_1ImgDesktop from 'assets/images/carousel/DonateCarousel_1-desktop.jpeg';
import Carousel_2Img from 'assets/images/carousel/DonateCarousel_2.jpg';
import Carousel_2ImgDesktop from 'assets/images/carousel/DonateCarousel_2-desktop.jpg';
import Carousel_3Img from 'assets/images/carousel/DonateCarousel_5.jpg';
import Carousel_3ImgDesktop from 'assets/images/carousel/DonateCarousel_5.jpg';
import Carousel_4Img from 'assets/images/carousel/DonateCarousel_4.jpg';
import Carousel_4ImgDesktop from 'assets/images/carousel/DonateCarousel_4.jpg';

import './styles.scss';

const carouselItems = [
  {
    image: {
      mobile: Carousel_1Img,
      desktop: Carousel_1ImgDesktop,
    },
    text: 'Piano Lessons',
  },
  {
    image: {
      mobile: Carousel_2Img,
      desktop: Carousel_2ImgDesktop,
    },
    text: 'Coding Classes',
  },
  {
    image: {
      mobile: Carousel_3Img,
      desktop: Carousel_3ImgDesktop,
    },
    text: 'Dancing classes',
  },
  {
    image: {
      mobile: Carousel_4Img,
      desktop: Carousel_4ImgDesktop,
    },
    text: 'Gymnastics classes',
  },
];

const DonatePage = () => {
  const [carouselActiveIndex, setCarouselActiveIndex] = React.useState(0);
  const [popupShown, setPopupShown] = React.useState(false);
  const [progressBarStatusLoaded, setProgressbarStatusLoaded] = React.useState(
    false
  );
  const [progressBarCurrent, setProgressBarCurrent] = React.useState(0);
  const [progressBarMax, setProgressBarMax] = React.useState(10000);

  const { posts, postsLoading } = useSelector((state) => ({
    posts: state.posts.posts.slice(0, 2),
    postsLoading: state.posts.loading,
  }));
  const dispatch = useDispatch();

  /*eslint no-undef: 0*/
  // const stripe = React.useRef(null);

  React.useEffect(() => {
    if (posts.length < 2 && !postsLoading) {
      dispatch(fetchPosts(1, 2 - posts.length));
    }
  }, [posts, dispatch, postsLoading]);

  React.useEffect(() => {
    setTimeout(() => {
      setCarouselActiveIndex((carouselActiveIndex + 1) % carouselItems.length);
    }, 5000);
  }, [carouselActiveIndex]);

  React.useEffect(() => {
    if (progressBarStatusLoaded) return;

    (async () => {
      try {
        const resp = await ApiClient.getDonationProgress();
        console.log(resp);
        if (resp.ok) {
          const data = await resp.json();
          console.log(data);
          setProgressBarMax(data.value.amount);
          setProgressBarCurrent(data.value.amountDonated);
        }
      } catch (err) {
        console.log(err);
      }

      setProgressbarStatusLoaded(true);
    })();
  }, [progressBarStatusLoaded]);

  const handleDonationPopupToggle = React.useCallback(() => {
    setPopupShown(!popupShown);
  }, [popupShown]);

  const submitDontationPopupHandler = React.useCallback(async (donation) => {
    const requestData = {
      amount: Number(donation.moneyAmount) * 100,
      donor: {
        fullName: donation.fullName,
        address: donation.address,
        city: donation.city,
        state: donation.state,
        country: donation.country,
        zipCode: donation.zipcode,
        email: donation.email,
      },

      comment: donation.comment,
    };

    if (donation.existingPaymentMethod) {
      requestData.paymentMethodId = donation.existingPaymentMethod.id;
    } else {
      const expDateSplit = donation.expDate.split('/');
      requestData.card = {
        number: donation.cardNumber,
        expMonth: Number(expDateSplit[0]),
        expYear: Number(expDateSplit[1]),
        cvc: donation.cvcCode,
      };
      requestData.savePaymentMethod = donation.savePaymentMethod;
    }
    try {
      console.log(requestData, donation);
      const resp = await ApiClient.createDontaion(requestData);

      if (resp.status === 200) {
        const response = resp.data;
        if (response.success) {
          console.log(response.value);
          const { clientSecret, paymentMethodId } = response.value;
          // if (!stripe.current) {
          //   stripe.current = Stripe(process.env.STRIPE_KEY);
          // }
          // const result = await stripe.current.confirmCardPayment(clientSecret, {
          //   payment_method: paymentMethodId,
          // });
          const result = await stripe.verifyPayment(
            paymentMethodId,
            clientSecret
          );
          console.log(result);
          if (!result.error) {
            setTimeout(() => {
              setProgressbarStatusLoaded(false);
            }, 3000);
          } else {
            return result.error;
          }
        }
      } else console.log(await resp.json());
    } catch (err) {
      console.error(err);
      return err;
    }
  }, []);

  const handleLinkClick = React.useCallback(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <BreakpointContextConsumer>
      {({ isMobile, breakpoint }) => (
        <Container
          className="DonatePage__root"
          paddingHorizontalDesktop={'210px'}
        >
          <DonationPopup
            show={popupShown}
            onClose={handleDonationPopupToggle}
            onSubmit={submitDontationPopupHandler}
          />
          <Carousel
            className="DonatePage__carousel"
            activeIndex={carouselActiveIndex}
            interval={null}
            controls={false}
            indicators={false}
          >
            {carouselItems.map((item, index) => (
              <Carousel.Item key={index}>
                <LandscapeImage
                  src={
                    breakpoint === 'lg' ? item.image.desktop : item.image.mobile
                  }
                  className="DonatePage__imgContainer"
                  imgClassName={'DonatePage__kidImg'}
                />
              </Carousel.Item>
            ))}
          </Carousel>
          <BootstrapContainer fluid className="DonatePage__content">
            <Row className="DonationPage__topRow">
              <Col
                sm={breakpoint === 'md' || isMobile ? 12 : 4}
                className="DontaionPage__topColumns"
              >
                <div className="DonatePage__yellowBox" />

                <h1 className="DonatePage__title">
                  Help kids in Kansas{isMobile && <br />}
                  <div className="DonatePage__titleItems">
                    attend
                    {!isMobile ? (
                      <br />
                    ) : (
                      <span style={{ minWidth: '0.5rem' }} />
                    )}
                    <Carousel
                      activeIndex={carouselActiveIndex}
                      interval={null}
                      controls={false}
                      indicators={false}
                      fade
                    >
                      {carouselItems.map((item, index) =>
                        !isMobile ? (
                          <Carousel.Item key={index}>
                            {item.text.split(' ')[0]}
                            <br />
                            {item.text.split(' ')[1]}
                            <span className="DonatePage__dot">.</span>
                          </Carousel.Item>
                        ) : (
                          <Carousel.Item
                            key={index}
                            className="DonatePage__cauruselTextContainerMobile"
                          >
                            {item.text}
                            <span className="DonatePage__dot">.</span>
                          </Carousel.Item>
                        )
                      )}
                    </Carousel>
                  </div>
                </h1>
              </Col>
              <Col
                sm={breakpoint === 'md' || isMobile ? 12 : 8}
                className="DontaionPage__topColumns"
              >
                <DonationProgressbar
                  value={progressBarCurrent / 100}
                  max={progressBarMax / 100}
                  onDonation={handleDonationPopupToggle}
                  shareText={`Help kids in Kansas attend ${carouselItems[carouselActiveIndex].text}`}
                />
              </Col>
            </Row>

            <div className="DonatePage__secondRow">
              <h2 className="DonatePage__subtitle">
                Blog <span className="DonatePage__subtitleAnd">&</span> News
                updates
              </h2>
              <div className="DonatePage__textContainer">
                <p className="DonatePage__text">
                  Get more updates on what we are doing and how we are helping
                  serve our community
                </p>
                {!isMobile && (
                  <Link
                    className="DonatePage_showMoreLink"
                    to={postRouts.root}
                    onClick={handleLinkClick}
                  >
                    Show more posts
                  </Link>
                )}
              </div>
            </div>
            <div className="DonatePage__posts">
              <div className="DonatePage__post">
                {posts[0] && (
                  <Post post={posts[0]}>
                    <Link
                      className="DonatePage__readMore"
                      to={postRouts.root + '/' + posts[0].id}
                      onClick={handleLinkClick}
                    >
                      Read more
                    </Link>
                  </Post>
                )}
              </div>
              <div className="DonatePage__postGap" />
              <div className="DonatePage__post">
                {posts[1] && (
                  <Post post={posts[1]}>
                    <Link
                      className="DonatePage__readMore"
                      to={postRouts.root + '/' + posts[1].id}
                      onClick={handleLinkClick}
                    >
                      Read more
                    </Link>
                  </Post>
                )}
              </div>
            </div>

            {isMobile && (
              <Link
                onClick={handleLinkClick}
                className="DonatePage_showMoreLink"
                to={postRouts.root}
              >
                Show more posts
              </Link>
            )}
          </BootstrapContainer>
        </Container>
      )}
    </BreakpointContextConsumer>
  );
};

export default DonatePage;
