import React, {useEffect, useMemo} from 'react';
import './styles.scss'
import {Formik, Form, Field} from 'formik'
import {Row, Col, Button, Spinner} from "react-bootstrap";
import LabeledFormControl from "../../../../core/components/FormControl";
import CustomRadio from "../../../../core/components/CustomRadio";
import DatePicker from "../../../../admin/components/DatePicker";
import PhoneInput from "../../../../admin/components/PhoneInput";
import {
    firstStepValues,
    HighestLevelEquivalent,
    STEPS,
    TypeOfEmployment, YesOrNoValues
} from "../../../constants";
import {firstStepSchema} from "../../../validation";
import FormikError from "../../../../admin/components/FormikError";
import {useHistory, useLocation, useParams} from "react-router-dom";
import Stepper from "../../Stepper";
import {careerRoutes} from "../../../routes/constants";
import {useDispatch, useSelector} from "react-redux";
import {getFirstStep, updateFirstStep} from "../../../../core/store/actions/careerActions";
import LoadingActionButton from "../../LoadingButton";
import CustomPrompt from "../../CustomPrompt";

export const CareerApplyFirstStep = () => {
    const {id} = useParams()
    const location = useLocation()
    const history = useHistory()
    const dispatch = useDispatch()
    const {loading, stepLoading, stepValues} = useSelector((state) => state.career)
    const onSubmit = (values) => {
        const payload = {
            ...values,
            startDateTime: values?.startDateTime.toLocaleDateString('en-US'),
            applicationId: Number(id)
        }
        dispatch(updateFirstStep(payload))
    }
    const onPrev = () => {
        history.push(careerRoutes.root)
    }
    useEffect(() => {
        if (id) {
            dispatch(getFirstStep(id))
        }
    }, [dispatch, id])
    const mappedValues = useMemo(() => {
        if (stepValues?.lastName) {
            return {
                ...stepValues,
                startDateTime: stepValues?.startDateTime ? new Date(stepValues.startDateTime) : ''
            }

        } else {
            return firstStepValues
        }

    }, [stepValues])

    return (
        <>
            {
                stepLoading ? <div className={'text-center'}>
                        <Spinner animation="border"/>
                    </div>
                    :
                    <>
                        <Stepper activeStep={location.state?.activeStep} steps={STEPS}/>
                        <h3>Forstter Youth Residential Center, Inc.</h3>
                        <Formik
                            enableReinitialize
                            validationSchema={firstStepSchema}
                            initialValues={{...mappedValues}}
                            onSubmit={onSubmit}
                            render={({handleSubmit, values, setFieldValue, dirty, submitCount}) => {
                                return (
                                    <>
                                        <Form onSubmit={handleSubmit}>
                                            <div className={'FirstStep__wrapper'}>
                                                <Row className={'mb-4'}>
                                                    <Col sm>
                                                        <Field
                                                            name={'lastName'}
                                                            label={'Last Name'}
                                                            placeholder={'Enter'}
                                                            component={LabeledFormControl}
                                                        />
                                                        <FormikError name="lastName"/>
                                                    </Col>
                                                    <Col sm>
                                                        <Field
                                                            name={'firstName'}
                                                            label={'First Name'}
                                                            placeholder={'Enter'}
                                                            component={LabeledFormControl}
                                                        />
                                                        <FormikError name="firstName"/>
                                                    </Col>
                                                    <Col sm>
                                                        <Field
                                                            name={'middleName'}
                                                            label={'Middle Name'}
                                                            placeholder={'Enter'}
                                                            component={LabeledFormControl}
                                                        />
                                                        <FormikError name="middleName"/>
                                                    </Col>
                                                </Row>
                                                <Row className={'mb-4'}>
                                                    <Col sm className={'mb-3'}>
                                                        <Field
                                                            name={'socialSecurity'}
                                                            label={'Social Security#'}
                                                            placeholder={'Enter'}
                                                            component={LabeledFormControl}
                                                        />
                                                        <FormikError name="socialSecurity"/>
                                                    </Col>
                                                    <Col sm>
                                                        <Field
                                                            name={'streetAddress'}
                                                            label={'Street Address'}
                                                            placeholder={'Enter'}
                                                            component={LabeledFormControl}
                                                        />
                                                        <FormikError name="streetAddress"/>
                                                    </Col>
                                                </Row>
                                                <Row className={'mb-4'}>
                                                    <Col sm className={'mb-3'}>
                                                        <Field
                                                            name={'cityStateZip'}
                                                            label={'City, State and Zip Code'}
                                                            placeholder={'Enter'}
                                                            component={LabeledFormControl}
                                                        />
                                                        <FormikError name="cityStateZip"/>
                                                    </Col>
                                                    <Col sm>
                                                        <Field
                                                            value={values.phoneNumber}
                                                            onChange={(value) => {
                                                                // console.log(e, 'PHONE')
                                                                setFieldValue('phoneNumber', value)
                                                            }}
                                                            name={'streetAddress'}
                                                            label={'Phone Number #'}
                                                            placeholder={'Enter'}
                                                            component={PhoneInput}
                                                        />
                                                        <FormikError name="phoneNumber"/>
                                                    </Col>
                                                </Row>
                                                <Row className={'mb-4'}>
                                                    <Col>
                                                        <Field
                                                            name={'positions'}
                                                            label={'POSITION\'S FOR WHICH YOU WOULD LIKE TO BE CONSIDERED'}
                                                            placeholder={'Enter'}
                                                            component={LabeledFormControl}
                                                        />
                                                        <FormikError name="positions"/>
                                                    </Col>
                                                </Row>
                                                <Row className={'mb-4'}>
                                                    <Col sm>
                                                        <Field
                                                            name={'ratePay'}
                                                            label={'RATE OF EXPECTED PAY'}
                                                            placeholder={'Enter'}
                                                            component={LabeledFormControl}
                                                        />
                                                        <FormikError name="ratePay"/>
                                                    </Col>
                                                    <Col sm>
                                                        <DatePicker
                                                            minDate={new Date()}
                                                            name={'startDateTime'}
                                                            label={'DATE YOU CAN START'}
                                                            yearDropdownItemNumber={100}
                                                            scrollableYearDropdown
                                                            locale="en-US"
                                                            showYearDropdown
                                                            showMonthDropdown
                                                            placeholderText={'mm/dd/yyyy'}
                                                            selected={values.startDateTime}
                                                            onChange={(e) => {
                                                                setFieldValue('startDateTime', e)
                                                            }}
                                                        />
                                                        <FormikError name="startDateTime"/>
                                                    </Col>
                                                </Row>
                                                <div className={'mb-4'}>
                                                    <p>ARE YOU 21 YEARS OF AGE OR OLDER? (THIS FACILITY IS ONLY ABLE TO
                                                        HIRE
                                                        APPLICANTS
                                                        THAT ARE OVER THE AGE OF 21 YEARS OF AGE).</p>
                                                    <div className={'d-flex align-items-center'}>
                                                        <Field
                                                            onChange={(e) => {
                                                                setFieldValue('alreadyTwentyOne', true)
                                                            }}
                                                            defaultChecked={values?.alreadyTwentyOne?.toString() === YesOrNoValues.YES}
                                                            value={YesOrNoValues.YES}
                                                            name={'alreadyTwentyOne'} component={CustomRadio}
                                                            label={'Yes'}/>
                                                        <Field
                                                            onChange={(e) => {
                                                                setFieldValue('alreadyTwentyOne', false)
                                                            }}
                                                            defaultChecked={values?.alreadyTwentyOne?.toString() === YesOrNoValues.NO}
                                                            value={YesOrNoValues.NO}
                                                            name={'alreadyTwentyOne'} className={'ml-4'}
                                                            component={CustomRadio}
                                                            label={'No'}/>
                                                    </div>
                                                    <FormikError name="alreadyTwentyOne"/>

                                                </div>
                                                <div className={'mb-4'}>
                                                    <p>TYPE OF EMPLOYMENT (Note: PART TIME IS LESS THAN 28 HRS A
                                                        WEEK)</p>
                                                    <div className={'d-flex align-items-center'}>
                                                        <Field
                                                            defaultChecked={TypeOfEmployment.PartTime === values.typeOfEmployment}
                                                            onChange={(e) => {
                                                                setFieldValue('typeOfEmployment', Number(e.target.value))
                                                            }}
                                                            value={TypeOfEmployment.PartTime}
                                                            name={'typeOfEmployment'} component={CustomRadio}
                                                            label={'Part Time'}/>
                                                        <Field
                                                            defaultChecked={TypeOfEmployment.FullTime === values.typeOfEmployment}
                                                            onChange={(e) => {
                                                                setFieldValue('typeOfEmployment', Number(e.target.value))
                                                            }}
                                                            value={TypeOfEmployment.FullTime}
                                                            name={'typeOfEmployment'} className={'ml-4'}
                                                            component={CustomRadio}
                                                            label={'Full Time'}/>
                                                    </div>
                                                    <FormikError name="typeOfEmployment"/>
                                                </div>
                                                <div className={'mb-4'}>
                                                    <p>CHECK THE HIGHEST LEVEL OR EQUIVALENT COMPLETED:</p>
                                                    <div className={'level-container'}>
                                                        <Field
                                                            defaultChecked={HighestLevelEquivalent.HighSchool === values.hightestLevelEquivalent}
                                                            value={HighestLevelEquivalent.HighSchool}
                                                            onChange={(e) => {
                                                                setFieldValue('hightestLevelEquivalent', Number(e.target.value))
                                                            }}
                                                            name={'hightestLevelEquivalent'}
                                                            component={CustomRadio} label={'High School'}/>
                                                        <Field
                                                            defaultChecked={HighestLevelEquivalent.College === values.hightestLevelEquivalent}
                                                            onChange={(e) => {
                                                                setFieldValue('hightestLevelEquivalent', Number(e.target.value))
                                                            }}
                                                            value={HighestLevelEquivalent.College}
                                                            name={'hightestLevelEquivalent'}
                                                            className={'ml-sm-0 ml-md-4'} component={CustomRadio}
                                                            label={'College'}/>
                                                        <Field
                                                            defaultChecked={HighestLevelEquivalent.CurrentlyStudent === values.hightestLevelEquivalent}
                                                            onChange={(e) => {
                                                                setFieldValue('hightestLevelEquivalent', Number(e.target.value))
                                                            }}
                                                            value={HighestLevelEquivalent.CurrentlyStudent}
                                                            name={'hightestLevelEquivalent'}
                                                            className={'ml-sm-0 ml-md-4'} component={CustomRadio}
                                                            label={'Currently a student'}/>
                                                    </div>
                                                    <FormikError name="hightestLevelEquivalent"/>
                                                </div>
                                                <Row className={'mb-4'}>
                                                    <Col>
                                                        <Field
                                                            name={'nameOfCollege'}
                                                            label={'NAME OF COLLEGE, UNIVERSTY OR TECH ATTENDED'}
                                                            placeholder={'Enter'}
                                                            component={LabeledFormControl}
                                                        />
                                                        <FormikError name="nameOfCollege"/>
                                                    </Col>
                                                </Row>
                                                <div>
                                                    <p>ARE YOU A PRESENT MEMBER OF THE NATIONAL GUARD OR RESERVES?</p>
                                                    <div className={'d-flex align-items-center'}>
                                                        <Field
                                                            defaultChecked={values?.isMember?.toString() === YesOrNoValues.YES}
                                                            value={YesOrNoValues.YES}
                                                            onChange={(e) => {
                                                                setFieldValue('isMember', true)
                                                            }}
                                                            name={'isMember'} component={CustomRadio} label={'Yes'}/>
                                                        <Field
                                                            onChange={(e) => {
                                                                setFieldValue('isMember', false)
                                                            }}
                                                            defaultChecked={values?.isMember?.toString() === YesOrNoValues.NO}
                                                            value={YesOrNoValues.NO}
                                                            name={'isMember'} className={'ml-4'} component={CustomRadio}
                                                            label={'No'}/>
                                                    </div>
                                                    <FormikError name="isMember"/>
                                                </div>
                                            </div>
                                            <div className={'Step__footer'}>
                                                <Button
                                                    onClick={onPrev}
                                                    className={'btn-outline-primary Step__action-button'}>
                                                    Back
                                                </Button>
                                                <LoadingActionButton loading={loading} text={'Next'}/>
                                            </div>
                                        </Form>
                                        <CustomPrompt when={dirty && submitCount === 0}/>
                                    </>
                                )
                            }}
                        />
                    </>
            }
        </>

    );
};

export default CareerApplyFirstStep;
