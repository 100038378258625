import { adminRoutes } from 'modules/admin/routes/constants';

export const adminTrainingsRoutes = {
  root: `${adminRoutes.root}/trainings`,
  trainings: `${adminRoutes.root}/trainings/trainings`,
  students: `${adminRoutes.root}/trainings/students`,
  trainingEditor: `${adminRoutes.root}/trainings/trainings/editor`,
  newTraining: `${adminRoutes.root}/trainings/trainings/editor/new`,
  editTraining: `${adminRoutes.root}/trainings/trainings/editor/:id`,
  editLesson: `${adminRoutes.root}/trainings/trainings/editor/:id/:lessonOrderNumber`,
  test: `${adminRoutes.root}/trainings/trainings/editor/:id/:lessonOrderNumber/test`,
};
