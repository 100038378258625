import React, { useCallback } from 'react';
import { Button, Dropdown } from 'react-bootstrap';
import PlayIcon from '../../../../assets/icons/PlayIcon';
import PauseIcon from '../../../../assets/icons/PauseIcon';
import './styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useStopwatch } from 'react-timer-hook';
import { usePrevious } from '../../hooks/usePrevious';
import { startTimer, stopTimer } from '../../store/actions/trackerActions';
import { setShowIsTurnOfModal, setShowIsTurnOnModal } from '../../store/reducers/trackerReducer';
import getOffsetTimestamp from '../../utils/getOffsetTimestap';
import TurnOnTimerModal from '../TurnOnTimerModal';
import TurnOfTimerModal from '../TurnOfTimeModal';

export const TrackerBoxWrapper = ({ isAuthenticated, isStaff }) => {
  const {
    currentTimeInformation: {
      todayTime,
      weekTime,
      loading: timerInformationLoading,
      isTracked
    },
    showIsTurnOnModal,
    timerLoading: actionTimerLoading,
    showIsTurnOfModal
  } = useSelector((state) => state.tracker);

  const dispatch = useDispatch();

  //useStopWatch hook
  const { reset, start, pause, isRunning, minutes, hours } = useStopwatch({ autoStart: false });

  const previousTimerInformationLoading = usePrevious(timerInformationLoading);

  const onStartTimer = () => {
    dispatch(startTimer({
      onStart: start
    }));
  }
  const onPauseTimer = () => {
    dispatch(stopTimer({
      onPause: pause
    }));
  }

  React.useEffect(() => {
    if (previousTimerInformationLoading && !timerInformationLoading) {
      if (!isTracked && isStaff && isAuthenticated) {
        dispatch(setShowIsTurnOnModal(true));
      }
    }

  }, [timerInformationLoading]);

  React.useEffect(() => {
    if (previousTimerInformationLoading && !timerInformationLoading) {
      if (!isTracked && isStaff && isAuthenticated) {
        dispatch(setShowIsTurnOnModal(true));
      }
    }

  }, [timerInformationLoading]);

  React.useEffect(() => {
    if (isStaff && isAuthenticated) {
      const stopwatchOffset = new Date();
      stopwatchOffset.setSeconds(stopwatchOffset.getSeconds() + getOffsetTimestamp(todayTime));
      if (isTracked) {
        reset(stopwatchOffset, true);
      } else {
        reset(stopwatchOffset, false);
      }
    }

  }, [isAuthenticated, isStaff, isTracked, todayTime]);

  if ( !isAuthenticated || !isStaff) {
    return null;
  }

  return (
    <>
      <TrackerBox
        hours={hours}
        isRunning={isRunning}
        minutes={minutes}
        onPause={onPauseTimer}
        onStart={onStartTimer}
        weekTime={weekTime} />
      <TurnOnTimerModal
        onStartTimer={onStartTimer}
        isLoading={actionTimerLoading}
        onHide={() => {
          dispatch(setShowIsTurnOnModal(false));
        }}
        isOpen={showIsTurnOnModal} />

      <TurnOfTimerModal
        onPauseTimer={onPauseTimer}
        onClose={() => {
          dispatch(setShowIsTurnOfModal(false));
        }}
        isOpen={showIsTurnOfModal}
        isLoading={actionTimerLoading}
      />
    </>
  );
};

const Digit = ({ value = 0 }) => {
  const leftDigit = value >= 10 ? value.toString()[0] : '0';
  const rightDigit = value >= 10 ? value.toString()[1] : value.toString();
  return (
    <span>
            {`${leftDigit}${rightDigit}`}
        </span>
  );
};

const TrackerBox = ({ isRunning, hours, minutes, onStart, onPause, weekTime }) => {
  return (
    <Dropdown className={'Tracker'}>
      <Dropdown.Toggle
        className={`Tracker__toggle ${isRunning ? 'dropdown-toggle--active' : ''}`} id='dropdown-basic'>
        <div>
          <Digit value={hours} />:
          <Digit value={minutes} />
        </div>
      </Dropdown.Toggle>

      <Dropdown.Menu className={'Tracker__menu'}>
        {
          isRunning ?
            (<Button
              onClick={onPause}
              className={`Tracker__btn-action Tracker__button-pause`}>
              <PauseIcon />
              <span className={'ml-1'}>
                                Stop Timer
                             </span>
            </Button>)
            :
            (<Button
              onClick={onStart}
              className={`Tracker__btn-action Tracker__button-play`}>
              <PlayIcon />
              <span className={'ml-1'}>
                                 Start Timer
                            </span>
            </Button>)
        }
        <div className={'Tracker__textContainer mt-3'}>
                    <span>
                        Today:
                    </span>
          <div>
            <Digit value={hours} />:
            <Digit value={minutes} />
          </div>
        </div>
        <div className={'Tracker__textContainer'}>
                    <span>
                        This Week:
                    </span>
          <div>
                        <span>
                            {weekTime}
                        </span>
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default TrackerBoxWrapper;
