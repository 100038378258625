import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { postRouts } from './constants';
//pages
import PostPage from 'modules/posts/pages/PostPage';
import PostsPage from 'modules/posts/pages/PostsPage';

const PostsRoutes = () => {
  return (
    <Switch>
      <Route path={postRouts.root} component={PostsPage} exact />
      <Route path={postRouts.post} component={PostPage} />
    </Switch>
  );
};

export default PostsRoutes;
