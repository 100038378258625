import React from 'react';
import { useHistory } from 'react-router-dom';
import isNumeric from 'validator/lib/isNumeric';
import { Button, ListGroup } from 'react-bootstrap';
import isCreditCard from 'validator/lib/isCreditCard';
import { useDispatch, useSelector } from 'react-redux';

import withPaymentMethods from 'modules/core/hoc/withFetchPaymentMethods';
import useAuth from 'modules/auth/hooks/useAuth';
import {
  createCardInputHandler,
  createExpDateHandler,
  isCardDate,
} from 'modules/core/utils/cardHelpers';
import { setSelectedPaymentMethod } from 'modules/core/store/actions/cardsActions';

import Input from 'modules/core/components/Input';
import CreditCardListItem from 'modules/core/components/CreditCartSelectorItem';
import CheckBox from 'modules/core/components/Checkbox';

import './styles.scss';

const CardSelector = withPaymentMethods(
  ({
    paymentMethods,
    paymentMethodsLoading,
    paymentMethodsLoaded,
    onDataChange,
    submited,
  }) => {
    const storedSelectedMethod = useSelector(
      (state) => state.cards.selectedMethod
    );

    const history = useHistory();
    const { isAuthenticated } = useAuth();
    const [cardNumber, setCardNumber] = React.useState(
      storedSelectedMethod?.newPaymentMethod?.cardNumber ?? ''
    );
    const [cardHolder, setCardHolder] = React.useState(
      storedSelectedMethod?.newPaymentMethod?.cardHolder ?? ''
    );
    const [expDate, setExpDate] = React.useState(
      storedSelectedMethod?.newPaymentMethod?.expDate ?? ''
    );
    const [cvcCode, setCvcCode] = React.useState(
      storedSelectedMethod?.newPaymentMethod?.cvcCode ?? ''
    );

    const [showCardsList, setShowCardsList] = React.useState(
      !!storedSelectedMethod?.existingPaymentMethod
    );
    const [savePaymentMethod, setSavePaymentMethod] = React.useState(false);
    const [activeCardId, setActiveCardId] = React.useState(
      storedSelectedMethod?.existingPaymentMethod?.id
    );

    const dispatch = useDispatch();

    React.useEffect(() => {
      if (paymentMethods.length > 0 && !activeCardId) {
        setActiveCardId(paymentMethods[0].id);
      }
    }, [paymentMethods, activeCardId]);

    React.useEffect(() => {
      if (onDataChange) {
        let selectedCardData;
        if (showCardsList) {
          selectedCardData = {
            existingPaymentMethod: paymentMethods.find(
              (pm) => pm.id === activeCardId
            ),
          };
        } else {
          selectedCardData = {
            newPaymentMethod: {
              savePaymentMethod,
              cardNumber,
              cardHolder,
              expDate,
              cvcCode,
            },
          };
        }
        onDataChange(selectedCardData);
        dispatch(setSelectedPaymentMethod(selectedCardData));
      }
    }, [
      onDataChange,
      activeCardId,
      cardNumber,
      showCardsList,
      expDate,
      cvcCode,
      cardHolder,
      paymentMethods,
      savePaymentMethod,
      dispatch,
    ]);

    const toggleSaveChanges = React.useCallback(() => {
      setSavePaymentMethod(!savePaymentMethod);
    }, [savePaymentMethod]);

    const handleExpDateUpdate = React.useCallback(
      createExpDateHandler(expDate, setExpDate),
      [setExpDate, expDate]
    );

    const handleCvcCodeUpdate = React.useCallback(
      (e) => {
        if (e.target.value !== '' && !isNumeric(e.target.value)) return;
        setCvcCode(e.target.value);
      },
      [setCvcCode]
    );

    const handleCardHolderUpdate = React.useCallback(
      (e) => {
        setCardHolder(e.target.value);
      },
      [setCardHolder]
    );

    const handleCardNumberUpdate = React.useCallback(
      createCardInputHandler(setCardNumber),
      [setCardNumber]
    );

    const handleToggleCardsList = React.useCallback(() => {
      setShowCardsList(!showCardsList);
    }, [showCardsList]);

    const handleCardItemClick = React.useCallback((e) => {
      e.preventDefault();
    }, []);
    const handleCardSelect = React.useCallback((key) => {
      setActiveCardId(Number(key));
    }, []);

    return (
      <div className='CardSelector__content'>
        {!showCardsList ? (
          <>
            <Input
              type="input"
              id="cardnumber"
              placeholder="4650058309922113"
              label="Card number"
              submited={submited}
              value={cardNumber}
              onChange={handleCardNumberUpdate}
              maxLength={19}
              minLength={13}
              isInvalid={
                submited &&
                cardNumber.length !== 16 &&
                !isCreditCard(cardNumber)
              }
              errorFeedback="Card number is not valid."
            />
            <Input
              type="input"
              id="cardholder"
              placeholder=" "
              label="Card holder"
              submited={submited}
              value={cardHolder}
              onChange={handleCardHolderUpdate}
            />
            <Input
              type="input"
              id="expdate"
              placeholder="MM / YY"
              label="Exp date"
              submited={submited}
              value={expDate}
              onChange={handleExpDateUpdate}
              maxLength={5}
              isInvalid={
                submited && (expDate.length < 5 || !isCardDate(expDate))
              }
              errorFeedback="Exp date is not valid"
            />
            <Input
              type="input"
              id="cvccode"
              placeholder="CVC"
              label="Code"
              submited={submited}
              maxLength={3}
              value={cvcCode}
              onChange={handleCvcCodeUpdate}
              isInvalid={submited && cvcCode.length < 3}
              errorFeedback="Code is too short."
            />
          </>
        ) : (
          <ListGroup
            defaultActiveKey={activeCardId}
            onSelect={handleCardSelect}
          >
            {paymentMethods.map((pm) => (
              <ListGroup.Item
                action
                href={pm.id}
                onClick={handleCardItemClick}
                key={pm.id}
              >
                <CreditCardListItem {...pm.card} />
              </ListGroup.Item>
            ))}
          </ListGroup>
        )}
        {isAuthenticated && (
          <div className="CardSelector__controls">
            {paymentMethods.length > 0 && (
              <Button
                variant="outline-primary"
                className="CardSelector__useExisting"
                onClick={handleToggleCardsList}
              >
                {showCardsList ? 'Use another card' : 'Use existing card'}
              </Button>
            )}
            {!showCardsList && (
              <CheckBox onChange={toggleSaveChanges}>
                <span className="DontationPopup__label">Save card</span>
              </CheckBox>
            )}
          </div>
        )}
      </div>
    );
  }
);

export default CardSelector;
