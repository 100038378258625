import React, {useEffect} from 'react';
import {useFormik} from "formik";
import {AidCprStatement, eighthStepValues, STEPS, stepsPath} from "../../../constants";
import './styles.scss'
import UploadCareerFile from "../../UploadCareerFile";
import CustomRadio from "../../../../core/components/CustomRadio";
import {eighthStepSchema} from "../../../validation";
import FormError from "../../../../core/components/FormError";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {careerRoutes} from "../../../routes/constants";
import {Button, Spinner} from "react-bootstrap";
import Stepper from "../../Stepper";
import {useDispatch, useSelector} from "react-redux";
import LoadingActionButton from "../../LoadingButton";
import {getEighthStep, updateEighthStep} from "../../../../core/store/actions/careerActions";
import CustomPrompt from "../../CustomPrompt";

export const CareerEighthStep = () => {
    const {id} = useParams()
    const location = useLocation()
    const history = useHistory()
    const {loading, stepLoading, stepValues} = useSelector((state) => state.career)
    const dispatch = useDispatch()
    const {handleSubmit, errors, setFieldValue, setFieldError, values, getFieldProps, submitCount, dirty} = useFormik({
        initialValues: (stepValues?.file?.hash || typeof stepValues?.aidCprStatement === 'boolean') ?
            {
                firstAidFile: stepValues.file,
                aidCprStatement: stepValues?.aidCprStatement ? stepValues.aidCprStatement : undefined
            }
            : eighthStepValues

        ,
        enableReinitialize: true,
        onSubmit: (values) => {
            const fd = new FormData()
            fd.append('ApplicationId', id)
            if (values.aidCprStatement && !values?.firstAidFile?.hash && !values?.firstAidFile?.name) {
                fd.append('AidCprStatement', values.aidCprStatement)
            }
            if (!values?.firstAidFile?.hash && values?.firstAidFile?.name) {
                fd.append('FirstAidFile', values.firstAidFile)
            }
            if (values?.firstAidFile?.hash) {
                fd.append('IsFileChanged', false)
            } else if (values?.firstAidFile?.name) {
                fd.append('IsFileChanged', true)
            }
            dispatch(updateEighthStep(fd, id))
        },
        validationSchema: eighthStepSchema
    })
    const onPrev = () => {
        history.push(careerRoutes.stepFn(id, stepsPath.sevenStep), {
            activeStep: 6
        })
    }
    useEffect(() => {
        if (id) {
            dispatch(getEighthStep(id))
        }
    }, [id, dispatch])
    return (
        <>
            {
                stepLoading ?
                    <div className={'text-center'}>
                        <Spinner animation="border"/>
                    </div> :
                    <>
                        <Stepper activeStep={location.state?.activeStep} steps={STEPS}/>
                        <form onSubmit={handleSubmit}>
                            <UploadCareerFile
                                fieldName={'firstAidFile'}
                                value={values?.firstAidFile}
                                setFieldValue={setFieldValue}
                                setFieldError={setFieldError}
                                error={errors?.firstAidFile}/>
                            <div className={'EightStep__radio-row'}>
                                <div className={'EightStep__description'}>
                                    First Aid / CPR (if you not have a current first Aid/CPR please check one of the
                                    following
                                    options.
                                </div>
                                <div>
                                    <CustomRadio
                                        {...getFieldProps('aidCprStatement')}
                                        onChange={(e) => {
                                            setFieldValue('aidCprStatement', Number(e.target.value))
                                        }}
                                        disabled={values?.firstAidFile?.name}
                                        defaultChecked={AidCprStatement.PlanToTake === values.aidCprStatement}
                                        value={AidCprStatement.PlanToTake}
                                        name={'aidCprStatement'}
                                        isBlack
                                        label={'I plan to take First Aid/CPR in the near future'}
                                    />
                                    {
                                        (errors?.aidCprStatement && submitCount > 0) ?
                                            <FormError>{errors?.aidCprStatement}</FormError>
                                            : null
                                    }
                                </div>
                                <div>
                                    <CustomRadio
                                        {...getFieldProps('aidCprStatement')}
                                        onChange={(e) => {
                                            setFieldValue('aidCprStatement', Number(e.target.value))
                                        }}
                                        disabled={values?.firstAidFile?.name}
                                        defaultChecked={AidCprStatement.WatchVideo === values.aidCprStatement}
                                        value={AidCprStatement.WatchVideo}
                                        name={'aidCprStatement'}
                                        isBlack
                                        label={'I would like to watch the video'}
                                    />
                                    {
                                        (errors?.aidCprStatement && submitCount > 0) ?
                                            <FormError>{errors?.aidCprStatement}</FormError>
                                            : null
                                    }
                                </div>
                            </div>
                            <div className={'Step__footer'}>
                                <Button
                                    onClick={onPrev}
                                    className={'btn-outline-primary Step__action-button'}>
                                    Back
                                </Button>
                                <LoadingActionButton loading={loading} text={'Next'}/>
                            </div>
                        </form>
                        <CustomPrompt when={dirty && submitCount === 0}/>
                    </>
            }

        </>
    );
};

