import * as yup from 'yup'
import {ErrorMessages} from "./messages";


export const eighthStepSchema = yup.object().shape({
    firstAidFile: yup.mixed().when('aidCprStatement', {
        is: (aidCprStatement) => {
            return (aidCprStatement && !Number.isNaN(aidCprStatement))
        },
        then: (schema) => schema,
        otherwise: (schema) => schema.required(ErrorMessages.REQUIRED)
    }),
    aidCprStatement: yup.number().when('firstAidFile', {
        is: (firstAidFile) => !!firstAidFile?.name,
        then: (schema) => schema,
        otherwise: (schema) => schema.required(ErrorMessages.REQUIRED)
    }),
}, ['aidCprStatement', 'firstAidFile'])
