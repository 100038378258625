import React from 'react';
import { Switch, Route } from 'react-router-dom';

//pages
import UserLoginPage from 'modules/login/pages';

const UserLoginRoutes = () => {
  return (
    <Switch>
      <Route path="/" component={UserLoginPage} />
    </Switch>
  );
};

export default UserLoginRoutes;
