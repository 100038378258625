import React from 'react';
import {Switch, Route} from 'react-router-dom';

import {careerRoutes} from 'modules/career/routes/constants';

import CareerPage from 'modules/career/pages/CareerPage';
import CareerViewPositionPage from 'modules/career/pages/CareerViewPositionPage';

import ScrollToTop from 'modules/core/utils/ScrollToTop';
import CareerViewChecklist from "../pages/CareerViewChecklist";
import CareerStepLayout from "../components/CareerStepLayout";

import {
    CareerApplyFirstStep,
    CareerApplySecondStep,
    CareerApplyThirdStep,
    CareerApplyFourthStep,
    CareerApplyFifthStep,
    CareerApplySixthStep,
    CareerApplySeventhStep,
    CareerEighthStep
} from "../components/CareerSteps";
import CareerFinishPage from "../pages/CareerFinishPage";

const CareerRoutes = () => {
    return (
        <>
            <ScrollToTop/>
            <Switch>
                <Route path={careerRoutes.root} component={CareerPage} exact/>
                <Route
                    exact
                    path={careerRoutes.position}
                    component={CareerViewPositionPage}
                />
                <Route
                    exact
                    path={careerRoutes.checklist}
                    // component={CareerApplyPage}
                    component={CareerViewChecklist}
                />
                <Route exact path={careerRoutes.finishPage} component={CareerFinishPage}/>
                <CareerStepLayout>
                    <Route
                        exact
                        path={careerRoutes.firstStep}
                        component={CareerApplyFirstStep}
                    />
                    <Route
                        exact
                        path={careerRoutes.secondStep}
                        component={CareerApplySecondStep}
                    />
                    <Route
                        exact
                        path={careerRoutes.thirdStep}
                        component={CareerApplyThirdStep}
                    />
                    <Route
                        exact
                        path={careerRoutes.fourthStep}
                        component={CareerApplyFourthStep}
                    />
                    <Route
                        exact
                        path={careerRoutes.fifthStep}
                        component={CareerApplyFifthStep}
                    />
                    <Route
                        exact
                        path={careerRoutes.sixStep}
                        component={CareerApplySixthStep}
                    />
                    <Route
                        exact
                        path={careerRoutes.sevenStep}
                        component={CareerApplySeventhStep}
                    />
                    <Route
                        exact
                        path={careerRoutes.eighthStep}
                        component={CareerEighthStep}
                    />
                </CareerStepLayout>
            </Switch>
        </>
    );
};

export default CareerRoutes;
