import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import throttle from 'lodash.throttle';
import { Link } from 'react-router-dom';

import FacilityItem from './FacilityItem';
import { startChangeCurrentFacility } from 'modules/core/store/actions/userInfoActions';
import { adminFacilityRoutes } from 'modules/admin-facility/routes/constants';
import ApiClient from 'modules/core/utils/ApiClient';
import { setShortFacilities } from 'modules/core/store/actions/facilityActions';

import showmoreIcon from 'assets/icons/showmore.svg';
import './styles.scss';

const LIST_HEIGHT = 135;
const ITEM_HEIGHT = 50;

const FacilitySelector = ({ show, onToggle, toggleParent }) => {
  const [loading, setLoading] = React.useState(false);
  // const [items, setItems] = React.useState([]);
  // const [totalItems, setTotalItems] = React.useState(9999);
  const [currentChangeLoading, setCurrentChangeLoading] = React.useState(false);
  const rootRef = React.useRef(null);
  const currentFacility = useSelector((store) => store.user.facility);

  const items = useSelector((store) => store.facility.shortFacilities);
  const totalItems = useSelector(
    (store) => store.facility.shortTotalItemsCount
  );

  const dispatch = useDispatch();

  const handleFetch = React.useCallback(
    async (searchStringChanged, searchString) => {
      if (!searchStringChanged && items.length >= totalItems) {
        return;
      }
      if (searchString && searchString.length < 3) {
        dispatch(setShortFacilities([], 9999));
        return;
      }

      const newItems = searchStringChanged ? [] : items;

      setLoading(true);

      try {
        const pageSize = ((LIST_HEIGHT / ITEM_HEIGHT) ^ 0) + 2;
        const response = await ApiClient.fetchShortFacilities(
          newItems.length / pageSize + 1,
          pageSize,
          searchString
        );
        if (!response?.data) return;

        dispatch(
          setShortFacilities(
            [...newItems, ...(response?.data?.value?.entities ?? [])],
            response.data.value.totalItems
          )
        );
      } catch (err) {
        console.log(err);
      }

      setLoading(false);
    },
    [items, totalItems, dispatch]
  );

  const initPaginationScrollEvent = React.useCallback(
    (ref) => {
      if (!ref) return;
      ref.onscroll = throttle(() => {
        if (
          !loading &&
          ref.scrollTop >= ref.scrollHeight - ref.clientHeight - ITEM_HEIGHT
        ) {
          handleFetch(false, '');
        }
      }, 200);
    },
    [handleFetch, loading]
  );

  const handleChangeFacility = React.useCallback(
    async (key) => {
      setCurrentChangeLoading(true);
      await dispatch(
        startChangeCurrentFacility(
          items.find((facility) => facility.id === key)
        )
      );
      setCurrentChangeLoading(false);
      onToggle();
    },
    [dispatch, items, onToggle]
  );

  React.useEffect(() => {
    handleFetch(false);
    /* eslint-disable */
  }, [totalItems, items]);

  return (
    <div ref={rootRef} className="SuperadminFacilitySelector__root">
      <div className="SuperadminFacilitySelector__account">
        <div className="SuperadminFacilitySelector__accountTop">
          <span>Account</span>

          <div onClick={onToggle}>
            <img
              src={showmoreIcon}
              alt="Collapse"
              className={
                'clickableIcon ' +
                (show ? 'SuperadminFacilitySelector__hideImg' : '')
              }
            />
          </div>
        </div>

        <span className="SuperadminFacilitySelector__accountCurrent">
          {currentFacility?.name ?? 'All Facilities Mode'}
          {currentChangeLoading && (
            <Spinner
              animation="border"
              size="sm"
              className="SuperadminFacilitySelector__currentChangeSpinner"
            />
          )}
        </span>
      </div>

      <div
        className="SuperadminFacilitySelector__list"
        ref={initPaginationScrollEvent}
        style={{
          height: items.length > 0 && show ? LIST_HEIGHT : 0,
          marginTop: show ? 15 : 0,
        }}
      >
        <FacilityItem
          name="All"
          isCurrent={!currentFacility}
          setCurrent={handleChangeFacility}
        />
        {items.map((item) => (
          <FacilityItem
            {...item}
            key={item.id}
            isCurrent={item.id === currentFacility?.id}
            setCurrent={handleChangeFacility}
          />
        ))}
        {loading && (
          <div className="SuperadminFacilitySelector__spinner">
            <Spinner animation="grow" size="sm" />
          </div>
        )}
      </div>
      {show && (
        <div className="SuperadminFacilitySelector__manageLink">
          <Link to={adminFacilityRoutes.manage} onClick={toggleParent}>
            Manage Facilities
          </Link>
        </div>
      )}
    </div>
  );
};

export default FacilitySelector;
