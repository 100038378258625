import ApiClient from 'modules/core/utils/ApiClient';
import { prettifyError } from 'modules/core/utils/error';

export const SET_GROUPS_LOADING = 'SET_SECURITY_GROUPS_LOADING';
export const SET_USERS_LOADING = 'SET_USERS_LOADING';
export const SET_SECURITY_GROUPS = 'SET_SECURITY_GROUPS';
export const SET_ACTIVATED_USERS = 'SET_ACTIVATED_USERS';
export const SET_DEACTIVATED_USERS = 'SET_DEACTIVATED_USERS';
export const CREATE_GROUP = 'CREATE_SECURITY_GROUP';
export const SET_ERROR = 'SET_SECURITY_GROUPS_ERROR';

export const startGetSecurityGroups = () => {
  return async (dispatch, getState) => {
    const { securityGroups } = getState().users;
    if (securityGroups === undefined) {
      dispatch({ type: SET_GROUPS_LOADING, value: true });
      try {
        const {
          securityGroups: responseSecurityGroups,
        } = await ApiClient.getSecurityGroups();

        dispatch({
          type: SET_SECURITY_GROUPS,
          securityGroups: responseSecurityGroups,
        });
      } catch (err) {
        console.log(err, err.response);
      }
      dispatch({ type: SET_GROUPS_LOADING, value: false });
    }
  };
};

export const setActivatedUsers = (
  activatedUsers,
  totalActivatedUsersCount
) => ({
  type: SET_ACTIVATED_USERS,
  activatedUsers,
  totalActivatedUsersCount,
});
export const setDeactivatedUsers = (
  deactivatedUsers,
  totalDeactivatedUsersCount
) => ({
  type: SET_DEACTIVATED_USERS,
  deactivatedUsers,
  totalDeactivatedUsersCount,
});

export const startSetActive = (id, value) => {
  return async (dispatch, getState) => {
    try {
      await ApiClient.setStaffActive(id, value);
      dispatch({
        type: SET_ACTIVATED_USERS,
        activatedUsers: [],
        totalActivatedUsersCount: 9999,
      });
      dispatch({
        type: SET_DEACTIVATED_USERS,
        deactivatedUsers: [],
        totalDeactivatedUsersCount: 9999,
      });
    } catch (err) {
      alert(prettifyError(err));
      console.log(err, err.response);
    }
  };
};
