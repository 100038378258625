import React from 'react';
import moment from 'moment';
import { Button, ProgressBar } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import history from 'modules/core/utils/history';
import { trainingsPaymentRoutes } from 'modules/trainings-payment/routes/constants';
import { trainingsLessonsRoutes } from 'modules/trainings-lessons/routes/constants';
import { trainingsCertificatesRoutes } from 'modules/trainings-certificates/routes/constants';
import AuthImage from 'modules/core/components/AuthImage';

import './styles.scss';

const TrainingsItem = ({
  image,
  heading,
  lessonsCount,
  price,
  timeInMin,
  paid,
  completedLessons,
  description,
  id,
}) => {
  const time = React.useMemo(() => moment.duration(timeInMin, 'minutes'), [
    timeInMin,
  ]);

  const handleBuy = React.useCallback(() => {
    history.push(trainingsPaymentRoutes.address.replace(':id', id));
  }, [id]);

  const handleStart = React.useCallback(() => {
    history.push(trainingsLessonsRoutes.root.replace(':trainingId', id));
  }, [id]);

  return (
    <div className="TrainingsItem__root">
      <div className="TrainingsItem__left-col">
        <div className="TrainingsItem__left-inner">
          <div
            className="TrainingsItem__preview"
            onClick={paid ? handleStart : handleBuy}
          >
            <AuthImage controllerPath="trainings" hash={image}>
              <img src={image} alt={heading} />
            </AuthImage>
          </div>
          <div className="TrainingsItem__box">
            {paid && <ProgressBar now={completedLessons} max={lessonsCount} />}

            <h4 className="TrainingsItem__title">{heading}</h4>
            <div className="TrainingsItem__property">
              <p>
                {lessonsCount} lesson{lessonsCount === 1 ? '' : 's'}
              </p>
              <p>
                {time.hours() > 0
                  ? `${time.hours()} h ${time.minutes()} min`
                  : `${time.minutes()} min`}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="TrainingsItem__right-col">
        <h3 className="TrainingsItem__title">{heading}</h3>
        <p>{description}</p>
        {!paid && (
          <div className="TrainingsItem__buy">
            <span className="TrainingsItem__price">${price / 100}</span>
            <Button size="lg" onClick={handleBuy}>
              Buy
            </Button>
          </div>
        )}
        {paid && completedLessons === 0 && (
          <div className="TrainingsItem__start">
            <Button size="lg" variant="outline-dark" onClick={handleStart}>
              Start
            </Button>
          </div>
        )}
        {paid && completedLessons !== 0 && completedLessons < lessonsCount && (
          <div className="TrainingsItem__start" onClick={handleStart}>
            <Button size="lg" variant="outline-dark">
              Continue
            </Button>
          </div>
        )}
        {paid && completedLessons === lessonsCount && (
          <div className="TrainingsItem__start">
            <Link
              className="btn btn-outline-dark btn-lg"
              to={trainingsCertificatesRoutes.root}
            >
              Go to certificate
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default TrainingsItem;
