import React from 'react';

const FileIcon = ({width, height, fill}) => {
    return (
        <svg width={width || "41"} height={height || "47"} viewBox="0 0 41 47" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M30.2219 0.5H5.13104C2.83104 0.5 0.949219 2.38182 0.949219 4.68182V33.9545H5.13104V4.68182H30.2219V0.5ZM36.4947 8.86364H13.4947C11.1947 8.86364 9.31286 10.7455 9.31286 13.0455V42.3182C9.31286 44.6182 11.1947 46.5 13.4947 46.5H36.4947C38.7947 46.5 40.6765 44.6182 40.6765 42.3182V13.0455C40.6765 10.7455 38.7947 8.86364 36.4947 8.86364ZM36.4947 42.3182H13.4947V13.0455H36.4947V42.3182Z"
                fill={fill || "#27AE60"}/>
        </svg>


    );
};
export default FileIcon;
