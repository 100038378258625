export const ErrorMessages = {
    REQUIRED: 'This field is required',
    EMAIL_INVALID: 'Email is invalid',
    POSITIVE_NUMBER:'This value should be greater than 0',
    PHONE_NUMBER_INCOMPLETE:'Please enter full number',
    NUMBER_INVALID:'This field should be a number',
    ONLY_DIGITS: 'Must be only digits',
    DATE_INVALID: 'You should be at least 21 year',
    MAX_LENGTH: 'Maximum 10 characters long',
    START_DATE: 'Must be the same or less than the end date',
    END_DATE: 'Must be the same or more than the start date',
    INVALID_DATE: 'The date must be today or later.'
}
