import ApiClient from 'modules/core/utils/ApiClient';
import { prettifyError } from 'modules/core/utils/error';
import { UPDATE_CURRENT_FACILITY } from 'modules/core/store/actions/userInfoActions';

export const SET_ACTIVATED_FACILITIES_LIST = 'SET_ACTIVATED_FACILITIES_LIST';
export const SET_DEACTIVATED_FACILITIES_LIST =
  'SET_DEACTIVATED_FACILITIES_LIST';
export const SET_SHORT_FACILITIES_LIST = 'SET_SHORT_FACILITIES_LIST';

export const setActivatedFacilities = (facilities, totalItemsCount) => ({
  type: SET_ACTIVATED_FACILITIES_LIST,
  facilities,
  totalItemsCount,
});

export const setDeactivatedFacilities = (facilities, totalItemsCount) => ({
  type: SET_DEACTIVATED_FACILITIES_LIST,
  facilities,
  totalItemsCount,
});

export const setShortFacilities = (facilities, totalItemsCount) => ({
  type: SET_SHORT_FACILITIES_LIST,
  facilities,
  totalItemsCount,
});

export const startSetFacilityActivationStatus = (facilityId, value) => {
  return async (dispatch, getState) => {
    try {
      await ApiClient.setFacilityActivationStatus(facilityId, value);

      dispatch(setActivatedFacilities([], 9999));
      dispatch(setDeactivatedFacilities([], 9999));
      dispatch(setShortFacilities([], 9999));

      if (getState().user.facility?.id === facilityId) {
        dispatch({ type: UPDATE_CURRENT_FACILITY, facility: null });
      }
    } catch (err) {
      alert(prettifyError(err));
    }
  };
};
