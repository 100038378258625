import React from 'react';

const StarIcon = ({width, height,fill}) => {
    return (
        <svg width={width || "53"} height={height || "51"} viewBox="0 0 53 51" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M26.4999 40.5428L11.2131 48.58L14.1333 31.557L1.7666 19.5024L18.8565 17.0206L26.4999 1.5332L34.1433 17.0206L51.2333 19.5024L38.8666 31.557L41.7868 48.58L26.4999 40.5428Z"
                stroke={fill || "#27AE60"} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    );
};

export default StarIcon;
