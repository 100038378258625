import React from 'react';

const PauseIcon = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.6001 3.60001V14.4M11.4001 3.60001V14.4" stroke="#403845"/>
        </svg>

    );
};

export default PauseIcon;
