import React from 'react';
import TitleDecor from 'modules/core/components/TitleDecor';

import './styles.scss';

const TrainingsWrapper = ({ children }) => {
  return (
    <div className="TrainingsWrapper__container">
      <TitleDecor content="Trainings" medium />
      <div className="TrainingsWrapper__root">{children}</div>
    </div>
  );
};

export default TrainingsWrapper;
