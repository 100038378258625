import React from 'react';

import ApiClient from 'modules/core/utils/ApiClient';
import history from 'modules/core/utils/history';
import { loginRoutes } from 'modules/login/routes/constants';
import { coreRoutes } from 'modules/core/routes/constants';
import WithLoading from 'modules/core/components/WithLoading';

const EmailConfirmationRedirectHandler = () => {
  console.log(history.location.search);
  React.useEffect(() => {
    const params = new URLSearchParams(history.location.search);
    const id = params.get('id');
    const token = params.get('token');
    console.log(`[EmailConfirmationRedirectHandler] id: ${id} token: ${token}`);

    ApiClient.confirmEmail({ id: Number(id), token })
      .then((response) => {
        console.log(response);
        history.push(loginRoutes.root);
      })
      .catch((err) => {
        console.log(err, err.response);
        history.push(coreRoutes.error);
      });
  }, []);
  return <WithLoading loading={true} />;
};

export default EmailConfirmationRedirectHandler;
