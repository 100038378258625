import React from 'react';

const PhoneLink = ({ phone, ...rest }) => {
  return (
    <a href={`tel:${phone}`} {...rest}>
      {phone}
    </a>
  );
};

export default PhoneLink;
