import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import SecurityGroupService from 'modules/core/utils/SecurityGroupService';
import history from 'modules/core/utils/history';
import useAuth from 'modules/auth/hooks/useAuth';
import { loginRoutes } from 'modules/login/routes/constants';

function PrivateRoute({
  component: Component,
  redirectTo,
  permission,
  permissionFallbackPath,
  ...rest
}) {
  const { isAuthenticated } = useAuth();
  const groups = useSelector((state) => state.user.securityGroups);

  React.useEffect(() => {
    if (!permission) return;

    if (!SecurityGroupService.containsPermission(groups, permission)) {
      history.push(
        permissionFallbackPath ??
          SecurityGroupService.getFirstGroupsPermissionPagePath(groups)
      );
    }
  }, [groups, permission, permissionFallbackPath]);

  return (
    <Route
      {...rest}
      render={
        isAuthenticated
          ? (props) => <Component {...props} />
          : () => <Redirect to={redirectTo || loginRoutes.root} />
      }
    />
    // <Route {...rest} component={Component} />
  );
}

export default PrivateRoute;
