import React from 'react';
import { Container as BootstrapContainer, Row, Col } from 'react-bootstrap';

import { BreakpointContextConsumer } from 'modules/core/contexts/BreakpointContext';
import Container from 'modules/core/components/Container';
import PersonDescription from 'modules/about/components/PersonDescription';

import './styles.scss';
// import person1Img from 'assets/images/AboutPage_person1.png';
import profileEmanuelImg from 'assets/images/AboutPage__Emanuel.png';
import profileWanikeiImg from 'assets/images/AboutPage__Wanikei.png';
import profileCarolyn from 'assets/images/AboutPage__Carolyn.jpg';
import profileChika from 'assets/images/AboutPage__Chika.png';
import profileTammara from 'assets/images/AboutPage__Tammara.png';
import profileJerron from 'assets/images/AboutPage__Jerron.jpg';

const people = [
  {
    name: 'Emanuel Ashiedu',
    experience: '',
    description: `I have always worked with kids since arriving in this country in the summer of 2009. I believe the greatest investment is in people and thus my commitment in making sure every child that comes through our center will be equipped, impacted, and guided with a vision on how to believe in themselves, grow developmentally, spiritually and become self-starters.`,
    addtitionalDescription: ''
  },
  {
    name: 'Wanakei Ashiedu',
    experience: '',
    description: `Growing up, I have always seen my parents provide a haven for children who were abused, abandoned, and neglected. As an adult with children of my own, It saddens my heart when I see a child in need and as thus, I have made it a priority to help others each day. `,
    addtitionalDescription: `Starting Forstter is a way for me to give back and ensure a youth is getting the quality care they need. We will provide 
    security, stability, consistency and emotional support. As well as everyday needs like, shelter, food, clothing, medical care, and protection from harm.`
  },
  {
    name: 'Carolyn Edwards-Ugiomoh',
    experience: '',
    description: `I am a seasoned Clinical Consultant at Forstter Youth Residential Center (FYC), 
            bringing the residential center a wealth of experience and dedication to the field of 
            youth services. I hold a Master of Social Work degree from Newman University in 
            Wichita, Kansas, and I am licensed as a Master-Level Social Worker in both Kansas 
            and Ohio.`,
    addtitionalDescription: 'In Ohio, I hold the distinguished title of Mental Health Clinician, operating under the \n' +
      'supervision of a licensed independent or clinical social worker. With my dual-state \n' +
      'licensure, I am uniquely positioned to serve and make a lasting impact on the lives \n' +
      'of youths and families in both states in various capacities.\n' +
      'With a career spanning many years, I am a steadfast advocate for the well-being \n' +
      'and growth of young individuals facing diverse challenges. I firmly believe that \n' +
      'there is nothing more fulfilling than witnessing the small and monumental victories \n' +
      'achieved by these youths as they navigate the complex path towards social and \n' +
      'emotional independence. My commitment to empowering and nurturing the \n' +
      'potential of these young minds is unwavering.\n' +
      'At FYC, I look forward to extending my reach and positively influencing the lives of \n' +
      'even more youth. I look forward to providing expertise to staff who supervise FYC’s \n' +
      'youths daily through the means of professional development trainings or \n' +
      'workshops. With my profound experience and compassionate approach, I am poised\n' +
      'to make a significant difference in the lives of the exceptional youth and staff who \n' +
      'walk through FYC’s doors.'
  },
  {
    name: 'Chika Amuneke',
    experience: '',
    description: `From a young age, I have been passionate about working with children, finding immense joy in nurturing their growth and development. I am dedicated to fostering environments where children feel safe, valued, and inspired to learn. Seeing their faces light up with understanding and excitement motivates me to continually innovate and improve my approach. I believe in the incredible potential that every child possesses. Working with kids is more than a job to me; it is a vocation that I am profoundly devoted to.`,
    addtitionalDescription: ''
  },
  {
    name: 'Tammara Allen',
    experience: '',
    description: `I was in the foster system and I know what it's like to always have to bounce and fall through the cracks. I am passionate and driven to show the children in our facility that there is someone out there that cares and understands what they are going through. I also want to show them a sense of stability in their lives.`,
    addtitionalDescription: ''
  },
  {
    name: 'Jerron Conner',
    experience: '',
    description: `I've worked as A Direct Support Professional for The past Decade assisting At Risk Youth and other special populations.`,
    addtitionalDescription: ''
  },
];

const MainPage = () => {
  const renderTitle = () => {
    return (
      <div className='AboutPage__titleContainer'>
        <h1 className='AboutPage__title'>
          About Us<span className='AboutPage__titleDot'>.</span>
        </h1>
        <div className='AboutPage__redBox' />
      </div>
    );
  };

  const renderDescription = React.useMemo(() => {
    return (
      <p className='AboutPage__description'>
        We are creating a youth center from the ground up to help fulfill a
        mission of helping every child living in Kansas find a home that's
        filled with love, care and every support they need to grow responsibly.
        <br /> <br />
        Our program rates different aspects of communication, youth daily living
        - creative, physical, mental and development stages. We believe every
        kid deserves equal opportunity. We believe opportunity begins with
        access.
      </p>
    );
  }, []);

  return (
    <BreakpointContextConsumer>
      {({ isMobile }) => (
        <Container
          className='AboutPage__root'
          paddingHorizontalDesktop={'250px'}
          paddingHorizontalMobile={'18px'}
        >
          {!isMobile && (
            <BootstrapContainer>
              <Row>
                <Col sm={4}>{renderTitle()}</Col>
                <Col sm={8}>
                  <div className='AboutPage__blueBox' />
                  {renderDescription}
                </Col>
              </Row>
              <Row className='AboutPage__row'>
                <Col sm={6} className='AboutPage__yelowBoxColumn'>
                  <Col sm={8}>
                    <img
                      className='AboutPage__image__left'
                      src={profileEmanuelImg}
                      alt={people[0].name}
                    />
                  </Col>
                  <Col sm={4}>
                    <div className='AboutPage__yellowBox' />
                  </Col>
                </Col>
                <Col sm={1} />
                <Col sm={5}>
                  <PersonDescription {...people[0]} />
                </Col>
              </Row>
              <Row className='AboutPage__secondRow'>
                <Col sm={5}>
                  <PersonDescription
                    btnClassName='AboutPage__secondPersonReadMore'
                    {...people[1]}
                  />
                  <div className='AboutPage__redBackBox' />
                </Col>
                <Col sm={1} />

                <Col sm={6} className='AboutPage__yelowBoxColumn'>
                  <Col sm={4} className='AboutPage__greenBlockContainer'>
                    <div className='AboutPage__greenBox' />
                  </Col>
                  <Col sm={8}>
                    <img
                      className='AboutPage__image__right'
                      src={profileWanikeiImg}
                      alt={people[1].name}
                    />
                  </Col>
                </Col>
              </Row>
              <Row className='AboutPage__row'>
                <Col sm={6} className='AboutPage__yelowBoxColumn'>
                  <Col sm={8}>
                    <img
                      className='AboutPage__image__left'
                      src={profileCarolyn}
                      alt={people[2].name}
                    />
                  </Col>
                  <Col sm={4}>
                    <div className='AboutPage__yellowBox' />
                  </Col>
                </Col>
                <Col sm={1} />
                <Col sm={5}>
                  <PersonDescription {...people[2]} />
                </Col>
              </Row>
              <Row className='AboutPage__secondRow'>
                <Col sm={5}>
                  <PersonDescription
                    btnClassName='AboutPage__secondPersonReadMore'
                    {...people[3]}
                  />
                  <div className='AboutPage__redBackBox' />
                </Col>
                <Col sm={1} />

                <Col sm={6} className='AboutPage__yelowBoxColumn'>
                  <Col sm={4} className='AboutPage__greenBlockContainer'>
                    <div className='AboutPage__greenBox' />
                  </Col>
                  <Col sm={8}>
                    <img
                      className='AboutPage__image__right'
                      src={profileChika}
                      alt={people[3].name}
                    />
                  </Col>
                </Col>
              </Row>
              <Row className='AboutPage__row'>
                <Col sm={6} className='AboutPage__yelowBoxColumn'>
                  <Col sm={8}>
                    <img
                      className='AboutPage__image__left'
                      src={profileTammara}
                      alt={people[4].name}
                    />
                  </Col>
                  <Col sm={4}>
                    <div className='AboutPage__yellowBox' />
                  </Col>
                </Col>
                <Col sm={1} />
                <Col sm={5}>
                  <PersonDescription {...people[4]} />
                </Col>
              </Row>
              <Row className='AboutPage__secondRow'>
                <Col sm={5}>
                  <PersonDescription
                    btnClassName='AboutPage__secondPersonReadMore'
                    {...people[5]}
                  />
                  <div className='AboutPage__redBackBox' />
                </Col>
                <Col sm={1} />

                <Col sm={6} className='AboutPage__yelowBoxColumn'>
                  <Col sm={4} className='AboutPage__greenBlockContainer'>
                    <div className='AboutPage__greenBox' />
                  </Col>
                  <Col sm={8}>
                    <img
                      className='AboutPage__image__right'
                      src={profileJerron}
                      alt={people[5].name}
                    />
                  </Col>
                </Col>
              </Row>
            </BootstrapContainer>
          )}
          {isMobile && (
            <div className='AboutPage__containerMobile'>
              {renderTitle()}
              {renderDescription}

              <div className='AboutPage__mobilePerson1Container'>
                <div className='PersonDescription__title-wrapper'>
                  <h1 className='PersonDescription__title'>{people[0].name}</h1>
                  {/* <span className="PersonDescription__experience">
                    4 years expirience
                  </span> */}
                </div>
                <div className='AboutPage__mobileYellowBox' />
                <img
                  className='AboutPage__image__left'
                  src={profileEmanuelImg}
                  alt={people[0].name}
                />
              </div>

              <PersonDescription {...people[0]} />
              <div className='AboutPage__mobileSeparatorContainer'>
                <div className='AboutPage__mobileSeparator' />
              </div>
              <div className='AboutPage__mobilePerson2Container'>
                <div className='PersonDescription__title-wrapper'>
                  <h1 className='PersonDescription__title'>{people[1].name}</h1>
                  {/* <span className="PersonDescription__experience">
                    4 years expirience
                  </span> */}
                </div>
                <div className='AboutPage__mobileGreenBox' />

                <img
                  className='AboutPage__image__right'
                  src={profileWanikeiImg}
                  alt={people[1].name}
                />
              </div>
              <PersonDescription {...people[1]} />
              <div className='AboutPage__mobilePerson1Container'>
                <div className='PersonDescription__title-wrapper'>
                  <h1 className='PersonDescription__title'>{people[2].name}</h1>
                </div>
                <div className='AboutPage__mobileYellowBox' />
                <img
                  className='AboutPage__image__left'
                  src={profileCarolyn}
                  alt={people[2].name}
                />
              </div>
              <PersonDescription {...people[2]} />
              <div className='AboutPage__mobilePerson2Container'>
                <div className='PersonDescription__title-wrapper'>
                  <h1 className='PersonDescription__title'>{people[3].name}</h1>
                </div>
                <div className='AboutPage__mobileGreenBox' />

                <img
                  className='AboutPage__image__right'
                  src={profileChika}
                  alt={people[3].name}
                />
              </div>
              <PersonDescription {...people[3]} />
              <div className='AboutPage__mobilePerson1Container'>
                <div className='PersonDescription__title-wrapper'>
                  <h1 className='PersonDescription__title'>{people[4].name}</h1>
                </div>
                <div className='AboutPage__mobileYellowBox' />
                <img
                  className='AboutPage__image__left'
                  src={profileTammara}
                  alt={people[4].name}
                />
              </div>
              <PersonDescription {...people[4]} />
              <div className='AboutPage__mobilePerson2Container'>
                <div className='PersonDescription__title-wrapper'>
                  <h1 className='PersonDescription__title'>{people[5].name}</h1>
                </div>
                <div className='AboutPage__mobileGreenBox' />

                <img
                  className='AboutPage__image__right'
                  src={profileJerron}
                  alt={people[5].name}
                />
              </div>
              <PersonDescription {...people[5]} />
            </div>
          )}
        </Container>
      )}
    </BreakpointContextConsumer>
  );
};

export default MainPage;
