import React from 'react';
import classNames from 'classnames';

import UserAvatar from 'modules/core/components/UserAvatar';

import './styles.scss';

const UserSelectorOption = ({
  title,
  fullName,
  avatarColor,
  image,
  onClick,
  selected,
}) => {
  return (
    <div
      onClick={onClick}
      className={classNames({
        UserSelectorOption__root: true,
        'UserSelectorOption__root--selected': selected,
      })}
    >
      <div className="UserSelectorOption__textContainer">
        <span className="UserSelectorOption__name">{fullName}</span>

        <span className="UserSelectorOption__title">{title}</span>
      </div>
      <UserAvatar
        name={fullName}
        round={true}
        size="48px"
        color={avatarColor}
        src={image}
      />
    </div>
  );
};

export default UserSelectorOption;
