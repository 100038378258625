import React from 'react';
import './styles.scss'
import Container from "../../../core/components/Container";

const CareerStepLayout = ({children}) => {
    return (
        <Container className={'Step__root'}>
            <div className={'Step__container'}>
                {children}
            </div>
        </Container>
    );
};

export default CareerStepLayout;
