import ApiClient from 'modules/core/utils/ApiClient';

export default class ImageService {
  static _cachedImages = {};

  /**
   * Request image. If image is cached, cashed version will be used.
   * @param {string} controllerPath
   * @param {string} hash
   */
  static async getImage(controllerPath, hash) {
    const cachedImage = ImageService._get(hash);
    if (cachedImage) {
      return cachedImage;
    } else {
      const imageBlob = await ApiClient.getImage(controllerPath, hash);
      const base64Image = URL.createObjectURL(imageBlob);
      ImageService._set(hash, base64Image);
      return base64Image;
    }
  }

  static _get(hash) {
    return this._cachedImages[hash];
  }

  static _set(hash, image) {
    this._cachedImages[hash] = image;
  }
}
