import {combineReducers} from "redux";

import postsReducer from './postsReducer';
import userInfoReducer from './userInfoReducer';
import authReducer from 'modules/auth/store/reducers/authReducer';
import cardsReducer from './cardsReducer';
import productsReducer from './productsReducer';
import cartReducer from './cartReducer';
import trainingsReducer from './trainingsReducer';
import usersReducer from './usersReducer';
import residentsReducer from './residentsReducer';
import facilityReducer from './facilityReducer';
import recentNotificationsReducer from './recentNotificationsReducer';
import careerReducer from "./careerReducer";
import trackerReducer from "./trackerReducer";

const rootReducer = combineReducers({
  posts: postsReducer,
  user: userInfoReducer,
  auth: authReducer,
  cards: cardsReducer,
  products: productsReducer,
  cart: cartReducer,
  trainings: trainingsReducer,
  users: usersReducer,
  residents: residentsReducer,
  facility: facilityReducer,
  recentNotifications: recentNotificationsReducer,
  career: careerReducer,
  tracker: trackerReducer
});

export default rootReducer;
