import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';

import './styles.scss';

export const RadioButtons = ({
  children,
  onChange,
  initialKey,
  variant,
  value,
}) => {
  const [activeKey, setActiveKey] = React.useState(initialKey);

  const setActive = React.useCallback(
    (key) => {
      if (value === undefined) setActiveKey(key);
      onChange(key);
    },
    [onChange, setActiveKey, value]
  );

  return (
    <div
      className={variant === 'circle' ? 'Radio__root' : 'Radio__root-buttons'}
    >
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          setActive,
          activeKey: value === undefined ? activeKey : value,
          variant,
        });
      })}
    </div>
  );
};

export const Radio = ({
  valueKey,
  setActive,
  activeKey,
  children,
  variant,
  className = '',
}) => {
  const clickHandler = React.useCallback(() => {
    setActive(valueKey);
  }, [setActive, valueKey]);

  const renderAsCircle = () => (
    <>
      {activeKey !== valueKey ? (
        <div className="Radio__svgContainer">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12Z"
              fill="white"
              stroke="#EBEBEB"
              strokeWidth="2"
            />
          </svg>
        </div>
      ) : (
        <div className="Radio__svgContainer">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12Z"
              fill="white"
              stroke="black"
              strokeWidth="2"
            />
            <path
              d="M12 19C15.866 19 19 15.866 19 12C19 8.13401 15.866 5 12 5C8.13401 5 5 8.13401 5 12C5 15.866 8.13401 19 12 19Z"
              fill="black"
            />
          </svg>
        </div>
      )}
    </>
  );

  const renderAsButtons = () => (
    <>
      <Button active={activeKey === valueKey}>{children}</Button>
    </>
  );

  return (
    <div
      onClick={clickHandler}
      className={
        variant === 'circle' ? 'Radio__element-circle' : 'Radio__element'
      }
    >
      {variant === 'circle' ? (
        <>
          {renderAsCircle()}
          <div className="Radio__buttonChildren"> {children}</div>
        </>
      ) : (
        renderAsButtons()
      )}
    </div>
  );
};

Radio.propTypes = {};

RadioButtons.defaultProps = {
  variant: 'circle',
};

RadioButtons.propTypes = {
  variant: PropTypes.string.isRequired,
};
