import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import history from 'modules/core/utils/history';
import { fetchUserInfo } from 'modules/core/store/actions/userInfoActions';

/**
 * Fetches user info if it wasn't fetched previously and saves it to redux
 * @param {*} exclude array of urls that should be ignored
 * @param {*} fallbackPageUrl redirects to this page if auth is failed
 */
const useInitUserInfo = ({ exclude = [], fallbackPageUrl, onError }) => {
  const dispatch = useDispatch();
  const { loaded: userInfoLoaded, loading: userInfoLoading } = useSelector(
    (state) => ({
      ...state.user,
    })
  );

  React.useEffect(() => {
    if (
      !userInfoLoaded &&
      !userInfoLoading &&
      exclude.every((item) => item !== history.location.pathname)
    ) {
      dispatch(fetchUserInfo({ exclude, fallbackPageUrl, onError }));
    }
  }, [
    userInfoLoaded,
    userInfoLoading,
    dispatch,
    fallbackPageUrl,
    exclude,
    onError,
  ]);

  return { userInfoLoaded, userInfoLoading };
};

export default useInitUserInfo;
