import * as yup from 'yup'
import {ErrorMessages} from "./messages";
import moment from "moment";

export const thirdStepSchema = yup.object().shape({
    agreeStatement: yup.boolean().oneOf([true], ErrorMessages.REQUIRED),
    agreeStatementInitial: yup.string().required(ErrorMessages.REQUIRED),
    applicationCorrect: yup.boolean().oneOf([true], ErrorMessages.REQUIRED),
    applicationCorrectInitial: yup.string().required(ErrorMessages.REQUIRED),
    applicationGood: yup.boolean().oneOf([true], ErrorMessages.REQUIRED),
    applicationDate: yup.string().required(ErrorMessages.REQUIRED).test('checkDate', ErrorMessages.INVALID_DATE, (value) => {
        return moment(value).isSameOrAfter(new Date().toDateString())
    }),
    socialSecurityCardSignature: yup.string().required(ErrorMessages.REQUIRED),
    confidentialityAgreement: yup.boolean().oneOf([true], ErrorMessages.REQUIRED),
    confidentialityAgreementName: yup.string().required(ErrorMessages.REQUIRED),
    confidentialityAgreementDate: yup.string().required(ErrorMessages.REQUIRED).test('checkDate', ErrorMessages.INVALID_DATE, (value) => {
        return moment(value).isSameOrAfter(new Date().toDateString())
    }),
})
