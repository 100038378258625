import React from 'react';
import PropTypes from 'prop-types';
// import shareFacebook from 'share-facebook';

import './styles.scss';
import twitterIcon from 'assets/icons/twitter.svg';
import facebookIcon from 'assets/icons/facebook.svg';

const Share = ({ shareLink, shareText = '', invertColor, scaleable }) => {
  const iconStyle = React.useMemo(
    () => ({ filter: invertColor ? 'invert(1)' : undefined }),
    [invertColor]
  );

  const shareClickHandler = React.useCallback(() => {
    if (navigator.share)
      navigator.share({
        text: shareText,
        url: shareLink,
      });
    else
      console.warn('pew-pew. navigator.share is unsupported by the browser :C');
  }, [shareLink, shareText]);

  const twitterClickHandler = React.useCallback(() => {
    window.open(
      'http://twitter.com/share?url=' +
        encodeURIComponent(shareLink) +
        '&text=' +
        encodeURIComponent(shareText),
      '',
      'left=0,top=0,width=550,height=450,personalbar=0,toolbar=0,scrollbars=0,resizable=0'
    );
  }, [shareLink, shareText]);

  const facebookClickHandler = React.useCallback(() => {
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        shareLink
      )}`,
      'pop',
      'width=600, height=400, scrollbars=no'
    );
  }, [shareLink]);

  return (
    <div
      className={
        (navigator.share ? 'Share__root' : 'Share__root-noNativeShare') +
        (scaleable ? '-scaleable' : '')
      }
    >
      {navigator.share && (
        <span className="Share__native" onClick={shareClickHandler}>
          Share
        </span>
      )}
      <img
        src={twitterIcon}
        className={'twitter' + (scaleable ? '-scaleable' : '')}
        alt="share via twitter"
        style={iconStyle}
        onClick={twitterClickHandler}
      />
      <img
        src={facebookIcon}
        alt="share via facebook"
        style={iconStyle}
        className={'facebook' + (scaleable ? '-scaleable' : '')}
        onClick={facebookClickHandler}
      />
    </div>
  );
};

Share.propTypes = {
  shareLink: PropTypes.string.isRequired,
  invertColor: PropTypes.bool,
  scaleable: PropTypes.bool,
};

export default Share;
