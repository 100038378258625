import React from 'react';
import { useFormikContext } from 'formik';

import FormError from 'modules/core/components/FormError';

const FormikError = ({ name, ...restProps }) => {
  const { submitCount, errors } = useFormikContext();
  if (errors[name] && submitCount > 0) {
    return <FormError {...restProps}>{errors[name]}</FormError>;
  } else return null;
};

export default FormikError;
