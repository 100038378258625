import React from 'react';
import {Button, Modal, ModalBody, Spinner} from "react-bootstrap";
import {ClockIcon} from "../../../../assets/icons/CloclIcon";
import './styles.scss'


const TurnOnTimerModal = ({isOpen, onHide, isLoading, onStartTimer}) => {
    return (
        <Modal className={'Modal'} centered show={isOpen} onHide={onHide} >
            <ModalBody className={'Modal__contentClassName'}>
                <div className={'d-flex flex-column align-items-stretch'} >
                    <div className={'align-self-center'}>
                        <ClockIcon/>
                    </div>
                    <h4 className={'text-center mt-4'}>
                        Don't forget to turn on the timer!
                    </h4>
                    {/*Start now*/}
                    <Button onClick={onStartTimer} className={'TurnOnTimer__button TurnOnTimer__button-green mt-3'}>
                        {isLoading ? <Spinner variant={'dark'} animation={'border'}/> : 'Start now'}
                    </Button>
                    <Button onClick={onHide} className={'TurnOnTimer__button'} variant={'outline-dark mt-3'}>
                        Close
                    </Button>

                </div>
            </ModalBody>
        </Modal>
    );
};

export default TurnOnTimerModal;
