import moment from "moment";

export default function timeFormat(time, type) {
	const difference = moment(new Date()).diff(time);
	// under minute
	if (moment.duration(difference).asMinutes() < 1) {
		return `${parseInt(moment.duration(difference).asSeconds())}s`;
	}
	// under hour
	if (moment.duration(difference).asHours() < 1) {
		return `${parseInt(moment.duration(difference).asMinutes())}m`;
	}
	// under days if activity type = 4 or 12
	if (moment.duration(difference).asDays() < 1 && type === "like&comment") {
		return `from ${parseInt(moment.duration(difference).asHours())}h ago`;
	}
	// under days
	if (moment.duration(difference).asDays() < 1 && type !== "like&comment") {
		return `${parseInt(moment.duration(difference).asHours())}h`;
	}
	// under week
	if (moment.duration(difference).asWeeks() < 1 && type !== "like&comment") {
		return `${parseInt(moment.duration(difference).asDays())}d`;
	}
	// under week if activity type = 4 or 12
	if (moment.duration(difference).asWeeks() < 1 && type === "like&comment") {
		return `from ${parseInt(moment.duration(difference).asDays())}d ago`;
	}
	// under year if activity type = 4 or 12
	if (moment.duration(difference).asYears() < 1 && type === "like&comment") {
		return `from ${moment(time).format("M/D")}`;
	}
	// under year
	if (moment.duration(difference).asYears() < 1 && type !== "like&comment") {
		return `${moment(time).format("M/D")}`;
	}
	// over year
	else {
		return `from ${moment(time).format("M/D/YY")}`;
	}
}
