import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

//module routes
import HomeRoutes from 'modules/main/routes';
import { mainRoutes } from 'modules/main/routes/constants';
import AboutRoutes from 'modules/about/routes';
import { aboutRoutes } from 'modules/about/routes/constants';
import { careerRoutes } from 'modules/career/routes/constants';
import CareerRoutes from 'modules/career/routes';
import DonateRouts from 'modules/donate/routes';
import { donateRouts } from 'modules/donate/routes/constants';
import PostsRoutes from 'modules/posts/routs';
import { postRouts } from 'modules/posts/routs/constants';
import { coreRoutes } from 'modules/core/routes/constants';
import NotFoundPage from 'modules/core/pages/NotFoundPage';
import ErrorPage from 'modules/core/pages/ErrorPage';
import EmailConfirmationPage from 'modules/core/pages/EmailConfirmationRedirectHandler';
import ResetPasswordPage from 'modules/core/pages/ResetPasswordConfirmationHandler';
import TrainingsRoutes from 'modules/trainings/routes';
import { trainingsRoutes } from 'modules/trainings/routes/constants';
import LoginRoutes from 'modules/login/routes';
import { loginRoutes } from 'modules/login/routes/constants';

const AppRoutes = () => {
  return (
    <>
      <Switch>
        <Route path={mainRoutes.root} component={HomeRoutes} exact />
        <Route path={aboutRoutes.root} component={AboutRoutes} exact />
        <Route path={donateRouts.root} component={DonateRouts} exact />
        <Route path={postRouts.root} component={PostsRoutes} />
        <Route path={coreRoutes.notFound} component={NotFoundPage} exact />
        <Route path={coreRoutes.error} component={ErrorPage} exact />
        <Route
          path={coreRoutes.emailConfirmation}
          component={EmailConfirmationPage}
        />
        <Route path={loginRoutes.root} component={LoginRoutes} />

        <Route path={coreRoutes.resetPassword} component={ResetPasswordPage} />
        <Route path={careerRoutes.root} component={CareerRoutes} />
        <Route path={trainingsRoutes.root} component={TrainingsRoutes} />
        <Route path="*">
          <Redirect to={coreRoutes.notFound} />
        </Route>
      </Switch>
    </>
  );
};

export default AppRoutes;
