import React from 'react';
import { useSelector } from 'react-redux';

import Modal from 'modules/core/components/Modal';
import ApiClient from 'modules/core/utils/ApiClient';
import { prettifyError } from 'modules/core/utils/error';

import './styles.scss';

const ContactUsModal = ({ show, onClose }) => {
  const userEmail = useSelector((state) => state.user.email);
  const userName = useSelector((state) => state.user.name);

  const [formData, setFormData] = React.useState({
    email: userEmail ?? '',
    name: userName ?? '',
    questionText: '',
  });

  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState('');

  const handleChange = React.useCallback(
    async (e) => {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    },
    [formData]
  );

  const handleSave = React.useCallback(async () => {
    try {
      if (loading) return;
      setLoading(true);
      await ApiClient.contactSales(formData);
      onClose();
    } catch (err) {
      console.log(err, err.response);
      setError(prettifyError(err));
    }
    setLoading(false);
  }, [onClose, formData, loading]);

  return (
    <Modal
      title="Question"
      show={show}
      onClose={onClose}
      loading={loading}
      onSubmit={handleSave}
      submitTitle="Send"
      error={error}
    >
      <div className="ContactUs__firstRow">
        <div>
          <Modal.ModalInput
            placeholder="Your name"
            id="contact_name"
            label="Your name"
            value={formData.name}
            onChange={handleChange}
            name="name"
          />
        </div>

        <div>
          <Modal.ModalInput
            placeholder="Your@email.com"
            id="contact_email"
            label="Your email address"
            value={formData.email}
            onChange={handleChange}
            name="email"
          />
        </div>
      </div>
      <Modal.ModalInput
        placeholder=""
        id="contact_questionText"
        name="questionText"
        label="Question"
        as="textarea"
        rows={4}
        // className="ContactUs__question"
        value={formData.questionText}
        onChange={handleChange}
      />
    </Modal>
  );
};

export default ContactUsModal;
