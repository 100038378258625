import React from 'react';
import classNames from 'classnames';
import showmoreIcon from 'assets/icons/showmore.svg';

import './styles.scss';
import { adminNotificationsRoutes } from 'modules/admin-notifications/routes/constants';
import { Link } from 'react-router-dom';
import useOutsideClickEventNotification from 'modules/core/hooks/useOutsideClickEventNotifications';
import ApiClient from 'modules/core/utils/ApiClient';
import timeFormat from 'modules/admin-notifications/utils/timeFormat';
import { useDispatch } from 'react-redux';
import { setNotifications } from 'modules/core/store/actions/recentNotificationsActions';
import navigateTo from 'modules/core/utils/notificationsNavigate';

const ViewModeNotification = ({
  show: showSelf,
  toggleShow,
  notifications,
}) => {
  const dispatch = useDispatch();
  const rootRef = React.useRef(null);

  const handleOutsideClick = React.useCallback(() => {
    if (showSelf) {
      toggleShow();
    }
  }, [showSelf, toggleShow]);

  useOutsideClickEventNotification(rootRef, handleOutsideClick);

  const handleClickNotification = (item) => {
    ApiClient.readNotificationItem(item.id)
      .then(() => ApiClient.fetchRecentNotifications())
      .then((response) => {
        navigateTo(item);
        toggleShow();
        dispatch(setNotifications(response.recentNotifications));
      });
  };

  const renderNotifications = ({ item }) => (
    <div
      className="NotificationItem__container"
      onClick={() =>{
        console.log('CLICK', item)
        handleClickNotification(item)
      }}
    >
      <div className="NotificationItem__content">
        <div>
          <span>{item?.message}</span>
        </div>
        <div className="NotificationItem__timeStamp">
          <span>{timeFormat(item?.createdAt)}</span>
        </div>
      </div>
      {!item?.isRead && (
        <div>
          <div className="NotificationItem__unreadItem" />
        </div>
      )}
    </div>
  );

  const renderEmptyList = () => (
    <div className="ViewModeNotification__emptyList">
      <span>No notifications</span>
    </div>
  );

  return (
    <div className="ViewModeSelector__root">
      <div className="ViewModeNotification__content">
        <div
          className={classNames({
            ViewModeSelector__container: true,
            'ViewModeNotification__container--show-full': showSelf,
          })}
        >
          <div className="ViewModeNotification__menuContainer" ref={rootRef}>
            <div className="ViewModeNotification__footer">
              <Link
                to={adminNotificationsRoutes.root}
                onClick={() => toggleShow()}
              >
                <span>See all</span>
                <img
                  src={showmoreIcon}
                  alt="Collapse"
                  className="ViewModeNotification__showMoreIcon"
                />
              </Link>
            </div>
            <div className="ViewModeNotification__separator" />
            {notifications.length
              ? notifications.map((item) => renderNotifications({ item }))
              : renderEmptyList()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewModeNotification;
