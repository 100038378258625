import React from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'react-bootstrap';
import classNames from 'classnames';

import { BreakpointContextConsumer } from 'modules/core/contexts/BreakpointContext';

import './styles.scss';

const PersonDescription = ({
  name,
  experience,
  addtitionalDescription,
  description,
  btnClassName,
}) => {
  const [collapsed, setCollapsed] = React.useState(true);

  const handleReadMoreClick = React.useCallback(() => {
    setCollapsed(!collapsed);
  }, [collapsed]);

  return (
    <BreakpointContextConsumer>
      {({ isMobile }) => (
        <div>
          {!isMobile && (
            <div className="PersonDescription__title-wrapper">
              <h1 className="PersonDescription__title">{name}</h1>{' '}
              <span className="PersonDescription__experience">
                {experience}
              </span>
            </div>
          )}
          <p className="PersonDescription__description">{description}</p>
          <Collapse in={!collapsed}>
            <p className="PersonDescription__description">
              {addtitionalDescription}
            </p>
          </Collapse>
          {addtitionalDescription && (
            <p
              role="button"
              className={classNames(
                'PersonDescription__readMore',
                btnClassName
              )}
              onClick={handleReadMoreClick}
            >
              {collapsed ? 'Read More' : 'Hide'}
            </p>
          )}
        </div>
      )}
    </BreakpointContextConsumer>
  );
};

PersonDescription.propTypes = {
  description: PropTypes.string.isRequired,
  addtitionalDescription: PropTypes.string,
  name: PropTypes.string.isRequired,
  btnClassName: PropTypes.string,
  experience: PropTypes.string,
};

export default PersonDescription;
