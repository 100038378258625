import React from 'react';
import './styles.scss'

const CustomRadio = React.forwardRef(({label,field, isBlack = false, ...rest}, ref) => {
    return (
        <div className={'CustomRadio__container'}>
            <input
                {...field}
                {...rest}
                ref={ref}
                type={'radio'}/>
            {
                label ? (
                        <span style={{color: isBlack? '#333333' : '#909090'}} className={'CustomRadio__label'}>
                            {label}
                         </span>
                    )
                    : null
            }

        </div>
    );
});

export default CustomRadio;
