/**
 * @param {Object} node
 * @param {FormData} formData
 * @param {string} currentKey
 */
const serializeNode = (node, formData, parentKey = '') => {
  for (let childKey in node) {
    const child = node[childKey];
    const keyStart = parentKey ? `${parentKey}.` : '';

    if (Array.isArray(child)) {
      for (let i = 0; i < child.length; i++) {
        if (typeof child[i] === 'object') {
          serializeNode(child[i], formData, `${keyStart}${childKey}[${i}]`);
        } else {
          formData.append(`${keyStart}${childKey}[${i}]`, child[i]);
        }
      }
    } else if (
      typeof child === 'object' &&
      child?.constructor?.name !== 'File'
    ) {
      serializeNode(child, formData, `${keyStart}${childKey}`);
    } else {
      formData.append(`${keyStart}${childKey}`, child);
    }
  }
};

/**
 * @param {object} object
 * @returns {FormData}
 */
const serializeToFormData = (object) => {
  const result = new FormData();
  serializeNode(object, result);
  return result;
};

export default serializeToFormData;
