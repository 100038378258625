import React, {useEffect, useMemo} from 'react';
import './styles.scss'
import {Formik, Form, Field} from 'formik'
import CustomRadio from "../../../../core/components/CustomRadio";
import LabeledFormControl from "../../../../core/components/FormControl";
import {Button, Col, FormCheck, Row, Spinner} from "react-bootstrap";
import DatePicker from "../../../../admin/components/DatePicker";
import PhoneInput from "../../../../admin/components/PhoneInput";
import {
    EmploymentAgency,
    EmploymentRole,
    fifthStepValues,
    ForsterCareAgency,
    ForsterCareRole, STEPS, stepsPath,
    YesOrNoValues
} from "../../../constants";
import FormikError from "../../../../admin/components/FormikError";
import {fifthStepSchema} from "../../../validation";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {careerRoutes} from "../../../routes/constants";
import Stepper from "../../Stepper";
import {useDispatch, useSelector} from "react-redux";
import {getFifthStep, updateFifthStep} from "../../../../core/store/actions/careerActions";
import LoadingActionButton from "../../LoadingButton";
import CustomPrompt from "../../CustomPrompt";

export const CareerApplyFifthStep = () => {
    const {id} = useParams()
    const location = useLocation()
    const history = useHistory()
    const {loading, stepLoading, stepValues} = useSelector((state) => state.career)
    const dispatch = useDispatch()
    const onSubmit = (values) => {
        const payload = {
            ...values,
            applicationId: Number(id),
            parentSignatureDate: values?.parentSignatureDate ? values.parentSignatureDate.toLocaleDateString('en-US') : '',
            fingerprintedBeforeDate: (values?.fingerprintedBeforeDate && values?.fingerprintedBefore) ? values.fingerprintedBeforeDate.toLocaleDateString('en-US') : '',
            effectiveDate: values?.effectiveDate ? values.effectiveDate.toLocaleDateString('en-US') : '',
            dateOfBirth: values?.dateOfBirth ? values.dateOfBirth.toLocaleDateString('en-US') : '',
            signatureDate: values?.signatureDate ? values.signatureDate.toLocaleDateString('en-US') : '',
        }
        dispatch(updateFifthStep(payload))
    }
    const onPrev = () => {
        history.push(careerRoutes.stepFn(id, stepsPath.fourthStep), {
            activeStep: 3
        })
    }
    const mappedValues = useMemo(() => {
        if (stepValues?.requestingAgency) {
            return {
                ...stepValues,
                parentSignatureDate: stepValues?.parentSignatureDate ? new Date(stepValues.parentSignatureDate) : '',
                fingerprintedBeforeDate: stepValues?.fingerprintedBeforeDate ? new Date(stepValues.fingerprintedBeforeDate) : '',
                effectiveDate: stepValues?.effectiveDate ? new Date(stepValues.effectiveDate) : '',
                dateOfBirth: stepValues?.dateOfBirth ? new Date(stepValues.dateOfBirth) : '',
                signatureDate: stepValues?.signatureDate ? new Date(stepValues.signatureDate) : '',
                employmentAgencyOther: stepValues?.employmentAgencyOther ? stepValues?.employmentAgencyOther : '',
            }

        } else {
            return fifthStepValues
        }

    }, [stepValues])
    useEffect(() => {
        if (id) {
            dispatch(getFifthStep(id))
        }
    }, [dispatch, id])
    return (
        <>
            {
                stepLoading ?
                    <div className={'text-center'}>
                        <Spinner animation="border"/>
                    </div>
                    :
                    <>
                        <Stepper activeStep={location.state?.activeStep} steps={STEPS}/>
                        <Formik
                            validationSchema={fifthStepSchema}
                            initialValues={{...mappedValues}} onSubmit={onSubmit}
                            render={({handleSubmit, values, setFieldValue, dirty, submitCount}) => (
                              <>
                                  <Form onSubmit={handleSubmit}>
                                      <div className={'FifthStep__item'}>
                                          <h3>Forster care licensing division</h3>
                                          <p className={'mb-0'}>Mailing address: PO BOX 1424 Topekd, KS 66601</p>
                                          <p className={'mb-0'}>Physical address: 500 SW Van Buren Topeka, KS 66603</p>
                                          <p className={'mb-0'}>Website:http:www.dcf.ks.gov</p>
                                          <p className={'mb-0'}>Email: DCF. FCL002@ks.gov</p>
                                          <p className={'mb-0'}>Authorization for background check</p>
                                          <div className={'mt-3'}>
                                              <p className={'mb-0'}>Who should use this form: This form is to be completed
                                                  for any
                                                  person required to have background checks for DCF Forster Care Licensing
                                                  purposes. The subject of the background check must complete sections 3
                                                  and 4.
                                                  Parent of guardian signature required if background check is for a minor
                                                  under
                                                  the age of 18.</p>
                                              <p className={'font-weight-bold'}>In order to be processed, this
                                                  authorization form
                                                  must be completed accurately and in full. For fingerprints please use
                                                  form
                                                  FP-1020</p>
                                          </div>

                                      </div>
                                      <div className={'FifthStep__grid-item'}>
                                          <div className={'FifthStep__grid-item-row'}>
                                              <div className={'flex-grow-1 maxW'}>
                                                  <div className={'padding-grid-box borderBottom'}>Select all that
                                                      apply:
                                                  </div>
                                                  <Row>
                                                      <Col>
                                                          <div
                                                              className={'d-flex padding-grid-box item-spacing-right header-background'}>
                                                              <Field
                                                                  disabled
                                                                  defaultChecked={values.isForsterCare}
                                                                  onChange={(e) => {
                                                                      setFieldValue('isForsterCare', e.target.checked)
                                                                  }}
                                                                  name={'isForsterCare'}
                                                                  type={'checkbox'}
                                                                  className={'checkbox'}
                                                                  component={FormCheck}
                                                              />
                                                              <span className={'font-weight-bold'}>
                                                            A
                                                         </span>
                                                              <span>
                                                            Forster Care / Placement
                                                        </span>
                                                          </div>
                                                          <FormikError name={'isForsterCare'}/>
                                                      </Col>
                                                  </Row>
                                              </div>
                                              <div className={'flex-grow-1 maxW'}>
                                                  <div className={'padding-grid-box borderX'}>Select all that apply:</div>
                                                  <div
                                                      className={'d-flex flex-column padding-grid-box border item-spacing'}>
                                                      <Field
                                                          // disabled={!values.isForsterCare}
                                                          disabled
                                                          onChange={(e) => {
                                                              setFieldValue('forsterCareAgency', Number(e.target.value))
                                                          }}
                                                          defaultChecked={ForsterCareAgency.FamilyForster === values.forsterCareAgency}
                                                          value={ForsterCareAgency.FamilyForster}
                                                          name={'forsterCareAgency'}
                                                          label={'Family Foster'}
                                                          component={CustomRadio}
                                                      />
                                                      <Field
                                                          // disabled={!values.isForsterCare}
                                                          disabled
                                                          onChange={(e) => {
                                                              setFieldValue('forsterCareAgency', Number(e.target.value))
                                                          }}
                                                          defaultChecked={ForsterCareAgency.HomeRelative === values.forsterCareAgency}
                                                          value={ForsterCareAgency.HomeRelative}
                                                          name={'forsterCareAgency'}
                                                          label={'Home relative'}
                                                          component={CustomRadio}
                                                      />
                                                      <Field
                                                          // disabled={!values.isForsterCare}
                                                          disabled
                                                          onChange={(e) => {
                                                              setFieldValue('forsterCareAgency', Number(e.target.value))
                                                          }}
                                                          defaultChecked={ForsterCareAgency.PlacementIcpc === values.forsterCareAgency}
                                                          value={ForsterCareAgency.PlacementIcpc}
                                                          name={'forsterCareAgency'}
                                                          label={'Placement ICPC'}
                                                          component={CustomRadio}
                                                      />
                                                      <Row>
                                                          <Col>
                                                              <div className={'d-flex align-items-center'}>
                                                                  <Field
                                                                      // disabled={!values.isForsterCare}
                                                                      disabled
                                                                      onChange={(e) => {
                                                                          setFieldValue('forsterCareAgency', Number(e.target.value))
                                                                      }}
                                                                      defaultChecked={ForsterCareAgency.Other === values.forsterCareAgency}
                                                                      value={ForsterCareAgency.Other}
                                                                      name={'forsterCareAgency'}
                                                                      component={CustomRadio}
                                                                  />
                                                                  <Field
                                                                      // disabled={!values.isForsterCare}
                                                                      disabled
                                                                      name={'forsterCareAgencyOther'}
                                                                      className={'ml-3'}
                                                                      placeholder={'Other'}
                                                                      component={LabeledFormControl}
                                                                  />
                                                              </div>
                                                              <FormikError name={'forsterCareAgencyOther'}/>
                                                          </Col>
                                                      </Row>
                                                      <FormikError name={'forsterCareAgency'}/>
                                                  </div>
                                              </div>
                                              <Row>
                                                  <Col>
                                                      <div className={'flex-grow-1'}>
                                                          <div className={'padding-grid-box borderBottom'}>
                                                              Role/Affiliation: (Select One):
                                                          </div>
                                                          <div
                                                              className={'d-flex flex-column padding-grid-box item-spacing'}>
                                                              <Field
                                                                  // disabled={!values.isForsterCare}
                                                                  disabled
                                                                  onChange={(e) => {
                                                                      setFieldValue('forsterCareRole', Number(e.target.value))
                                                                  }}
                                                                  defaultChecked={ForsterCareRole.Applicant === values.forsterCareRole}
                                                                  value={ForsterCareRole.Applicant}
                                                                  name={'forsterCareRole'}
                                                                  label={'Applicant'}
                                                                  component={CustomRadio}
                                                              />
                                                              <Field
                                                                  // disabled={!values.isForsterCare}
                                                                  disabled
                                                                  onChange={(e) => {
                                                                      setFieldValue('forsterCareRole', Number(e.target.value))
                                                                  }}
                                                                  defaultChecked={ForsterCareRole.ResidentOfHome === values.forsterCareRole}
                                                                  value={ForsterCareRole.ResidentOfHome}
                                                                  name={'forsterCareRole'}
                                                                  label={'Resident of home'}
                                                                  component={CustomRadio}
                                                              />
                                                              <Field
                                                                  // disabled={!values.isForsterCare}
                                                                  disabled
                                                                  onChange={(e) => {
                                                                      setFieldValue('forsterCareRole', Number(e.target.value))
                                                                  }}
                                                                  defaultChecked={ForsterCareRole.AlternativeCaregiver === values.forsterCareRole}
                                                                  value={ForsterCareRole.AlternativeCaregiver}
                                                                  name={'forsterCareRole'}
                                                                  label={'Alternative Caregiver'}
                                                                  component={CustomRadio}
                                                              />
                                                              <FormikError name={'forsterCareRole'}/>
                                                          </div>
                                                      </div>
                                                  </Col>

                                              </Row>
                                          </div>
                                          <div className={'FifthStep__grid-item-row'}>
                                              <div className={'flex-grow-1 maxW'}>
                                                  <Row>
                                                      <Col>
                                                          <div
                                                              className={'d-flex padding-grid-box item-spacing-right header-background borderTop'}>
                                                              <Field
                                                                  disabled
                                                                  defaultChecked={values.isEmployment}
                                                                  onChange={(e) => {
                                                                      setFieldValue('isEmployment', e.target.checked)
                                                                  }}
                                                                  name={'isEmployment'}
                                                                  type={'checkbox'}
                                                                  className={'checkbox'}
                                                                  component={FormCheck}
                                                              />
                                                              <span className={'font-weight-bold'}>
                                                                 B
                                                            </span>
                                                              <span>
                                                                 Employment / Provider
                                                             </span>
                                                          </div>
                                                          <FormikError name={'isEmployment'}/>
                                                      </Col>
                                                  </Row>
                                              </div>
                                              <div className={'flex-grow-1 maxW'}>
                                                  <div
                                                      className={'d-flex flex-column padding-grid-box border item-spacing'}>
                                                      <Field
                                                          // disabled={!values.isEmployment}
                                                          disabled
                                                          onChange={(e) => {
                                                              setFieldValue('employmentAgency', Number(e.target.value))
                                                          }}
                                                          defaultChecked={EmploymentAgency.ChildPlacingAgency === values.employmentAgency}
                                                          value={EmploymentAgency.ChildPlacingAgency}
                                                          name={'employmentAgency'}
                                                          label={'Child Placing Agency'}
                                                          component={CustomRadio}
                                                      />
                                                      <Field
                                                          // disabled={!values.isEmployment}
                                                          disabled
                                                          onChange={(e) => {
                                                              setFieldValue('employmentAgency', Number(e.target.value))
                                                          }}
                                                          defaultChecked={EmploymentAgency.ResidentialCenter === values.employmentAgency}
                                                          value={EmploymentAgency.ResidentialCenter}
                                                          name={'employmentAgency'}
                                                          label={'Residential Center/ Group Boarding Home'}
                                                          component={CustomRadio}
                                                      />
                                                      <Field
                                                          // disabled={!values.isEmployment}
                                                          disabled
                                                          onChange={(e) => {
                                                              setFieldValue('employmentAgency', Number(e.target.value))
                                                          }}
                                                          defaultChecked={EmploymentAgency.Detention === values.employmentAgency}
                                                          value={EmploymentAgency.Detention}
                                                          name={'employmentAgency'}
                                                          label={'Detention/Secure Care Center'}
                                                          component={CustomRadio}
                                                      />
                                                      <Field
                                                          // disabled={!values.isEmployment}
                                                          disabled
                                                          onChange={(e) => {
                                                              setFieldValue('employmentAgency', Number(e.target.value))
                                                          }}
                                                          defaultChecked={EmploymentAgency.StaffSecureFacility === values.employmentAgency}
                                                          value={EmploymentAgency.StaffSecureFacility}
                                                          name={'employmentAgency'}
                                                          label={'Staff Secure Facility'}
                                                          component={CustomRadio}
                                                      />
                                                      <Field
                                                          // disabled={!values.isEmployment}
                                                          disabled
                                                          onChange={(e) => {
                                                              setFieldValue('employmentAgency', Number(e.target.value))
                                                          }}
                                                          defaultChecked={EmploymentAgency.AttendantCareFacility === values.employmentAgency}
                                                          value={EmploymentAgency.AttendantCareFacility}
                                                          name={'employmentAgency'}
                                                          label={'Attendant Care Facility'}
                                                          component={CustomRadio}
                                                      />
                                                      <Field
                                                          // disabled={!values.isEmployment}
                                                          disabled
                                                          onChange={(e) => {
                                                              setFieldValue('employmentAgency', Number(e.target.value))
                                                          }}
                                                          defaultChecked={EmploymentAgency.Jcic === values.employmentAgency}
                                                          value={EmploymentAgency.Jcic}
                                                          name={'employmentAgency'}
                                                          label={'JCIC'}
                                                          component={CustomRadio}
                                                      />
                                                      <Row>
                                                          <Col>
                                                              <div className={'d-flex align-items-center'}>
                                                                  <Field
                                                                      // disabled={!values.isEmployment}
                                                                      disabled
                                                                      onChange={(e) => {
                                                                          setFieldValue('employmentAgency', Number(e.target.value))
                                                                      }}
                                                                      defaultChecked={EmploymentAgency.Other === values.employmentAgency}
                                                                      value={EmploymentAgency.Other}
                                                                      name={'employmentAgency'}
                                                                      component={CustomRadio}
                                                                  />
                                                                  <Field
                                                                      // disabled={!values.isEmployment}
                                                                      disabled
                                                                      name={'employmentAgencyOther'}
                                                                      className={'ml-3'}
                                                                      placeholder={'Other'}
                                                                      component={LabeledFormControl}
                                                                  />
                                                              </div>
                                                              <FormikError name={'employmentAgencyOther'}/>
                                                          </Col>
                                                      </Row>
                                                      <FormikError name={'employmentAgency'}/>
                                                  </div>
                                              </div>
                                              <div className={'flex-grow-1 maxW'}>
                                                  <div
                                                      className={'d-flex flex-column padding-grid-box item-spacing borderTop'}>
                                                      <Field
                                                          // disabled={!values.isEmployment}
                                                          disabled
                                                          name={'employmentRole'}
                                                          onChange={(e) => {
                                                              setFieldValue('employmentRole', Number(e.target.value))
                                                          }}
                                                          defaultChecked={EmploymentRole.EmploymentCandidate === values.employmentRole}
                                                          value={EmploymentRole.EmploymentCandidate}
                                                          label={'Employment candidate'}
                                                          component={CustomRadio}
                                                      />
                                                      <Field
                                                          // disabled={!values.isEmployment}
                                                          disabled
                                                          name={'employmentRole'}
                                                          onChange={(e) => {
                                                              setFieldValue('employmentRole', Number(e.target.value))
                                                          }}
                                                          defaultChecked={EmploymentRole.Volunteer === values.employmentRole}
                                                          value={EmploymentRole.Volunteer}
                                                          label={'Volunteer'}
                                                          component={CustomRadio}
                                                      />
                                                      <Field
                                                          // disabled={!values.isEmployment}
                                                          disabled
                                                          name={'employmentRole'}
                                                          onChange={(e) => {
                                                              setFieldValue('employmentRole', Number(e.target.value))
                                                          }}
                                                          defaultChecked={EmploymentRole.Other === values.employmentRole}
                                                          value={EmploymentRole.Other}
                                                          label={'Other'}
                                                          component={CustomRadio}
                                                      />
                                                      <Field
                                                          // disabled={!values.isEmployment}
                                                          disabled
                                                          name={'employmentRole'}
                                                          onChange={(e) => {
                                                              setFieldValue('employmentRole', Number(e.target.value))
                                                          }}
                                                          defaultChecked={EmploymentRole.EmploymentCandidateNotRequiringFingerprints === values.employmentRole}
                                                          value={EmploymentRole.EmploymentCandidateNotRequiringFingerprints}
                                                          label={'Employment candidate not requiring fingerprints'}
                                                          component={CustomRadio}
                                                      />
                                                      <FormikError name={'employmentRole'}/>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                      <div className={'FifthStep__item item-spacing'}>
                                          <Row className={'align-items-center col-spacing-md'}>
                                              <Col lg={6} md>
                                                  <div>Have you been fingerprinted for DCF before?</div>
                                              </Col>
                                              <Col lg={3} md>
                                                  <div className={'d-flex'}>
                                                      <Field
                                                          name={'fingerprintedBefore'}
                                                          onChange={() => {
                                                              setFieldValue('fingerprintedBefore', true)
                                                          }}
                                                          defaultChecked={values?.fingerprintedBefore?.toString() === YesOrNoValues.YES}
                                                          value={YesOrNoValues.YES}
                                                          label={'Yes'}
                                                          component={CustomRadio}
                                                      />
                                                      <div className={'margin-left'}>
                                                          <Field
                                                              name={'fingerprintedBefore'}
                                                              onChange={() => {
                                                                  setFieldValue('fingerprintedBefore', false)
                                                              }}
                                                              defaultChecked={values?.fingerprintedBefore?.toString() === YesOrNoValues.NO}
                                                              value={YesOrNoValues.NO}
                                                              label={"No"}
                                                              component={CustomRadio}
                                                          />
                                                      </div>
                                                  </div>
                                              </Col>
                                              <Col lg={3} md>
                                                  <DatePicker
                                                      disabled={values?.fingerprintedBefore?.toString() === YesOrNoValues.NO}
                                                      yearDropdownItemNumber={100}
                                                      scrollableYearDropdown
                                                      showYearDropdown
                                                      showMonthDropdown
                                                      placeholderText={'If yes, Date Submitted:'}
                                                      selected={values.fingerprintedBeforeDate}
                                                      onChange={(e) => {
                                                          setFieldValue('fingerprintedBeforeDate', e)
                                                      }}
                                                  />
                                                  <FormikError name={'fingerprintedBeforeDate'}/>
                                              </Col>
                                              <FormikError name={'fingerprintedBefore'}/>
                                          </Row>
                                          <Row className={'col-spacing-md'}>
                                              <Col lg={6} md>
                                                  <div>Have fingerprint been submitted?</div>
                                              </Col>
                                              <Col lg={3} md>
                                                  <div className={'d-flex'}>
                                                      <Field
                                                          name={'fingerprintSubmited'}
                                                          onChange={() => {
                                                              setFieldValue('fingerprintSubmited', true)
                                                          }}
                                                          defaultChecked={values?.fingerprintSubmited?.toString() === YesOrNoValues.YES}
                                                          value={YesOrNoValues.YES}
                                                          label={'Yes'}
                                                          component={CustomRadio}
                                                      />
                                                      <div className={'margin-left'}>
                                                          <Field
                                                              name={'fingerprintSubmited'}
                                                              onChange={() => {
                                                                  setFieldValue('fingerprintSubmited', false)
                                                              }}
                                                              defaultChecked={values?.fingerprintSubmited?.toString() === YesOrNoValues.NO}
                                                              value={YesOrNoValues.NO}
                                                              label={"No"}
                                                              component={CustomRadio}
                                                          />
                                                      </div>

                                                  </div>
                                              </Col>
                                              <FormikError name={'fingerprintSubmited'}/>
                                          </Row>
                                          <Row className={'col-spacing-md'}>
                                              <Col lg={6} md>
                                                  <div>Will this person be providing direct care or services to children
                                                      in DCF
                                                      Custody?
                                                  </div>
                                              </Col>
                                              <Col lg={3} md>
                                                  <div className={'d-flex'}>
                                                      <Field
                                                          name={'personDirectCare'}
                                                          onChange={() => {
                                                              setFieldValue('personDirectCare', true)
                                                          }}
                                                          defaultChecked={values?.personDirectCare?.toString() === YesOrNoValues.YES}
                                                          value={YesOrNoValues.YES}
                                                          label={'Yes'}
                                                          component={CustomRadio}
                                                      />
                                                      <div className={'margin-left'}>
                                                          <Field
                                                              name={'personDirectCare'}
                                                              onChange={() => {
                                                                  setFieldValue('personDirectCare', false)
                                                              }}
                                                              defaultChecked={values?.personDirectCare?.toString() === YesOrNoValues.NO}
                                                              value={YesOrNoValues.YES}
                                                              label={"No"}
                                                              component={CustomRadio}
                                                          />
                                                      </div>
                                                  </div>
                                              </Col>
                                              <FormikError name={'personDirectCare'}/>
                                          </Row>
                                      </div>
                                      <div className={'FifthStep__item row-spacing'}>
                                          <h3 style={{textTransform: 'uppercase'}}>1.1 To be completed only when removing
                                              an
                                              affiliate</h3>
                                          <p>This section is required to be completed on all providers in Section 1
                                              Category A
                                              Foster Care/Placement and is optional for Providers in Category B
                                              Employment/Provider, Sections 2 and 3 will need to be filled out: Section 4
                                              is not
                                              required when removing an affiliate.</p>
                                          <Row className={'col-spacing-sm'}>
                                              <Col sm>
                                                  <DatePicker
                                                      label={'Effective Date:'}
                                                      yearDropdownItemNumber={100}
                                                      scrollableYearDropdown
                                                      showYearDropdown
                                                      showMonthDropdown
                                                      placeholderText={'mm/dd/yyyy'}
                                                      selected={values.effectiveDate}
                                                      onChange={(e) => {
                                                          setFieldValue('effectiveDate', e)
                                                      }}
                                                  />
                                                  <FormikError name={'effectiveDate'}/>
                                              </Col>
                                              <Col sm>
                                                  <Field
                                                      name={'reasonForRemoval'}
                                                      label={'Reason for removal:'}
                                                      placeholder={'Enter'}
                                                      component={LabeledFormControl}
                                                  />
                                                  <FormikError name={'reasonForRemoval'}/>
                                              </Col>
                                          </Row>
                                      </div>
                                      <div className={'FifthStep__item row-spacing'}>
                                          <h3 style={{textTransform: 'uppercase'}}>2 To be completed by the requesting
                                              agency</h3>
                                          <Row>
                                              <Col>
                                                  <Field
                                                      name={'requestingAgency'}
                                                      label={'Requesting Agency:'}
                                                      placeholder={'Enter'}
                                                      component={LabeledFormControl}
                                                  />
                                                  <FormikError name={'requestingAgency'}/>
                                              </Col>
                                          </Row>
                                          <Row>
                                              <Col>
                                                  <Field
                                                      name={'familyForsterHomeName'}
                                                      label={'Facility/Agency/Family Foster Home name or license number to have person affiliated with:'}
                                                      placeholder={'Enter'}
                                                      component={LabeledFormControl}
                                                  />
                                                  <FormikError name={'familyForsterHomeName'}/>
                                              </Col>
                                          </Row>
                                          <Row className={'col-spacing-sm'}>
                                              <Col>
                                                  <Field
                                                      name={'applicableLicenseNumbers'}
                                                      label={'If needing to be affiliated with multiple facilities, list all applicable license numbers:'}
                                                      placeholder={'Enter'}
                                                      component={LabeledFormControl}
                                                  />
                                                  <FormikError name={'applicableLicenseNumbers'}/>
                                              </Col>
                                          </Row>
                                          <Row className={'col-spacing-sm'}>
                                              <Col sm>
                                                  <Field
                                                      name={'contactPersonName'}
                                                      label={'Contact Person Name:'}
                                                      placeholder={'Enter'}
                                                      component={LabeledFormControl}
                                                  />
                                                  <FormikError name={'contactPersonName'}/>
                                              </Col>
                                              <Col sm>
                                                  <Field
                                                      name={'streetAddress'}
                                                      label={'Street Address:'}
                                                      placeholder={'Enter'}
                                                      component={LabeledFormControl}
                                                  />
                                                  <FormikError name={'streetAddress'}/>
                                              </Col>
                                          </Row>
                                          <Row className={'col-spacing-sm'}>
                                              <Col sm>
                                                  <Field
                                                      name={'city'}
                                                      label={'City:'}
                                                      placeholder={'Enter'}
                                                      component={LabeledFormControl}
                                                  />
                                                  <FormikError name={'city'}/>
                                              </Col>
                                              <Col sm>
                                                  <Field
                                                      name={'state'}
                                                      label={'State:'}
                                                      placeholder={'Enter'}
                                                      component={LabeledFormControl}
                                                  />
                                                  <FormikError name={'state'}/>
                                              </Col>
                                          </Row>
                                          <Row className={'col-spacing-sm'}>
                                              <Col sm>
                                                  <Field
                                                      name={'zip'}
                                                      label={'Zip:'}
                                                      placeholder={'Enter'}
                                                      component={LabeledFormControl}
                                                  />
                                                  <FormikError name={'zip'}/>
                                              </Col>
                                              <Col sm>
                                                  <Field
                                                      value={values.phone}
                                                      onChange={(e) => {
                                                          setFieldValue('phone', e)
                                                      }}
                                                      name={'phone'}
                                                      label={'Phone:'}
                                                      placeholder={'Enter'}
                                                      component={PhoneInput}
                                                  />
                                                  <FormikError name={'phone'}/>
                                              </Col>
                                              <Col sm>
                                                  <Field
                                                      name={'email'}
                                                      label={'Email:'}
                                                      placeholder={'Enter'}
                                                      component={LabeledFormControl}
                                                  />
                                                  <FormikError name={'email'}/>
                                              </Col>
                                          </Row>
                                      </div>
                                      <div className={'FifthStep__item row-spacing'}>
                                          <h3>3</h3>
                                          <Row className={'col-spacing-sm'}>
                                              <Col sm>
                                                  <Field
                                                      name={'firstName'}
                                                      label={'First Name:'}
                                                      placeholder={'Enter'}
                                                      component={LabeledFormControl}
                                                  />
                                                  <FormikError name={'firstName'}/>
                                              </Col>
                                              <Col sm>
                                                  <Field
                                                      name={'middleName'}
                                                      label={'Middle Name:'}
                                                      placeholder={'Enter'}
                                                      component={LabeledFormControl}
                                                  />
                                                  <FormikError name={'middleName'}/>
                                              </Col>
                                              <Col sm>
                                                  <Field
                                                      name={'lastName'}
                                                      label={'Last Name:'}
                                                      placeholder={'Enter'}
                                                      component={LabeledFormControl}
                                                  />
                                                  <FormikError name={'lastName'}/>
                                              </Col>
                                          </Row>
                                          <Row className={'col-spacing-sm'}>
                                              <Col lg={4} md={6} sm>
                                                  <DatePicker
                                                      maxDate={new Date()}
                                                      label={'Date of Birth:'}
                                                      yearDropdownItemNumber={100}
                                                      scrollableYearDropdown
                                                      showYearDropdown
                                                      showMonthDropdown
                                                      placeholderText={'mm/dd/yyyy'}
                                                      selected={values.dateOfBirth}
                                                      onChange={(e) => {
                                                          setFieldValue('dateOfBirth', e)
                                                      }}
                                                  />
                                                  <FormikError name={'dateOfBirth'}/>
                                              </Col>
                                              <Col lg={4} md={6} sm>
                                                  <div className={'d-flex item-spacing-right helper-margin'}>
                                                      <Field
                                                          onChange={() => {
                                                              setFieldValue('isMale', false)
                                                          }}
                                                          value={YesOrNoValues.YES}
                                                          name={'isMale'}
                                                          defaultChecked={values?.isMale?.toString() === YesOrNoValues.YES}
                                                          label={'Male'}
                                                          component={CustomRadio}
                                                      />
                                                      <Field
                                                          onChange={() => {
                                                              setFieldValue('isMale', false)
                                                          }}
                                                          value={YesOrNoValues.NO}
                                                          name={'isMale'}
                                                          defaultChecked={values?.isMale?.toString() === YesOrNoValues.NO}
                                                          label={'Female'}
                                                          component={CustomRadio}
                                                      />
                                                      <span className={'helper-text'}>(mark one)</span>
                                                  </div>
                                                  <FormikError name={'isMale'}/>
                                              </Col>

                                          </Row>
                                          <Row className={'align-items-end col-spacing-sm'}>
                                              <Col lg={6} md>
                                                  <Field
                                                      name={'maidenNamesUsed'}
                                                      label={'Maiden and/or Any Names Formerly Used (First/Middle/Last):'}
                                                      placeholder={'Enter'}
                                                      component={LabeledFormControl}
                                                  />
                                                  <FormikError name={'maidenNamesUsed'}/>
                                              </Col>
                                              <Col lg={3} md>
                                                  <Field
                                                      name={'snn'}
                                                      label={'SNN:'}
                                                      placeholder={'Enter'}
                                                      component={LabeledFormControl}
                                                  />
                                                  <FormikError name={'snn'}/>
                                              </Col>
                                              <Col lg={3} md>
                                                  <Field
                                                      name={'race'}
                                                      label={'Race'}
                                                      placeholder={'Enter'}
                                                      component={LabeledFormControl}
                                                  />
                                                  <FormikError name={'race'}/>

                                              </Col>
                                          </Row>
                                          <Row>
                                              <Col>
                                                  <Field
                                                      name={'currentStreetAddress'}
                                                      label={'Current Street Address / Apt / Lot#'}
                                                      placeholder={'Enter'}
                                                      component={LabeledFormControl}
                                                  />
                                                  <FormikError name={'currentStreetAddress'}/>

                                              </Col>
                                          </Row>
                                          <Row>
                                              <Col>
                                                  <Field
                                                      name={'outsideAddress'}
                                                      label={'If you have lived out of the state of Kansas in the last 5 years, please included all addresses below. Please Include Street/City/State/Zip Dates From/To'}
                                                      placeholder={'Enter'}
                                                      component={LabeledFormControl}
                                                  />
                                                  <FormikError name={'outsideAddress'}/>

                                              </Col>
                                          </Row>
                                          <Row className={'col-spacing-sm'}>
                                              <Col sm>
                                                  <Field
                                                      name={'outsideCity'}
                                                      label={'City:'}
                                                      placeholder={'Enter'}
                                                      component={LabeledFormControl}
                                                  />
                                                  <FormikError name={'outsideCity'}/>

                                              </Col>
                                              <Col sm>
                                                  <Field
                                                      name={'outsideState'}
                                                      label={'State:'}
                                                      placeholder={'Enter'}
                                                      component={LabeledFormControl}
                                                  />
                                                  <FormikError name={'outsideState'}/>

                                              </Col>

                                          </Row>
                                          <Row className={'col-spacing-sm'}>
                                              <Col md>
                                                  <Field
                                                      name={'outsideZip'}
                                                      label={'Zip:'}
                                                      placeholder={'Enter'}
                                                      component={LabeledFormControl}
                                                  />
                                                  <FormikError name={'outsideZip'}/>
                                              </Col>
                                              <Col md>
                                                  <Field
                                                      value={values.outsidePhone}
                                                      name={'outsidePhone'}
                                                      onChange={(e) => {
                                                          setFieldValue('outsidePhone', e)
                                                      }}
                                                      label={'Phone:'}
                                                      placeholder={'Enter'}
                                                      component={PhoneInput}
                                                  />
                                                  <FormikError name={'outsidePhone'}/>

                                              </Col>
                                              <Col md>
                                                  <Field
                                                      name={'outsideEmail'}
                                                      label={'Email:'}
                                                      placeholder={'Enter'}
                                                      component={LabeledFormControl}
                                                  />
                                                  <FormikError name={'outsideEmail'}/>

                                              </Col>
                                          </Row>
                                      </div>
                                      <div className={'FifthStep__item row-spacing'}>
                                          <div className="d-flex">
                                              <h3 style={{textTransform: 'uppercase'}} className={'mb-0'}>4
                                                  Authorization/certification</h3>
                                              <span className={'ml-1 hide'}>
                                        (Select yes or no on each question)
                                    </span>
                                          </div>
                                          <Row className={'col-spacing-sm'}>
                                              <Col md={8}>
                                                  <div>Have you ever been indicated as a perpetrator in an abuse/neglect
                                                      investigation involving a child or adult?
                                                  </div>
                                              </Col>
                                              <Col md={4}>
                                                  <div className={'d-flex align-items-center item-spacing-right'}>
                                                      <Field
                                                          name={'indicatedAsPerpetrator'}
                                                          onChange={() => {
                                                              setFieldValue('indicatedAsPerpetrator', true)
                                                          }}
                                                          defaultChecked={values?.indicatedAsPerpetrator?.toString() === YesOrNoValues.YES}
                                                          value={YesOrNoValues.YES}
                                                          label={'Yes'}
                                                          component={CustomRadio}
                                                      />
                                                      <Field
                                                          name={'indicatedAsPerpetrator'}
                                                          onChange={() => {
                                                              setFieldValue('indicatedAsPerpetrator', false)
                                                          }}
                                                          defaultChecked={values?.indicatedAsPerpetrator?.toString() === YesOrNoValues.NO}
                                                          value={YesOrNoValues.NO}
                                                          label={'No'}
                                                          component={CustomRadio}
                                                      />

                                                  </div>
                                              </Col>
                                              <FormikError name={'indicatedAsPerpetrator'}/>
                                          </Row>
                                          <Row className={'col-spacing-sm'}>
                                              <Col md={8}>
                                                  <div>Have you ever had your parental rights terminated?</div>
                                              </Col>
                                              <Col md={4}>
                                                  <div className={'d-flex align-items-center item-spacing-right'}>
                                                      <Field
                                                          name={'parentalRightsTerminated'}
                                                          onChange={() => {
                                                              setFieldValue('parentalRightsTerminated', true)
                                                          }}
                                                          defaultChecked={values?.parentalRightsTerminated?.toString() === YesOrNoValues.YES}
                                                          value={YesOrNoValues.YES}
                                                          label={'Yes'}
                                                          component={CustomRadio}
                                                      />
                                                      <Field
                                                          name={'parentalRightsTerminated'}
                                                          onChange={() => {
                                                              setFieldValue('parentalRightsTerminated', false)
                                                          }}
                                                          defaultChecked={values?.parentalRightsTerminated?.toString() === YesOrNoValues.NO}
                                                          value={YesOrNoValues.NO}
                                                          label={'No'}
                                                          component={CustomRadio}
                                                      />

                                                  </div>
                                              </Col>
                                              <FormikError name={'parentalRightsTerminated'}/>
                                          </Row>
                                          <Row className={'col-spacing-sm'}>
                                              <Col md={8}>
                                                  <div>Have you been found to be a disabled person in need of a guarding
                                                      or
                                                      conservator or both?
                                                  </div>
                                              </Col>
                                              <Col md={4}>
                                                  <div className={'d-flex align-items-center item-spacing-right'}>
                                                      <Field
                                                          name={'disabledPersonInNeed'}
                                                          onChange={() => {
                                                              setFieldValue('disabledPersonInNeed', true)
                                                          }}
                                                          defaultChecked={values?.disabledPersonInNeed?.toString() === YesOrNoValues.YES}
                                                          value={YesOrNoValues.YES}
                                                          label={'Yes'}
                                                          component={CustomRadio}
                                                      />
                                                      <Field
                                                          name={'disabledPersonInNeed'}
                                                          onChange={() => {
                                                              setFieldValue('disabledPersonInNeed', false)
                                                          }}
                                                          defaultChecked={values?.disabledPersonInNeed?.toString() === YesOrNoValues.NO}
                                                          value={YesOrNoValues.NO}
                                                          label={'No'}
                                                          component={CustomRadio}
                                                      />

                                                  </div>
                                              </Col>
                                              <FormikError name={'disabledPersonInNeed'}/>
                                          </Row>
                                          <Row className={'col-spacing-sm'}>
                                              <Col md={8}>
                                                  <div>Have you ever been convicted of a criminal offense?</div>
                                              </Col>
                                              <Col md={4}>
                                                  <div className={'d-flex align-items-center item-spacing-right'}>
                                                      <Field
                                                          name={'convictedOrCriminalOffense'}
                                                          onChange={() => {
                                                              setFieldValue('convictedOrCriminalOffense', true)
                                                          }}
                                                          defaultChecked={values?.convictedOrCriminalOffense?.toString() === YesOrNoValues.YES}
                                                          value={YesOrNoValues.YES}
                                                          label={'Yes'}
                                                          component={CustomRadio}
                                                      />
                                                      <Field
                                                          name={'convictedOrCriminalOffense'}
                                                          onChange={() => {
                                                              setFieldValue('convictedOrCriminalOffense', false)
                                                          }}
                                                          defaultChecked={values?.convictedOrCriminalOffense?.toString() === YesOrNoValues.NO}
                                                          value={YesOrNoValues.NO}
                                                          label={'No'}
                                                          component={CustomRadio}
                                                      />

                                                  </div>
                                              </Col>
                                              <FormikError name={'convictedOrCriminalOffense'}/>
                                          </Row>
                                          <Row>
                                              <Col>
                                                  <div className={'d-flex'}>
                                                      <Field
                                                          name={'permissionForBackground'}
                                                          defaultChecked={values.permissionForBackground}
                                                          onChange={(e) => {
                                                              setFieldValue('permissionForBackground', e.target.checked)
                                                          }}
                                                          className={'checkbox'}
                                                          component={FormCheck}
                                                      />
                                                      <p className={'ml-3'}>
                                                          I give permission for background history to be checked by DCF to
                                                          determine
                                                          eligibility for program participation or employment purposes. I
                                                          understand
                                                          the
                                                          informaition released is for excusive and confidential use of
                                                          DCF or
                                                          designee os
                                                          the Secretary.
                                                      </p>
                                                  </div>
                                                  <FormikError name={'permissionForBackground'}/>
                                              </Col>
                                          </Row>
                                          <Row className={'col-spacing-sm'}>
                                              <Col sm>
                                                  <Field
                                                      name={'signature'}
                                                      label={'Signature'}
                                                      placeholder={'Enter'}
                                                      component={LabeledFormControl}
                                                  />
                                                  <FormikError name={'signature'}/>

                                              </Col>
                                              <Col sm>
                                                  <DatePicker
                                                      minDate={new Date()}
                                                      label={'Date'}
                                                      yearDropdownItemNumber={100}
                                                      scrollableYearDropdown
                                                      showYearDropdown
                                                      showMonthDropdown
                                                      placeholderText={'mm/dd/yyyy'}
                                                      selected={values.signatureDate}
                                                      onChange={(e) => {
                                                          setFieldValue('signatureDate', e)
                                                      }}
                                                  />
                                                  <FormikError name={'signatureDate'}/>
                                              </Col>
                                          </Row>
                                          <Row className={'col-spacing-sm'}>
                                              <Col sm>
                                                  <Field
                                                      name={'parentSignature'}
                                                      label={'Parent/Guardian Signature (if under 18)'}
                                                      placeholder={'Enter'}
                                                      component={LabeledFormControl}
                                                  />
                                                  <FormikError name={'parentSignature'}/>

                                              </Col>
                                              <Col sm>
                                                  <DatePicker
                                                      minDate={new Date()}
                                                      label={'Date'}
                                                      yearDropdownItemNumber={100}
                                                      scrollableYearDropdown
                                                      showYearDropdown
                                                      showMonthDropdown
                                                      placeholderText={'mm/dd/yyyy'}
                                                      selected={values.parentSignatureDate}
                                                      onChange={(e) => {
                                                          setFieldValue('parentSignatureDate', e)
                                                      }}
                                                  />
                                                  <FormikError name={'parentSignatureDate'}/>
                                              </Col>
                                          </Row>
                                          <Row>
                                              <Col>
                                                  <Field
                                                      name={'results'}
                                                      label={'Results, dcf use only:'}
                                                      placeholder={'Enter'}
                                                      component={LabeledFormControl}
                                                  />
                                                  <FormikError name={'results'}/>
                                              </Col>
                                          </Row>
                                      </div>
                                      <div className={'Step__footer'}>
                                          <Button
                                              onClick={onPrev}
                                              className={'btn-outline-primary Step__action-button'}>
                                              Back
                                          </Button>
                                          <LoadingActionButton loading={loading} text={'Next'}/>
                                      </div>
                                  </Form>
                                  <CustomPrompt when={dirty && submitCount === 0}/>
                              </>
                            )}
                        />
                    </>
            }
        </>
    );
};

