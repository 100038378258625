import * as yup from 'yup'
import {ErrorMessages} from "./messages";
import {checkYear} from "../utils/checkYear";
import moment from "moment";

export const sixStepSchema = yup.object().shape({
    facilityName: yup.string().required(ErrorMessages.REQUIRED),
    license: yup.string().required(ErrorMessages.REQUIRED),
    streetAddress: yup.string().required(ErrorMessages.REQUIRED),
    city: yup.string().required(ErrorMessages.REQUIRED),
    zipCode: yup.number().required(ErrorMessages.REQUIRED).typeError(ErrorMessages.ONLY_DIGITS)
        .test('len', ErrorMessages.MAX_LENGTH, val => val && val.toString().length <= 10)
    ,
    country: yup.string().required(ErrorMessages.REQUIRED),
    attendantCareFacility: yup.boolean(),
    groupBoardingHome: yup.boolean(),
    secureResidential: yup.boolean(),
    detentionCenter: yup.boolean(),
    staffSecureFacility: yup.boolean(),
    secureCareCenter: yup.boolean(),
    familyForsterHome: yup.boolean(),
    residentialCenter: yup.boolean(),
    firstStaffName: yup.string().required(ErrorMessages.REQUIRED),
    middleStaffName: yup.string().required(ErrorMessages.REQUIRED),
    lastStaffName: yup.string().required(ErrorMessages.REQUIRED),
    staffDateOfBirth: yup.string().required(ErrorMessages.REQUIRED).test('DOB', ErrorMessages.DATE_INVALID, (value) => {
        return checkYear(value)
    }),
    seePhysicianReguarty: yup.boolean().required(ErrorMessages.REQUIRED),
    anyMedicationRegularly: yup.boolean().required(ErrorMessages.REQUIRED),
    anySurgeryInPast: yup.boolean().required(ErrorMessages.REQUIRED),
    anyHandicappingConditions: yup.boolean().required(ErrorMessages.REQUIRED),
    headaches: yup.boolean().required(ErrorMessages.REQUIRED),
    heartDisease: yup.boolean().required(ErrorMessages.REQUIRED),
    highBloodPressure: yup.boolean().required(ErrorMessages.REQUIRED),
    lungDisease: yup.boolean().required(ErrorMessages.REQUIRED),
    cancer: yup.boolean().required(ErrorMessages.REQUIRED),
    diabetes: yup.boolean().required(ErrorMessages.REQUIRED),
    convulsions: yup.boolean().required(ErrorMessages.REQUIRED),
    mentalIllness: yup.boolean().required(ErrorMessages.REQUIRED),
    alcoholism: yup.boolean().required(ErrorMessages.REQUIRED),
    arthrits: yup.boolean().required(ErrorMessages.REQUIRED),
    liverDisease: yup.boolean().required(ErrorMessages.REQUIRED),
    other: yup.boolean().required(ErrorMessages.REQUIRED),
    otherReason: yup.string().when('other', {
        is: true,
        then: yup.string().required(ErrorMessages.REQUIRED)
    }),
    healthAssessmentsPerformance: yup.number().required(ErrorMessages.REQUIRED),
    negativeTuberculinTest: yup.boolean().when('resultsFile', {
        is: (resultsFile) => !!(resultsFile?.name || resultsFile?.hash),
        then: (schema) => schema,
        otherwise: (schema) => schema.required(ErrorMessages.REQUIRED)
    }),
    testDate: yup.string().when('resultsFile', {
        is: (resultsFile) => !!(resultsFile?.name || resultsFile?.hash),
        then: (schema) => schema,
        otherwise: (schema) => schema.required(ErrorMessages.REQUIRED)
    }),
    testRead: yup.string().when('resultsFile', {
        is: (resultsFile) => !!(resultsFile?.name || resultsFile?.hash),
        then: (schema) => schema,
        otherwise: (schema) => schema.required(ErrorMessages.REQUIRED)
    }),
    testReadDate: yup.string().when('resultsFile', {
        is: (resultsFile) => !!(resultsFile?.name || resultsFile?.hash),
        then: (schema) => schema,
        otherwise: (schema) => schema.required(ErrorMessages.REQUIRED)
    }),
    resultsFile: yup.mixed(),
    signature: yup.string().required(ErrorMessages.REQUIRED),
    signatureDate: yup.string().required(ErrorMessages.REQUIRED).test('checkDate', ErrorMessages.INVALID_DATE, (value) => {
        return moment(value).isSameOrAfter(new Date().toDateString())
    }),
})
