import React from 'react';
import {
  Button,
  Container as BootstrapContainer,
  Row,
  Col,
  Image,
} from 'react-bootstrap';

import history from 'modules/core/utils/history';
import { donateRouts } from 'modules/donate/routes/constants';
import { BreakpointContextConsumer } from 'modules/core/contexts/BreakpointContext';
import Container from 'modules/core/components/Container';
import LandscapeImage from 'modules/core/components/LandscapeImage';
import ContactUsModal from 'modules/main/components/ContactUsModal';

import './styles.scss';
import imgBanner from 'assets/images/main-img1.jpg';
import mainPage1Img from 'assets/images/MainPage_1.png';
import mainPage2Img from 'assets/images/MainPage_8.png';
import mainPage3Img from 'assets/images/MainPage_3.png';
import mainPage4Img from 'assets/images/MainPage_4.png';
import mainPage5Img from 'assets/images/MainPage_5.png';
import mainPage6Img from 'assets/images/MainPage_9.png';

import mainPage1ImgMobile from 'assets/images/MainPage_8m.png';
import mainPage2ImgMobile from 'assets/images/MainPage_2m.png';

const MainPage = () => {
  const [showModal, setShowModal] = React.useState(false);

  const toggleContactModalHandler = React.useCallback(() => {
    setShowModal((value) => !value);
  }, []);

  const getStartedClickHandler = React.useCallback(() => {
    history.push(donateRouts.root);
  }, []);

  return (
    <BreakpointContextConsumer>
      {({ isMobile, breakpoint }) => (
        <Container
          className="MainPage__root"
          // paddingHorizontalDesktop={'210px'}
        >
          <ContactUsModal
            show={showModal}
            onClose={toggleContactModalHandler}
          />
          <LandscapeImage
            src={imgBanner}
            className="MainPage__imgContainer"
            imgClassName={'MainPage__kidImg'}
          >
            <div className="MainPage__kidOverlay">
              <div>
                <h1>
                  Finding long-lasting loving homes for every Kansas child.
                </h1>
                <p className="MainPage__overlayText">
                  We are driven, not just to improve the lives of the children
                  and families who come to us for care, but to transform the
                  lives of children and families
                </p>
              </div>

              <p>
                <Button
                  variant="primary"
                  size="lg"
                  onClick={getStartedClickHandler}
                >
                  Get started
                </Button>
              </p>
            </div>
          </LandscapeImage>
          {!isMobile && (
            <BootstrapContainer className="MainPage__rootGrid" fluid>
              <Row>
                <Col sm={6}>
                  <BootstrapContainer>
                    <Row className="MainPage__row1">
                      <Col sm={4}>
                        <div className="MainPage__yellowBox" />
                      </Col>
                      <Col sm={8}>
                        <Image
                          src={mainPage1Img}
                          fluid
                          className="MainPage__image"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={8} className="MainPage__image1Container">
                        <Image
                          src={mainPage2Img}
                          fluid
                          className="MainPage__image"
                        />
                      </Col>
                      <Col sm={4} className="MainPage__greenBoxContainer">
                        <div className="MainPage__greenBox" />
                      </Col>
                    </Row>
                  </BootstrapContainer>
                </Col>
                <Col sm={6}>
                  <BootstrapContainer>
                    <Row>
                      <Col sm={4}>
                        <Image
                          src={mainPage3Img}
                          fluid
                          className="MainPage__image"
                        />
                      </Col>
                      <Col sm={4}>
                        <Image
                          src={mainPage4Img}
                          fluid
                          className="MainPage__image"
                        />
                      </Col>
                      <Col sm={4}>
                        <div className="MainPage__blueBox" />
                      </Col>
                    </Row>
                    <Row className="MainPage__row2">
                      <Col sm={7} className="MainPage__mastersContainer">
                        <h1 className="MainPage__mastersTitle">
                          Restoring Hope For A Brighter Future
                        </h1>
                        <p className="MainPage__text">
                          Our programs provide behavioral support, medication
                          management, and medical support, particularly for teen
                          girls. We also integrate activities that are geared
                          towards the physical, emotional, social, and
                          behavioral growth of our teens.
                        </p>
                        <p>
                          <Button
                            variant="primary"
                            size="lg"
                            onClick={toggleContactModalHandler}
                          >
                            Ask a Question
                          </Button>
                        </p>
                      </Col>
                      <Col sm={5}>
                        <Image src={mainPage5Img} className="MainPage__image" />
                      </Col>
                    </Row>
                    <Row className="MainPage__row2">
                      <Col sm={4}></Col>
                      <Col sm={4} className="MainPage__bottomCol">
                        <div className="MainPage__redBox" />
                      </Col>
                      <Col sm={4} className="MainPage__bottomCol">
                        <Image src={mainPage6Img} className="MainPage__image" />
                      </Col>
                    </Row>
                  </BootstrapContainer>
                </Col>
              </Row>
            </BootstrapContainer>
          )}
          {isMobile && (
            <BootstrapContainer fluid className="MainPage__rootGrid">
              <Row>
                <Col xs={1} className="MainPage__leftColumnt">
                  <div className="MainPage__yellowBoxMobile" />
                </Col>
                <Col xs={5} className="MainPage__centredColumn">
                  <Image src={mainPage2ImgMobile} fluid />
                </Col>
                <Col xs={5} className="MainPage__centredColumn">
                  <Image src={mainPage1ImgMobile} fluid />
                </Col>
                <Col xs={1} className="MainPage__rightColumn">
                  <div className="MainPage__greenBoxMobile" />
                </Col>
              </Row>
              <Row className="MainPage__mastersContainerMobile">
                <h1 className="MainPage__mastersTitle-mobile">
                  Restoring Hope For A Brighter Future
                </h1>
                <p className="MainPage__text">
                  Our programs provide behavioral support, medication
                  management, and medical support, particularly for teen girls.
                  We also integrate activities that are geared towards the
                  physical, emotional, social, and behavioral growth of our
                  teens.
                </p>
                <p>
                  <Button
                    variant="primary"
                    size="lg"
                    onClick={toggleContactModalHandler}
                  >
                    Ask a Question
                  </Button>
                </p>
              </Row>
              <Row>
                <Col xs={7}>
                  <Row style={{ marginBottom: '1.6rem' }}>
                    <Col xs={6}>
                      <Image
                        src={mainPage3Img}
                        fluid
                        className="MainPage__image"
                      />
                    </Col>
                    <Col xs={6}>
                      <Image
                        src={mainPage4Img}
                        fluid
                        className="MainPage__image"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={6}>
                      <div className="MainPage__bottomLeftColumn">
                        <div className="MainPage__redBox MainPage__redBoxAligned" />
                      </div>
                    </Col>
                    <Col xs={6}>
                      <Image
                        src={mainPage6Img}
                        fluid
                        className="MainPage__image"
                      />
                    </Col>
                  </Row>
                </Col>
                <Col xs={5}>
                  <Image src={mainPage5Img} fluid className="MainPage__image" />
                </Col>
              </Row>
            </BootstrapContainer>
          )}
        </Container>
      )}
    </BreakpointContextConsumer>
  );
};

export default MainPage;
