function hashFnv32a(str, asString, seed) {
  /*jshint bitwise:false */
  var i,
    l,
    hval = seed === undefined ? 0x811c9dc5 : seed;

  for (i = 0, l = str.length; i < l; i++) {
    hval ^= str.charCodeAt(i);
    hval +=
      (hval << 1) + (hval << 4) + (hval << 7) + (hval << 8) + (hval << 24);
  }
  if (asString) {
    // Convert to 8 digit hex string
    return ('0000000' + (hval >>> 0).toString(16)).substr(-8);
  }
  return hval >>> 0;
}

const colors = [
  '#726a95',
  '#709fb0',
  '#a0c1b8',
  '#28abb9',
  '#2d6187',
  '#a8dda8',
];

/**
 * Generates consistent color for string
 * @param {string} name
 * @returns {string}
 * */
export default (name) => {
  return colors[hashFnv32a(name) % colors.length];
};
