import React, {useEffect} from 'react';

import Container from 'modules/core/components/Container';
import TitleDecor from 'modules/core/components/TitleDecor';
import CareerCarousel from 'modules/career/components/CareerCarousel';
import CareerPosition from 'modules/career/components/CareerPosition';
import CareerBlockQuote from 'modules/career/components/CareerBlockQuote';

import './styles.scss';
import {useDispatch, useSelector} from "react-redux";
import {getAllApplicants, hideJobApplication} from "../../../core/store/actions/careerActions";
import JobApplicationItem from "../../components/JobApplicationItem";
import {Spinner} from "react-bootstrap";
import useAuth from "../../../auth/hooks/useAuth";

const CareerPage = () => {
  const dispatch = useDispatch()
  const {jobApplications, loading} = useSelector((state) => state.career)
  const { isAuthenticated } = useAuth();
  useEffect(() => {
    if(isAuthenticated){
      dispatch(getAllApplicants())
    }

  }, [dispatch, isAuthenticated])
  const onHideJobApplication = (id) => {
    dispatch(hideJobApplication(id))
  }
  return (
    <Container className="CareerPage__root">
      <div className="CareerPage__container">
        <div>
          {
            loading ? <div className={'text-center mb-5'}>
                  <Spinner animation="border" />
                </div>
                : (
                    <div className={'CareerPage__list'}>
                      {
                        jobApplications.map((item) =>(
                            <JobApplicationItem
                                onHideJobApplication={onHideJobApplication}
                                key={item.applicationId} item={item}/>
                        ))
                      }
                    </div>
                )
          }
        </div>
        <div className="CareerPage__title">
          <TitleDecor content="Let’s work together" />
          <p className="CareerPage__subtitle">
            Work hard with highly motivated team and talented people.
          </p>
        </div>
        <CareerCarousel />
        <CareerPosition />
        <CareerBlockQuote />
      </div>
    </Container>
  );
};

export default CareerPage;
