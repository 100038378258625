import { LOGOUT } from 'modules/auth/store/actions/authActions';
import {
  SET_GROUPS_LOADING,
  SET_ACTIVATED_USERS,
  SET_DEACTIVATED_USERS,
  SET_SECURITY_GROUPS,
  SET_USERS_LOADING,
  CREATE_GROUP,
  SET_ERROR,
} from 'modules/core/store/actions/usersActions';
import { UPDATE_CURRENT_FACILITY } from 'modules/core/store/actions/userInfoActions';

const initialState = {
  loadingGroups: false,
  loadingPermissions: false,
  permissions: undefined,
  activatedUsers: [],
  totalActivatedUsersCount: 9999,
  deactivatedUsers: [],
  totalDeactivatedUsersCount: 9999,
  error: '',
};

const securityReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_CURRENT_FACILITY:
    case LOGOUT:
      return initialState;
    case SET_SECURITY_GROUPS:
      return {
        ...state,
        securityGroups: action.securityGroups,
      };
    case CREATE_GROUP:
      return {
        ...state,
        users: [action.createdGroup, ...action.users],
      };
    case SET_ACTIVATED_USERS:
      const { type, ...rest } = action;
      return {
        ...state,
        ...rest,
      };
    case SET_DEACTIVATED_USERS:
      const { _type, ..._rest } = action;
      return {
        ...state,
        ..._rest,
      };
    case SET_GROUPS_LOADING:
      return { ...state, loadingGroups: action.value };
    case SET_USERS_LOADING:
      return { ...state, loadingPermissions: action.value };
    case SET_ERROR:
      return { ...state, error: action.value };
    default:
      return state;
  }
};

export default securityReducer;
