import React from 'react';
import Avatar from 'react-avatar';
import classNames from 'classnames';

import generateNameColor from 'modules/core/utils/generateNameColor';
import AuthImage from 'modules/core/components/AuthImage';

import './styles.scss';

const UserAvatar = ({ name, color, src, className, ...rest }) => {
  const calculatedColor = React.useMemo(() => {
    if (color) return color;
    if (!src && name) return generateNameColor(name);
  }, [color, src, name]);

  return src ? (
    <AuthImage controllerPath="users" hash={src}>
      <Avatar
        name={name}
        color={calculatedColor}
        // src={src}
        round={true}
        className={classNames({
          UserAvatar__avatarDefaultStyle: true,
          [className]: !!className,
        })}
        {...rest}
      />
    </AuthImage>
  ) : (
    <Avatar
      name={name}
      color={calculatedColor}
      round={true}
      className={classNames({
        UserAvatar__avatarDefaultStyle: true,
        [className]: !!className,
      })}
      {...rest}
    />
  );
};

export default UserAvatar;
