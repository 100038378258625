import React from 'react';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';

import useIsAdmin from 'modules/core/hooks/useIsAdmin';
import useOutsideClickEvent from 'modules/core/hooks/useOutsideClickEvent';
import { updateUserMode } from 'modules/core/store/actions/userInfoActions';
import { logout } from 'modules/auth/store/actions/authActions';
import UserSelectorOption from './UserSelectorOption';
import FacilitySelector from 'modules/core/components/FacilitySelector';
import SecurityGroupsService from 'modules/core/utils/SecurityGroupService';
import logoutIcon from 'assets/icons/logout-header.svg';
import history from 'modules/core/utils/history';
import { loginRoutes } from 'modules/login/routes/constants';

import './styles.scss';

const ViewModeSelector = ({ show: showSelf, toggleShow }) => {
  const rootRef = React.useRef(null);

  const isAdmin = useIsAdmin();
  const userFullName = useSelector((store) => store.user.name);
  const avatarColor = useSelector((store) => store.user.avatarColor);
  const image = useSelector((store) => store.user.image);
  const userMode = useSelector((store) => store.user.userMode);
  const currentGroups = useSelector((store) => store.user.securityGroups);
  const isSuperAdmin = React.useMemo(
    () => SecurityGroupsService.containsPermission(currentGroups, 'Superadmin'),
    [currentGroups]
  );
  const [showFacilitySelector, setShowFacilitySelector] = React.useState(false);

  const dispatch = useDispatch();

  const handleToggleShowFacility = React.useCallback(() => {
    setShowFacilitySelector(!showFacilitySelector);
  }, [showFacilitySelector]);

  const handleOutsideClick = React.useCallback(() => {
    if (showSelf) {
      toggleShow();
      setShowFacilitySelector(false);
    }
  }, [showSelf, toggleShow]);

  useOutsideClickEvent(rootRef, handleOutsideClick);

  const handleAdminClick = React.useCallback(() => {
    dispatch(updateUserMode('admin'));
    toggleShow();
  }, [toggleShow, dispatch]);

  const handleUserClick = React.useCallback(() => {
    dispatch(updateUserMode('user'));
    toggleShow();
  }, [toggleShow, dispatch]);

  const handleLogoutClick = React.useCallback(() => {
    dispatch(logout());
    history.push(loginRoutes.root);
  }, [dispatch]);

  return !isAdmin ? (
    <></>
  ) : (
    <div className="ViewModeSelector__root">
      <div className="ViewModeSelector__content">
        <div
          className={classNames({
            ViewModeSelector__container: true,
            'ViewModeSelector__container--show-quoter':
              showSelf && !showFacilitySelector && !isSuperAdmin,
            'ViewModeSelector__container--show-half':
              showSelf && !showFacilitySelector && isSuperAdmin,
            'ViewModeSelector__container--show-full':
              showSelf && showFacilitySelector,
          })}
        >
          <div className="ViewModeSelector__menuContainer" ref={rootRef}>
            {isSuperAdmin && (
              <FacilitySelector
                show={showFacilitySelector}
                onToggle={handleToggleShowFacility}
                toggleParent={handleOutsideClick}
              />
            )}
            <div className="ViewModeSelector__body">
              <div>
                <UserSelectorOption
                  fullName={userFullName}
                  image={image}
                  title="Admin"
                  avatarColor={avatarColor}
                  onClick={handleAdminClick}
                  selected={userMode === 'admin'}
                />
                <UserSelectorOption
                  fullName={userFullName}
                  image={image}
                  title="User"
                  avatarColor={avatarColor}
                  onClick={handleUserClick}
                  selected={userMode === 'user'}
                />
              </div>

              <div className="ViewModeSelector__logout">
                <div onClick={handleLogoutClick}>
                  <img src={logoutIcon} alt="logout" />
                  <span>Logout</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewModeSelector;
