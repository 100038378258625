import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';

import ApiClient from 'modules/core/utils/ApiClient';
import AppRoutes from './routes';
import AppHeader from 'modules/core/components/AppHeader';
import PrivateRoute from 'modules/auth/components/PrivateRoute';

import { coreRoutes } from 'modules/core/routes/constants';
import { adminRoutes } from 'modules/admin/routes/constants';
import { userRoutes } from 'modules/user/routes/constants';
import { storeRoutes } from 'modules/store/routes/constants';

import store from 'modules/core/store';
// utils
import history from './utils/history';
// contexts
import { BreakpointContext } from './contexts/BreakpointContext';
// components
import Footer from 'modules/core/components/Footer';
import WaitingComponent from 'modules/core/components/WaitingComponent';
//pages
// import NotFoundPage from 'modules/core/pages/NotFoundPage';

const AdminRoutes = React.lazy(() => import('modules/admin/routes'));

const UserRoutes = React.lazy(() => import('modules/user/routes'));

const StoreRoutes = React.lazy(() => import('modules/store/routes'));

ApiClient.init();

// fix for https://dev.to/ianwalter/fixing-chunkloaderror-3791
window.addEventListener('error', (e) => {
  if (/Loading (CSS )?chunk [\d]+ failed/.test(e.message)) {
    window.location.reload();
  }
});

if (process.env.DISABLE_LOGS) {
  console.log = () => {};
  console.error = () => {};
  console.warn = () => {};
}

const App = () => {
  return (
    <Router history={history}>
      <ReduxProvider store={store}>
        <BreakpointContext>
          <AppHeader />

          <Switch>
            <PrivateRoute
              path={adminRoutes.root}
              component={WaitingComponent(AdminRoutes)}
            />
            <Route
              path={userRoutes.root}
              component={WaitingComponent(UserRoutes)}
            />
            <Route
              path={storeRoutes.root}
              component={WaitingComponent(StoreRoutes)}
            />
            <Route path={coreRoutes.root} component={AppRoutes} />
          </Switch>
          <Footer />
        </BreakpointContext>
      </ReduxProvider>
    </Router>
  );
};

export default App;
