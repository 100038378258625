class LocalStorageService {
  storeTokenData(tokenData) {
    localStorage.setItem('accessToken', tokenData.accessToken);
    localStorage.setItem('refreshToken', tokenData.refreshToken);
    localStorage.setItem(
      'accessTokenExpirationUtc',
      tokenData.accessTokenExpirationUtc
    );
  }

  storeUserInfo(userInfo) {
    localStorage.setItem('userInfo', JSON.stringify(userInfo));
  }

  storeCart(cartData) {
    localStorage.setItem('cart', JSON.stringify(cartData));
  }

  getExpTime() {
    return localStorage.getItem('accessTokenExpirationUtc');
  }

  getAccessToken() {
    return localStorage.getItem('accessToken');
  }

  getRefreshToken() {
    return localStorage.getItem('refreshToken');
  }

  getCart() {
    return localStorage.getItem('cart');
  }

  getTokenData() {
    const accessToken = this.getAccessToken();

    if (!accessToken) return null;
    return {
      accessToken,
      accessTokenExpirationUtc: this.getExpTime(),
      refreshToken: this.getRefreshToken(),
    };
  }

  getUserInfo() {
    return JSON.parse(localStorage.getItem('userInfo'));
  }

  clearTokenData() {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('accessTokenExpirationUtc');
  }

  clearUserInfo() {
    localStorage.removeItem('userInfo');
  }

  clearCart() {
    localStorage.removeItem('cart');
  }
}

export default new LocalStorageService();
