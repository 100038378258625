import React from 'react';
import { Button, Spinner } from 'react-bootstrap';

const AsyncButton = ({ children, onClick, variant, ...restProps }) => {
  const [loading, setLoading] = React.useState(false);

  const wrappedOnClick = React.useCallback(async () => {
    setLoading(true);
    await onClick();
    setLoading(false);
  }, [onClick]);

  return (
    <Button
      disabled={loading}
      onClick={wrappedOnClick}
      variant={variant}
      {...restProps}
    >
      {children}
      {loading && (
        <Spinner
          animation="border"
          size="sm"
          variant={variant}
          className="buttonSpinner"
        />
      )}
    </Button>
  );
};

export default AsyncButton;
