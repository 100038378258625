import { adminRoutes } from 'modules/admin/routes/constants';

export const adminResidentsRoutes = {
  root: `${adminRoutes.root}/residents`,
  resident: `${adminRoutes.root}/residents/:residentId`,
  logs: `${adminRoutes.root}/residents/:residentId/logs`,
  logsDaily: `${adminRoutes.root}/residents/:residentId/logs/daily`,
  logsDailyFn: (residentId) =>
    `${adminRoutes.root}/residents/${residentId}/logs/daily`,
  logsDailyLog: `${adminRoutes.root}/residents/:residentId/logs/daily/:logId`,
  logsDailyLogFn: (residentId, logId) =>
    `${adminRoutes.root}/residents/${residentId}/logs/daily/${logId}`,
  logsWeekly: `${adminRoutes.root}/residents/:residentId/logs/weekly`,
  logsWeeklyFn: (residentId) =>
    `${adminRoutes.root}/residents/${residentId}/logs/weekly`,
  logsMonthly: `${adminRoutes.root}/residents/:residentId/logs/monthly`,
  logsMonthlyFn: (residentId) =>
    `${adminRoutes.root}/residents/${residentId}/logs/monthly`,
  logsMonthlyLog: `${adminRoutes.root}/residents/:residentId/logs/monthly/:logId`,
  logsMonthlyLogFn: (residentId, logId) =>
    `${adminRoutes.root}/residents/${residentId}/logs/monthly/${logId}`,

  logsMonthlyLogSection: `${adminRoutes.root}/residents/:residentId/logs/monthly/:logId/:section`,
  logsMonthlyLogSectionFn: (residentId, logId, sectionName) =>
    `${adminRoutes.root}/residents/${residentId}/logs/monthly/${logId}/${sectionName}`,
  schedule: `${adminRoutes.root}/residents/:residentId/schedule`,
  medication: `${adminRoutes.root}/residents/:residentId/medication`,
  medicationFn: (residentId) =>
    `${adminRoutes.root}/residents/${residentId}/medication`,
  medicationLog: `${adminRoutes.root}/residents/:residentId/medication/:logId`,
  medicationLogFn: (residentId, logId, query) =>
    `${adminRoutes.root}/residents/${residentId}/medication/${logId}${
      query
        ? Object.keys(query).reduce(
            (acc, key) => `${acc}${key}=${query[key]}`,
            '?'
          )
        : ''
    }`,
  reports: `${adminRoutes.root}/residents/:residentId/reports`,
  reportsFn: (residentId) =>
    `${adminRoutes.root}/residents/${residentId}/reports`,
  reportSectionFn: (residentId, reportType, reportId, section) =>
    `${adminRoutes.root}/residents/${residentId}/reports/${reportType}/${reportId}/${section}`,
  incidentReport: `${adminRoutes.root}/residents/:residentId/reports/incident/:reportId`,
  incidentReportBase: `${adminRoutes.root}/residents/:residentId/reports/incident`,
  incidentReportBaseFn: (residentId) =>
    `${adminRoutes.root}/residents/${residentId}/reports/incident`,
  incidentReportFn: (residentId, reportId) =>
    `${adminRoutes.root}/residents/${residentId}/reports/incident/${reportId}`,
  disruptionReport: `${adminRoutes.root}/residents/:residentId/reports/disruption/:reportId`,
  disruptionReportBase: `${adminRoutes.root}/residents/:residentId/reports/disruption`,
  disruptionReportBaseFn: (residentId) =>
    `${adminRoutes.root}/residents/${residentId}/reports/disruption`,
};
