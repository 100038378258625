import React, {useEffect, useRef, useState} from 'react';
import {Button, Modal} from "react-bootstrap";
import './styles.scss'
import {useHistory} from "react-router-dom";

const CustomPrompt = ({when}) => {
    const history = useHistory();

    const [showPrompt, setShowPrompt] = useState(false);
    const [currentPath, setCurrentPath] = useState(null);

    const unblockRef = useRef(null);

    function handleShowModal() {
        setShowPrompt(true);
    }

    function onCancel() {
        setShowPrompt(false);
    }

    useEffect(() => {
        unblockRef.current = history.block(({pathname, state}) => {
            if (when) {
                setCurrentPath({
                    pathname,
                    state
                });
                handleShowModal();
                return false;
            }
            return true;
        });
        return () => {
            unblockRef.current && unblockRef.current();
        };
    }, [when]);

    function handleConfirm() {
        if (unblockRef?.current) {
            unblockRef.current();
        }
        setShowPrompt(false);
        history.push(currentPath?.pathname, currentPath?.state);
    }
    return (
        <Modal
            className={'Prompt'}
            centered show={showPrompt} onHide={onCancel}>
            <Modal.Body className={'Prompt__body'}>
                <div className={'Prompt__title'}>
                    Are you sure you want to complete the filling? You can continue filling out the form later in the
                    career section.
                </div>
            </Modal.Body>
            <div className={'Prompt__footer'}>
                <Button className={'Prompt__button'} variant="primary" onClick={onCancel}>
                    No
                </Button>
                <Button
                    className={'Prompt__button'}
                    variant="primary" onClick={handleConfirm}>
                    Yes
                </Button>
            </div>
        </Modal>
    );
};

export default CustomPrompt;
