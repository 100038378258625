import React, {useState} from 'react';
import './styles.scss'
import UploadIcon from "assets/icons/UploadIcon";
import FileIcon from "assets/icons/FileIcon";
import ArrowIcon from "assets/icons/ArrowIcon";
import StarIcon from "../../../../assets/icons/StarIcon";
import {Button, CloseButton, Spinner} from "react-bootstrap";
import useBreakpoint from "../../../core/hooks/useBreakpoint";
import {useHistory} from "react-router-dom";
import {careerRoutes} from "../../routes/constants";
import {pageNumbers} from "../../constants";
import {ordersRoutes} from "../../../orders/routes/constants";
import DeclineIcon from "assets/icons/DeclineIcon";
import FileUtils from "../../../core/utils/FileUtils";

const JobApplicationItem = ({item, onHideJobApplication}) => {
    const [loading, setLoading] = useState(false)
    const {isMobile} = useBreakpoint()
    const history = useHistory()
    const handleClick = () => {
        if (item.isDraft) {
            history.push(careerRoutes.stepFn(item.applicationId, pageNumbers[item.pageNumber - 1]), {
                activeStep: item.pageNumber - 1
            })
        }
        if (item.isAccepted) {
            history.push(ordersRoutes.root)
        }
        if (!item.isDraft && !item.isAccepted) {
            FileUtils.downloadFile(item.applicationId, setLoading).catch(console.log)
        }
    }
    return (
        <div
            className={`JobApplication__row ${item.isDraft ? "JobApplication__row-draft" : ""}`}>
            <div
                className={`JobApplication__iconBox ${item.isDraft ? 'JobApplication__iconBox-draft' : ""}`}>
                {
                    item.isAccepted ? <StarIcon width={isMobile ? '21' : '50'} height={isMobile ? '19' : '47'}/>
                        :
                        <FileIcon
                            fill={item.isDraft && '#0F69C9'}
                            width={isMobile ? '16' : '40'} height={isMobile ? '19' : '46'}/>
                }
            </div>
            <div
                className={'JobApplication__description'}>
                <div className={'JobApplication__title'}>
                    {
                        item.isDraft ? 'You have an unfinished form.' : item.isAccepted ?
                            'Congratulations!' :
                            `You have submitted an application for the vacancy of ${item.positionName}.`
                    }
                </div>

                <div className={'JobApplication__subtitle'}>
                    {
                        item.isDraft ? 'Do you want to continue filling out the form?' : item.isAccepted ?
                            'You can open the backoffice through your account'
                            : '  Would you like to download the PDF and view the application?'
                    }
                </div>

            </div>
            <Button
                onClick={handleClick}
                className={`JobApplication__actionButton ${item.isDraft ? 'JobApplication__actionButton-draft' : ''}`}>
                {
                    (loading && !item.isDraft) ?
                        <Spinner
                            animation="border"
                            size="sm"
                        />
                        :
                        <>
                                <span style={{marginRight: item.isAccepted ? "0px" : "13px"}}>
                                    {item.isDraft ? 'Continue' : item.isAccepted ? 'Open the backoffice' : 'Download'}
                                </span>
                            {
                                item.isDraft ?
                                    <div className={'transform'}>
                                        <ArrowIcon fill={'#2F80ED'}/>
                                    </div>
                                    : item.isAccepted ? null
                                        : <UploadIcon/>
                            }

                        </>
                }
            </Button>
            <CloseButton
                onClick={
                    () => onHideJobApplication(item.applicationId)
                }
                style={{
                    display: (!item.isDraft && typeof item.isAccepted !== 'boolean') ? 'block' : 'none'
                }}
                className={'JobApplication__closeButton'}/>

        </div>
    );
};

export default JobApplicationItem;
