import React from 'react';
import { Modal, Button, Spinner } from 'react-bootstrap';

import LabeledFormControl from 'modules/core/components/FormControl';
import AsyncButton from 'modules/admin/components/AsyncButton';

import './styles.scss';

export const ModalInput = LabeledFormControl;

const CoreModal = ({
  show,
  onClose,
  children,
  title,
  loading,
  onSubmit,
  submitTitle,
  cancelTitle,
  error,
  containerClass = '',
  showCancel = true,
  HeaderComponent,
  BottomComponentOverwrite,
  errorPosition = 'top',
}) => {
  return (
    <Modal
      className={`CoreModal__popup ${containerClass}`}
      show={show}
      onHide={onClose}
      size={'xl'}
      centered
      animation={false}
    >
      <div className="CoreModal__container">
        <div className="CoreModal__top">
          <div>
            <h2>{title}</h2>
            {HeaderComponent && <HeaderComponent />}
          </div>
          <div className="CoreModal__close clickableIcon" onClick={onClose} />
        </div>
        <div className="CoreModal__form">
          {error && errorPosition === 'top' && (
            <h2 className="serverError">{error}</h2>
          )}
          {children}
        </div>

        {error && errorPosition === 'bottom' && (
          <h2 className="serverError">{error}</h2>
        )}
        <div className="CoreModal__bottom">
          {BottomComponentOverwrite ?? (
            <>
              {showCancel && (
                <AsyncButton variant="outline-dark" size="lg" onClick={onClose}>
                  {cancelTitle ?? 'Cancel'}
                </AsyncButton>
              )}

              <Button
                size="lg"
                disabled={loading || !onSubmit}
                onClick={onSubmit}
                className="ml-auto"
              >
                {submitTitle ?? 'Save'}
                {loading && (
                  <Spinner
                    animation="border"
                    size="sm"
                    variant="secondary"
                    className="buttonSpinner"
                  />
                )}
              </Button>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};

CoreModal.ModalInput = ModalInput;

export default CoreModal;
