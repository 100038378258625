import React from 'react';
import {Button, Spinner} from "react-bootstrap";

const LoadingActionButton = ({loading, text, type = 'submit'}) => {
    return (
            <Button
                type={type}
                disabled={loading}
                className={'btn-outline-primary Step__action-button'}
            >
                {
                    loading ?
                        <Spinner
                            animation="border"
                            size="sm"
                        />
                        : text
                }
            </Button>
    );
};

export default LoadingActionButton;
