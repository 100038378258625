import React from 'react';
import { Carousel } from 'react-responsive-carousel';

import './styles.scss';

const ResponsiveCarousel = ({ children, ...props }) => {
  return (
    <div className="Carousel__wrapper">
      <Carousel {...props}>{children}</Carousel>
    </div>
  );
};

export default ResponsiveCarousel;
