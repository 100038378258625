import React, {useCallback} from 'react';
import {useDropzone} from "react-dropzone";
import fileIcon from "assets/icons/file-view-base.svg";
import {humanFileSize} from "../../utils/humanFileSize";
import {Button} from "react-bootstrap";
import closeIcon from "assets/icons/small-close.svg";
import uploadFile from "../../../../assets/icons/upload-file.svg";
import FormError from "../../../core/components/FormError";
import './styles.scss'

const FileBox = ({name, size ,removeFile}) => (
    <div className={'viewFileBox'}>
        <img src={fileIcon} alt="file-icon"/>
        <div className={'viewFileBox__item'}>
            <div className={'viewFileBox__name'}>
                {name}
            </div>
            <div>
                {humanFileSize(size)}
            </div>
        </div>
        <Button
            onClick={removeFile}
            className={'viewFileBox__close-button'}>
            <img src={closeIcon} alt={'close-icon'}/>
        </Button>

    </div>
)

const UploadCareerFile = ({setFieldValue, setFieldError, error, value, fieldName = ''}) => {
    const onDrop = useCallback(async (acceptedFiles) => {
        if (acceptedFiles[0]) {
            if ((acceptedFiles[0].size / 1024).toFixed(1) <= 10000) {
                await setFieldValue(fieldName, acceptedFiles[0])
            } else {
                setFieldError(fieldName, 'File is too large')
            }
        }
    }, [fieldName, setFieldError, setFieldValue])
    const removeFile = useCallback(() => {
        setFieldValue(fieldName, null)
    },[fieldName, setFieldValue])
    const {getRootProps, getInputProps, isDragActive, open} = useDropzone(
        {
            onDrop,
            accept: {
                'image/png': [],
                '.jpg': [],
                'image/bmp': [],
                '.pdf': []
            },
            useFsAccessApi: false,
            noClick: true
        }
    )
    return (
        <>
            {
                (value?.name || value?.fileName) ?
                    <div className={'uploadFileBox'}>
                        <div className={'uploadFileBox__center'}>
                            <FileBox
                                removeFile={removeFile}
                                size={value.size}
                                name={value.name || value.fileName}/>
                        </div>
                    </div>
                    :
                    <div className={'uploadFileBox'} {...getRootProps()}
                         style={{backgroundColor: isDragActive ? 'rgba(249, 249, 249, .7)' : '#FFFFFF'}}>
                        <input {...getInputProps()} />
                        <div className={'uploadFileBox__center'}>
                            {
                                isDragActive ?
                                    <div className={'uploadFileBox__drop-tex'}>
                                        Drop the file here
                                    </div>
                                    :
                                    <div className={'uploadFileBox__item'}>
                                        <div>
                                            <img
                                                title='upload-file'
                                                src={uploadFile} alt="upload-file"/>
                                        </div>
                                        <div className={'uploadFileBox__spacing'}>
                                            <div className={'uploadFileBox__title'}>
                                                Upload driver license here
                                            </div>
                                            <div className={'uploadFileBox__description'}>
                                                JPG, PNG or PDF, file size no more than 10MB
                                            </div>
                                        </div>
                                        <Button onClick={open}>
                                            Select file
                                        </Button>
                                        {error
                                            ? <FormError>
                                                {error}
                                            </FormError>
                                            : null
                                        }
                                    </div>
                            }
                        </div>
                    </div>
            }

        </>
    );
};

export default UploadCareerFile;
