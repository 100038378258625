import React from 'react';

import CreditCard from 'modules/core/components/CreditCard';
import './styles.scss';

const CreditCardSelectorItem = ({ brand, last4, isDefault }) => {
  return (
    <div className="CreditCardSelectorItem__root">
      <CreditCard isDefault={isDefault} brand={brand} last4={last4} />
    </div>
  );
};

export default CreditCardSelectorItem;
