import React from 'react';
import { Link } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';

import ApiClient from 'modules/core/utils/ApiClient';

import arrowIcon from 'assets/icons/arrow2.svg';

import './styles.scss';

const CareerPositionItem = ({ id, name, description }) => (
  <div className="CareerPosition__item">
    <Link to={`/career/${id}`}>
      <div className="CareerPosition__item-col">
        <p>{name}</p>
        <span>{description}</span>
      </div>
      <div className="CareerPosition__item-col">
        <div className="CareerPosition__arrow">
          <img src={arrowIcon} alt="icon" />
        </div>
      </div>
    </Link>
  </div>
);

const CareerPosition = () => {
  const [items, setItems] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setLoading(true);
    (async () => {
      try {
        setItems((await ApiClient.getJobPositions()).entities);
      } catch (err) {
        console.log(err);
      }
      setLoading(false);
    })();
  }, []);
  return (
    <div className="CareerPosition__root">
      <div className="CareerPosition__title">
        <h2>Open Positions</h2>
      </div>
      <div className="CareerPosition__list">
        {loading ? (
          <div className="CareerPosition__spinner">
            <Spinner animation="border" />
          </div>
        ) : (
          items.map((item) => <CareerPositionItem {...item} key={item.id} />)
        )}
      </div>
    </div>
  );
};

export default CareerPosition;
