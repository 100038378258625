import * as yup from 'yup'
import {ErrorMessages} from "./messages";
import moment from "moment";
export const firstStepSchema = yup.object().shape({
    firstName: yup.string().required(ErrorMessages.REQUIRED),
    lastName: yup.string().required(ErrorMessages.REQUIRED),
    middleName: yup.string().required(ErrorMessages.REQUIRED),
    socialSecurity: yup.string().required(ErrorMessages.REQUIRED),
    streetAddress: yup.string().required(ErrorMessages.REQUIRED),
    cityStateZip: yup.string().required(ErrorMessages.REQUIRED),
    phoneNumber: yup.string().required(ErrorMessages.REQUIRED),
    positions: yup.string().required(ErrorMessages.REQUIRED),
    ratePay: yup.number().required(ErrorMessages.REQUIRED).positive(ErrorMessages.POSITIVE_NUMBER).typeError(ErrorMessages.NUMBER_INVALID),
    startDateTime: yup.string().required(ErrorMessages.REQUIRED).test('checkDate', ErrorMessages.INVALID_DATE, (value) => {
        return moment(value).isSameOrAfter(new Date().toDateString())
    }),
    alreadyTwentyOne: yup.boolean(ErrorMessages.REQUIRED).oneOf([true], 'Must be over 21').required(ErrorMessages.REQUIRED),
    typeOfEmployment: yup.number().required(ErrorMessages.REQUIRED),
    hightestLevelEquivalent: yup.number().required(ErrorMessages.REQUIRED),
    nameOfCollege: yup.string().required(ErrorMessages.REQUIRED),
    isMember: yup.boolean(ErrorMessages.REQUIRED).required(ErrorMessages.REQUIRED),
})
