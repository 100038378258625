import ApiClient from "./ApiClient";

class FileUtils {
  static download(dataUrl, fileName) {
    var link = document.createElement('a');
    link.download = fileName;
    link.href = dataUrl;
    link.click();
  }

  static async blobToDataUrl(blob) {
    return new Promise((resolve, reject) => {
      const a = new FileReader();
      a.onload = (e) => {
        resolve(e.target.result);
      };
      a.onerror = reject;
      a.readAsDataURL(blob);
    });
  }

  static async blobToBase64(blob) {
    return (await this.blobToDataUrl(blob)).replace(/^data:(.*,)?/, '');
  }

  static async downloadBlob(blob, fileName) {
    const url = await this.blobToDataUrl(blob);
    this.download(url, fileName ?? blob.name);
  }
  static async downloadFile (id, setLoading) {
    try {
      setLoading(true)
      const result = await ApiClient.getDocument(id)
      setLoading(false)
      const url = window.URL.createObjectURL(result.data);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
          'download',
          result.headers['content-disposition']
              .split('filename=')[1]
              .split(';')[0],
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url)
    } catch (error) {
      alert(error);
    }

  }
}

export default FileUtils;
