import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { donateRouts } from './constants';
//pages
import DonatePage from 'modules/donate/pages/DonatePage';

const DonateRoutes = () => {
  return (
    <>
      <Switch>
        <Route path={donateRouts.root} component={DonatePage} />
      </Switch>
    </>
  );
};

export default DonateRoutes;
