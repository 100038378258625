import React from 'react';

const EmailLink = ({ email, ...rest }) => {
  return (
    <a href={`mailto:${email}`} {...rest}>
      {email}
    </a>
  );
};

export default EmailLink;
