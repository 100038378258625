import React from 'react';
import { Spinner } from 'react-bootstrap';
import PropTypes from 'prop-types';

import useFetchImagesAuthorized from 'modules/core/hooks/useFetchImagesAuthorized';

import noPhotoSrc from 'assets/images/no_photo.png';
import './styles.scss';

const AuthImage = React.forwardRef(
  (
    {
      controllerPath,
      hash,
      children,
      attributeName = 'src',
      localImageUrl,
      onLoad,
      ...rest
    },
    ref
  ) => {
    const { response, loading, error } = useFetchImagesAuthorized(
      controllerPath,
      hash,
      {
        doNotFetch: !!localImageUrl,
        onLoad,
      }
    );

    const mappedChildren = React.useMemo(() => {
      return React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          [attributeName]: localImageUrl ?? response ?? noPhotoSrc,
          alt: error ?? child.props.alt,
          ref,
          ...rest,
        });
      });
    }, [children, attributeName, response, rest, error, localImageUrl, ref]);

    const itemClassName = React.useMemo(() => {
      return rest?.className;
    }, [rest]);

    return loading ? (
      <div className={`AuthImage__root ${itemClassName}`}>
        <Spinner animation="border"></Spinner>
      </div>
    ) : (
      mappedChildren
    );
  }
);

AuthImage.propTypes = {
  controllerPath: PropTypes.string.isRequired,
  hash: PropTypes.string.isRequired,
  // children: PropTypes.oneOf([
  //   PropTypes.element,
  //   PropTypes.arrayOf(PropTypes.element),
  // ]).isRequired,

  localImageUrl: PropTypes.string,
  onLoad: PropTypes.func,
};

export default AuthImage;
