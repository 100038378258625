import ApiClient from 'modules/core/utils/ApiClient';

export const SET_POSTS = 'SET_POSTS';
export const SET_DELETED_POSTS = 'SET_DELETED_POSTS';
export const SET_POSTS_LOADING = 'SET_POSTS_LOADING';
export const EDIT_POST = 'EDIT_POST';
export const CREATE_POST = 'CREATE_POST';
export const DELETE_POST = 'DELETE_POST';
export const DELETE_POST_FOREVER = 'DELETE_POST_FOREVER';
export const RESTORE_POST = 'RESTORE_POST';

const setPosts = (data, postsTotalItems, clear) => ({
  type: SET_POSTS,
  data,
  postsTotalItems,
  clear,
});

const setDeletedPosts = (data, deletedPostsTotalItems) => ({
  type: SET_DELETED_POSTS,
  data,
  deletedPostsTotalItems,
});

export const editPost = (id, post) => ({
  type: EDIT_POST,
  id,
  post,
});

export const fetchPosts = (pageSize) => {
  return async (dispatch, getState) => {
    const state = getState();
    if (
      state.posts.loading ||
      state.posts.posts.length >= state.posts.postsTotalItems
    ) {
      return;
    }
    dispatch({ type: SET_POSTS_LOADING, value: true });
    const shouldClear = state.posts.posts.length < pageSize;
    const page = shouldClear ? 0 : (state.posts.posts.length / pageSize) ^ 0;
    const response = await ApiClient.fetchPosts(page + 1, pageSize);
    dispatch(setPosts(response.entities, response.totalItems, shouldClear));
    dispatch({ type: SET_POSTS_LOADING, value: false });
  };
};

export const fetchPostsWithItems = (pageSize) => {
  return async (dispatch, getState) => {
    const state = getState();
    if (
      state.posts.loading ||
      state.posts.posts.length >= state.posts.postsTotalItems
    ) {
      return;
    }
    dispatch({ type: SET_POSTS_LOADING, value: true });
    const shouldClear = state.posts.posts.length < pageSize;
    const page = shouldClear ? 0 : (state.posts.posts.length / pageSize) ^ 0;
    const response = await ApiClient.fetchPostsWithItems(page + 1, pageSize);
    dispatch(setPosts(response.entities, response.totalItems, shouldClear));
    dispatch({ type: SET_POSTS_LOADING, value: false });
  };
};

export const fetchDeletedPosts = (pageSize) => {
  return async (dispatch, getState) => {
    const state = getState();
    if (
      state.posts.loading ||
      state.posts.deleted.length >= state.posts.deletedPostsTotalItems
    ) {
      return;
    }
    dispatch({ type: SET_POSTS_LOADING, value: true });

    const page = (state.posts.deleted.length / pageSize) ^ 0;
    const response = await ApiClient.fetchDeletedPosts(page + 1, pageSize);
    dispatch(setDeletedPosts(response.entities, response.totalItems));
    dispatch({ type: SET_POSTS_LOADING, value: false });
  };
};

export const fetchFullPost = (postId) => {
  return async (dispatch, getState) => {
    const response = await ApiClient.fetchFullPost(postId);
    const state = getState();
    const post = state.posts.posts.find((post) => post.id === postId);
    const fetchedPost = {
      ...response.data.value,
      postText: response.data.value.text,
      // headingImage: { url: response.data.value.titlePictureUrl },
      id: postId,
    };
    if (post) {
      dispatch(editPost(postId, fetchedPost));
    } else {
      dispatch(setPosts([fetchedPost], state.posts.postsTotalItems));
    }
    return fetchedPost;
  };
};

export const startEditPost = (post) => {
  return async (dispatch) => {
    const response = await ApiClient.updatePost(post);
    // dispatch(editPost(post.id, post));
    dispatch({ type: CREATE_POST });

    return response;
  };
};

export const startDeletePostTemporary = (id) => {
  return async (dispatch) => {
    await ApiClient.deletePostTemporary(id);
    dispatch({ type: DELETE_POST, id });
  };
};

export const startRestorePost = (post) => {
  return async (dispatch) => {
    await ApiClient.restorePost(post.id);
    dispatch({ type: RESTORE_POST, post });
  };
};

export const startDeletePostForever = (id) => {
  return async (dispatch) => {
    await ApiClient.deletePostPermanently(id);
    dispatch({ type: DELETE_POST_FOREVER, id });
  };
};

export const startCreatePost = (post) => {
  return async (dispatch) => {
    await ApiClient.createPost(post);
    dispatch({ type: CREATE_POST });
  };
};
