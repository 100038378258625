import React from 'react';

const useOutsideClickEvent = (ref, callback) => {
  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        ref.current &&
        !ref.current.contains(event?.target) &&
        !document.getElementById('header_avatar')?.contains(event.target)
      ) {
        callback();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, callback]);
};

export default useOutsideClickEvent;
