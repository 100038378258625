import { useParams } from 'react-router-dom';

export default () => {
  const params = useParams();

  for (let key in params) {
    if ((key.includes('id') || key.includes('Id')) && params[key] !== 'new-permanent' && params[key] !== 'new-overnight' && params[key] !== 'new') {
      params[key] = Number(params[key]);
    }
  }

  return params;
};
