import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { startFetchPaymentMethods } from 'modules/core/store/actions/cardsActions';
import useAuth from 'modules/auth/hooks/useAuth';

const withFetchPaymentMethods = (WrappedComponent) => {
  const NewComponent = (props) => {
    const { isAuthenticated } = useAuth();

    const { paymentMethods, loaded, loading } = useSelector(
      (state) => state.cards
    );

    const dispatch = useDispatch();

    React.useEffect(() => {
      if (!loaded && !loading && isAuthenticated) {
        dispatch(startFetchPaymentMethods());
      }
    }, [loaded, loading, dispatch, isAuthenticated]);

    return (
      <WrappedComponent
        paymentMethods={paymentMethods}
        paymentMethodsLoaded={loaded}
        paymentMethodsLoading={loading}
        {...props}
      />
    );
  };

  return NewComponent;
};

export default withFetchPaymentMethods;
