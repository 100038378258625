import React from 'react';

import { FormControl } from 'react-bootstrap';

import './styles.scss';

const Input = ({
  value,
  onChange,
  type,
  placeholder,
  label,
  id,
  errorFeedback,
  isInvalid,
  submited,
  rootClass = '',
  className = '',
  required = true,
  ...otherProps
}) => {
  return (
    <div className={'form-group floating-label Input__root ' + rootClass}>
      <FormControl
        type={type}
        id={id}
        className={'Input__input ' + className}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        isInvalid={isInvalid || (submited && !value && required)}
        {...otherProps}
      />
      <label htmlFor="name" className="Input__label">
        {label}
      </label>
      <FormControl.Feedback type="invalid">
        {!value ? 'Field is required. ' : errorFeedback}
      </FormControl.Feedback>
    </div>
  );
};

Input.propTypes = {};

export default Input;
