import React from 'react';
import { Button, Spinner, NavLink } from 'react-bootstrap';
import isEmail from 'validator/lib/isEmail';
import { useDispatch, useSelector } from 'react-redux';

import { startLogin } from 'modules/auth/store/actions/authActions';

import { BreakpointContextConsumer } from 'modules/core/contexts/BreakpointContext';
import Input from 'modules/core/components/Input';
import Container from 'modules/core/components/Container';
import TitleDecor from 'modules/core/components/TitleDecor';
import { userSignUpRoutes } from 'modules/user-signup/routes/constants';
import { userResetPasswordRoutes } from 'modules/user-reset-password/routes/constants';

import './styles.scss';

const UserLoginPage = () => {
  const [password, setPassword] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [submited, setSubmited] = React.useState(false);

  const dispatch = useDispatch();
  const { error, loading } = useSelector((store) => store.auth);

  const passwordInputHandler = React.useCallback(
    (e) => {
      setPassword(e.target.value);
    },
    [setPassword]
  );

  const emailInputHandler = React.useCallback(
    (e) => {
      setEmail(e.target.value);
    },
    [setEmail]
  );

  const loginSubmitHandler = React.useCallback(
    async (e) => {
      e.preventDefault();
      if (isEmail(email) && password.length > 5) {
        dispatch(startLogin(password, email));
      }
      setSubmited(true);
    },
    [email, password, dispatch]
  );

  return (
    <Container className="LoginPage__root" paddingHorizontalMobile="18px">
      <BreakpointContextConsumer>
        {({ isMobile, breakpoint }) => (
          <div className="LoginPage__container">
            <div
              className={'LoginPage__content' + (submited ? '-submited' : '')}
            >
              <TitleDecor content="Login" />
              <Input
                type="email"
                id="fyc_admin_mail"
                placeholder=" "
                label="Email"
                submited={submited}
                // maxLength={3}
                value={email}
                onChange={emailInputHandler}
                isInvalid={submited && !isEmail(email)}
                errorFeedback="Email is not valid."
                rootClass="LoginPage__input"
              />
              <Input
                type="password"
                id="fyc_admin_pass"
                placeholder=" "
                label="Password"
                submited={submited}
                value={password}
                onChange={passwordInputHandler}
                isInvalid={submited && password.length < 5}
                errorFeedback="Password is too short."
                rootClass="LoginPage__input"
              />
              {error && <h1 className="LoginPage__serverMessage">{error}</h1>}

              <Button
                type="sumbit"
                size="lg"
                onClick={loginSubmitHandler}
                disabled={loading}
              >
                Login
                {loading && (
                  <Spinner
                    animation="border"
                    size="sm"
                    variant="secondary"
                    className="buttonSpinner"
                  />
                )}
              </Button>
              {error && (
                <span className="LoginPage__subtitle mt-2">
                  Forgot your password? Click
                  <NavLink
                    className="LoginPage__link"
                    href={userResetPasswordRoutes.root}
                  >
                    Here
                  </NavLink>
                </span>
              )}
              <span className="LoginPage__subtitle">
                Don’t have account?
                <NavLink
                  className="LoginPage__link"
                  href={userSignUpRoutes.root}
                >
                  Sign Up
                </NavLink>
              </span>
            </div>
          </div>
        )}
      </BreakpointContextConsumer>
    </Container>
  );
};

export default UserLoginPage;
