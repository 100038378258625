import React, {useEffect, useMemo} from 'react';
import './styles.scss'
import {Button, Col, Row, Spinner} from "react-bootstrap";
import {Field, FieldArray, Form, Formik} from "formik";
import LabeledFormControl from "../../../../core/components/FormControl";
import { secondStepValues, STEPS, stepsPath} from "../../../constants";
import PhoneInput from "../../../../admin/components/PhoneInput";
import {secondStepSchema} from "../../../validation";
import FormError from "../../../../core/components/FormError";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {careerRoutes} from "../../../routes/constants";
import Stepper from "../../Stepper";
import {useDispatch, useSelector} from "react-redux";
import LoadingActionButton from "../../LoadingButton";
import {getSecondStep, updateSecondStep} from "../../../../core/store/actions/careerActions";
import CustomPrompt from "../../CustomPrompt";

export const CareerApplySecondStep = () => {
    const {id} = useParams()
    const location = useLocation()
    const history = useHistory()
    const dispatch = useDispatch()
    const {loading, stepLoading, stepValues} = useSelector((state) => state.career)
    const onSubmit = (values) => {
        dispatch(updateSecondStep({
            ...values,
            applicationId: Number(id)
        }))
    }
    const onPrev = () => {
        history.push(careerRoutes.stepFn(id, stepsPath.firstStep), {
            activeStep: 0
        })
    }
    useEffect(() => {
        if (id) {
            dispatch(getSecondStep(id))
        }
    }, [id, dispatch])
    const mappedValues = useMemo(() => {
        if (stepValues?.models?.[0]?.name) {
            return {
                ...stepValues,
            }

        } else {
            return secondStepValues
        }

    }, [stepValues])
    return (
        <>
            {
                stepLoading ? <div className={'text-center'}>
                        <Spinner animation="border"/>
                    </div>
                    :
                    <>
                        <Stepper activeStep={location.state?.activeStep} steps={STEPS}/>
                        <h3>LIST THREE (3) People (no relatives) you have worked with and whom we may contact for a
                            reference if
                            necessary.</h3>
                        <Formik
                            validationSchema={secondStepSchema}
                            initialValues={{...mappedValues}}
                            onSubmit={onSubmit}
                            render={({handleSubmit, values, setFieldValue, errors, touched, dirty,submitCount}) => (
                                <Form onSubmit={handleSubmit}>
                                    <FieldArray
                                        name={'models'}
                                        render={(props) => {
                                            return (
                                                <>
                                                    {
                                                        values.models && values.models.length !== 0 &&
                                                        values.models.map((_, index) => (
                                                            <div key={index.toString()}
                                                                 className={'SecondStep__item mb-4'}>
                                                                <Row className={'mb-4 SecondStep__item__row'}>
                                                                    <Col sm>
                                                                        <Field
                                                                            name={`models.${index}.name`}
                                                                            label={'Name'}
                                                                            placeholder={'Enter'}
                                                                            component={LabeledFormControl}
                                                                        />
                                                                        {
                                                                            errors?.models?.[index]?.name && touched?.models?.[index]?.name &&
                                                                            <FormError>{errors.models[index].name}</FormError>
                                                                        }
                                                                    </Col>
                                                                    <Col sm>
                                                                        <Field
                                                                            name={`models.${index}.occupation`}
                                                                            label={'Occupation'}
                                                                            placeholder={'Enter'}
                                                                            component={LabeledFormControl}
                                                                        />
                                                                        {
                                                                            errors?.models?.[index]?.occupation && touched?.models?.[index]?.occupation &&
                                                                            <FormError>{errors.models[index].occupation}</FormError>
                                                                        }
                                                                    </Col>
                                                                    <Col sm>
                                                                        <Field
                                                                            value={values.models[index].phone}
                                                                            onChange={(value) => {
                                                                                // console.log(e, 'PHONE')
                                                                                setFieldValue(`models.${index}.phone`, value)
                                                                            }}
                                                                            name={`models.${index}.phone`}
                                                                            label={'Phone #'}
                                                                            placeholder={'Enter'}
                                                                            component={PhoneInput}
                                                                        />
                                                                        {
                                                                            errors?.models?.[index]?.phone && touched?.models?.[index]?.phone &&
                                                                            <FormError>{errors.models[index].phone}</FormError>
                                                                        }
                                                                    </Col>
                                                                </Row>
                                                                <Row className={'SecondStep__item__row'}>
                                                                    <Col sm>
                                                                        <Field
                                                                            name={`models.${index}.address`}
                                                                            label={'Address'}
                                                                            placeholder={'Enter'}
                                                                            component={LabeledFormControl}
                                                                        />
                                                                        {
                                                                            errors?.models?.[index]?.address && touched?.models?.[index]?.address &&
                                                                            <FormError>{errors.models[index].address}</FormError>
                                                                        }
                                                                    </Col>
                                                                    <Col sm>
                                                                        <Field
                                                                            name={`models.${index}.city`}
                                                                            label={'City'}
                                                                            placeholder={'Enter'}
                                                                            component={LabeledFormControl}
                                                                        />
                                                                        {
                                                                            errors?.models?.[index]?.city && touched?.models?.[index]?.city &&
                                                                            <FormError>{errors.models[index].city}</FormError>
                                                                        }
                                                                    </Col>
                                                                    <Col sm>
                                                                        <Field
                                                                            name={`models.${index}.state`}
                                                                            label={'State'}
                                                                            placeholder={'Enter'}
                                                                            component={LabeledFormControl}
                                                                        />
                                                                        {
                                                                            errors?.models?.[index]?.state && touched?.models?.[index]?.state &&
                                                                            <FormError>{errors.models[index].state}</FormError>
                                                                        }
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        ))
                                                    }
                                                </>
                                            )
                                        }}
                                    />
                                    <div className={'Step__footer'}>
                                        <Button
                                            onClick={onPrev}
                                            className={'btn-outline-primary Step__action-button'}>
                                            Back
                                        </Button>
                                        <LoadingActionButton loading={loading} text={'Next'}/>
                                    </div>
                                    <CustomPrompt when={dirty && submitCount === 0}/>
                                </Form>
                            )}
                        />
                    </>
            }
        </>
    );
};

