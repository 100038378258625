import {
  CHANGE_AMOUNT,
  ADD_TO_CART,
  REMOVE,
  SET,
  CLEAR,
} from 'modules/core/store/actions/cartActions';
import { LOGOUT } from 'modules/auth/store/actions/authActions';

import localStorageService from 'modules/core/utils/localStorageService';

const initialState = {
  items: [],
};

const cartReducer = (state = initialState, action) => {
  const save = (newState) => {
    localStorageService.storeCart({
      ...newState,
      items: newState.items.map((item) => ({
        ...item,
        product: undefined,
      })),
    });
  };

  switch (action.type) {
    case ADD_TO_CART: {
      let newItems = action.items.map((item, i) => ({
        ...item,
        id: item.productId + ' ' + item.color + item.size,
      }));

      for (let i of state.items) {
        console.log(i, action.items);
        if (
          !newItems.some((item) => {
            if (item.id === i.id) {
              item.amount = i.amount + 1;
              return true;
            } else return false;
          })
        ) {
          newItems.push(i);
        }
      }
      const newState = {
        ...state,
        items: newItems,
      };

      save(newState);

      return newState;
    }
    case SET: {
      const newState = {
        ...state,
        items: action.items.map((item, i) => ({
          ...item,
          id: item.productId + item.color + item.size,
        })),
      };

      save(newState);

      return newState;
    }
    case REMOVE: {
      const newState = {
        ...state,
        items: state.items.filter((i) => i.id !== action.id),
      };
      save(newState);

      return newState;
    }
    case CHANGE_AMOUNT: {
      const newState = {
        ...state,
        items: state.items.map((item) => {
          if (item.id === action.id) {
            item.amount = action.amount;
            return item;
          }
          return item;
        }),
      };

      save(newState);

      return newState;
    }
    case LOGOUT:
    case CLEAR:
      localStorageService.clearCart();
      return initialState;
    default:
      return state;
  }
};

export default cartReducer;
