import React from 'react';

const PlayIcon = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.40002 15V3L13.8 9L5.40002 15Z" fill="#27AE60"/>
        </svg>

    );
};

export default PlayIcon;
