import React from 'react';
import {Button, Modal, ModalBody, Spinner} from "react-bootstrap";
import {ClockIcon} from "../../../../assets/icons/CloclIcon";
import './styles.scss'


const TurnOfTimerModal = ({isOpen, onClose, onPauseTimer, isLoading}) => {
    return (
        <Modal className={'Modal'} centered show={isOpen}>
            <ModalBody className={'Modal__contentClassName'}>
                <div className={'d-flex flex-column align-items-stretch'} >
                    <div className={'align-self-center'}>
                        <ClockIcon fill={'#F79F77'}/>
                    </div>
                    <h4 className={'text-center mt-4'}>
                        You have been working for 8 hours, do not forget to turn off the timer.
                    </h4>
                    <Button
                        onClick={onPauseTimer}
                        className={'TurnOfTimer__button TurnOfTimer__button-orange mt-3'}>
                        {isLoading ? <Spinner variant={'dark'} animation={'border'}/> : 'Stop now'}
                    </Button>
                    <Button
                        onClick={onClose}
                        className={'TurnOfTimer__button'} variant={'outline-dark mt-3'}>
                        Close
                    </Button>

                </div>
            </ModalBody>
        </Modal>
    );
};

export default TurnOfTimerModal;
