import React, {useState} from 'react';
import Container from "../../../core/components/Container";
import './styles.scss'
import FileIcon from "assets/icons/FileIcon";
import upload from 'assets/icons/upload-finish-page.svg'
import {Button, Spinner} from "react-bootstrap";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {careerRoutes} from "../../routes/constants";
import ArrowIcon from "assets/icons/ArrowIcon";
import useBreakpoint from "../../../core/hooks/useBreakpoint";
import FileUtils from "../../../core/utils/FileUtils";

const CareerFinishPage = () => {
    const [loading, setLoading] = useState()
    const location = useLocation()
    const {id} = useParams()
    const history = useHistory()
    const {isMobile} = useBreakpoint()
    return (
        <Container className={'FinishPage__root'}>
            <div className={'FinishPage__container'}>
                <div className={'FinishPage__item'}>
                    <div className={'FinishPage__file-box'}>
                        <FileIcon
                            width={isMobile ? '16' : '40'} height={isMobile ? '19' : '46'}/>
                    </div>
                    <div className={'FinishPage__text-box'}>
                        <div className={'FinishPage__title'}>{
                            `You have submitted an application for the vacancy of
                            ${location.state?.positionName ? location.state.positionName : ''}`
                        }
                        </div>
                        <div className={'FinishPage__description'}>Would you like to download the PDF and view the
                            application?
                        </div>
                    </div>
                    <Button
                        onClick={() => {
                            FileUtils.downloadFile(id, setLoading).catch(console.log)
                        }}
                        className={'FinishPage__upload'}>
                        {
                            loading ?
                                <Spinner
                                    animation="border"
                                    size="sm"
                                />
                                :
                                <>
                                    Download
                                    <div className={'FinishPage__icon'}>
                                        <img src={upload} alt={'upload'}/>

                                    </div>
                                </>
                        }
                    </Button>
                </div>
                <Button
                    onClick={() => history.replace(careerRoutes.root)}
                    className={'FinishPage__back'}>
                    <div className={'FinishPage__back-icon'}>
                        <ArrowIcon/>
                    </div>
                    Back to Career
                </Button>
            </div>
        </Container>
    );
};
export default CareerFinishPage;
