import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import history from 'modules/core/utils/history';
import { coreRoutes } from 'modules/core/routes/constants';
import { fetchFullPost } from 'modules/core/store/actions/postsActions';
import { BreakpointContextConsumer } from 'modules/core/contexts/BreakpointContext';
import Container from 'modules/core/components/Container';
import LargePost from 'modules/posts/components/LargePost';
import useParams from 'modules/core/hooks/wrappedUseParams';

import './styles.scss';

//TODO: if post is not stored in redux store - request it

const PostPage = () => {
  const { id } = useParams();
  const post = useSelector((store) =>
    store.posts.posts.find((p) => id === p.id)
  );

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (!post) {
      (async () => {
        try {
          await dispatch(fetchFullPost(id));
        } catch (err) {
          console.log(err);
          history.push(coreRoutes.notFound);
        }
      })();
    }
  }, [id, post, dispatch]);

  return (
    <BreakpointContextConsumer>
      {({ isMobile, breakpoint }) => (
        <Container
          className="PostPage__root"
          paddingHorizontalDesktop={'210px'}
        >
          {post && <LargePost post={post} showPostsLink={isMobile} />}
        </Container>
      )}
    </BreakpointContextConsumer>
  );
};

export default PostPage;
