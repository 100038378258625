import React from 'react';
import PropTypes from 'prop-types';
import { ProgressBar, Button } from 'react-bootstrap';

import Share from 'modules/core/components/Share';

import './styles.scss';

const DonationProgressbar = ({ value, max, onDonation, shareText = '' }) => {
  return (
    <div className="DonationProgressbar__root">
      <div className="DonationProgressbar__progressBarContainer">
        <div className="DonationProgressbar__row">
          <h1 className="DonationProgressbar__currentValue">
            ${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          </h1>
          <p className="DonationProgressbar__max">
            of ${max.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          </p>
        </div>
        <ProgressBar variant="danger" now={value} max={max} />
      </div>
      <p className="DonationProgressbar__text">
        Your gift of any size matters. Donate now or let us help you explore
        planned giving options. The generosity of our supporters enables
        groundbreaking research into more effective treatment, helps more kids
        find loving homes, and allows us to expand our education and outreach
        programs.
      </p>
      <div className="DonationProgressbar__secondRow">
        <Button variant="primary" size="lg" onClick={onDonation}>
          Donate Now
        </Button>
        <div className="DonationProgressbar__share">
          <Share
            shareLink={window.location.href}
            shareText={shareText}
            invertColor
          />
        </div>
      </div>
    </div>
  );
};

DonationProgressbar.propTypes = {
  value: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  onDonation: PropTypes.func.isRequired,
};

export default DonationProgressbar;
