import bellIcon from 'assets/icons/bell.svg';
import cartIcon from 'assets/icons/cart.svg';
import classNames from 'classnames';
import {adminNotificationsRoutes} from 'modules/admin-notifications/routes/constants';
import useAuth from 'modules/auth/hooks/useAuth';
import useInitUserInfo from 'modules/auth/hooks/useInitUserInfo';
import Header from 'modules/core/components/Header';
import UserAvatar from 'modules/core/components/UserAvatar';
import {BreakpointContextConsumer} from 'modules/core/contexts/BreakpointContext';
import useIsAdmin from 'modules/core/hooks/useIsAdmin';
import {setNotifications} from 'modules/core/store/actions/recentNotificationsActions';
import ApiClient from 'modules/core/utils/ApiClient';
import history from 'modules/core/utils/history';
import {loginRoutes} from 'modules/login/routes/constants';
import {profileRoutes} from 'modules/profile/routes/constants';
import {storeCartRoutes} from 'modules/store-cart/routes/constants';
import React from 'react';
import {Button, Spinner} from 'react-bootstrap';
import {useDispatch, useSelector} from 'react-redux';
import {Link, Route, Switch, useLocation} from 'react-router-dom';
import './styles.scss';
import ViewModeNotification from './ViewModeNotification';
import ViewModeSelector from './ViewModeSelector';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useIsRole from "../../hooks/useIsRole";
import {getCurrentTimeInformation} from "../../store/actions/trackerActions";
import {setShowIsTurnOfModal, setShowIsTurnOnModal} from "../../store/reducers/trackerReducer";
import TrackerBoxWrapper from "../TrackerBoxWrapper";

const AppHeader = () => {
    const dispatch = useDispatch();
    const location = useLocation()
    const {userInfoLoaded} = useInitUserInfo({});
    const [modeSelectorShown, setModeSelectorShown] = React.useState(false);
    const [modeShownNotification, setModeShownNotification] =
        React.useState(false);
    const [recentNotification, setRecentNotification] = React.useState([]);
    const isAdmin = useIsAdmin();
    const isStaff = useIsRole('Staff')
    const isMainPage = location.pathname === '/';

    const userFullName = useSelector((store) => store.user.name);
    const avatarColor = useSelector((store) => store.user.avatarColor);
    const image = useSelector((store) => store.user.image);
    const isCartEmpty = useSelector((store) => store.cart.items.length === 0);
    const recentNotifications = useSelector(
        (store) => store.recentNotifications.items
    );
    const isNotificationsRead = useSelector(
        (store) => store.recentNotifications.isNotificationsRead
    );


    const [bellIndicator, setBellIndicator] = React.useState();

    const {isAuthenticated} = useAuth();

    const loginClickHandler = React.useCallback(() => {
        history.push(loginRoutes.root);
    }, []);

    const toggleViewModeNotification = React.useCallback(() => {
        setModeShownNotification((value) => !value);
    }, []);

    const handleNotificationClick = React.useCallback(() => {
        toggleViewModeNotification();
    }, [toggleViewModeNotification]);

    const toggleViewModeSelector = React.useCallback(() => {
        if (!isAdmin) return;
        setModeSelectorShown((value) => !value);
    }, [isAdmin]);

    const handleAvatarClick = React.useCallback(() => {
        if (isAdmin) toggleViewModeSelector();
        else history.push(profileRoutes.root);
    }, [isAdmin, toggleViewModeSelector]);

    const renderLoginButton = React.useMemo(() => {
        return (
            <Button variant="primary" onClick={loginClickHandler}>
                Login
            </Button>
        );
    }, [loginClickHandler]);

    const renderAvatar = React.useMemo(() => {
        return userInfoLoaded ? (
            <UserAvatar
                name={userFullName}
                className="AppHeader__avatar"
                onClick={handleAvatarClick}
                round={true}
                size="64px"
                color={avatarColor}
                src={image}
            />
        ) : (
            <Spinner animation="border"/>
        );
    }, [userFullName, userInfoLoaded, handleAvatarClick, avatarColor, image]);


    React.useEffect(() => {
        if (isAuthenticated) {
            ApiClient.fetchRecentNotifications().then((response) =>
                dispatch(setNotifications(response.recentNotifications))
            );
        }
    }, [recentNotification, isAuthenticated, dispatch, bellIndicator]);

    React.useEffect(() => {
       if(isAuthenticated){
           ApiClient.startConnection();
           ApiClient.getRecentNotification(setRecentNotification);
           ApiClient.getBellIndicator(setBellIndicator);
       }
    }, [isAuthenticated]);


    React.useEffect(() => {
        if (isAuthenticated && isStaff) {
            dispatch(getCurrentTimeInformation())
            ApiClient.getTimerNotificationMessage(() => {
                dispatch(setShowIsTurnOfModal(true))
            }).catch(console.log)
        }

    }, [isAuthenticated, isStaff])


    return (
        <>
            <BreakpointContextConsumer>
                {({isTablet}) => (
                    <Header
                        mobileMenuContent={isTablet && isAuthenticated && renderAvatar}
                        mobileBottomMenuContent={renderLoginButton}
                    >
                        <ToastContainer
                            position="top-right"
                            autoClose={5000}
                            hideProgressBar={true}
                            newestOnTop={true}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                        />
                        <>
                            <TrackerBoxWrapper isAuthenticated={isAuthenticated} isStaff={isStaff}/>
                            {!isTablet ? (
                                <>
                                    {isAuthenticated && (
                                        <>
                                            <div
                                                onClick={handleNotificationClick}
                                                id="header_bell"
                                                className={classNames({
                                                    Header__bell: true,
                                                    active: !isNotificationsRead,
                                                })}
                                            >
                                                <img src={bellIcon} alt="notifications"/>
                                                <span></span>
                                            </div>
                                            {modeShownNotification && (
                                                <ViewModeNotification
                                                    show={modeShownNotification}
                                                    toggleShow={toggleViewModeNotification}
                                                    notifications={recentNotifications}
                                                />
                                            )}
                                        </>
                                    )}
                                    <Link
                                        to={storeCartRoutes.cart}
                                        className={classNames({
                                            Header__cart: true,
                                            active: !isCartEmpty,
                                        })}
                                    >
                                        <img src={cartIcon} alt="cart"/>
                                        <span></span>
                                    </Link>
                                    <Switch>
                                        <Route path={loginRoutes.root} exact>
                                            {renderLoginButton}
                                        </Route>
                                        <Route path="*">
                                            {isAuthenticated ? (
                                                <div
                                                    className="AppHeader__avatarContainer"
                                                    id="header_avatar"
                                                >
                                                    {renderAvatar}
                                                    <ViewModeSelector
                                                        show={modeSelectorShown}
                                                        toggleShow={toggleViewModeSelector}
                                                    />
                                                </div>
                                            ) : (
                                                renderLoginButton
                                            )}
                                        </Route>
                                    </Switch>
                                </>
                            ) : (
                                <>
                                    {isAuthenticated && (
                                        <Link
                                            to={adminNotificationsRoutes.root}
                                            className={classNames({
                                                Header__bell: true,
                                                active: !isNotificationsRead,
                                            })}
                                        >
                                            <img src={bellIcon} alt="notifications"/>
                                            <span></span>
                                        </Link>
                                    )}
                                    <Link
                                        to={storeCartRoutes.cart}
                                        className={classNames({
                                            Header__cart: true,
                                            active: !isCartEmpty,
                                        })}
                                    >
                                        <img src={cartIcon} alt="cart"/>
                                        <span></span>
                                    </Link>
                                </>
                            )}
                        </>
                    </Header>
                )}
            </BreakpointContextConsumer>
        </>
    );
};

export default AppHeader;
