import * as yup from 'yup'
import {ErrorMessages} from "./messages";
import {checkYear} from "../utils/checkYear";
import moment from "moment";

export const fifthStepSchema = yup.object().shape({
    // isForsterCare: yup.boolean().test('isForsterCareTrue', ErrorMessages.REQUIRED, function (value){
    //     return this.parent.isEmployment ? true : !!value
    // }),
    isForsterCare: yup.boolean(),
    // forsterCareAgency: yup.number().when(('isForsterCare'), {
    //     is: true,
    //     then: (schema) => schema.required(ErrorMessages.REQUIRED),
    //     otherwise: (schema) => schema
    // }),
    forsterCareAgency: yup.number().nullable(true),
    forsterCareAgencyOther: yup.string().nullable(true),
    // forsterCareAgencyOther: yup.string().when(['forsterCareAgency', 'isForsterCare'], {
    //     is: (forsterCareAgency, isForsterCare) => forsterCareAgency === 4 && isForsterCare,
    //     then: yup.string().required(ErrorMessages.REQUIRED)
    // }),
    forsterCareRole: yup.number().nullable(true),
    // forsterCareRole: yup.number().when(('isForsterCare'), {
    //     is: true,
    //     then: (schema) => schema.required(ErrorMessages.REQUIRED),
    //     otherwise: (schema) => schema
    // }),
    // isEmployment: yup.boolean().test('isEmploymentTrue', ErrorMessages.REQUIRED, function (value){
    //     return this.parent.isForsterCare ? true : !!value
    // }),
    isEmployment: yup.boolean(),
    employmentAgency: yup.number().nullable(true),
    // employmentAgency: yup.number().when(('isEmployment'), {
    //     is: true,
    //     then: (schema) => schema.required(ErrorMessages.REQUIRED),
    //     otherwise: (schema) => schema
    // }).nullable(true),
    employmentAgencyOther: yup.string(),
    // employmentAgencyOther: yup.string().when(['employmentAgency', 'isEmployment'], {
    //     is: (employmentAgency, isEmployment) => employmentAgency === 7 && isEmployment,
    //     then: yup.string().required(ErrorMessages.REQUIRED)
    // }),
    employmentRole: yup.number().nullable(true),
    // employmentRole: yup.number().when(('isEmployment'), {
    //     is: true,
    //     then: (schema) => schema.required(ErrorMessages.REQUIRED),
    //     otherwise: (schema) => schema
    // }).nullable(true),
    fingerprintedBefore: yup.boolean().required(ErrorMessages.REQUIRED),
    fingerprintedBeforeDate: yup.string().when('fingerprintedBefore', {
        is: true,
        then: yup.string().required(ErrorMessages.REQUIRED)
    }),
    fingerprintSubmited: yup.boolean().required(ErrorMessages.REQUIRED),
    personDirectCare: yup.boolean().required(ErrorMessages.REQUIRED),
    effectiveDate: yup.string().when('isEmployment', {
        is: true,
        then: (schema) => schema.required(ErrorMessages.REQUIRED)
    }),
    reasonForRemoval: yup.string().when('isEmployment', {
        is: true,
        then: (schema) => schema.required(ErrorMessages.REQUIRED)
    }),
    requestingAgency: yup.string().required(ErrorMessages.REQUIRED),
    familyForsterHomeName: yup.string().required(ErrorMessages.REQUIRED),
    applicableLicenseNumbers: yup.string().required(ErrorMessages.REQUIRED),
    contactPersonName: yup.string().required(ErrorMessages.REQUIRED),
    streetAddress: yup.string().required(ErrorMessages.REQUIRED),
    city: yup.string().required(ErrorMessages.REQUIRED),
    state: yup.string().required(ErrorMessages.REQUIRED),
    zip: yup.number().required(ErrorMessages.REQUIRED).typeError(ErrorMessages.ONLY_DIGITS)
        .test('len', ErrorMessages.MAX_LENGTH, val => val && val.toString().length <= 10)
    ,
    phone: yup.string().required(ErrorMessages.REQUIRED),
    email: yup.string().email(ErrorMessages.EMAIL_INVALID).required(ErrorMessages.REQUIRED),
    firstName: yup.string().required(ErrorMessages.REQUIRED),
    middleName: yup.string().required(ErrorMessages.REQUIRED),
    lastName: yup.string().required(ErrorMessages.REQUIRED),
    dateOfBirth: yup.string().required(ErrorMessages.REQUIRED).test('DOB', ErrorMessages.DATE_INVALID, (value) => {
        return checkYear(value)
    }),
    isMale: yup.boolean().required(ErrorMessages.REQUIRED),
    maidenNamesUsed: yup.string().required(ErrorMessages.REQUIRED),
    snn: yup.string().required(ErrorMessages.REQUIRED),
    race: yup.string().required(ErrorMessages.REQUIRED),
    currentStreetAddress: yup.string().required(ErrorMessages.REQUIRED),
    outsideAddress: yup.string().required(ErrorMessages.REQUIRED),
    outsideCity: yup.string().required(ErrorMessages.REQUIRED),
    outsideState: yup.string().required(ErrorMessages.REQUIRED),
    outsideZip: yup.number().required(ErrorMessages.REQUIRED).typeError(ErrorMessages.ONLY_DIGITS)
        .test('len', ErrorMessages.MAX_LENGTH, val => val && val.toString().length <= 10)
    ,
    outsidePhone: yup.string().required(ErrorMessages.REQUIRED),
    outsideEmail: yup.string().email(ErrorMessages.EMAIL_INVALID).required(ErrorMessages.REQUIRED),
    indicatedAsPerpetrator: yup.boolean().required(ErrorMessages.REQUIRED),
    parentalRightsTerminated: yup.boolean().required(ErrorMessages.REQUIRED),
    disabledPersonInNeed: yup.boolean().required(ErrorMessages.REQUIRED),
    convictedOrCriminalOffense: yup.boolean().required(ErrorMessages.REQUIRED),
    permissionForBackground: yup.boolean().oneOf([true], ErrorMessages.REQUIRED),
    // permissionForBackground: yup.boolean(),
    signature: yup.string().required(ErrorMessages.REQUIRED),
    signatureDate: yup.string().required(ErrorMessages.REQUIRED).test('checkDate', ErrorMessages.INVALID_DATE, (value) => {
        return moment(value).isSameOrAfter(new Date().toDateString())
    }),
    parentSignature: yup.string().required(ErrorMessages.REQUIRED),
    parentSignatureDate: yup.string().required(ErrorMessages.REQUIRED).test('checkDate', ErrorMessages.INVALID_DATE, (value) => {
        return moment(value).isSameOrAfter(new Date().toDateString())
    }),
    results: yup.string().required(ErrorMessages.REQUIRED),

})
// ['isEmployment', 'isForsterCare']
