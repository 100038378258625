import {
  UPDATE,
  UPDATE_CURRENT_FACILITY,
  SET_LOADING,
} from 'modules/core/store/actions/userInfoActions';
import { LOGOUT } from 'modules/auth/store/actions/authActions';
import generateNameColor from 'modules/core/utils/generateNameColor';
import localStorageService from 'modules/core/utils/localStorageService';

const initialState = {
  loaded: false,
  loading: true,
  sidebarCollapsed: false,
};

const userInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE:
      const newState = { ...state, ...action.updates };

      if (!newState.avatarColor) {
        newState.avatarColor = generateNameColor(newState.name);
      }
      const toSave = { ...newState };
      delete toSave['loading'];
      delete toSave['loaded'];
      localStorageService.storeUserInfo(toSave);

      return newState;
    case UPDATE_CURRENT_FACILITY: {
      const newState = { ...state, facility: action.facility };

      const toSave = { ...newState };
      delete toSave['loading'];
      delete toSave['loaded'];
      localStorageService.storeUserInfo(toSave);

      return newState;
    }
    case LOGOUT:
      localStorageService.clearUserInfo();
      return initialState;
    case SET_LOADING:
      return { ...state, loading: action.value };
    default:
      return state;
  }
};

export default userInfoReducer;
