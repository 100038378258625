import React from 'react';
import { Button, Nav } from 'react-bootstrap';

import { mainRoutes } from 'modules/main/routes/constants';
import { aboutRoutes } from 'modules/about/routes/constants';
import { donateRouts } from 'modules/donate/routes/constants';
import history from 'modules/core/utils/history';
import { BreakpointContextConsumer } from 'modules/core/contexts/BreakpointContext';
import Share from 'modules/core/components/Share';
import Container from 'modules/core/components/Container';
import PhoneLink from 'modules/core/components/PhoneLink';
import EmailLink from 'modules/core/components/EmailLink';

import './styles.scss';

const Footer = () => {
  const getStartedButtonHandler = React.useCallback(() => {
    window.scrollTo(0, 0);
    history.push(donateRouts.root);
  }, []);

  const selectHandler = React.useCallback((selectedKey) => {
    window.scrollTo(0, 0);
    history.push(selectedKey);
  }, []);

  const navClickHandler = React.useCallback((e) => {
    e.preventDefault();
  }, []);

  return (
    <BreakpointContextConsumer>
      {({ isMobile, breakpoint }) => (
        <Container
          paddingHorizontalDesktop={breakpoint === 'md' ? '105px' : '210px'}
          paddingHorizontalMobile={'51px'}
          paddingVerticalMobile={'32px'}
          className="Footer__container"
        >
          <div className="Footer__content">
            <Nav
              activeKey={mainRoutes.root}
              onSelect={selectHandler}
              className="Footer__nav"
              onClick={navClickHandler}
            >
              <Nav.Item>
                <Nav.Link href={mainRoutes.root}>Home</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey={aboutRoutes.root}>About Us</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey={donateRouts.root}>Donate</Nav.Link>
              </Nav.Item>
            </Nav>
            <span className="Footer__address">
              © 2021 Forstter Youth Center, Inc.{' '}
              <EmailLink email="Support@forstter.org" />{' '}
              <PhoneLink phone="785-404-1295" />
            </span>

            {isMobile ? (
              <div className="Footer__shareContainer">
                <Share
                  shareLink={
                    window.location.protocol + '//' + window.location.host
                  }
                />
              </div>
            ) : (
              <div className="Footer__deksLeftContainer">
                <div className="Footer__shareContainer">
                  <Share
                    shareLink={
                      window.location.protocol + '//' + window.location.host
                    }
                  />
                </div>
                <Button
                  variant="outline-secondary"
                  onClick={getStartedButtonHandler}
                >
                  Get started
                </Button>
              </div>
            )}
          </div>
        </Container>
      )}
    </BreakpointContextConsumer>
  );
};

export default Footer;
