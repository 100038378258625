export const ADD_TO_CART = 'ADD_TO_CART';
export const SET = 'SET_CART_ITEMS';
export const REMOVE = 'REMOVE_FROM_CART';
export const CHANGE_AMOUNT = 'CHANGE_CART_AMOUNT';
export const CLEAR = 'CLEAR_CART';

export const addToCart = (items) => {
  return {
    type: ADD_TO_CART,
    items,
  };
};

export const setCartItems = (items) => {
  return {
    type: SET,
    items,
  };
};

export const removeFromCart = (id) => {
  return {
    type: REMOVE,
    id,
  };
};

export const changeAmount = (id, amount) => {
  return {
    type: CHANGE_AMOUNT,
    id,
    amount,
  };
};

export const clear = () => {
  return {
    type: CLEAR,
  };
};
