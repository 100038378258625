import { matchPath } from 'react-router-dom';

import ApiClient from 'modules/core/utils/ApiClient';
import history from 'modules/core/utils/history';
import SecurityGroupService from 'modules/core/utils/SecurityGroupService';
import { ordersRoutes } from 'modules/orders/routes/constants';
import { adminFacilityRoutes } from 'modules/admin-facility/routes/constants';

export const UPDATE = 'UPDATE_USER_INFO';
export const UPDATE_CURRENT_FACILITY = 'UPDATE_CURRENT_FACILITY';
export const SET_LOADING = 'SET_LOADING_USER_INFO';

export const setIsLoading = (value) => {
  return {
    type: SET_LOADING,
    value,
  };
};

export const updateUserInfo = (data) => {
  return {
    type: UPDATE,
    updates: data,
  };
};

export const fetchUserInfo = ({ fallbackPage, onError }) => {
  return async (dispatch, getState) => {
    if (!getState().auth.tokenData) {
      if (fallbackPage) history.push(fallbackPage);
      if (onError) onError();

      return;
    }

    try {
      dispatch(setIsLoading(true));
      const userInfo = await ApiClient.getUserInfo();
      dispatch(updateUserInfo({ ...userInfo, loading: false, loaded: true }));
    } catch (err) {
      console.log(err);
    }
  };
};

/**
 * Sets user mode & redirects to pages of specified mode
 * @param {'admin' | 'user'} userMode
 */
export const updateUserMode = (userMode) => {
  return async (dispatch, getState) => {
    const groups = getState().user.securityGroups;
    if (userMode === 'admin' && (!groups || groups.length === 0)) {
      return;
    }

    dispatch({
      type: UPDATE,
      updates: { userMode },
    });
    if (userMode === 'admin') {
      history.push(
        SecurityGroupService.getFirstGroupsPermissionPagePath(groups)
      );
    } else if (userMode === 'user') {
      history.push(ordersRoutes.root);
    }
  };
};

export const updateUserSecurityGroups = (securityGroups) => {
  return async (dispatch, getState) => {
    let userMode = getState().user.userMode;
    if (securityGroups?.length === 0) {
      userMode = 'user';
    }

    dispatch({
      type: UPDATE,
      updates: { userMode, securityGroups },
    });

    if (userMode === 'admin') {
      history.push(
        SecurityGroupService.getFirstGroupsPermissionPagePath(securityGroups)
      );
    } else if (userMode === 'user') {
      history.push(ordersRoutes.root);
    }
  };
};

export const startChangeCurrentFacility = (facility) => {
  return async (dispatch) => {
    if (facility) {
      await ApiClient.setOwnFacility(facility?.id);
    } else {
      await ApiClient.setAllFacilitiesMode();
    }

    dispatch({ type: UPDATE_CURRENT_FACILITY, facility });

    if (
      matchPath(history.location.pathname, {
        path: adminFacilityRoutes.list,
      }) ||
      (matchPath(history.location.pathname, {
        path: adminFacilityRoutes.facility,
      }) &&
        !matchPath(history.location.pathname, {
          path: adminFacilityRoutes.manage,
        }))
    ) {
      history.push(adminFacilityRoutes.root);
    }

    return facility?.id;
  };
};
