import React, {useEffect, useMemo} from 'react';
import {Formik, Form, Field} from 'formik'
import './styles.scss'
import LabeledFormControl from "../../../../core/components/FormControl";
import {Button, Col, FormCheck, Row, Spinner} from "react-bootstrap";
import PhoneInput from "../../../../admin/components/PhoneInput";
import CustomRadio from "../../../../core/components/CustomRadio";
import DatePicker from "../../../../admin/components/DatePicker";
import {fourthStepValues, STEPS, stepsPath, YesOrNoValues} from "../../../constants";
import {fourthStepSchema} from "../../../validation";
import FormikError from "../../../../admin/components/FormikError";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {careerRoutes} from "../../../routes/constants";
import Stepper from "../../Stepper";
import {useDispatch, useSelector} from "react-redux";
import {getFourthStep, updateFourthStep} from "../../../../core/store/actions/careerActions";
import LoadingActionButton from "../../LoadingButton";
import CustomPrompt from "../../CustomPrompt";

export const CareerApplyFourthStep = () => {
    const {id} = useParams()
    const location = useLocation()
    const history = useHistory()
    const {loading, stepLoading, stepValues} = useSelector((state) => state.career)
    const dispatch = useDispatch()
    const onSubmit = (values) => {
        const payload = {
            ...values,
            applicationId: Number(id),
            dateOfBirth: values?.dateOfBirth.toLocaleDateString('en-US'),
            signatureDate: values?.signatureDate.toLocaleDateString('en-US'),
            abuseDate: values?.abuseDate.toLocaleDateString('en-US'),
            disciplineFirstDate: values?.disciplineFirstDate.toLocaleDateString('en-US'),
            disciplineSecondDate: values?.disciplineSecondDate.toLocaleDateString('en-US'),
        }
        dispatch(updateFourthStep(payload))
    }
    const onPrev = () => {
        history.push(careerRoutes.stepFn(id, stepsPath.thirdStep), {
            activeStep: 2
        })
    }
    const mappedValues = useMemo(() => {
        if (stepValues?.fullName) {
            return {
                ...stepValues,
                dateOfBirth: new Date(stepValues.dateOfBirth),
                signatureDate: new Date(stepValues.signatureDate),
                abuseDate: new Date(stepValues.abuseDate),
                disciplineFirstDate: new Date(stepValues.disciplineFirstDate),
                disciplineSecondDate: new Date(stepValues.disciplineSecondDate),
            }

        } else {
            return fourthStepValues
        }

    }, [stepValues])
    useEffect(() => {
        if (id) {
            dispatch(getFourthStep(id))
        }

    }, [id, dispatch])

    return (
        <>
            {
                stepLoading ?
                    <div className={'text-center'}>
                        <Spinner animation="border"/>
                    </div>
                    :
                    <>
                        <Stepper activeStep={location.state?.activeStep} steps={STEPS}/>
                        <Formik
                            validationSchema={fourthStepSchema}
                            initialValues={{
                                ...mappedValues
                            }} onSubmit={onSubmit}
                            render={({handleSubmit, values, setFieldValue, dirty, submitCount}) => (
                               <>
                                   <Form onSubmit={handleSubmit}>
                                       <div className={'FourthStep__item'}>
                                           <h3>
                                               Adult abuse, neglect, exploitation central registry release of information
                                           </h3>
                                           <Row>
                                               <Col>
                                                   <Field
                                                       name={'fullName'}
                                                       label={'Print Full Name'}
                                                       placeholder={'Enter'}
                                                       component={LabeledFormControl}
                                                   />
                                                   <FormikError name="fullName"/>
                                               </Col>
                                           </Row>
                                           <p className={'my-2'}>
                                               Give permission for the release of information concerning myself in the
                                               Adult Abuse,
                                               Neglect, Exploitation Central Registry to:
                                           </p>
                                           <Row className={'col-spacing'}>
                                               <Col sm>
                                                   <Field
                                                       name={'contactPerson'}
                                                       label={'Contact Person'}
                                                       placeholder={'Enter'}
                                                       component={LabeledFormControl}
                                                   />
                                                   <FormikError name="contactPerson"/>
                                               </Col>
                                               <Col sm>
                                                   <Field
                                                       value={values.phone}
                                                       onChange={(e) => {
                                                           setFieldValue('phone', e)
                                                       }}
                                                       name={'phone'}
                                                       label={'Phone'}
                                                       placeholder={'Enter'}
                                                       component={PhoneInput}
                                                   />
                                                   <FormikError name="phone"/>
                                               </Col>
                                           </Row>
                                           <Row className={'col-spacing'}>
                                               <Col sm>
                                                   <Field
                                                       name={'agencyName'}
                                                       label={'Agency Name'}
                                                       placeholder={'Enter'}
                                                       component={LabeledFormControl}
                                                   />
                                                   <FormikError name="agencyName"/>
                                               </Col>
                                               <Col sm>
                                                   <Field
                                                       name={'agencyMailingAddress'}
                                                       label={'Agency mailing address'}
                                                       placeholder={'Enter'}
                                                       component={LabeledFormControl}
                                                   />
                                                   <FormikError name="agencyMailingAddress"/>
                                               </Col>
                                           </Row>
                                           <Row>
                                               <Col>
                                                   <Field
                                                       name={'emailAddress'}
                                                       label={'Email address: (Will return via Encrypted email unless marked otherwise)'}
                                                       placeholder={'Enter'}
                                                       component={LabeledFormControl}
                                                   />
                                                   <FormikError name="emailAddress"/>
                                               </Col>
                                           </Row>
                                           <Row>
                                               <Col>
                                                   <Field
                                                       name={'maidenName'}
                                                       label={'Maiden Name and/or Other Names Known by: (Print Only)'}
                                                       placeholder={'Enter'}
                                                       component={LabeledFormControl}
                                                   />
                                                   <FormikError name="maidenName"/>
                                               </Col>
                                           </Row>
                                           <Row className={'col-spacing'}>
                                               <Col lg={5} md={6}>
                                                   <Field
                                                       name={'streetAddress'}
                                                       label={'Street address'}
                                                       placeholder={'Enter'}
                                                       component={LabeledFormControl}
                                                   />
                                                   <FormikError name="streetAddress"/>
                                               </Col>
                                               <Col lg={3} md={6}>
                                                   <Field
                                                       name={'city'}
                                                       label={'City'}
                                                       placeholder={'Enter'}
                                                       component={LabeledFormControl}
                                                   />
                                                   <FormikError name="city"/>
                                               </Col>
                                               <Col lg={4} md={12}>
                                                   <Row>
                                                       <Col sm>
                                                           <Field
                                                               name={'state'}
                                                               label={'State'}
                                                               placeholder={'Enter'}
                                                               component={LabeledFormControl}
                                                           />
                                                           <FormikError name="state"/>
                                                       </Col>
                                                       <Col sm>
                                                           <Field
                                                               name={'zipCode'}
                                                               label={'Zip code'}
                                                               placeholder={'Enter'}
                                                               component={LabeledFormControl}
                                                           />
                                                           <FormikError name="zipCode"/>
                                                       </Col>

                                                   </Row>
                                               </Col>
                                           </Row>
                                           <Row className={'col-spacing'}>
                                               <Col lg={5} md={3}>
                                                   <DatePicker
                                                       name={'dateOfBirth'}
                                                       maxDate={new Date()}
                                                       label={'DOB:'}
                                                       yearDropdownItemNumber={100}
                                                       scrollableYearDropdown
                                                       showYearDropdown
                                                       showMonthDropdown
                                                       placeholderText={'mm/dd/yyyy'}
                                                       selected={values.dateOfBirth}
                                                       onChange={(e) => {
                                                           setFieldValue('dateOfBirth', e)

                                                       }}
                                                   />
                                                   <FormikError name="dateOfBirth"/>
                                               </Col>
                                               <Col lg={3} md={3}>
                                                   <Field
                                                       name={'ss'}
                                                       label={'SS#:'}
                                                       placeholder={'Enter'}
                                                       component={LabeledFormControl}
                                                   />
                                                   <FormikError name="ss"/>
                                               </Col>
                                               <Col lg={4} md={6}>
                                                   <div className={'gender-box'}>
                                                       <Field
                                                           defaultChecked={values?.isMale?.toString() === YesOrNoValues.YES}
                                                           onChange={(e) => {
                                                               setFieldValue('isMale', true)
                                                           }}
                                                           value={YesOrNoValues.YES}
                                                           name={'isMale'}
                                                           label={'Male'}
                                                           component={CustomRadio}
                                                       />
                                                       <Field
                                                           defaultChecked={values?.isMale?.toString() === YesOrNoValues.NO}
                                                           onChange={(e) => {
                                                               setFieldValue('isMale', false)
                                                           }}
                                                           value={YesOrNoValues.NO}
                                                           name={'isMale'}
                                                           label={'Female'}
                                                           component={CustomRadio}
                                                       />
                                                       <span>
                                                (mark one)
                                            </span>
                                                   </div>
                                                   <FormikError name="isMale"/>
                                               </Col>
                                           </Row>
                                           <Row>
                                               <Col>
                                                   <div className={'d-flex'}>
                                                       <Field
                                                           defaultChecked={values.confidentialUseInformation}
                                                           onChange={(e) => {
                                                               setFieldValue('confidentialUseInformation', e.target.checked)
                                                           }}
                                                           name={'confidentialUseInformation'}
                                                           className={'checkbox'}
                                                           component={FormCheck}
                                                       />
                                                       <p className={'ml-3'}>
                                                           I Understand that all information released will be for the
                                                           exclusive and
                                                           confidential use of the above named organization/person. I have
                                                           read and
                                                           understand this form and information provided is true and
                                                           correct to the
                                                           best of my knowledge.
                                                       </p>
                                                   </div>
                                                   <FormikError name="confidentialUseInformation"/>
                                               </Col>
                                           </Row>
                                           <Row>
                                               <Col>
                                                   <div className={'d-flex'}>
                                                       <Field
                                                           defaultChecked={values.releaseAnyInformation}
                                                           onChange={(e) => {
                                                               setFieldValue('releaseAnyInformation', e.target.checked)
                                                           }}
                                                           name={'releaseAnyInformation'}
                                                           className={'checkbox'}
                                                           component={FormCheck}
                                                       />
                                                       <p className={'ml-3'}>
                                                           I give permission for the release of any information concerning
                                                           myself in
                                                           the Adult Abuse, Neglect, Explotation Central Registry each year
                                                           while I am
                                                           employed or associated with the above agency.
                                                       </p>
                                                   </div>
                                                   <FormikError name="releaseAnyInformation"/>
                                               </Col>
                                           </Row>
                                           <Row className={'col-spacing'}>
                                               <Col lg={8} md={12}>
                                                   <Field
                                                       name={'signature'}
                                                       label={'Signature (An Ink Signature or a Verified E-Signature is Required for Processing)'}
                                                       placeholder={'Enter'}
                                                       component={LabeledFormControl}
                                                   />
                                                   <FormikError name="signature"/>

                                               </Col>
                                               <Col lg={4} md={12}>
                                                   <DatePicker
                                                       minDate={new Date()}
                                                       name={'signatureDate'}
                                                       label={'Date'}
                                                       yearDropdownItemNumber={100}
                                                       scrollableYearDropdown
                                                       showYearDropdown
                                                       showMonthDropdown
                                                       placeholderText={'mm/dd/yyyy'}
                                                       selected={values.signatureDate}
                                                       onChange={(e) => {
                                                           setFieldValue('signatureDate', e)
                                                       }}
                                                   />
                                                   <FormikError name="signatureDate"/>

                                               </Col>
                                           </Row>
                                       </div>
                                       <div className={'FourthStep__item'}>
                                           <h3>Return to:</h3>
                                           <div>
                                               <span>Email:</span>
                                               <span className={'font-weight-bold'}>DCF.APSRegistry@ks.gov</span>
                                           </div>
                                           <div>
                                               <span>Mail:</span>
                                               <span
                                                   className={'font-weight-bold'}>Office of Background Investigation</span>
                                           </div>
                                           <p>Adult Abuse Registry</p>
                                           <p>500 SW Van Buren St., Topeka, Kansas 66603</p>
                                           <p className={'font-italic font-weight-light'}>Please allows 3-5 days for
                                               processing email
                                               requests and additional 5-7 days if returning bby US Postal Service)</p>
                                           <Row className={'col-spacing'}>
                                               <Col sm>
                                                   <Field
                                                       name={'officialUserProhibited'}
                                                       label={'For official Use Only: Mark in this area if Prohibited'}
                                                       placeholder={'Enter'}
                                                       component={LabeledFormControl}
                                                   />
                                                   <FormikError name="officialUserProhibited"/>

                                               </Col>
                                               <Col sm>
                                                   <Field
                                                       name={'officialUseCleaned'}
                                                       label={'For official Use Only: Mark in this area if Cleared'}
                                                       placeholder={'Enter'}
                                                       component={LabeledFormControl}
                                                   />
                                                   <FormikError name="officialUseCleaned"/>

                                               </Col>
                                           </Row>
                                       </div>
                                       <div className={'FourthStep__item'}>
                                           <h3>Abuse/Neglect Reporting Policy</h3>
                                           <p>I understand the provisions of the Kansas Code of Care of Children and the
                                               policies of
                                               Forstter Residential Center in this regard. I also agree to report any
                                               suspected child
                                               abuse, neglect, sexual abuse and exploitation to the local authorities
                                               immediately.</p>
                                           <Row className={'col-spacing'}>
                                               <Col sm>
                                                   <Field
                                                       name={'abusePrintedName'}
                                                       label={'Employee\'s Printed Name'}
                                                       placeholder={'Enter'}
                                                       component={LabeledFormControl}
                                                   />
                                                   <FormikError name="abusePrintedName"/>

                                               </Col>
                                               <Col sm>
                                                   <Field
                                                       name={'abusePosition'}
                                                       label={'Position'}
                                                       placeholder={'Enter'}
                                                       component={LabeledFormControl}
                                                   />
                                                   <FormikError name="abusePosition"/>

                                               </Col>
                                           </Row>
                                           <Row className={'col-spacing'}>
                                               <Col sm>
                                                   <Field
                                                       name={'abuseSignature'}
                                                       label={'Employee\'s Signature'}
                                                       placeholder={'Enter'}
                                                       component={LabeledFormControl}
                                                   />
                                                   <FormikError name="abuseSignature"/>

                                               </Col>
                                               <Col sm>
                                                   <DatePicker
                                                       minDate={new Date()}
                                                       label={'Date'}
                                                       yearDropdownItemNumber={100}
                                                       scrollableYearDropdown
                                                       showYearDropdown
                                                       showMonthDropdown
                                                       placeholderText={'mm/dd/yyyy'}
                                                       selected={values.abuseDate}
                                                       onChange={(e) => {
                                                           setFieldValue('abuseDate', e)
                                                       }}
                                                   />
                                                   <FormikError name="abuseDate"/>
                                               </Col>
                                           </Row>
                                           <h3>Discipline Policy</h3>
                                           <p>
                                               I have read the Discipline Policy of Forstter Youth Residential Center. I
                                               understand
                                               that Forstter Youth Residential Center will not allow any form of discipline
                                               administered in any fashion, which will cause a youth to suffer physical or
                                               emotional
                                               damage. Discipline, which causes pain either physically or emotional, such
                                               as hitting,
                                               beating, shaking, cursing, threatening, binding, closeting, isolation,
                                               denial of meals
                                               derogatory remarks about the child or her family will not be allowed.
                                           </p>
                                           <Row>
                                               <Col>
                                                   <div className={'d-flex'}>
                                                       <Field
                                                           name={'abilityRestraintApproach'}
                                                           defaultChecked={values.abilityRestraintApproach}
                                                           onChange={(e) => {
                                                               setFieldValue('abilityRestraintApproach', e.target.checked)
                                                           }}
                                                           className={'checkbox'}
                                                           component={FormCheck}
                                                       />
                                                       <p className={'ml-3'}>
                                                           I understand what non-aggressive restraint includes. I am
                                                           confident in my
                                                           ability to use the non- aggressive restraint approach.
                                                       </p>
                                                   </div>
                                                   <FormikError name="abilityRestraintApproach"/>
                                               </Col>
                                           </Row>
                                           <Row>
                                               <Col>
                                                   <div className={'d-flex'}>
                                                       <Field
                                                           name={'moreRestraintTechniques'}
                                                           defaultChecked={values.moreRestraintTechniques}
                                                           onChange={(e) => {
                                                               setFieldValue('moreRestraintTechniques', e.target.checked)
                                                           }}
                                                           className={'checkbox'}
                                                           component={FormCheck}
                                                       />
                                                       <p className={'ml-3'}>
                                                           I understand what non-aggressive restraint includes. I would
                                                           like more
                                                           training on restraint techniques and when it is appropriate to
                                                           restrain a
                                                           child.
                                                       </p>
                                                   </div>
                                                   <FormikError name="moreRestraintTechniques"/>
                                               </Col>
                                           </Row>
                                           <Row className={'col-spacing'}>
                                               <Col sm>
                                                   <Field
                                                       name={'disciplineFirstName'}
                                                       label={'Employee\'s Printed Name'}
                                                       placeholder={'Enter'}
                                                       component={LabeledFormControl}
                                                   />
                                                   <FormikError name="disciplineFirstName"/>
                                               </Col>
                                               <Col sm>
                                                   <Field
                                                       name={'disciplineFirstPosition'}
                                                       label={'Position'}
                                                       placeholder={'Enter'}
                                                       component={LabeledFormControl}
                                                   />
                                                   <FormikError name="disciplineFirstPosition"/>
                                               </Col>
                                           </Row>
                                           <Row className={'col-spacing'}>
                                               <Col sm>
                                                   <Field
                                                       name={'disciplineFirstSignature'}
                                                       label={'Employee\'s Signature'}
                                                       placeholder={'Enter'}
                                                       component={LabeledFormControl}
                                                   />
                                                   <FormikError name="disciplineFirstSignature"/>
                                               </Col>
                                               <Col sm>
                                                   <DatePicker
                                                       minDate={new Date()}
                                                       label={'Date'}
                                                       yearDropdownItemNumber={100}
                                                       scrollableYearDropdown
                                                       showYearDropdown
                                                       showMonthDropdown
                                                       placeholderText={'mm/dd/yyyy'}
                                                       selected={values.disciplineFirstDate}
                                                       onChange={(e) => {
                                                           setFieldValue('disciplineFirstDate', e)
                                                       }}
                                                   />
                                                   <FormikError name="disciplineFirstDate"/>

                                               </Col>
                                           </Row>
                                           <p>CAR 28-4-271 (ABC) Administration I have read Z8-4-271 (ABC). I understand
                                               what the
                                               Kansas rules say. If not, I'll ask my employer. I also read CAR 28-4-275.
                                               Healthcare. </p>
                                           <Row className={'col-spacing'}>
                                               <Col sm>
                                                   <Field
                                                       name={'disciplineSecondName'}
                                                       label={'Employee\'s Printed Name'}
                                                       placeholder={'Enter'}
                                                       component={LabeledFormControl}
                                                   />
                                                   <FormikError name="disciplineSecondName"/>

                                               </Col>
                                               <Col sm>
                                                   <Field
                                                       name={'disciplineSecondPosition'}
                                                       label={'Position'}
                                                       placeholder={'Enter'}
                                                       component={LabeledFormControl}
                                                   />
                                                   <FormikError name="disciplineSecondPosition"/>

                                               </Col>
                                           </Row>
                                           <Row className={'col-spacing'}>
                                               <Col sm>
                                                   <Field
                                                       name={'disciplineSecondSignature'}
                                                       label={'Employee\'s Signature'}
                                                       placeholder={'Enter'}
                                                       component={LabeledFormControl}
                                                   />
                                                   <FormikError name="disciplineSecondSignature"/>

                                               </Col>
                                               <Col sm>
                                                   <DatePicker
                                                       minDate={new Date()}
                                                       label={'Date'}
                                                       yearDropdownItemNumber={100}
                                                       scrollableYearDropdown
                                                       showYearDropdown
                                                       showMonthDropdown
                                                       placeholderText={'mm/dd/yyyy'}
                                                       selected={values.disciplineSecondDate}
                                                       onChange={(e) => {
                                                           setFieldValue('disciplineSecondDate', e)
                                                       }}
                                                   />
                                                   <FormikError name="disciplineSecondDate"/>
                                               </Col>
                                           </Row>
                                       </div>
                                       <div className={'Step__footer'}>
                                           <Button
                                               onClick={onPrev}
                                               className={'btn-outline-primary Step__action-button'}>
                                               Back
                                           </Button>
                                           <LoadingActionButton loading={loading} text={'Next'}/>
                                       </div>
                                   </Form>
                                   <CustomPrompt when={dirty && submitCount === 0}/>
                               </>
                            )}
                        />

                    </>
            }
        </>
    );
};

