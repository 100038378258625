import * as yup from 'yup'
import {ErrorMessages} from "./messages";

export const secondStepSchema = yup.object().shape({
    models: yup.array().of(
        yup.object().shape({
            name: yup.string().required(ErrorMessages.REQUIRED),
            occupation: yup.string().required(ErrorMessages.REQUIRED),
            phone: yup.string().required(ErrorMessages.REQUIRED),
            address: yup.string().required(ErrorMessages.REQUIRED),
            city: yup.string().required(ErrorMessages.REQUIRED),
            state: yup.string().required(ErrorMessages.REQUIRED)
        })
    )
})
