import ApiClient from 'modules/core/utils/ApiClient';
import {
  updateUserInfo,
  setIsLoading,
} from 'modules/core/store/actions/userInfoActions';
import history from 'modules/core/utils/history';
import SecurityGroupService from 'modules/core/utils/SecurityGroupService';
import { ordersRoutes } from 'modules/orders/routes/constants';
import { prettifyError } from 'modules/core/utils/error';

export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const LOGIN_STARTED = 'LOGIN_STARTED';
export const LOGIN_ERROR = 'LOGIN_ERROR';

export const SIGN_UP_STARTED = 'SIGN_UP_STARTED';
export const SIGNED_UP = 'SIGNED_UP';

export const login = (tokenData) => {
  return { type: LOGIN, tokenData };
};

export const startLogin = (password, email) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: LOGIN_STARTED });
      const { userInfo, tokenData } = await ApiClient.login(email, password);
      const currentUserMode = getState().user.userMode;
      const isAdmin = userInfo.securityGroups.length > 0;

      let newUserMode = 'user';
      if (currentUserMode) {
        newUserMode = currentUserMode;
      } else if (isAdmin) {
        newUserMode = 'admin';
      }

      dispatch(
        updateUserInfo({
          ...userInfo,
          loading: false,
          loaded: true,
          userMode: newUserMode,
        })
      );
      dispatch(login(tokenData));

      // TODO: use user/admin view modes
      if (isAdmin) {
        history.push(
          SecurityGroupService.getFirstGroupsPermissionPagePath(
            userInfo.securityGroups
          )
        );
      } else {
        history.push(ordersRoutes.root);
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: LOGIN_ERROR,
        error:
          err?.response?.data?.errors?.[0]?.message ||
          'Email or password is incorrect',
      });
    }
  };
};
export const startSignup = ({ email, name, password, repeatPassword }) => {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: SIGN_UP_STARTED });
      const { messages, errors } = await ApiClient.register({
        email,
        name,
        password,
        repeatPassword,
      });

      if (errors?.length > 0) {
        dispatch({
          type: LOGIN_ERROR,
          error: errors[0].message,
        });
      } else {
        dispatch({ type: SIGNED_UP, message: messages[0].message });
      }
    } catch (err) {
      console.log(err, err.data, err.response);
      dispatch({
        type: LOGIN_ERROR,
        error: prettifyError(err),
      });
    }
  };
};
export const logout = () => {
  return async (dispatch, getState) => {
    if (getState().auth.isAuthenticated) {
      const { refreshToken, accessToken } = getState().auth.tokenData;

      dispatch(setIsLoading(true));
      try {
        await ApiClient.logout(accessToken, refreshToken);
      } catch (err) {
        console.log(err, err.message);
      }
      dispatch(setIsLoading(false));
    }

    dispatch({
      type: LOGOUT,
    });
  };
};
