import React from 'react';
import { Button, Nav, Fade } from 'react-bootstrap';

import { mainRoutes } from 'modules/main/routes/constants';
import { aboutRoutes } from 'modules/about/routes/constants';
import { donateRouts } from 'modules/donate/routes/constants';
import { storeRoutes } from 'modules/store/routes/constants';
import { trainingsRoutes } from 'modules/trainings/routes/constants';
import { careerRoutes } from 'modules/career/routes/constants';
import history from 'modules/core/utils/history';
import { BreakpointContextConsumer } from 'modules/core/contexts/BreakpointContext';
import Container from 'modules/core/components/Container';

import menuIcon from 'assets/icons/menu.svg';
import closeIcon from 'assets/icons/close.svg';

import './styles.scss';

const Header = ({
  children,
  border,
  mobileMenuContent,
  mobileBottomMenuContent,
}) => {
  const [mobileMenuShown, setMobileMenuShown] = React.useState(false);
  const [mobileMenuContentShown, setMobileMenuContentShown] =
    React.useState(false);

  const toggleMenuButtonClickHandler = React.useCallback(() => {
    console.log('toggle menu');
    if (!mobileMenuShown) {
      window.document.body.style.overflow = 'hidden';
      setTimeout(() => {
        setMobileMenuContentShown(true);
      }, 100);
    } else {
      window.document.body.style.overflow = '';
      setMobileMenuContentShown(false);
    }
    setMobileMenuShown(!mobileMenuShown);
  }, [setMobileMenuShown, mobileMenuShown]);

  const donateButtonClickHandler = React.useCallback(() => {
    if (mobileMenuShown) toggleMenuButtonClickHandler();
    history.push('/donate');
  }, [toggleMenuButtonClickHandler, mobileMenuShown]);

  const handleNavSelect = React.useCallback(
    (selectedKey) => {
      if (mobileMenuShown) toggleMenuButtonClickHandler();
      history.push(selectedKey);
    },
    [toggleMenuButtonClickHandler, mobileMenuShown]
  );

  const titleClickHandler = React.useCallback(() => {
    if (mobileMenuShown) toggleMenuButtonClickHandler();
    history.push(mainRoutes.root);
  }, [toggleMenuButtonClickHandler, mobileMenuShown]);

  const navClickHandler = React.useCallback((e) => {
    e.preventDefault();
  }, []);
  const handleLinkClick = React.useCallback(() => {
      window.open("https://maecrafts.com/");

  },[])

  return (
    <BreakpointContextConsumer>
      {({ isTablet }) => (
        <Container
          paddingHorizontalDesktop={'210px'}
          paddingVerticalDesktop={'0px'}
          paddingHorizontalMobile={'18px'}
          paddingVerticalMobile={'15px'}
          className={border ? 'Header__root-bordered' : 'Header__root'}
        >
          <div className="Header__content">
            <div
              className={
                history.location.pathname === '/'
                  ? 'Header__titleContainer'
                  : 'Header__titleContainer active'
              }
              onClick={titleClickHandler}
            >
              <h1 className="Header__title">Forstter Youth Center</h1>
              <p className="Header__subtitle">fostering a difference</p>
            </div>
            {!isTablet && (
              <Nav
                activeKey={mainRoutes.root}
                onSelect={handleNavSelect}
                onClick={navClickHandler}
              >
                <Nav.Item>
                  <Nav.Link href={mainRoutes.root}>Home</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey={aboutRoutes.root}>About Us</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey={donateRouts.root}>Donate</Nav.Link>
                </Nav.Item>
                <Nav.Item
                >
                  {/*eventKey={storeRoutes.root}*/}
                  <Nav.Link
                      onClick={handleLinkClick}
                  >
                    Store
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey={careerRoutes.root}>Career</Nav.Link>
                </Nav.Item>
                {/*<Nav.Item>*/}
                {/*  <Nav.Link eventKey={trainingsRoutes.root}>Trainings</Nav.Link>*/}
                {/*</Nav.Item>*/}
              </Nav>
            )}
            <div className="Header__buttons">
              {children}
              {
                  isTablet && (
                      <img
                          src={menuIcon}
                          alt="menu"
                          className="Header__clickableIcon"
                          onClick={toggleMenuButtonClickHandler}
                      />
                  )
              }
            </div>
            {/*{!isTablet && children}*/}
            {/*{isTablet && (*/}
            {/*  <div className="Header__buttons">*/}
            {/*    {children}*/}

            {/*    <img*/}
            {/*      src={menuIcon}*/}
            {/*      alt="menu"*/}
            {/*      className="Header__clickableIcon"*/}
            {/*      onClick={toggleMenuButtonClickHandler}*/}
            {/*    />*/}
            {/*  </div>*/}
            {/*)}*/}
          </div>
          {mobileMenuShown && (
            <div className="Header_mobileMenuOverlayContainer">
              <div className="Header_mobileMenuOverlay">
                <Fade in={mobileMenuContentShown}>
                  <div className="Header_mobileMenuOverlayMenu">
                    <Button
                      className="Header__close"
                      variant="link"
                      onClick={toggleMenuButtonClickHandler}
                    >
                      <img src={closeIcon} alt="menu" />
                    </Button>
                    {/* <div className="AdminHeader__avatarContainer">
                      {mobileMenuContent}
                    </div> */}
                    {mobileMenuContent}

                    <Nav
                      activeKey={mainRoutes.root}
                      onSelect={handleNavSelect}
                      className="Header_mobileNav"
                      fill
                    >
                      <Nav.Item className="Header__mobileNavFirstElementContainer">
                        <Nav.Link href={mainRoutes.root}>Home</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey={aboutRoutes.root}>
                          About Us
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey={donateRouts.root}>Donate</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        {/*eventKey={storeRoutes.root}*/}
                        <Nav.Link
                            onClick={handleLinkClick}
                            >
                          Store
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey={careerRoutes.root}>Career</Nav.Link>
                      </Nav.Item>
                      {/*<Nav.Item>*/}
                      {/*  <Nav.Link eventKey={trainingsRoutes.root}>*/}
                      {/*    Trainings*/}
                      {/*  </Nav.Link>*/}
                      {/*</Nav.Item>*/}
                    </Nav>
                    {mobileBottomMenuContent}
                  </div>
                </Fade>
              </div>
            </div>
          )}
        </Container>
      )}
    </BreakpointContextConsumer>
  );
};

export default Header;
