import React from 'react';
import { Spinner } from 'react-bootstrap';

import './styles.scss';

const Fallback = () => (
  <div className="WaitingComponent__root">
    Loading...
    <Spinner animation={'grow'} />
  </div>
);

const WaitingComponent = (Component) => {
  return (props) => (
    <React.Suspense fallback={<Fallback />}>
      <Component {...props} />
    </React.Suspense>
  );
};

export default WaitingComponent;
