import {
  SET_PAYMENT_METHODS,
  REMOVE_PAYMENT_METHOD,
  TOGGLE_LOADING,
  UPDATE_PAYMENT_METHODS,
  MAKE_DEFAULT,
  UPDATE_ADDRESS,
  TOGGLE_ADDRESS_LOADING,
  SELECT_METHOD,
  RESET,
} from 'modules/core/store/actions/cardsActions';
import { LOGOUT } from 'modules/auth/store/actions/authActions';

const initialState = {
  loaded: false,
  loading: false,
  paymentMethods: [],

  addressLoaded: false,
  addressLoading: false,
  shippingAddress: {
    fullName: '',
    addressLine: '',
    city: '',
    state: '',
    country: '',
    zipCode: '',
    email: '',
  },
  billingAddress: {
    fullName: '',
    addressLine: '',
    city: '',
    state: '',
    country: '',
    zipCode: '',
    email: '',
  },
  selectedMethod: undefined,
};

const cardsReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_LOADING:
      return {
        ...state,
        loading: !state.loading,
      };
    case TOGGLE_ADDRESS_LOADING:
      return {
        ...state,
        addressLoading: !state.addressLoading,
      };
    case SET_PAYMENT_METHODS:
      return {
        ...state,
        loaded: true,
        loading: false,
        paymentMethods: action.data,
      };
    case UPDATE_PAYMENT_METHODS:
      return {
        ...state,
        paymentMethods: [...state.paymentMethods, ...action.updates],
      };
    case REMOVE_PAYMENT_METHOD:
      return {
        ...state,
        paymentMethods: state.paymentMethods.filter((p) => p.id !== action.id),
      };
    case MAKE_DEFAULT:
      return {
        ...state,
        paymentMethods: state.paymentMethods.map((p) => {
          p.isDefault = p.id === action.id;
          return p;
        }),
      };
    case LOGOUT:
    case RESET:
      return initialState;
    case UPDATE_ADDRESS:
      return {
        ...state,
        shippingAddress: action.shippingAddress,
        billingAddress: action.billingAddress,
        addressesEqual: action.addressesEqual,
        addressLoaded: true,
      };
    case SELECT_METHOD:
      return {
        ...state,
        selectedMethod: action.selectedMethod,
      };

    default:
      return state;
  }
};

export default cardsReducer;
