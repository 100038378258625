import fileDialog from 'file-dialog';

export default async (accept) => {
  const files = await fileDialog({accept: accept? accept : undefined});
  if (files[0]) {
    if ((files[0].size / 1024).toFixed(1) >= 50000) {
      return 'File is too large';
    } else {
      const [file] = files;
      return file;
    }
  }
};
