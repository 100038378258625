import React from 'react';
import { Link } from 'react-router-dom';

import { mainRoutes } from 'modules/main/routes/constants';
import './styles.scss';

const NotFoundPage = () => {
  return (
    <div className="NotFoundPage__root">
      <div className="NotFoundPage__content">
        <h1>Sorry, we can't find that page.</h1>
        <h2>404 - page not found</h2>
        <Link className="btn btn-primary" to={mainRoutes.root} role="button">
          Back to Home
        </Link>
      </div>
    </div>
  );
};

export default NotFoundPage;
