import React from 'react';

import ImageService from 'modules/core/utils/ImageService';

const useFetchImagesAuthorized = (
  controllerPath,
  hash,
  { doNotFetch = false, onLoad } = {}
) => {
  const [response, setResponse] = React.useState();
  const [error, setError] = React.useState();
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (doNotFetch) return;

    const doFetch = async () => {
      setLoading(true);
      try {
        setResponse(await ImageService.getImage(controllerPath, hash));
      } catch (err) {
        console.log('Error fetching image: ', err, err.response);
        setError(err);
      } finally {
        if (onLoad) onLoad();
        setLoading(false);
      }
    };
    doFetch().then();
  }, [controllerPath, hash, doNotFetch]);
  return { response, error, loading };
};

export default useFetchImagesAuthorized;
