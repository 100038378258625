import {createSlice} from "@reduxjs/toolkit";
import {
    getWorkingTimeByWeek,
    getWorkingTimeByMonth,
    updatePricePerHour,
    getCurrentTimeInformation, startTimer, stopTimer
} from "../actions/trackerActions";

const initialState = {
    loading: false,
    weekItems: [],
    monthItems: [],
    pricePerHour: '',
    updatePrice: {
        loading: false,
        errors: []
    },
//    tracker logic
    currentTimeInformation: {
        todayTime: '00:00',
        weekTime: '00:00',
        loading: false,
        isTracked: false,
    },
    showIsTurnOnModal: false,
    showIsTurnOfModal: false,
    timerLoading: false
}

const TrackerSlice = createSlice({
    name: 'tracker',
    initialState,
    reducers: {
        setShowIsTurnOnModal: (state, action) => {
            state.showIsTurnOnModal = action.payload
        },
        setShowIsTurnOfModal: (state, action) => {
            state.showIsTurnOfModal = action.payload
        }
    },
    extraReducers: builder => builder.addCase(getWorkingTimeByWeek.pending, (state) => {
        state.loading = true
    }).addCase(getWorkingTimeByWeek.fulfilled, (state, action) => {
        state.loading = false
        state.weekItems = action.payload.timerInfo
        state.pricePerHour = action.payload.pricePerHour
    }).addCase(getWorkingTimeByWeek.rejected, (state) => {
        state.loading = false
    }).addCase(getWorkingTimeByMonth.pending, (state) => {
        state.loading = true
    }).addCase(getWorkingTimeByMonth.fulfilled, (state, action) => {
        state.loading = false
        state.monthItems = action.payload.timerInfo
        state.pricePerHour = action.payload.pricePerHour
    }).addCase(getWorkingTimeByMonth.rejected, (state) => {
        state.loading = false
    }).addCase(updatePricePerHour.pending, (state) => {
        state.updatePrice.loading = true
        state.updatePrice.errors = []
    }).addCase(updatePricePerHour.fulfilled, (state) => {
        state.updatePrice.loading = false
    }).addCase(updatePricePerHour.rejected, (state, action) => {
        state.updatePrice.loading = false
        state.updatePrice.errors = action.payload
    }).addCase(getCurrentTimeInformation.pending, (state) => {
        state.currentTimeInformation.loading = true
    }).addCase(getCurrentTimeInformation.fulfilled, (state, action) => {
        state.currentTimeInformation.loading = false
        state.currentTimeInformation.todayTime = action.payload.todayTime
        state.currentTimeInformation.weekTime = action.payload.weekTime
        state.currentTimeInformation.isTracked = action.payload.isTracked
    }).addCase(getCurrentTimeInformation.rejected, (state) => {
        state.currentTimeInformation.loading = false
    }).addCase(startTimer.pending, (state) => {
        state.timerLoading = true
    }).addCase(startTimer.fulfilled, (state) => {
        state.timerLoading = false
    }).addCase(startTimer.rejected, (state) => {
        state.timerLoading = false
    }).addCase(stopTimer.pending, (state) => {
        state.timerLoading = true
    }).addCase(stopTimer.fulfilled, (state) => {
        state.timerLoading = false
    }).addCase(stopTimer.rejected, (state) => {
        state.timerLoading = false
    })
})

export default TrackerSlice.reducer

export const {setShowIsTurnOnModal, setShowIsTurnOfModal} = TrackerSlice.actions
