import React from 'react';
import { Link } from 'react-router-dom';

import { mainRoutes } from 'modules/main/routes/constants';
import './styles.scss';

const ErrorPage = () => {
  return (
    <div className="ErrorPage__root">
      <div className="ErrorPage__content">
        <h1>Oops... Something went wrong</h1>
        <h2>an error occurred.</h2>
        <Link className="btn btn-primary" to={mainRoutes.root} role="button">
          Back to Home
        </Link>
      </div>
    </div>
  );
};

export default ErrorPage;
