import React from 'react';
import ReactDatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import './styles.scss';
import calendarIcon from 'assets/icons/calendar.svg';

const defaultMaxDate = new Date(9999, 1, 1);

const DatePicker = ({ label, disabled, id, onChange, ...props }) => {
  const [focused, setFocused] = React.useState(false);
  const datePickerRef = React.useRef(null);

  const wrappedOnChange = React.useCallback(
    (date) => {
      onChange(date);
    },
    [onChange]
  );

  const handleImgClick = React.useCallback(() => {
    datePickerRef.current.onInputClick();
    datePickerRef.current.setFocus(true);
  }, []);

  return (
    <>
      {label && <label htmlFor={id}>{label}</label>}
      <div
        disabled={disabled}
        className={`form-control DateTimePicker__root ${
          focused ? 'DateTimePicker__focused' : ''
        }`}
        id={id}
      >
        <img
          src={calendarIcon}
          alt="Date selector"
          onClick={handleImgClick}
          className="DateTimePicker__icon"
        />
        <ReactDatePicker
          ref={datePickerRef}
          disabled={disabled}
          defaultMaxDate={defaultMaxDate}
          {...props}
          onChange={wrappedOnChange}
          onFocus={setFocused.bind(this, true)}
          onBlur={setFocused.bind(this, false)}
        />
      </div>
    </>
  );
};

export default DatePicker;
