import React from 'react';
import PropTypes from 'prop-types';

import AuthImage from 'modules/core/components/AuthImage';

import './styles.scss';

const Post = ({ post, children, className = '', onClick }) => {
  return (
    <div className={`Post__root ` + className}>
      <div onClick={onClick}>
        <AuthImage
          controllerPath="posts"
          hash={post.image}
          className="Post__image"
        >
          <img className="Post__image" alt={post.heading} />
        </AuthImage>
        <h1 className="Post__title" onClick={onClick}>
          {post.heading}{' '}
        </h1>
      </div>

      <div className="Post__bottom">
        <div className="Post__textContainer">
          <p className="Post__text-small">{post.postText}</p>
        </div>

        <div className="Post__bottomControls">{children}</div>
      </div>
    </div>
  );
};

Post.propTypes = {
  post: PropTypes.object.isRequired,
};

export default Post;
