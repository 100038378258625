import {
  SET_IS_READ,
  SET_NOTIFICATIONS,
} from '../actions/recentNotificationsActions';

const initialState = {
  items: [],
  isNotificationsRead: true,
};

const recentNotificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_NOTIFICATIONS: {
      const isRead = action.notifications.find((item) => item.isRead === false);
      if (isRead?.isRead === false) {
        return {
          ...state,
          items: action.notifications,
          isNotificationsRead: false,
        };
      } else
        return {
          ...state,
          items: action.notifications,
          isNotificationsRead: true,
        };
    }
    case SET_IS_READ: {
      const isRead = state.items.find((item) => item.id === action.id);
      console.log(isRead, "is Read from redux");
    }
    default:
      return state;
  }
};

export default recentNotificationsReducer;
