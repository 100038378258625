export const firstStepValues = {
    firstName: '',
    lastName: '',
    middleName: '',
    socialSecurity: '',
    streetAddress: '',
    cityStateZip: '',
    phoneNumber: '',
    positions: '',
    ratePay: '',
    startDateTime: '',
    alreadyTwentyOne: '',
    typeOfEmployment: '',
    hightestLevelEquivalent: '',
    nameOfCollege: '',
    isMember: ''
}
export const secondStepValues = {
    models: [...new Array(3)].map(() => ({
        name: "",
        occupation: "",
        phone: "",
        address: "",
        city: "",
        state: ""
    }))
}
export const thirdStepValues = {
    agreeStatement: false,
    agreeStatementInitial: '',
    applicationCorrect: false,
    applicationCorrectInitial: '',
    applicationGood: false,
    applicationDate: '',
    socialSecurityCardSignature: '',
    confidentialityAgreement: false,
    confidentialityAgreementName: '',
    confidentialityAgreementDate: ''
}
export const fourthStepValues = {
    fullName: '',
    contactPerson: '',
    phone: '',
    agencyName: '',
    agencyMailingAddress: '',
    emailAddress: '',
    maidenName: '',
    streetAddress: '',
    city: '',
    state: '',
    zipCode: '',
    dateOfBirth: '',
    ss: '',
    isMale: undefined,
    confidentialUseInformation: false,
    releaseAnyInformation: false,
    signature: '',
    signatureDate: '',
    officialUserProhibited: '',
    officialUseCleaned: '',
    abusePrintedName: '',
    abusePosition: '',
    abuseSignature: '',
    abuseDate: '',
    abilityRestraintApproach: false,
    moreRestraintTechniques: false,
    disciplineFirstName: '',
    disciplineFirstPosition: '',
    disciplineFirstSignature: '',
    disciplineFirstDate: '',
    disciplineSecondName: '',
    disciplineSecondPosition: '',
    disciplineSecondSignature: '',
    disciplineSecondDate: '',

}
export const fifthStepValues = {
    isForsterCare: false,
    forsterCareAgency: undefined,
    forsterCareAgencyOther: '',
    forsterCareRole: undefined,
    isEmployment: true,
    employmentAgency: 2,
    employmentAgencyOther: '',
    employmentRole: undefined,
    fingerprintedBefore: undefined,
    fingerprintedBeforeDate: '',
    fingerprintSubmited: undefined,
    personDirectCare: undefined,
    effectiveDate: '',
    reasonForRemoval: '',
    requestingAgency: '',
    familyForsterHomeName: '',
    applicableLicenseNumbers: '',
    contactPersonName: '',
    streetAddress: '',
    city: '',
    state: '',
    zip: '',
    phone: '',
    email: '',
    firstName: '',
    middleName: '',
    lastName: '',
    dateOfBirth: '',
    isMale: undefined,
    maidenNamesUsed: '',
    snn: '',
    race: '',
    currentStreetAddress: '',
    outsideAddress: '',
    outsideCity: '',
    outsideState: '',
    outsideZip: '',
    outsidePhone: '',
    outsideEmail: '',
    indicatedAsPerpetrator: undefined,
    parentalRightsTerminated: undefined,
    disabledPersonInNeed: undefined,
    convictedOrCriminalOffense: undefined,
    permissionForBackground: false,
    signature: '',
    signatureDate: '',
    parentSignature: '',
    parentSignatureDate: '',
    results: '',
}
export const sixStepValues = {
    facilityName: '',
    license: '',
    streetAddress: '',
    city: '',
    zipCode: '',
    country: '',
    attendantCareFacility: false,
    groupBoardingHome: true,
    secureResidential: false,
    detentionCenter: false,
    staffSecureFacility: false,
    secureCareCenter: false,
    familyForsterHome: false,
    residentialCenter: true,
    firstStaffName: '',
    middleStaffName: '',
    lastStaffName: '',
    staffDateOfBirth: '',
    seePhysicianReguarty: undefined,
    anyMedicationRegularly: undefined,
    anySurgeryInPast: undefined,
    anyHandicappingConditions: undefined,
    headaches: undefined,
    heartDisease: undefined,
    highBloodPressure: undefined,
    lungDisease: undefined,
    cancer: undefined,
    diabetes: undefined,
    convulsions: undefined,
    mentalIllness: undefined,
    alcoholism: undefined,
    arthrits: undefined,
    liverDisease: undefined,
    other: undefined,
    otherReason: '',
    healthAssessmentsPerformance: '',
    signature: '',
    signatureDate: '',
    negativeTuberculinTest: undefined,
    testDate: '',
    testRead: '',
    testReadDate: '',
    resultsFile: undefined,
    // isFileChanged: '',
}
export const eighthStepValues = {
    firstAidFile: null,
    aidCprStatement: undefined
}
export const TypeOfEmployment = {
    PartTime: 1,
    FullTime: 2
}
export const HighestLevelEquivalent = {
    HighSchool: 1,
    College: 2,
    CurrentlyStudent: 3
}
export const ForsterCareRole = {
    Applicant: 1,
    ResidentOfHome: 2,
    AlternativeCaregiver: 3
}
export const ForsterCareAgency = {
    FamilyForster: 1,
    HomeRelative: 2,
    PlacementIcpc: 3,
    Other: 4
}
export const EmploymentRole = {
    EmploymentCandidate: 1,
    Volunteer: 2,
    Other: 3,
    EmploymentCandidateNotRequiringFingerprints: 4
}
export const EmploymentAgency = {
    ChildPlacingAgency: 1,
    ResidentialCenter: 2,
    Detention: 3,
    StaffSecureFacility: 4,
    AttendantCareFacility: 5,
    Jcic: 6,
    Other: 7
}
export const YesOrNoValues = {
    YES: 'true',
    NO: 'false'
}
export const HealthAssessmentsPerformance = {
    NoEvidence: 1,
    AnyEvidence: 2
}
export const AidCprStatement = {
    PlanToTake: 1,
    WatchVideo: 2
}
