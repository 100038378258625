import React from 'react';

import { Radio } from 'modules/core/components/Radio';

const FacilityMenuItem = ({ name, id, isCurrent, setCurrent }) => {
  return (
    <div className="SuperadminFacilitySelector__item">
      <Radio
        variant="circle"
        setActive={setCurrent}
        checked={isCurrent}
        valueKey={id}
        activeKey={isCurrent && id}
      >
        {name}
      </Radio>
    </div>
  );
};

export default FacilityMenuItem;
