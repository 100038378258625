import React from 'react';

const ArrowIcon = ({width, height, fill}) => {
    return (
        <svg width={width || "18"} height={height || "14"} viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_6773_23079)">
                <path d="M7.125 0.368164L0.375 6.99974" stroke={fill || "#333333"} strokeMiterlimit="10" strokeLinecap="round"
                      strokeLinejoin="round"/>
                <path d="M7.125 13.6316L0.375 7" stroke={fill || "#333333"} strokeMiterlimit="10" strokeLinecap="round"
                      strokeLinejoin="round"/>
                <path d="M17.625 7H0.375" stroke={fill || "#333333"} strokeMiterlimit="10" strokeLinecap="round"
                      strokeLinejoin="round"/>
            </g>
            <defs>
                <clipPath id="clip0_6773_23079">
                    <rect width="18" height="14" fill="white" transform="matrix(-1 0 0 1 18 0)"/>
                </clipPath>
            </defs>
        </svg>

    );
};

export default ArrowIcon;
