import React from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import './styles.scss';

const ForstterPhoneInput = ({
  label,
  id,
  value,
  onChange,
  disabled,
  ...props
}) => {
  return (
    <>
      {label && (
        <label htmlFor={id} className="FormControl__label">
          {label}
        </label>
      )}
      <PhoneInput
        country={'us'}
        countryCodeEditable={false}
        disableDropdown={true}
        containerClass="PhoneInput__input"
        value={value}
        onChange={onChange}
        inputProps={{ ...props, disabled, id }}
        disabled={disabled}
        inputStyle={disabled ? { background: '#e9ecef' } : undefined}
      />
    </>
  );
};
export default ForstterPhoneInput;
