import React from 'react';

export const ClockIcon = ({fill}) => {
    return (
        <svg width="109" height="109" viewBox="0 0 109 109" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M54.5 21.8V54.5L76.3 76.3M54.5 105.367C26.4071 105.367 3.63334 82.5929 3.63334 54.5C3.63334 26.4071 26.4071 3.63333 54.5 3.63333C82.5929 3.63333 105.367 26.4071 105.367 54.5C105.367 82.5929 82.5929 105.367 54.5 105.367Z"
                stroke={fill || "#99DCB5"} strokeWidth="3" strokeLinejoin="round"/>
        </svg>
    );
};
