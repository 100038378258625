import React from 'react';
import classNames from 'classnames';

import './styles.scss';

const TitleDecor = ({ content, small, medium }) => {
  return (
    <div
      className={classNames('TitleDecor__root', {
        small: small,
        medium: medium,
      })}
    >
      <h1 className="TitleDecor__title">
        {content} <span className="TitleDecor__dot"></span>
        <div className="TitleDecor__drop"></div>
      </h1>
    </div>
  );
};

export default TitleDecor;
