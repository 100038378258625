import React from 'react';

const UploadIcon = ({width, height, fill}) => {
    return (
        <svg width={width || "13"} height={height || "13"} viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.5 9.5L3.25 6.5M6.5 9.5L9.5 6.5M6.5 9.5V0M12.5 6V12.5H0.5V6" stroke={fill || "#27AE60"}/>
        </svg>

    );
};

export default UploadIcon;
