import {
    addOneTimeMedsLog,
    createMedicationLog, deleteMedicationLog, deleteOneTimeMedsLog,
    getMedicationsEvents,
    getMedicationsLogs, getOneTimeLogById,
    getOneTimeMedications, getResidentMedicationLog,
    updateMedicationEvent, updateTimeMedsLog,
} from 'modules/core/store/actions/residentsActions';
import {LOGOUT} from 'modules/auth/store/actions/authActions';
import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    residents: [],
    totalItemsCount: 9999,
    showOneTimeMedsModal: false,
    showMedicationLogModal: false,
    showUpdateModal: false,
    createOneTimeMedsLoading: false,
    medications: {
        totalItems: 0,
        items: [],
        loading: false,
        loadingMore: false,
        currentPage: 1
    },
    updateOneTimeMeds: {
        id: 0,
        loading: false
    },
    getOneTimeLog: {
        data: null,
        loading: false
    },
    getMedicationsEvents: {
        items: [],
        loading: false
    },
    updateMedicationsEvents: {
        loading: false
    },
    medicationsLogs: {
        totalItems: 0,
        items: [],
        loading: false,
        loadingMore: false,
        currentPage: 1
    },
    createMedicationLog: {
        loading: false
    },
    getMedicationLog: {
        id: 0,
        loading: false,
        data: null
    },
    deleteMedicationLog: {
        loading: false
    },
    deleteOneTimeMedicationLog: {
        loading: false
    }

};
const residentsReducer = createSlice({
    name: 'residents',
    initialState,
    reducers: {
        setResidents: {
            reducer: (state, action) => {
                state.residents = action.payload.residents
                state.totalItemsCount = action.payload.totalItemsCount
            },
            prepare: (residents, totalItemsCount) => {
                return {
                    payload: {
                        residents,
                        totalItemsCount
                    }
                }
            }
        },
        setShowMedicationLogModal: (state, action) => {
            state.showMedicationLogModal = action.payload
        },
        setShowUpdateOneTimeModal: (state, action) => {
            state.showUpdateModal = action.payload
        },
        setShowOneTimeModal: (state, action) => {
            state.showOneTimeMedsModal = action.payload
        },
        setOneTimeLogId: (state, action) => {
            state.updateOneTimeMeds.id = action.payload
        },
        seMedicationLogId: (state, action) => {
            state.getMedicationLog.id = action.payload
        },
        resetMedicationLog: (state) => {
            state.getMedicationLog = initialState.getMedicationLog
        },
        setOneTimeLogsCurrentPage:(state, action) => {
            state.medications.currentPage = action.payload
        },
        setMedicationsCurrentPage:(state, action) => {
            state.medicationsLogs.currentPage = action.payload
        }
    },
    extraReducers: builder => builder.addCase(getOneTimeMedications.pending, (state, {meta}) => {
        if (meta.arg.currentPage === 1) {
            state.medications.loading = true
        } else {
            state.medications.loadingMore = true
        }

    }).addCase(getOneTimeMedications.fulfilled, (state, action) => {
        state.medications.totalItems = action.payload.totalItems
        state.medications.loadingMore = false
        state.medications.loading = false
        state.medications.items = action.payload.currentPage === 1 ? action.payload.items : [...state.medications.items, ...action.payload.items]
    }).addCase(getOneTimeMedications.rejected, (state) => {
        state.medications.loadingMore = false
        state.medications.loading = false
    }).addCase(getMedicationsEvents.pending, (state) => {
        state.getMedicationsEvents.loading = true
    }).addCase(getMedicationsEvents.fulfilled, (state, action) => {
        state.getMedicationsEvents.loading = false
        state.getMedicationsEvents.items = action.payload
    }).addCase(getMedicationsEvents.rejected, (state) => {
        state.getMedicationsEvents.loading = false
    }).addCase(getMedicationsLogs.pending, (state, {meta}) => {
        if (meta.arg.currentPage === 1) {
            state.medicationsLogs.loading = true
        } else {
            state.medicationsLogs.loadingMore = true
        }
    }).addCase(getMedicationsLogs.fulfilled, (state, action) => {
        state.medicationsLogs.totalItems = action.payload.totalItems
        state.medicationsLogs.loadingMore = false
        state.medicationsLogs.loading = false
        state.medicationsLogs.items = action.payload.currentPage === 1 ? action.payload.items : [...state.medicationsLogs.items, ...action.payload.items]
    }).addCase(getMedicationsLogs.rejected, (state) => {
        state.medicationsLogs.loading = false
    }).addCase(createMedicationLog.pending, (state) => {
        state.createMedicationLog.loading = true
    }).addCase(createMedicationLog.fulfilled, (state) => {
        state.createMedicationLog.loading = false
        // state.getMedicationLog = initialState.getMedicationLog
    }).addCase(createMedicationLog.rejected, (state) => {
        state.createMedicationLog.loading = false
    }).addCase(getResidentMedicationLog.pending, (state) => {
        state.getMedicationLog.loading = true
    }).addCase(getResidentMedicationLog.fulfilled, (state, action) => {
        state.getMedicationLog.loading = false
        state.getMedicationLog.data = action.payload
    }).addCase(getResidentMedicationLog.rejected, (state) => {
        state.getMedicationLog.loading = false
    }).addCase(addOneTimeMedsLog.pending, (state) => {
        state.createOneTimeMedsLoading = true
    }).addCase(addOneTimeMedsLog.fulfilled, (state, action) => {
        state.createOneTimeMedsLoading = false
    }).addCase(addOneTimeMedsLog.rejected, (state) => {
        state.createOneTimeMedsLoading = false
    }).addCase(getOneTimeLogById.pending, (state) => {
        state.getOneTimeLog.loading = true
        // state.getMedicationLog.data = null

    }).addCase(getOneTimeLogById.fulfilled, (state, action) => {
        state.getOneTimeLog.loading = false
        state.getOneTimeLog.data = action.payload

    }).addCase(getOneTimeLogById.rejected, (state) => {
        state.getOneTimeLog.loading = false
    })
        .addCase(updateTimeMedsLog.pending, (state) => {
            state.updateOneTimeMeds.loading = true

        }).addCase(updateTimeMedsLog.fulfilled, (state) => {
            state.updateOneTimeMeds.loading = false

        }).addCase(updateTimeMedsLog.rejected, (state) => {
            state.updateOneTimeMeds.loading = false
        }).addCase(updateMedicationEvent.pending, (state) => {
            state.updateMedicationsEvents.loading = true

        }).addCase(updateMedicationEvent.fulfilled, (state) => {
            state.updateMedicationsEvents.loading = false

        }).addCase(updateMedicationEvent.rejected, (state) => {
            state.updateMedicationsEvents.loading = false
        })
        .addCase(deleteMedicationLog.pending, (state) => {
            state.deleteMedicationLog.loading = true
        })
        .addCase(deleteMedicationLog.fulfilled, (state) => {
            state.deleteMedicationLog.loading = false
        })
        .addCase(deleteMedicationLog.rejected, (state) => {
            state.deleteMedicationLog.loading = false
        })
        .addCase(deleteOneTimeMedsLog.pending, (state) => {
            state.deleteOneTimeMedicationLog.loading = true
        })
        .addCase(deleteOneTimeMedsLog.fulfilled, (state) => {
            state.deleteOneTimeMedicationLog.loading = false
        })
        .addCase(deleteOneTimeMedsLog.rejected, (state) => {
            state.deleteOneTimeMedicationLog.loading = false
        })
        .addCase(LOGOUT, () => {
            return {
                ...initialState
            }
        })

})
export const {
    setShowOneTimeModal,
    setShowUpdateOneTimeModal,
    setShowMedicationLogModal,
    setResidents,
    setOneTimeLogId,
    seMedicationLogId,
    resetMedicationLog,
    setOneTimeLogsCurrentPage,
    setMedicationsCurrentPage
} = residentsReducer.actions


export default residentsReducer.reducer;

