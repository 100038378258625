import React from 'react';
import throttle from 'lodash.throttle';
import { useDispatch, useSelector } from 'react-redux';

import TrainingsWrapper from 'modules/trainings/components/TrainingsWrapper';
import TrainingsItem from 'modules/trainings/components/TrainingsItem';
import useBreakpoint from 'modules/core/hooks/useBreakpoint';
import { fetchTrainings } from 'modules/core/store/actions/trainingsActions';
import WithLoading from 'modules/core/components/WithLoading';
import history from 'modules/core/utils/history';

const ITEM_HEIGHT = 400;

const TrainingsRootPage = () => {
  const { trainings, loading } = useSelector((store) => store.trainings);
  const dispatch = useDispatch();

  const { isMobile, breakpoint } = useBreakpoint();
  const [perWidth, setPerWidth] = React.useState(isMobile ? 1 : 2);
  const [pageSize, setPageSize] = React.useState(
    (perWidth * (window.innerHeight / ITEM_HEIGHT)) ^ 0
  );

  React.useEffect(() => {
    const _perWidth = isMobile ? 1 : 2;
    setPageSize((perWidth * (window.innerHeight / ITEM_HEIGHT)) ^ 0);
    setPerWidth(_perWidth);
  }, [isMobile, breakpoint, perWidth]);

  React.useEffect(() => {
    if (trainings.length < pageSize * 2) {
      dispatch(fetchTrainings(pageSize * 2));
    }
  }, [pageSize, trainings, dispatch]);

  React.useEffect(() => {
    window.onscroll = throttle(() => {
      if (
        window.pageYOffset >=
        document.documentElement.scrollHeight -
          document.documentElement.clientHeight -
          ITEM_HEIGHT * 2
      ) {
        dispatch(fetchTrainings(pageSize * 2));
      }
    }, 100);
  }, [pageSize, dispatch]);

  return (
    <TrainingsWrapper>
      <WithLoading loading={loading}>
        {trainings.map((item) => (
          <TrainingsItem key={item.id} {...item} />
        ))}
      </WithLoading>
    </TrainingsWrapper>
  );
};

export default TrainingsRootPage;
