import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Fade, Form, Carousel, Spinner } from 'react-bootstrap';
import isEmail from 'validator/lib/isEmail';
// import isNumeric from 'validator/lib/isNumeric';
import isCreditCard from 'validator/lib/isCreditCard';

import {
  // createCardInputHandler,
  // createExpDateHandler,
  isCardDate,
} from 'modules/core/utils/cardHelpers';
import { RadioButtons, Radio } from 'modules/core/components/Radio';
import CheckBox from 'modules/core/components/Checkbox';
import Input from 'modules/core/components/Input';
import CardSelector from 'modules/core/components/CardSelector';
import withPaymentAddress from 'modules/core/hoc/withPaymentAddress';
import useBreakpoint from 'modules/core/hooks/useBreakpoint';

import closeIcon from 'assets/icons/close.svg';

import './styles.scss';

const DonationPopup = withPaymentAddress(
  ({
    show,
    onClose,
    onSubmit,
    billingAddress,
    loading: billingAddressLoading,
    isAuthenticated,
  }) => {
    const { isMobile } = useBreakpoint();
    const [currentSection, setCurrentSection] = React.useState(0);
    const [paymentInProgress, setPaymentInProgress] = React.useState(false);
    const [paymentError, setPaymentError] = React.useState('');

    //#region  First Section States
    const [frequency, setFrequency] = React.useState(0);
    const [currency, setCurrency] = React.useState(0);
    const [radioAmountValue, setRadioAmount] = React.useState(10);
    const [customAmountValue, setCustomAmount] = React.useState(undefined);
    const [showCustomAmount, setShowCustomAmount] = React.useState(false);
    const [wrightComment, setWrightComment] = React.useState(true);
    const [comment, setComment] = React.useState('');
    const [firstFormSubmited, setFirstFormSubmited] = React.useState(false);
    //#endregion

    //#region  Second Section States

    // submit button is outside of the form so that we need this treak
    const [secondFormSubmit, setSecondFormSubmit] = React.useState(false);
    const [fullName, setFullName] = React.useState('');
    const [address, setAddress] = React.useState('');
    const [city, setCity] = React.useState('');
    const [state, setState] = React.useState('');
    const [country, setCountry] = React.useState('');
    const [zipcode, setZipCode] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [submited, setSubmited] = React.useState(false);

    React.useEffect(() => {
      if (!isAuthenticated) return;
      setFullName(billingAddress.fullName);
      setAddress(billingAddress.addressLine);
      setCity(billingAddress.city);
      setCountry(billingAddress.country);
      setState(billingAddress.state);
      setZipCode(billingAddress.zipCode);
      setEmail(billingAddress.email);
    }, [billingAddress, isAuthenticated]);

    //#endregion

    //#region  Third Section States

    const [cardData, setCardData] = React.useState();

    //#endregion

    //#region First Section Handlers

    const firstSectionSubmit = React.useCallback(() => {
      if (customAmountValue !== undefined && customAmountValue !== '') {
        const amount = Number(customAmountValue);

        if (!isNaN(amount) && amount >= 1) {
          setCurrentSection(1);
        }
      } else {
        setCurrentSection(1);
      }
      setFirstFormSubmited(true);
    }, [setCurrentSection, customAmountValue]);

    const commentUpdateHandler = React.useCallback(
      (e) => {
        setComment(e.target.value);
      },
      [setComment]
    );

    const custonAmountChangeHandler = React.useCallback(
      (e) => {
        setCustomAmount(e.target.value);
      },
      [setCustomAmount]
    );

    //#endregion

    //#region  Second Section Handlers

    const secondSectionSubmit = React.useCallback(() => {
      if (email && state && fullName && zipcode && isEmail(email)) {
        setCurrentSection(2);
        // setCardHolder(fullName);
      } else {
        console.log('invalid');
      }
      setSecondFormSubmit(true);
    }, [setCurrentSection, email, state, fullName, zipcode]);

    const nameChangeHandler = React.useCallback(
      (e) => {
        setFullName(e.target.value);
      },
      [setFullName]
    );

    const addressChangeHandler = React.useCallback(
      (e) => {
        setAddress(e.target.value);
      },
      [setAddress]
    );
    const cityChangeHandler = React.useCallback(
      (e) => {
        setCity(e.target.value);
      },
      [setCity]
    );
    const stateChangeHandler = React.useCallback(
      (e) => {
        setState(e.target.value);
      },
      [setState]
    );

    const countryUpdateHandler = React.useCallback(
      (e) => {
        setCountry(e.target.value);
      },
      [setCountry]
    );

    const zipcodeChangeHandler = React.useCallback(
      (e) => {
        setZipCode(e.target.value);
      },
      [setZipCode]
    );
    const emailChangeHandler = React.useCallback(
      (e) => {
        setEmail(e.target.value);
      },
      [setEmail]
    );

    //#endregion

    const handleCardDataChange = React.useCallback((data) => {
      console.log(data);
      setCardData(data);
    }, []);

    //#region Third Section Handlers
    const finalSubmitHandler = React.useCallback(async () => {
      setPaymentInProgress(true);
      let error;
      if (cardData.existingPaymentMethod) {
        error = await onSubmit({
          paymentFrequency: frequency,
          currency,
          moneyAmount: customAmountValue || radioAmountValue,
          comment,
          fullName,
          address,
          city,
          state,
          country,
          zipcode,
          email,
          existingPaymentMethod: cardData.existingPaymentMethod,
        });
      } else if (cardData.newPaymentMethod) {
        const { cardNumber, cardHolder, expDate, cvcCode, savePaymentMethod } =
          cardData.newPaymentMethod;

        error = await onSubmit({
          paymentFrequency: frequency,
          currency,
          moneyAmount: customAmountValue || radioAmountValue,
          comment,
          fullName,
          address,
          city,
          state,
          country,
          zipcode,
          email,
          cardNumber,
          cardHolder,
          expDate,
          cvcCode,
          savePaymentMethod,
        });
      }

      setPaymentInProgress(false);
      if (error) {
        setPaymentError(error);
      }
    }, [
      onSubmit,
      currency,
      customAmountValue,
      radioAmountValue,
      comment,
      fullName,
      address,
      city,
      state,
      country,
      zipcode,
      email,
      // cardNumber,
      // cardHolder,
      // expDate,
      // cvcCode,
      frequency,
      cardData,
    ]);
    const handleThirdFormSubmit = React.useCallback(async () => {
      setSubmited(true);
      if (paymentInProgress) return;

      if (cardData.existingPaymentMethod) {
        await finalSubmitHandler();
        setCurrentSection(3);
        return;
      }

      const { cardNumber, expDate, cvcCode } = cardData.newPaymentMethod;

      if (
        cardNumber.length > 12 &&
        isCreditCard(cardNumber) &&
        // cardHolder &&
        expDate.length === 5 &&
        isCardDate(expDate) &&
        cvcCode.length === 3
      ) {
        await finalSubmitHandler();
        setCurrentSection(3);
      }
    }, [cardData, finalSubmitHandler, paymentInProgress, setSubmited]);

    const handleLastCloseButton = React.useCallback(() => {
      onClose();
      setCurrentSection(0);
      setPaymentError('');
    }, [onClose]);

    //#endregion

    const backButtonHandler = React.useCallback(() => {
      setCurrentSection(currentSection - 1);
    }, [currentSection]);

    const handleAmountChange = (value) => {
      if (typeof value === 'number') {
        setRadioAmount(value);
        setShowCustomAmount(false);
      } else {
        setShowCustomAmount(value);
      }
    };

    return (
      <Modal
        show={show}
        onHide={onClose}
        size={'xl'}
        centered
        className="DontaionPopup__show"
      >
        <Carousel
          activeIndex={currentSection}
          interval={null}
          controls={false}
          indicators={false}
        >
          <Carousel.Item key={0}>
            <Modal.Header className="DontaionPopup__header">
              <RadioButtons
                initialKey={frequency}
                onChange={setFrequency}
                variant="buttons"
              >
                <Radio valueKey={0}>One-time</Radio>
              </RadioButtons>
              <img
                className="DonationPopup__closeIcon"
                src={closeIcon}
                onClick={onClose}
                alt="close"
              />
            </Modal.Header>
            <Modal.Body>
              <Form.Group className="DonationPopup__body">
                <Form.Control as="select" size="lg" onSelect={setCurrency}>
                  <option value={0}>US Dollars (USD)</option>
                </Form.Control>

                <RadioButtons
                  initialKey={radioAmountValue}
                  onChange={handleAmountChange}
                >
                  <Radio valueKey={10}>
                    <span className="DontationPopup__label">$ 10</span>
                  </Radio>
                  <Radio valueKey={50}>
                    <span className="DontationPopup__label">$ 50</span>
                  </Radio>
                  <Radio valueKey={200}>
                    <span className="DontationPopup__label">$ 200</span>
                  </Radio>
                  <Radio valueKey={500}>
                    <span className="DontationPopup__label">$ 500</span>
                  </Radio>
                  <Radio valueKey={1000}>
                    <span className="DontationPopup__label">$ 1,000</span>
                  </Radio>
                  {!(showCustomAmount && isMobile) ? (
                    <Radio valueKey={true}>
                      <span className="DontationPopup__label">Custom</span>
                    </Radio>
                  ) : (
                    <></>
                  )}
                </RadioButtons>
                {showCustomAmount && (
                  <div>
                    <Form.Control
                      type={'number'}
                      placeholder="$ custom amount"
                      onChange={custonAmountChangeHandler}
                      size="lg"
                      isInvalid={
                        firstFormSubmited &&
                        customAmountValue !== undefined &&
                        customAmountValue !== '' &&
                        (Number(customAmountValue < 1) ||
                          isNaN(Number(customAmountValue)))
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      Minimum amount is $1.
                    </Form.Control.Feedback>
                  </div>
                )}
                <div className="DonationPopup_checkBox">
                  <CheckBox checked={wrightComment} onChange={setWrightComment}>
                    <span className="DontationPopup__label">
                      Write us a comment
                    </span>
                  </CheckBox>
                </div>

                <Fade in={wrightComment}>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Enter"
                    className="DonationPopup__textarea"
                    onChange={commentUpdateHandler}
                    style={{ height: '7.75rem' }}
                  />
                </Fade>
              </Form.Group>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="primary" size="lg" onClick={firstSectionSubmit}>
                Next
              </Button>
            </Modal.Footer>
          </Carousel.Item>
          <Carousel.Item key={1}>
            <Modal.Header className="DontaionPopup__header">
              <img
                className="DonationPopup__closeIcon"
                src={closeIcon}
                onClick={onClose}
                alt="close"
              />
            </Modal.Header>
            <Modal.Body
              className={
                secondFormSubmit
                  ? 'DonationPopup__section2-submited'
                  : 'DonationPopup__section2'
              }
            >
              <Input
                type="text"
                id="fullname"
                placeholder=" "
                label="Full Name"
                value={fullName}
                onChange={nameChangeHandler}
                submited={secondFormSubmit}
              />

              <Input
                type="input"
                id="address"
                placeholder=" "
                label="Address"
                value={address}
                onChange={addressChangeHandler}
                submited={secondFormSubmit}
              />

              <Input
                type="text"
                id="city"
                placeholder=" "
                label="City"
                value={city}
                onChange={cityChangeHandler}
                submited={secondFormSubmit}
              />

              <Input
                type="input"
                id="state"
                placeholder=" "
                label="State"
                value={state}
                onChange={stateChangeHandler}
                submited={secondFormSubmit}
              />

              <Input
                type="input"
                id="country"
                placeholder=" "
                label="Country"
                value={country}
                onChange={countryUpdateHandler}
                submited={secondFormSubmit}
              />

              <Input
                type="input"
                id="zipcode"
                placeholder="XXXXX"
                label="ZIP code"
                value={zipcode}
                onChange={zipcodeChangeHandler}
                submited={secondFormSubmit}
              />

              <Input
                type="email"
                id="email"
                placeholder="example@mail.com"
                label="Email"
                value={email}
                onChange={emailChangeHandler}
                submited={secondFormSubmit}
                isInvalid={secondFormSubmit && !isEmail(email)}
                errorFeedback="Email is invalid."
              />
            </Modal.Body>
            <Modal.Footer className="DonationPopup__footer">
              <Button
                variant="outline-primary"
                size={'lg'}
                onClick={backButtonHandler}
              >
                Back
              </Button>
              <Button
                variant="primary"
                size={'lg'}
                onClick={secondSectionSubmit}
                className={'DonationPopup__footerTopButton'}
                // type="submit"
              >
                Next
              </Button>
            </Modal.Footer>
          </Carousel.Item>
          <Carousel.Item key={2}>
            <Modal.Header className="DontaionPopup__header">
              <img
                className="DonationPopup__closeIcon"
                src={closeIcon}
                onClick={onClose}
                alt="close"
              />
            </Modal.Header>
            <Modal.Body>
              <Form.Group className="DonationPopup__section3">
                <CardSelector
                  onDataChange={handleCardDataChange}
                  submited={submited}
                />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer className="DonationPopup__footer">
              <Button
                variant="outline-primary"
                size={'lg'}
                onClick={backButtonHandler}
                disabled={paymentInProgress}
              >
                Back
              </Button>
              <Button
                variant="primary"
                size={'lg'}
                onClick={handleThirdFormSubmit}
                className="DonationPopup__footerTopButton"
              >
                {paymentInProgress && (
                  <Spinner
                    size="sm"
                    animation="border"
                    className="DonationPopup__spinner"
                  />
                )}
                Donate
              </Button>
            </Modal.Footer>
          </Carousel.Item>
          <Carousel.Item key={3}>
            <Modal.Header className="DontaionPopup__header">
              <img
                className="DonationPopup__closeIcon"
                src={closeIcon}
                onClick={onClose}
                alt="close"
              />
            </Modal.Header>
            <Modal.Body className="DonationPopup__section4">
              {paymentError ? (
                <>
                  <h1>Oops...</h1>
                  <h4 className="DonationPopup__error">
                    {paymentError?.response?.data?.errors?.[0].message}
                  </h4>
                </>
              ) : (
                <>
                  <svg
                    width="95"
                    height="95"
                    viewBox="0 0 95 95"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M47.5 93.8913C73.1212 93.8913 93.8913 73.1212 93.8913 47.5C93.8913 21.8788 73.1212 1.1087 47.5 1.1087C21.8787 1.1087 1.10864 21.8788 1.10864 47.5C1.10864 73.1212 21.8787 93.8913 47.5 93.8913Z"
                      stroke="#F2C94C"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M68.1654 68.1653C62.6828 73.6479 55.5132 77.0218 47.5002 77.0218C39.4871 77.0218 31.8958 73.6479 26.835 68.1653"
                      stroke="#F2C94C"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M28.5216 41.1739C30.8508 41.1739 32.739 39.2857 32.739 36.9565C32.739 34.6273 30.8508 32.7391 28.5216 32.7391C26.1924 32.7391 24.3042 34.6273 24.3042 36.9565C24.3042 39.2857 26.1924 41.1739 28.5216 41.1739Z"
                      fill="#F2C94C"
                    />
                    <path
                      d="M66.4781 41.1739C68.8073 41.1739 70.6955 39.2857 70.6955 36.9565C70.6955 34.6273 68.8073 32.7391 66.4781 32.7391C64.1489 32.7391 62.2607 34.6273 62.2607 36.9565C62.2607 39.2857 64.1489 41.1739 66.4781 41.1739Z"
                      fill="#F2C94C"
                    />
                  </svg>
                  <h1 className="DonationPopup__congradsTitle">
                    Thank you for your donation!
                  </h1>
                </>
              )}

              <Button
                variant="primary"
                size={'lg'}
                onClick={handleLastCloseButton}
                className="DonationPopup__congradsButton"
              >
                Close
              </Button>
            </Modal.Body>
          </Carousel.Item>
        </Carousel>
      </Modal>
    );
  }
);

DonationPopup.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default DonationPopup;
