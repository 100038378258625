import history from "../../utils/history";
import ApiClient from 'modules/core/utils/ApiClient';
import {loginRoutes} from "../../../login/routes/constants";
import {toast} from "react-toastify";
import {careerRoutes} from "../../../career/routes/constants";
import {stepsPath} from "../../../career/constants";

export const SET_CAREER_LOADING = 'SET_CAREER_LOADING'
export const SET_APPLICANTS = 'SET_APPLICANTS'
export const SET_DATA_STEP_LOADING = 'SET_DATA_STEP_LOADING'
export const SET_STEP_VALUES = 'SET_STEP_VALUES'
export const SET_JOB_POSITIONS_SHORT = 'SET_JOB_POSITIONS_SHORT'
export const SET_SHOW_ALERTER = 'SET_SHOW_ALERTER'
export const SET_ALERTER_LOADING = 'SET_ALERTER_LOADING'
export const SET_ERRORS = 'SET_ERRORS'

export const SET_JOB_APPLICATIONS_LOADING = 'SET_JOB_APPLICATIONS_LOADING'
export const SET_JOB_APPLICATIONS = 'SET_JOB_APPLICATIONS'
export const SET_JOB_APPLICATIONS_ERRORS = 'SET_JOB_APPLICATIONS_ERRORS'

export const HIDE_JOB_APPLICATION = 'HIDE_JOB_APPLICATION'


const setJobApplicationsLoading = (value) => ({
    type: SET_JOB_APPLICATIONS_LOADING,
    payload: value
})
const setJobApplications = (data) => ({
    type: SET_JOB_APPLICATIONS,
    payload: data
})
const setJobApplicationsErrors = (errors) => ({
    type: SET_JOB_APPLICATIONS_ERRORS,
    payload: errors
})

const setErrors = (errors) => ({
    type: SET_ERRORS,
    payload: errors
})

const setCareerLoading = (value) => ({
    type: SET_CAREER_LOADING,
    payload: value
})
const setApplicants = (data) => ({
    type: SET_APPLICANTS,
    payload: data
})
const setStepLoading = (value) => ({
    type: SET_DATA_STEP_LOADING,
    payload: value
})
const setStepValues = (data) => ({
    type: SET_STEP_VALUES,
    payload: data
})
const setJobPositions = (data) => ({
    type: SET_JOB_POSITIONS_SHORT,
    payload: data
})
const setAlerterLoading = (value) => ({
    type:SET_ALERTER_LOADING,
    payload: value
})

export const createJobApplicationsRequest = (jobPositionId, name) => {
    return async (dispatch, getState) => {
        try {
            const {auth} = getState()
            if (!auth.isAuthenticated) {
                history.push(loginRoutes.root)
                return
            }
            dispatch(setCareerLoading(true))
            const response = await ApiClient.createJobApplications(jobPositionId)
            if (response.success) {
                history.push(`/career/apply/${response.value}/${name}`)
            } else {
                toast.info(response?.errors[0]?.message)
            }
            dispatch(setCareerLoading(false))
        } catch (error) {
            toast.error(error)
            dispatch(setCareerLoading(false))
        }
    }
}
export const updateFirstStep = (data) => async (dispatch) => {
    // applicationId
    try {
        dispatch(setCareerLoading(true))
        const response = await ApiClient.updateFirstStep(data)
        if (response.success) {
            history.push(careerRoutes.stepFn(data.applicationId, stepsPath.secondStep), {
                activeStep: 1
            })
        } else {
            toast.error(response?.errors[0]?.message)
        }
        dispatch(setCareerLoading(false))

    } catch (error) {
        toast.error(error)
        dispatch(setCareerLoading(false))
    }
}
export const updateSecondStep = (data) => async (dispatch) => {
    // applicationId
    try {
        dispatch(setCareerLoading(true))
        const response = await ApiClient.updateSecondStep(data)
        if (response.success) {
            history.push(careerRoutes.stepFn(data.applicationId, stepsPath.thirdStep), {
                activeStep: 2
            })
        } else {
            toast.error(response?.errors[0]?.message)
        }
        dispatch(setCareerLoading(false))
    } catch (error) {
        toast.error(error)
        dispatch(setCareerLoading(false))
    }
}
export const updateThirdStep = (data) => async (dispatch) => {
    // applicationId
    try {
        dispatch(setCareerLoading(true))
        const response = await ApiClient.updateThirdStep(data)
        if (response.success) {
            history.push(careerRoutes.stepFn(data.applicationId, stepsPath.fourthStep), {
                activeStep: 3
            })
        } else {
            toast.error(response?.errors[0]?.message)
        }
        dispatch(setCareerLoading(false))
    } catch (error) {
        toast.error(error)
        dispatch(setCareerLoading(false))
    }
}
export const updateFourthStep = (data) => async (dispatch) => {
    // applicationId
    try {
        dispatch(setCareerLoading(true))
        const response = await ApiClient.updateFourthStep(data)
        if (response.success) {
            history.push(careerRoutes.stepFn(data.applicationId, stepsPath.fifthStep), {
                activeStep: 4
            })
        } else {
            toast.error(response?.errors[0]?.message)
        }
        dispatch(setCareerLoading(false))
    } catch (error) {
        toast.error(error)
        dispatch(setCareerLoading(false))
    }
}
export const updateFifthStep = (data) => async (dispatch) => {
    // applicationId
    try {
        dispatch(setCareerLoading(true))
        const response = await ApiClient.updateFifthStep(data)
        if (response.success) {
            history.push(careerRoutes.stepFn(data.applicationId, stepsPath.sixStep), {
                activeStep: 5
            })
        } else {
            toast.error(response?.errors[0]?.message)
        }
        dispatch(setCareerLoading(false))
    } catch (error) {
        toast.error(error)
        dispatch(setCareerLoading(false))
    }
}
export const updateSixthStep = (data, id) => async (dispatch) => {
    // applicationId
    try {
        dispatch(setCareerLoading(true))
        const response = await ApiClient.updateSixthStep(data)
        if (response.success) {
            history.push(careerRoutes.stepFn(id, stepsPath.sevenStep), {
                activeStep: 6
            })
        } else {
            toast.error(response?.errors[0]?.message)
        }
        dispatch(setCareerLoading(false))
    } catch (error) {
        toast.error(error)
        dispatch(setCareerLoading(false))
    }
}
export const updateSeventhStep = (data, id) => async (dispatch) => {
    // applicationId
    try {
        dispatch(setCareerLoading(true))
        const response = await ApiClient.updateSeventhStep(data)
        if (response.success) {
            history.push(careerRoutes.stepFn(id, stepsPath.eightStep), {
                activeStep: 7
            })
        } else {
            toast.error(response?.errors[0]?.message)
        }
        dispatch(setCareerLoading(false))
    } catch (error) {
        toast.error(error)
        dispatch(setCareerLoading(false))
    }
}
export const updateEighthStep = (data, id) => async (dispatch) => {
    // applicationId
    try {
        dispatch(setCareerLoading(true))
        const response = await ApiClient.updateEighthStep(data)
        if (response.success) {
            history.push(careerRoutes.stepFn(id, stepsPath.finish), {positionName: response.value.name})
        } else {
            toast.error(response?.errors[0]?.message)
        }
        dispatch(setCareerLoading(false))
    } catch (error) {
        toast.error(error)
        dispatch(setCareerLoading(false))
    }
}
export const getFirstStep = (id) => async (dispatch) =>{
    try{
        dispatch(setStepLoading(true))
        const response = await ApiClient.getDataForStep(id,'first-step')
        if(response.success){
            dispatch(setStepValues(response.value))
        }else {
            toast.error(response?.errors[0]?.message)
        }
        dispatch(setStepLoading(false))
    }catch (err){
        dispatch(setStepLoading(false))
    }
}
export const getSecondStep = (id) => async (dispatch) =>{
    try{
        dispatch(setStepLoading(true))
        const response = await ApiClient.getDataForStep(id,'second-step')
        if(response.success){
            dispatch(setStepValues(response.value))
        }else {
            toast.error(response?.errors[0]?.message)
        }
        dispatch(setStepLoading(false))
    }catch (err){
        dispatch(setStepLoading(false))
    }
}
export const getThirdStep = (id) => async (dispatch) =>{
    try{
        dispatch(setStepLoading(true))
        const response = await ApiClient.getDataForStep(id,'third-step')
        if(response.success){
            dispatch(setStepValues(response.value))
        }else {
            toast.error(response?.errors[0]?.message)
        }
        dispatch(setStepLoading(false))
    }catch (err){
        dispatch(setStepLoading(false))
    }
}
export const getFourthStep = (id) => async (dispatch) =>{
    try{
        dispatch(setStepLoading(true))
        const response = await ApiClient.getDataForStep(id,'fourth-step')
        if(response.success){
            dispatch(setStepValues(response.value))
        }else {
            toast.error(response?.errors[0]?.message)
        }
        dispatch(setStepLoading(false))
    }catch (err){
        dispatch(setStepLoading(false))
    }
}
export const getFifthStep = (id) => async (dispatch) =>{
    try{
        dispatch(setStepLoading(true))
        const response = await ApiClient.getDataForStep(id,'fiftth-step')
        if(response.success){
            dispatch(setStepValues(response.value))
        }else {
            toast.error(response?.errors[0]?.message)
        }
        dispatch(setStepLoading(false))
    }catch (err){
        dispatch(setStepLoading(false))
    }
}
export const getSixthStep = (id) => async (dispatch) =>{
    try{
        dispatch(setStepLoading(true))
        const response = await ApiClient.getDataForStep(id,'sixth-step')
        if(response.success){
            dispatch(setStepValues(response.value))
        }else {
            toast.error(response?.errors[0]?.message)
        }
        dispatch(setStepLoading(false))
    }catch (err){
        dispatch(setStepLoading(false))
    }
}
export const getSeventhStep = (id) => async (dispatch) =>{
    try{
        dispatch(setStepLoading(true))
        const response = await ApiClient.getDataForStep(id,'seventh-step')
        if(response.success){
            dispatch(setStepValues(response.value))
        }else {
            toast.error(response?.errors[0]?.message)
        }
        dispatch(setStepLoading(false))
    }catch (err){
        dispatch(setStepLoading(false))
    }
}
export const getEighthStep = (id) => async (dispatch) =>{
    try{
        dispatch(setStepLoading(true))
        const response = await ApiClient.getDataForStep(id,'eighth-step')
        if(response.success){
            dispatch(setStepValues(response.value))
        }else {
            toast.error(response?.errors[0]?.message)
        }
        dispatch(setStepLoading(false))
    }catch (err){
        dispatch(setStepLoading(false))
    }
}
export const getAllApplicants = () => async (dispatch) => {
    try {
        dispatch(setCareerLoading(true))
        const response = await ApiClient.getJobApplications()
        if (response.success) {
            dispatch(setApplicants(response.value.jobApplications))
        } else {
            toast.error(response?.errors[0]?.message)
        }
        dispatch(setCareerLoading(false))

    } catch (err) {
        toast.error(err)
        dispatch(setCareerLoading(false))
    }
}
export const getPositions = () => async (dispatch) => {
    try {
        dispatch(setCareerLoading(true))
        const response = await ApiClient.getJobPositionsShort()
        if(response.success){
            dispatch(setJobPositions(response.value.positions))

        }else {
            toast.error(response?.errors[0]?.message)
        }
        dispatch(setCareerLoading(false))

    }catch (err){
        toast.error(err)
        dispatch(setCareerLoading(false))
    }
}
export const setShowAlerter = (value) => ({
    type: SET_SHOW_ALERTER,
    payload: value
})
export const acceptJobApplication = (id) => async (dispatch) => {
    try {
        dispatch(setErrors([]))
        dispatch(setAlerterLoading(true))
        console.log(id, 'acceptJobApplication')
        const response = await ApiClient.acceptJobApplication(id)
        if(response.success){
            toast.success(response.messages[0].message)
        }else {
            dispatch(setErrors(response.errors))
            toast.error(response.errors[0].message)
        }
        dispatch(setAlerterLoading(false))

    }catch (error){
        toast.error(error)
        dispatch(setAlerterLoading(false))
    }
}
export const declineJobApplication = (id) => async (dispatch) => {
    try {
        dispatch(setErrors([]))
        dispatch(setAlerterLoading(true))
        console.log(id, 'declineJobApplication')
        const response = await ApiClient.declineJobApplication(id)
        if(response.success){
            toast.success(response.messages[0].message)
        }else {
            dispatch(setErrors(response.errors))
            toast.error(response.errors[0].message)
        }
        dispatch(setAlerterLoading(false))

    }catch (error){
        toast.error(error)
        dispatch(setAlerterLoading(false))
    }
}

export const getJobApplications = (sortByName, desc, jobPositions, currentPage, pageSize) => async (dispatch) => {
    try{
        dispatch(setJobApplicationsErrors([]))
        dispatch(setJobApplicationsLoading(true))
        const response = await ApiClient.fetchJobApplications(currentPage,pageSize,desc,sortByName,jobPositions)
        if(response.success){
            dispatch(setJobApplications(response.value))
        }else {
            dispatch(setJobApplicationsErrors(response.errors))
            toast.error(response.errors[0].message)
        }
        dispatch(setJobApplicationsLoading(false))


    }catch (error){
        dispatch(setJobApplicationsLoading(false))
        toast.error(error)
    }
}
export const hideJobApplication = (id)  => async (dispatch) => {
    try {
        dispatch(setErrors([]))
        const response = await ApiClient.hideJobApplication(id)
        if(response.success){
            dispatch({
                type: HIDE_JOB_APPLICATION,
                payload: id
            })
        }else {
            dispatch(setErrors(response.errors[0].message))
            toast.error(response.errors[0].message)
        }

    }catch (error){
        toast.error(error)
    }
}
