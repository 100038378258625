import { adminRoutes } from 'modules/admin/routes/constants';

export const adminFacilityRoutes = {
  root: `${adminRoutes.root}/facility`,
  manage: `${adminRoutes.root}/facility/manage`,
  activatedFacilities: `${adminRoutes.root}/facility/manage/activated`,
  deactivatedFacilities: `${adminRoutes.root}/facility/manage/deactivated`,
  list: `${adminRoutes.root}/facility/list`,
  facility: `${adminRoutes.root}/facility/:id`,
  facilityFn: (id) => `${adminRoutes.root}/facility/${id}`,
  facilityInfo: `${adminRoutes.root}/facility/:id/info`,
  facilityInfoFn: (id) => `${adminRoutes.root}/facility/${id}/info`,
  facilityReports: `${adminRoutes.root}/facility/:id/report`,
  facilityReportsFn: (id) => `${adminRoutes.root}/facility/${id}/report`,
  facilityReport: `${adminRoutes.root}/facility/:id/report/:reportId`,
  facilityReportFn: (facilityId, reportId) =>
    `${adminRoutes.root}/facility/${facilityId}/report/${reportId}`,
  facilityReportSectionFn: (facilityId, reportId, sectionPath) =>
    `${adminRoutes.root}/facility/${facilityId}/report/${reportId}/${sectionPath}`,

  facilityCostReports: `${adminRoutes.root}/facility/:id/cost-report`,
  facilityCostReportsFn: (id) =>
    `${adminRoutes.root}/facility/${id}/cost-report`,
  facilityMonthlyCostReports: `${adminRoutes.root}/facility/:id/cost-report/monthly`,
  facilityMonthlyCostReportsFn: (id) =>
    `${adminRoutes.root}/facility/${id}/cost-report/monthly`,
  facilityMonthlyCostReport: `${adminRoutes.root}/facility/:id/cost-report/monthly/:reportId`,
  facilityMonthlyCostReportSectionFn: (facilityId, reportId, sectionPath) =>
    `${adminRoutes.root}/facility/${facilityId}/cost-report/monthly/${reportId}/${sectionPath}`,

  facilityYearlyCostReports: `${adminRoutes.root}/facility/:id/cost-report/yearly`,
  facilityYearlyCostReport: `${adminRoutes.root}/facility/:id/cost-report/yearly/:reportId`,
  facilityYearlyCostReportSectionFn: (facilityId, reportId, sectionPath) =>
    `${adminRoutes.root}/facility/${facilityId}/cost-report/yearly/${reportId}/${sectionPath}`,
  facilityCostReportsYearlyFn: (id) =>
    `${adminRoutes.root}/facility/${id}/cost-report/yearly`,

  facilityResidentReports: `${adminRoutes.root}/facility/:id/resident-reports`,

  reports: `${adminRoutes.root}/facility/:id/resident-reports`,
  reportsFn: (id) => `${adminRoutes.root}/facility/${id}/resident-reports`,
  reportSectionFn: (id, reportType, reportId, section, residentId) =>
    `${adminRoutes.root}/facility/${id}/resident-reports/${residentId}/${reportType}/${reportId}/${section}`,
  incidentReport: `${adminRoutes.root}/facility/:id/resident-reports/:residentId/incident/:reportId`,
  incidentReportBase: `${adminRoutes.root}/facility/:id/resident-reports/:residentId/incident`,
  incidentReportBaseFn: (id, residentId) =>
    `${adminRoutes.root}/facility/${id}/resident-reports/${residentId}/incident`,
  incidentReportFn: (id, reportId, residentId) =>
    `${adminRoutes.root}/facility/${id}/resident-reports/${residentId}/incident/${reportId}`,
  disruptionReport: `${adminRoutes.root}/facility/:id/resident-reports/:residentId/disruption/:reportId`,
  disruptionReportBase: `${adminRoutes.root}/facility/:id/resident-reports/:residentId/disruption`,
  disruptionReportBaseFn: (id, residentId) =>
    `${adminRoutes.root}/facility/${id}/resident-reports/${residentId}/disruption`,
};
