import React, {useEffect} from 'react';
import {useFormik} from "formik";
import {seventhStepSchema} from "../../../validation";
import UploadCareerFile from "../../UploadCareerFile";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {careerRoutes} from "../../../routes/constants";
import {STEPS, stepsPath} from "../../../constants";
import {Button, Spinner} from "react-bootstrap";
import Stepper from "../../Stepper";
import {useDispatch, useSelector} from "react-redux";
import LoadingActionButton from "../../LoadingButton";
import {getSeventhStep, updateSeventhStep} from "../../../../core/store/actions/careerActions";
import CustomPrompt from "../../CustomPrompt";


export const CareerApplySeventhStep = () => {
    const {id} = useParams()
    const location = useLocation()
    const history = useHistory()
    const {loading, stepLoading, stepValues} = useSelector((state) => state.career)
    const dispatch = useDispatch()
    const {handleSubmit, setFieldValue, setFieldError, values, errors, dirty, submitCount} = useFormik({
        initialValues: {
            drivingLicenseFile: stepValues?.file?.hash ? stepValues?.file : null
        },
        enableReinitialize: true,
        onSubmit: (values) => {
            console.log(values, 'VALUES')
            const fd = new FormData()
            fd.append('ApplicationId', id)
            if (!values?.drivingLicenseFile?.hash && values?.drivingLicenseFile?.name) {
                fd.append('DrivingLicenseFile', values.drivingLicenseFile)
            }
            if (values?.drivingLicenseFile?.hash) {
                fd.append('IsFileChanged', false)
            } else if (values?.drivingLicenseFile?.name) {
                fd.append('IsFileChanged', true)
            }
            dispatch(updateSeventhStep(fd, id))
        },
        validationSchema: seventhStepSchema
    })
    const onPrev = () => {
        history.push(careerRoutes.stepFn(id, stepsPath.sixStep), {
            activeStep: 5
        })
    }
    useEffect(() => {
        if (id) {
            dispatch(getSeventhStep(id))
        }

    }, [id, dispatch])

    return (
        <>
            {
                stepLoading ?
                    <div className={'text-center'}>
                        <Spinner animation="border"/>
                    </div> :
                    <>
                        <Stepper activeStep={location.state?.activeStep} steps={STEPS}/>
                        <form onSubmit={handleSubmit}>
                            <UploadCareerFile
                                fieldName={'drivingLicenseFile'}
                                error={errors?.drivingLicenseFile}
                                value={values.drivingLicenseFile}
                                setFieldError={setFieldError}
                                setFieldValue={setFieldValue}/>
                            <div className={'Step__footer'}>
                                <Button
                                    onClick={onPrev}
                                    className={'btn-outline-primary Step__action-button'}>
                                    Back
                                </Button>
                                <LoadingActionButton loading={loading} text={'Next'}/>
                            </div>
                        </form>
                        <CustomPrompt when={dirty && submitCount === 0}/>
                    </>
            }
        </>
    );
};

