import React from 'react';
import ReactDOM from 'react-dom';

import './assets/fonts/Helvetica.otf';
import './styles/index.scss';

import App from './modules/core/App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  process.env.DISABLE_LOGS ? (
    <App />
  ) : (
    <React.StrictMode>
      <App />
    </React.StrictMode>
  ),
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
