import React, {useEffect, useMemo} from 'react';
import './styles.scss'
import {Formik, Form, Field} from 'formik'
import {Button, Col, FormCheck, Row, Spinner} from "react-bootstrap";
import LabeledFormControl from "../../../../core/components/FormControl";
import DatePicker from "../../../../admin/components/DatePicker";
import CustomRadio from "../../../../core/components/CustomRadio";
import {
    HealthAssessmentsPerformance,
    sixStepValues,
    STEPS,
    stepsPath,
    YesOrNoValues
} from "../../../constants";
import {sixStepSchema} from "../../../validation";
import FormikError from "../../../../admin/components/FormikError";
import selectFile from 'modules/career/utils/selectFile';
import uploadIcon from 'assets/icons/upload.svg';
import {useHistory, useLocation, useParams} from "react-router-dom";
import {careerRoutes} from "../../../routes/constants";
import Stepper from "../../Stepper";
import {useDispatch, useSelector} from "react-redux";
import LoadingActionButton from "../../LoadingButton";
import {getSixthStep, updateSixthStep} from "../../../../core/store/actions/careerActions";
import CustomPrompt from "../../CustomPrompt";

export const CareerApplySixthStep = () => {
    const {id} = useParams()
    const location = useLocation()
    const history = useHistory()
    const {loading, stepLoading, stepValues} = useSelector((state) => state.career)
    const dispatch = useDispatch()
    const onSubmit = (values) => {
        //values?.resultsFile?.name
        const fd = new FormData()
        fd.append('ApplicationId', id)
        fd.append('FacilityName', values.facilityName)
        fd.append('License', values.license)
        fd.append('StreetAddress', values.streetAddress)
        fd.append('City', values.city)
        fd.append('ZipCode', values.zipCode)
        fd.append('Country', values.country)
        fd.append('AttendantCareFacility', values.attendantCareFacility)
        fd.append('GroupBoardingHome', values.groupBoardingHome)
        fd.append('SecureResidential', values.secureResidential)
        fd.append('DetentionCenter', values.detentionCenter)
        fd.append('StaffSecureFacility', values.staffSecureFacility)
        fd.append('SecureCareCenter', values.secureCareCenter)
        fd.append('FamilyForsterHome', values.familyForsterHome)
        fd.append('ResidentialCenter', values.residentialCenter)
        fd.append('FirstStaffName', values.firstStaffName)
        fd.append('MiddleStaffName', values.middleStaffName)
        fd.append('LastStaffName', values.lastStaffName)
        fd.append('StaffDateOfBirth', values.staffDateOfBirth ? values.staffDateOfBirth.toLocaleDateString('en-US') : '')
        fd.append('SeePhysicianReguarty', values.seePhysicianReguarty)
        fd.append('AnyMedicationRegularly', values.anyMedicationRegularly)
        fd.append('AnySurgeryInPast', values.anySurgeryInPast)
        fd.append('AnyHandicappingConditions', values.anyHandicappingConditions)
        fd.append('Headaches', values.headaches)
        fd.append('HeartDisease', values.heartDisease)
        fd.append('HighBloodPressure', values.highBloodPressure)
        fd.append('LungDisease', values.lungDisease)
        fd.append('Cancer', values.cancer)
        fd.append('Diabetes', values.diabetes)
        fd.append('Convulsions', values.convulsions)
        fd.append('MentalIllness', values.mentalIllness)
        fd.append('Alcoholism', values.alcoholism)
        fd.append('Arthrits', values.arthrits)
        fd.append('LiverDisease', values.liverDisease)
        fd.append('Other', values.other)
        fd.append('OtherReason', values.otherReason)
        fd.append('HealthAssessmentsPerformance', values.healthAssessmentsPerformance)
        fd.append('Signature', values.signature)
        fd.append('SignatureDate', values.signatureDate ? values.signatureDate.toLocaleDateString('en-US') : '')
        if (typeof values.negativeTuberculinTest === 'boolean' && !values?.resultsFile?.name && !values?.resultsFile?.hash) {
            fd.append('NegativeTuberculinTest', values.negativeTuberculinTest)
        }
        fd.append('TestDate', (values.testDate && !values?.resultsFile?.name && !values?.resultsFile?.hash) ? values.testDate.toLocaleDateString('en-US') : '')
        fd.append('TestRead', (values.testRead && !values?.resultsFile?.name && !values?.resultsFile?.hash) ? values.testRead : '')
        fd.append('TestReadDate', (values.testReadDate && !values?.resultsFile?.name && !values?.resultsFile?.hash) ? values.testReadDate.toLocaleDateString('en-US') : '')
        if (!values?.resultsFile?.hash && values?.resultsFile?.name) {
            fd.append('ResultsFile', values.resultsFile)
        }
        if (values?.resultsFile?.hash) {
            fd.append('IsFileChanged', false)
        } else if (values?.resultsFile?.name) {
            fd.append('IsFileChanged', true)
        }

        dispatch(updateSixthStep(fd, id))
    }
    const onPrev = () => {
        history.push(careerRoutes.stepFn(id, stepsPath.fifthStep), {
            activeStep: 4
        })
    }
    const mappedValues = useMemo(() => {
        if (stepValues?.facilityName) {
            const {file, ...rest} = stepValues
            return {
                ...rest,
                staffDateOfBirth: stepValues?.staffDateOfBirth ? new Date(stepValues.staffDateOfBirth) : '',
                signatureDate: stepValues?.signatureDate ? new Date(stepValues.signatureDate) : '',
                testDate: stepValues?.testDate ? new Date(stepValues.testDate) : '',
                testReadDate: stepValues?.testReadDate ? new Date(stepValues.testReadDate) : '',
                resultsFile: file?.hash ? file : undefined,
                testRead: stepValues?.testRead ? stepValues.testRead : '',
                otherReason: stepValues?.otherReason ? stepValues.otherReason : '',
                negativeTuberculinTest: typeof stepValues?.negativeTuberculinTest === 'boolean' ? stepValues.negativeTuberculinTest : undefined
            }

        } else {
            return sixStepValues
        }

    }, [stepValues])
    useEffect(() => {
        if (id) {
            dispatch(getSixthStep(id))
        }

    }, [id, dispatch])
    return (
        <>
            {
                stepLoading ?
                    <div className={'text-center'}>
                        <Spinner animation="border"/>
                    </div>
                    :
                    <>
                        <Stepper activeStep={location.state?.activeStep} steps={STEPS}/>
                        <div>
                            <h3>Kansas Department for Children and Families</h3>
                            <p>
                                Forster Care and residential Facility Licensing Division
                                555 South Kansas Avenue 2nd Floor Topeks KS, 66603
                            </p>
                        </div>
                        <div>
                            <h3>Certificate of Health assessment for persons 16 years of age or older</h3>
                            <p>
                                K.A.R 28-4-126(b)(1) requires each person over 16 years of age regularly caring for
                                children to
                                have a health assessment completed by a licensed physician or by a nurse trained to
                                perform
                                health assessment. All persons over 16 years of age living in a Family Forster Home
                                [K.A.R.
                                28-4-316(b)(1)] must have a health assessment. A Physician Assistant (PA) may complete
                                the
                                health assessment and must include the signature of the licensed physician authorizing
                                the P.A.
                                The Health Assessment must be recorded on this DCF form. Substitute forms are not
                                accepted.
                            </p>
                        </div>
                        <Formik
                            enableReinitialize
                            validationSchema={sixStepSchema}
                            initialValues={{...mappedValues}}
                            onSubmit={onSubmit}
                            render={({handleSubmit, values, setFieldValue, setFieldError, dirty, submitCount}) => (
                               <>
                                   <Form onSubmit={handleSubmit}>
                                       <div className={'SixthStep__item row-spacing'}>
                                           <h3>To be completed by provider/staff (Please print)</h3>
                                           <Row className={'col-spacing-sm'}>
                                               <Col sm>
                                                   <Field
                                                       name={'facilityName'}
                                                       label={'Name of the facility (exactly as stated on the license)'}
                                                       placeholder={'Enter'}
                                                       component={LabeledFormControl}
                                                   />
                                                   <FormikError name={'facilityName'}/>
                                               </Col>
                                               <Col sm>
                                                   <Field
                                                       name={'license'}
                                                       label={'License #'}
                                                       placeholder={'Enter'}
                                                       component={LabeledFormControl}
                                                   />
                                                   <FormikError name={'license'}/>
                                               </Col>
                                           </Row>
                                           <Row className={'col-spacing-sm'}>
                                               <Col sm>
                                                   <Field
                                                       name={'streetAddress'}
                                                       label={'Street address'}
                                                       placeholder={'Enter'}
                                                       component={LabeledFormControl}
                                                   />
                                                   <FormikError name={'streetAddress'}/>
                                               </Col>
                                               <Col sm>
                                                   <Field
                                                       name={'city'}
                                                       label={'City'}
                                                       placeholder={'Enter'}
                                                       component={LabeledFormControl}
                                                   />
                                                   <FormikError name={'city'}/>
                                               </Col>
                                           </Row>
                                           <Row className={'col-spacing-sm'}>
                                               <Col sm>
                                                   <Field
                                                       name={'zipCode'}
                                                       label={'Zip Code'}
                                                       placeholder={'Enter'}
                                                       component={LabeledFormControl}
                                                   />
                                                   <FormikError name={'zipCode'}/>
                                               </Col>
                                               <Col sm>
                                                   <Field
                                                       name={'country'}
                                                       label={'Country'}
                                                       placeholder={'Enter'}
                                                       component={LabeledFormControl}
                                                   />
                                                   <FormikError name={'country'}/>
                                               </Col>
                                           </Row>
                                       </div>
                                       <div className={'SixthStep__item row-spacing'}>
                                           <h3>Check type of child care facility:</h3>
                                           <Row className={'col-spacing-sm'}>
                                               <Col md={5}>
                                                   <Field
                                                       disabled
                                                       defaultChecked={values.attendantCareFacility}
                                                       onChange={(e) => {
                                                           setFieldValue('attendantCareFacility', e.target.checked)
                                                       }}
                                                       name={'attendantCareFacility'}
                                                       label={'Attendant Care Facility'}
                                                       type={'checkbox'}
                                                       className={'checkbox'}
                                                       component={FormCheck}
                                                   />
                                               </Col>
                                               <Col md={5}>
                                                   <Field
                                                       disabled
                                                       defaultChecked={values.staffSecureFacility}
                                                       onChange={(e) => {
                                                           setFieldValue('staffSecureFacility', e.target.checked)
                                                       }}
                                                       name={'staffSecureFacility'}
                                                       label={'Staff Secure Facility'}
                                                       className={'checkbox'}
                                                       component={FormCheck}
                                                   />
                                               </Col>
                                           </Row>
                                           <Row className={'col-spacing-sm'}>
                                               <Col md={5}>
                                                   <Field
                                                       disabled
                                                       defaultChecked={values.detentionCenter}
                                                       onChange={(e) => {
                                                           setFieldValue('detentionCenter', e.target.checked)
                                                       }}
                                                       name={'detentionCenter'}
                                                       label={'Detention Center'}
                                                       className={'checkbox'}
                                                       component={FormCheck}
                                                   />
                                               </Col>
                                               <Col md={5}>
                                                   <Field
                                                       disabled
                                                       defaultChecked={values.residentialCenter}
                                                       onChange={(e) => {
                                                           setFieldValue('residentialCenter', e.target.checked)
                                                       }}
                                                       name={'residentialCenter'}
                                                       label={'Residential Center'}
                                                       className={'checkbox'}
                                                       component={FormCheck}
                                                   />
                                               </Col>
                                           </Row>
                                           <Row className={'col-spacing-sm'}>
                                               <Col md={5}>
                                                   <Field
                                                       disabled
                                                       defaultChecked={values.familyForsterHome}
                                                       onChange={(e) => {
                                                           setFieldValue('familyForsterHome', e.target.checked)
                                                       }}
                                                       name={'familyForsterHome'}
                                                       label={'Family Forster Home'}
                                                       className={'checkbox'}
                                                       component={FormCheck}
                                                   />
                                               </Col>
                                               <Col md={5}>
                                                   <Field
                                                       disabled
                                                       defaultChecked={values.secureResidential}
                                                       onChange={(e) => {
                                                           setFieldValue('secureResidential', e.target.checked)
                                                       }}
                                                       name={'secureResidential'}
                                                       label={'Secure Residential Treatment Facility'}
                                                       className={'checkbox'}
                                                       component={FormCheck}
                                                   />
                                               </Col>
                                           </Row>
                                           <Row className={'col-spacing-sm'}>
                                               <Col md={5}>
                                                   <Field
                                                       disabled
                                                       defaultChecked={values.groupBoardingHome}
                                                       onChange={(e) => {
                                                           setFieldValue('groupBoardingHome', e.target.checked)
                                                       }}
                                                       name={'groupBoardingHome'}
                                                       label={'Group Boarding Home'}
                                                       className={'checkbox'}
                                                       component={FormCheck}
                                                   />
                                               </Col>
                                               <Col md={5}>
                                                   <Field
                                                       disabled
                                                       defaultChecked={values.secureCareCenter}
                                                       onChange={(e) => {
                                                           setFieldValue('secureCareCenter', e.target.checked)
                                                       }}
                                                       name={'secureCareCenter'}
                                                       label={'Secure Care Center'}
                                                       className={'checkbox'}
                                                       component={FormCheck}
                                                   />
                                               </Col>
                                           </Row>
                                           <h3>Name of Forster Parent/Staff</h3>
                                           <Row className={'col-spacing-sm'}>
                                               <Col sm>
                                                   <Field
                                                       name={'firstStaffName'}
                                                       label={'First'}
                                                       placeholder={'Enter'}
                                                       component={LabeledFormControl}
                                                   />
                                                   <FormikError name={'firstStaffName'}/>
                                               </Col>
                                               <Col sm>
                                                   <Field
                                                       name={'middleStaffName'}
                                                       label={'Middle'}
                                                       placeholder={'Enter'}
                                                       component={LabeledFormControl}
                                                   />
                                                   <FormikError name={'middleStaffName'}/>

                                               </Col>
                                           </Row>
                                           <Row className={'col-spacing-sm'}>
                                               <Col sm>
                                                   <Field
                                                       name={'lastStaffName'}
                                                       label={'Last'}
                                                       placeholder={'Enter'}
                                                       component={LabeledFormControl}
                                                   />
                                                   <FormikError name={'lastStaffName'}/>
                                               </Col>
                                               <Col sm>
                                                   <DatePicker
                                                       maxDate={new Date()}
                                                       label={'Date of Birth:'}
                                                       yearDropdownItemNumber={100}
                                                       scrollableYearDropdown
                                                       showYearDropdown
                                                       showMonthDropdown
                                                       placeholderText={'mm/dd/yyyy'}
                                                       selected={values.staffDateOfBirth}
                                                       onChange={(e) => {
                                                           setFieldValue('staffDateOfBirth', e)
                                                       }}
                                                   />
                                                   <FormikError name={'staffDateOfBirth'}/>

                                               </Col>
                                           </Row>
                                       </div>
                                       <div className={'SixthStep__item row-spacing'}>
                                           <h3>Please check each question. If answer is yes, please explain.</h3>
                                           <div>
                                               <div
                                                   className={'d-lg-flex justify-content-between col-spacing-sm d-md-block'}>
                                                   <div>
                                                       1.Do you see a physician regularly for any health condition?
                                                   </div>
                                                   <div className={'d-flex align-items-center item-spacing-right'}>
                                                       <Field
                                                           name={'seePhysicianReguarty'}
                                                           onChange={() => {
                                                               setFieldValue('seePhysicianReguarty', true)
                                                           }}
                                                           defaultChecked={values?.seePhysicianReguarty?.toString() === YesOrNoValues.YES}
                                                           value={YesOrNoValues.YES}
                                                           label={'Yes'}
                                                           component={CustomRadio}
                                                       />
                                                       <Field
                                                           name={'seePhysicianReguarty'}
                                                           onChange={() => {
                                                               setFieldValue('seePhysicianReguarty', false)
                                                           }}
                                                           defaultChecked={values?.seePhysicianReguarty?.toString() === YesOrNoValues.NO}
                                                           value={YesOrNoValues.NO}
                                                           label={'No'}
                                                           component={CustomRadio}
                                                       />
                                                   </div>
                                               </div>
                                               <FormikError name={'seePhysicianReguarty'}/>
                                           </div>
                                           <div>
                                               <div
                                                   className={'d-lg-flex justify-content-between col-spacing-sm d-md-block'}>
                                                   <div>
                                                       2. Are you taking any medication regularly?
                                                   </div>
                                                   <div className={'d-flex align-items-center item-spacing-right'}>
                                                       <Field
                                                           name={'anyMedicationRegularly'}
                                                           onChange={() => {
                                                               setFieldValue('anyMedicationRegularly', true)
                                                           }}
                                                           defaultChecked={values?.anyMedicationRegularly?.toString() === YesOrNoValues.YES}
                                                           value={YesOrNoValues.YES}
                                                           label={'Yes'}
                                                           component={CustomRadio}
                                                       />
                                                       <Field
                                                           name={'anyMedicationRegularly'}
                                                           onChange={() => {
                                                               setFieldValue('anyMedicationRegularly', false)
                                                           }}
                                                           defaultChecked={values?.anyMedicationRegularly?.toString() === YesOrNoValues.NO}
                                                           value={YesOrNoValues.NO}
                                                           label={'No'}
                                                           component={CustomRadio}
                                                       />
                                                   </div>
                                               </div>
                                               <FormikError name={'anyMedicationRegularly'}/>
                                           </div>
                                           <div>
                                               <div
                                                   className={'d-lg-flex justify-content-between col-spacing-sm d-md-block'}>
                                                   <div>
                                                       3. Have you had any surgery in the past 3 years?
                                                   </div>
                                                   <div className={'d-flex align-items-center item-spacing-right'}>
                                                       <Field
                                                           name={'anySurgeryInPast'}
                                                           onChange={() => {
                                                               setFieldValue('anySurgeryInPast', true)
                                                           }}
                                                           defaultChecked={values?.anySurgeryInPast?.toString() === YesOrNoValues.YES}
                                                           value={YesOrNoValues.YES}
                                                           label={'Yes'}
                                                           component={CustomRadio}
                                                       />
                                                       <Field
                                                           name={'anySurgeryInPast'}
                                                           onChange={() => {
                                                               setFieldValue('anySurgeryInPast', false)
                                                           }}
                                                           defaultChecked={values?.anySurgeryInPast?.toString() === YesOrNoValues.NO}
                                                           value={YesOrNoValues.NO}
                                                           label={'No'}
                                                           component={CustomRadio}
                                                       />
                                                   </div>
                                               </div>
                                               <FormikError name={'anySurgeryInPast'}/>
                                           </div>
                                           <div>
                                               <div
                                                   className={'d-lg-flex justify-content-between col-spacing-sm d-md-block'}>
                                                   <div>
                                                       4. Do you have any handicapping conditions which might interface
                                                       with the
                                                       care
                                                       of children?
                                                   </div>
                                                   <div className={'d-flex align-items-center item-spacing-right'}>
                                                       <Field
                                                           name={'anyHandicappingConditions'}
                                                           onChange={() => {
                                                               setFieldValue('anyHandicappingConditions', true)
                                                           }}
                                                           defaultChecked={values?.anyHandicappingConditions?.toString() === YesOrNoValues.YES}
                                                           value={YesOrNoValues.YES}
                                                           label={'Yes'}
                                                           component={CustomRadio}
                                                       />
                                                       <Field
                                                           name={'anyHandicappingConditions'}
                                                           onChange={() => {
                                                               setFieldValue('anyHandicappingConditions', false)
                                                           }}
                                                           defaultChecked={values?.anyHandicappingConditions?.toString() === YesOrNoValues.NO}
                                                           value={YesOrNoValues.NO}
                                                           label={'No'}
                                                           component={CustomRadio}
                                                       />
                                                   </div>
                                               </div>
                                               <FormikError name={'anyHandicappingConditions'}/>
                                           </div>
                                           <div>
                                               5. Do you have any chronic illness conditions <span
                                               className={'font-weight-bold'}>such as:</span>
                                           </div>
                                           <Row className={'col-spacing-md'}>
                                               <Col lg={4} md={12} className={'row-spacing'}>
                                                   <div>
                                                       <div className={'d-flex justify-content-between'}>
                                                           <div>
                                                               Headaches
                                                           </div>
                                                           <div className={'d-flex align-items-center item-spacing-right'}>
                                                               <Field
                                                                   name={'headaches'}
                                                                   onChange={() => {
                                                                       setFieldValue('headaches', true)
                                                                   }}
                                                                   defaultChecked={values?.headaches?.toString() === YesOrNoValues.YES}
                                                                   value={YesOrNoValues.YES}
                                                                   label={'Yes'}
                                                                   component={CustomRadio}
                                                               />
                                                               <Field
                                                                   name={'headaches'}
                                                                   onChange={() => {
                                                                       setFieldValue('headaches', false)
                                                                   }}
                                                                   defaultChecked={values?.headaches?.toString() === YesOrNoValues.NO}
                                                                   value={YesOrNoValues.NO}
                                                                   label={'No'}
                                                                   component={CustomRadio}
                                                               />
                                                           </div>
                                                       </div>
                                                       <FormikError name={'headaches'}/>
                                                   </div>
                                                   <div>
                                                       <div className={'d-flex justify-content-between'}>
                                                           <div>
                                                               Heart Disease
                                                           </div>
                                                           <div className={'d-flex align-items-center item-spacing-right'}>
                                                               <Field
                                                                   name={'heartDisease'}
                                                                   onChange={() => {
                                                                       setFieldValue('heartDisease', true)
                                                                   }}
                                                                   defaultChecked={values?.heartDisease?.toString() === YesOrNoValues.YES}
                                                                   value={YesOrNoValues.YES}
                                                                   label={'Yes'}
                                                                   component={CustomRadio}
                                                               />
                                                               <Field
                                                                   name={'heartDisease'}
                                                                   onChange={() => {
                                                                       setFieldValue('heartDisease', false)
                                                                   }}
                                                                   defaultChecked={values?.heartDisease?.toString() === YesOrNoValues.NO}
                                                                   value={YesOrNoValues.NO}
                                                                   label={'No'}
                                                                   component={CustomRadio}
                                                               />
                                                           </div>
                                                       </div>
                                                       <FormikError name={'heartDisease'}/>
                                                   </div>
                                                   <div>
                                                       <div className={'d-flex justify-content-between'}>
                                                           <div>
                                                               High Blood Pressure
                                                           </div>
                                                           <div className={'d-flex align-items-center item-spacing-right'}>
                                                               <Field
                                                                   name={'highBloodPressure'}
                                                                   onChange={() => {
                                                                       setFieldValue('highBloodPressure', true)
                                                                   }}
                                                                   defaultChecked={values?.highBloodPressure?.toString() === YesOrNoValues.YES}
                                                                   value={YesOrNoValues.YES}
                                                                   label={'Yes'}
                                                                   component={CustomRadio}
                                                               />
                                                               <Field
                                                                   name={'highBloodPressure'}
                                                                   onChange={() => {
                                                                       setFieldValue('highBloodPressure', false)
                                                                   }}
                                                                   defaultChecked={values?.highBloodPressure?.toString() === YesOrNoValues.NO}
                                                                   value={YesOrNoValues.NO}
                                                                   label={'No'}
                                                                   component={CustomRadio}
                                                               />
                                                           </div>
                                                       </div>
                                                       <FormikError name={'highBloodPressure'}/>
                                                   </div>
                                                   <div>
                                                       <div className={'d-flex justify-content-between'}>
                                                           <div>
                                                               Lung Disease
                                                           </div>
                                                           <div className={'d-flex align-items-center item-spacing-right'}>
                                                               <Field
                                                                   name={'lungDisease'}
                                                                   onChange={() => {
                                                                       setFieldValue('lungDisease', true)
                                                                   }}
                                                                   defaultChecked={values?.lungDisease?.toString() === YesOrNoValues.YES}
                                                                   value={YesOrNoValues.YES}
                                                                   label={'Yes'}
                                                                   component={CustomRadio}
                                                               />
                                                               <Field
                                                                   name={'lungDisease'}
                                                                   onChange={() => {
                                                                       setFieldValue('lungDisease', false)
                                                                   }}
                                                                   defaultChecked={values?.lungDisease?.toString() === YesOrNoValues.NO}
                                                                   value={YesOrNoValues.NO}
                                                                   label={'No'}
                                                                   component={CustomRadio}
                                                               />
                                                           </div>
                                                       </div>
                                                       <FormikError name={'lungDisease'}/>
                                                   </div>

                                               </Col>
                                               <Col lg={4} md={12} className={'row-spacing'}>
                                                   <div>
                                                       <div className={'d-flex justify-content-between'}>
                                                           <div>
                                                               Cancer
                                                           </div>
                                                           <div className={'d-flex align-items-center item-spacing-right'}>
                                                               <Field
                                                                   name={'cancer'}
                                                                   onChange={() => {
                                                                       setFieldValue('cancer', true)
                                                                   }}
                                                                   defaultChecked={values?.cancer?.toString() === YesOrNoValues.YES}
                                                                   value={YesOrNoValues.YES}
                                                                   label={'Yes'}
                                                                   component={CustomRadio}
                                                               />
                                                               <Field
                                                                   name={'cancer'}
                                                                   onChange={() => {
                                                                       setFieldValue('cancer', false)
                                                                   }}
                                                                   defaultChecked={values?.cancer?.toString() === YesOrNoValues.NO}
                                                                   value={YesOrNoValues.NO}
                                                                   label={'No'}
                                                                   component={CustomRadio}
                                                               />
                                                           </div>
                                                       </div>
                                                       <FormikError name={'cancer'}/>
                                                   </div>
                                                   <div>
                                                       <div className={'d-flex justify-content-between'}>
                                                           <div>
                                                               Diabetes
                                                           </div>
                                                           <div className={'d-flex align-items-center item-spacing-right'}>
                                                               <Field
                                                                   name={'diabetes'}
                                                                   onChange={() => {
                                                                       setFieldValue('diabetes', true)
                                                                   }}
                                                                   defaultChecked={values?.diabetes?.toString() === YesOrNoValues.YES}
                                                                   value={YesOrNoValues.YES}
                                                                   label={'Yes'}
                                                                   component={CustomRadio}
                                                               />
                                                               <Field
                                                                   name={'diabetes'}
                                                                   onChange={() => {
                                                                       setFieldValue('diabetes', false)
                                                                   }}
                                                                   defaultChecked={values?.diabetes?.toString() === YesOrNoValues.NO}
                                                                   value={YesOrNoValues.NO}
                                                                   label={'No'}
                                                                   component={CustomRadio}
                                                               />
                                                           </div>
                                                       </div>
                                                       <FormikError name={'diabetes'}/>
                                                   </div>
                                                   <div>
                                                       <div className={'d-flex justify-content-between'}>
                                                           <div>
                                                               Convulsions
                                                           </div>
                                                           <div className={'d-flex align-items-center item-spacing-right'}>
                                                               <Field
                                                                   name={'convulsions'}
                                                                   onChange={() => {
                                                                       setFieldValue('convulsions', true)
                                                                   }}
                                                                   defaultChecked={values?.convulsions?.toString() === YesOrNoValues.YES}
                                                                   value={YesOrNoValues.YES}
                                                                   label={'Yes'}
                                                                   component={CustomRadio}
                                                               />
                                                               <Field
                                                                   name={'convulsions'}
                                                                   onChange={() => {
                                                                       setFieldValue('convulsions', false)
                                                                   }}
                                                                   defaultChecked={values?.convulsions?.toString() === YesOrNoValues.NO}
                                                                   value={YesOrNoValues.NO}
                                                                   label={'No'}
                                                                   component={CustomRadio}
                                                               />
                                                           </div>
                                                       </div>
                                                       <FormikError name={'convulsions'}/>
                                                   </div>
                                                   <div>
                                                       <div className={'d-flex justify-content-between'}>
                                                           <div>
                                                               Mental Illness
                                                           </div>
                                                           <div className={'d-flex align-items-center item-spacing-right'}>
                                                               <Field
                                                                   name={'mentalIllness'}
                                                                   onChange={() => {
                                                                       setFieldValue('mentalIllness', true)
                                                                   }}
                                                                   defaultChecked={values?.mentalIllness?.toString() === YesOrNoValues.YES}
                                                                   value={YesOrNoValues.YES}
                                                                   label={'Yes'}
                                                                   component={CustomRadio}
                                                               />
                                                               <Field
                                                                   name={'mentalIllness'}
                                                                   onChange={() => {
                                                                       setFieldValue('mentalIllness', false)
                                                                   }}
                                                                   defaultChecked={values?.mentalIllness?.toString() === YesOrNoValues.NO}
                                                                   value={YesOrNoValues.NO}
                                                                   label={'No'}
                                                                   component={CustomRadio}
                                                               />
                                                           </div>
                                                       </div>
                                                       <FormikError name={'mentalIllness'}/>
                                                   </div>

                                               </Col>
                                               <Col lg={4} md={12} className={'row-spacing'}>
                                                   <div>
                                                       <div className={'d-flex justify-content-between'}>
                                                           <div>
                                                               Alcoholism
                                                           </div>
                                                           <div className={'d-flex align-items-center item-spacing-right'}>
                                                               <Field
                                                                   name={'alcoholism'}
                                                                   onChange={() => {
                                                                       setFieldValue('alcoholism', true)
                                                                   }}
                                                                   defaultChecked={values?.alcoholism?.toString() === YesOrNoValues.YES}
                                                                   value={YesOrNoValues.YES}
                                                                   label={'Yes'}
                                                                   component={CustomRadio}
                                                               />
                                                               <Field
                                                                   name={'alcoholism'}
                                                                   onChange={() => {
                                                                       setFieldValue('alcoholism', false)
                                                                   }}
                                                                   defaultChecked={values?.alcoholism?.toString() === YesOrNoValues.NO}
                                                                   value={YesOrNoValues.NO}
                                                                   label={'No'}
                                                                   component={CustomRadio}
                                                               />
                                                           </div>
                                                       </div>
                                                       <FormikError name={'alcoholism'}/>
                                                   </div>
                                                   <div>
                                                       <div className={'d-flex justify-content-between'}>
                                                           <div>
                                                               Arthrits
                                                           </div>
                                                           <div className={'d-flex align-items-center item-spacing-right'}>
                                                               <Field
                                                                   name={'arthrits'}
                                                                   onChange={() => {
                                                                       setFieldValue('arthrits', true)
                                                                   }}
                                                                   defaultChecked={values?.arthrits?.toString() === YesOrNoValues.YES}
                                                                   value={YesOrNoValues.YES}
                                                                   label={'Yes'}
                                                                   component={CustomRadio}
                                                               />
                                                               <Field
                                                                   name={'arthrits'}
                                                                   onChange={() => {
                                                                       setFieldValue('arthrits', false)
                                                                   }}
                                                                   defaultChecked={values?.arthrits?.toString() === YesOrNoValues.NO}
                                                                   value={YesOrNoValues.NO}
                                                                   label={'No'}
                                                                   component={CustomRadio}
                                                               />
                                                           </div>
                                                       </div>
                                                       <FormikError name={'arthrits'}/>
                                                   </div>
                                                   <div>
                                                       <div className={'d-flex justify-content-between'}>
                                                           <div>
                                                               Liver Disease
                                                           </div>
                                                           <div className={'d-flex align-items-center item-spacing-right'}>
                                                               <Field
                                                                   name={'liverDisease'}
                                                                   onChange={() => {
                                                                       setFieldValue('liverDisease', true)
                                                                   }}
                                                                   defaultChecked={values?.liverDisease?.toString() === YesOrNoValues.YES}
                                                                   value={YesOrNoValues.YES}
                                                                   label={'Yes'}
                                                                   component={CustomRadio}
                                                               />
                                                               <Field
                                                                   name={'liverDisease'}
                                                                   onChange={() => {
                                                                       setFieldValue('liverDisease', false)
                                                                   }}
                                                                   defaultChecked={values?.liverDisease?.toString() === YesOrNoValues.NO}
                                                                   value={YesOrNoValues.NO}
                                                                   label={'No'}
                                                                   component={CustomRadio}
                                                               />
                                                           </div>
                                                       </div>
                                                       <FormikError name={'liverDisease'}/>
                                                   </div>
                                                   <div>
                                                       <div className={'d-flex justify-content-between'}>
                                                           <div>
                                                               Other
                                                           </div>
                                                           <div className={'d-flex align-items-center item-spacing-right'}>
                                                               <Field
                                                                   name={'other'}
                                                                   onChange={() => {
                                                                       setFieldValue('other', true)
                                                                   }}
                                                                   defaultChecked={values?.other?.toString() === YesOrNoValues.YES}
                                                                   value={YesOrNoValues.YES}
                                                                   label={'Yes'}
                                                                   component={CustomRadio}
                                                               />
                                                               <Field
                                                                   name={'other'}
                                                                   onChange={() => {
                                                                       setFieldValue('other', false)
                                                                   }}
                                                                   defaultChecked={values?.other?.toString() === YesOrNoValues.NO}
                                                                   value={YesOrNoValues.NO}
                                                                   label={'No'}
                                                                   component={CustomRadio}
                                                               />
                                                           </div>
                                                       </div>
                                                       <FormikError name={'other'}/>
                                                   </div>

                                               </Col>
                                           </Row>
                                           <Row>
                                               <Col>
                                                   <Field
                                                       name={'otherReason'}
                                                       label={'If other describe'}
                                                       placeholder={'Enter'}
                                                       component={LabeledFormControl}
                                                   />
                                                   <FormikError name={'otherReason'}/>
                                               </Col>
                                           </Row>
                                       </div>
                                       <div className={'SixthStep__item row-spacing'}>
                                           <h3>
                                               To be completed by licensed physician or nurse trained to perform health
                                               assessments:
                                           </h3>
                                           <div>
                                               I have reviewed the above information and have conducted an examination and
                                               any
                                               tests indicated.
                                               <div className={'font-weight-bold'}>
                                                   Sign one of the statements below:
                                               </div>
                                           </div>
                                           <Row className={'align-items-center'}>
                                               <Col>
                                                   <Field
                                                       onChange={(e) => {
                                                           setFieldValue('healthAssessmentsPerformance', Number(e.target.checked))
                                                       }}
                                                       defaultChecked={HealthAssessmentsPerformance.NoEvidence === values.healthAssessmentsPerformance}
                                                       value={HealthAssessmentsPerformance.NoEvidence}
                                                       name={'healthAssessmentsPerformance'}
                                                       label={'1. I do not find evidence of physical or mental illness that would conflict with the ability to care for the health, safety or welfare od children.'}
                                                       component={CustomRadio}
                                                   />
                                                   <FormikError name={'healthAssessmentsPerformance'}/>
                                               </Col>
                                           </Row>
                                           <Row className={'align-items-center'}>
                                               <Col>
                                                   <Field
                                                       onChange={(e) => {
                                                           setFieldValue('healthAssessmentsPerformance', Number(e.target.checked))
                                                       }}
                                                       defaultChecked={HealthAssessmentsPerformance.AnyEvidence === values.healthAssessmentsPerformance}
                                                       value={HealthAssessmentsPerformance.AnyEvidence}
                                                       name={'healthAssessmentsPerformance'}
                                                       label={'2. I found evidence of physical or mental illness that would conflict with the ability to care for the health, safely or welfare of children.'}
                                                       component={CustomRadio}
                                                   />
                                                   <FormikError name={'healthAssessmentsPerformance'}/>
                                               </Col>
                                           </Row>
                                           <Row className={'col-spacing-sm align-items-end'}>
                                               <Col lg={8} md>
                                                   <Field
                                                       name={'signature'}
                                                       placeholder={'Enter'}
                                                       label={'Signature of licensed physician or nurse trained to perform health assessments'}
                                                       component={LabeledFormControl}
                                                   />
                                                   <FormikError name={'signature'}/>
                                               </Col>
                                               <Col lg={4} md>
                                                   <DatePicker
                                                       minDate={new Date()}
                                                       label={'Date'}
                                                       yearDropdownItemNumber={100}
                                                       scrollableYearDropdown
                                                       showYearDropdown
                                                       showMonthDropdown
                                                       placeholderText={'mm/dd/yyyy'}
                                                       selected={values.signatureDate}
                                                       onChange={(e) => {
                                                           setFieldValue('signatureDate', e)
                                                       }}
                                                   />
                                                   <FormikError name={'signatureDate'}/>
                                               </Col>

                                           </Row>
                                       </div>
                                       <div className={'SixthStep__item row-spacing'}>
                                           <h3>
                                               Record results of TS test or attach results to this form.
                                           </h3>
                                           <div className={'testRow col-spacing-md'}>
                                               <Field
                                                   disabled={values?.resultsFile?.name || values?.resultsFile?.hash}
                                                   isBlack
                                                   defaultChecked={values?.negativeTuberculinTest?.toString() === YesOrNoValues.YES}
                                                   onChange={(e) => {
                                                       setFieldValue('negativeTuberculinTest', true)
                                                   }}
                                                   value={YesOrNoValues.YES}
                                                   name={'negativeTuberculinTest'}
                                                   label={'Negative tuberculin test'}
                                                   component={CustomRadio}
                                               />
                                               <Field
                                                   disabled={values?.resultsFile?.name || values?.resultsFile?.hash}
                                                   isBlack
                                                   defaultChecked={values?.negativeTuberculinTest?.toString() === YesOrNoValues.NO}
                                                   onChange={(e) => {
                                                       setFieldValue('negativeTuberculinTest', false)
                                                   }}
                                                   value={YesOrNoValues.NO}
                                                   name={'negativeTuberculinTest'}
                                                   label={'or negative chest x-ray'}
                                                   component={CustomRadio}
                                               />
                                               <div className={'d-flex align-items-center'}>
                                                   <span className={'mr-3'}>On</span>
                                                   <div>
                                                       <DatePicker
                                                           disabled={values?.resultsFile?.name || values?.resultsFile?.hash}
                                                           yearDropdownItemNumber={100}
                                                           scrollableYearDropdown
                                                           showYearDropdown
                                                           showMonthDropdown
                                                           placeholderText={'mm/dd/yyyy'}
                                                           selected={values.testDate}
                                                           onChange={(e) => {
                                                               setFieldValue('testDate', e)
                                                           }}
                                                       />
                                                       <FormikError name={'testDate'}/>
                                                   </div>
                                               </div>
                                           </div>
                                           <div>
                                               (Repeat test not needed unless there is exposure or symptoms)
                                           </div>
                                           <Row className={'align-items-end col-spacing-sm'}>
                                               <Col lg={8} md>
                                                   <Field
                                                       disabled={values?.resultsFile?.name || values?.resultsFile?.hash}
                                                       name={'testRead'}
                                                       placeholder={'Enter'}
                                                       label={'Test read by (Licensed Physician/Nurse Signature or Health Department'}
                                                       component={LabeledFormControl}
                                                   />
                                                   <FormikError name={'testRead'}/>

                                               </Col>
                                               <Col lg={4} md>
                                                   <DatePicker
                                                       disabled={values?.resultsFile?.name || values?.resultsFile?.hash}
                                                       label={'Date'}
                                                       yearDropdownItemNumber={100}
                                                       scrollableYearDropdown
                                                       showYearDropdown
                                                       showMonthDropdown
                                                       placeholderText={'mm/dd/yyyy'}
                                                       selected={values.testReadDate}
                                                       onChange={(e) => {
                                                           setFieldValue('testReadDate', e)
                                                       }}
                                                   />
                                                   <FormikError name={'testReadDate'}/>

                                               </Col>
                                           </Row>
                                           <div>
                                               <div className={'select-block col-spacing-sm'}>
                                                   <div style={{marginRight: values?.resultsFile?.name ? '0px' : '10px'}}>
                                                       Or you can attach results here:
                                                   </div>
                                                   {(values?.resultsFile?.name || values?.resultsFile?.fileName)
                                                       &&
                                                       <div className={`fileBox marginX`}>
                                                           {values.resultsFile.name || values.resultsFile.fileName}
                                                       </div>
                                                   }
                                                   {
                                                       values?.resultsFile?.name || values?.resultsFile?.fileName ?
                                                           <Button
                                                               onClick={() => {
                                                                   setFieldValue('resultsFile', undefined)
                                                               }}
                                                               className={'action-button'}>
                                                               Remove
                                                           </Button>
                                                           :
                                                           <Button className={'action-button'} onClick={async () => {
                                                               const selectedFile = await selectFile(['.png', '.jpg', 'pdf'])
                                                               if (selectedFile === 'File is too large') {
                                                                   setFieldError('resultsFile', 'File is too large')
                                                               } else {
                                                                   setFieldValue('resultsFile', selectedFile)
                                                               }
                                                           }}>
                                                               <img
                                                                   className={'action-button__icon'}
                                                                   title={'Download'}
                                                                   alt="Download"
                                                                   src={uploadIcon}
                                                               />
                                                               Attach results
                                                           </Button>
                                                   }

                                               </div>
                                               <FormikError name={'resultsFile'}/>
                                           </div>
                                       </div>
                                       <div className={'Step__footer'}>
                                           <Button
                                               onClick={onPrev}
                                               className={'btn-outline-primary Step__action-button'}>
                                               Back
                                           </Button>
                                           <LoadingActionButton loading={loading} text={'Next'}/>
                                       </div>
                                   </Form>
                                   <CustomPrompt when={dirty && submitCount === 0}/>
                               </>
                            )}
                        />
                    </>
            }
        </>
    );
};

