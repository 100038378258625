import {
  EDIT_PRODUCT,
  SET_PRODUCTS,
  SET_PRODUCTS_LOADING,
  DELETE_PRODUCT,
  CREATE_PRODUCT,
  SET_PRODUCT,
} from 'modules/core/store/actions/productsActions';

const initialState = {
  products: {},
  loading: false,
  totalItemsInCategory: { 1: 9999, 2: 9999, 3: 9999, 4: 9999, 5: 9999 },
};

const storeReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PRODUCTS:
      return {
        ...state,
        products: {
          ...state.products,
          [action.categoryId]: [
            ...(action.clear ? [] : state.products[action.categoryId]),
            ...action.data,
          ],
        },
        totalItemsInCategory: {
          ...state.totalItemsInCategory,
          [action.categoryId]: action.totalItemsInCategory,
        },
      };
    case EDIT_PRODUCT:
      return {
        ...state,
        products: {
          ...state.products,
          [action.categoryId]: [] /*state.products[action.categoryId]
            ? state.products[action.categoryId].map((product) => {
                if (product.id === action.id) {
                  return { ...product, ...action.product };
                }
                return product;
              })    
              : [action.product],*/,
        },
        totalItemsInCategory: {
          ...state.totalItemsInCategory,
          [state.categoryId]: 9999,
        },
      };
    case SET_PRODUCT:
      let mappedProducts = state.products[action.categoryId] ?? [];
      if (!!mappedProducts.find((p) => p.id === action.id)) {
        mappedProducts = mappedProducts.map((product) => {
          if (product.id === action.id) {
            return { ...product, ...action.product };
          }
          return product;
        });
      } else {
        mappedProducts.push(action.product);
      }

      return {
        ...state,
        products: {
          ...state.products,
          [action.categoryId]: mappedProducts,
        },
        // totalItemsInCategory: {
        //   ...state.totalItemsInCategory,
        //   [state.categoryId]: 9999,
        // },
      };
    case DELETE_PRODUCT:
      return {
        ...state,
        products: {},
        totalItemsInCategory: {
          ...state.totalItemsInCategory,
          [state.categoryId]: 9999,
        },
      };
    case SET_PRODUCTS_LOADING:
      return {
        ...state,
        loading: action.value,
      };

    case CREATE_PRODUCT:
      return {
        ...state,
        products: {},
        totalItemsInCategory: {
          ...state.totalItemsInCategory,
          [state.categoryId]: 9999,
        },
      };
    default:
      return state;
  }
};

export default storeReducer;
