import React, {useEffect, useMemo} from 'react';
import './styles.scss'
import {Button, Col, FormCheck, Row, Spinner} from "react-bootstrap";
import {Field, Form, Formik} from "formik";
import LabeledFormControl from "../../../../core/components/FormControl";
import FormCheckLabel from "react-bootstrap/FormCheckLabel";
import { STEPS, stepsPath, thirdStepValues} from "../../../constants";
import DatePicker from "../../../../admin/components/DatePicker";
import {thirdStepSchema} from "../../../validation";
import FormikError from "../../../../admin/components/FormikError";
import Stepper from "../../Stepper";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {careerRoutes} from "../../../routes/constants";
import {useDispatch, useSelector} from "react-redux";
import {getThirdStep, updateThirdStep} from "../../../../core/store/actions/careerActions";
import LoadingActionButton from "../../LoadingButton";
import CustomPrompt from "../../CustomPrompt";

export const CareerApplyThirdStep = () => {
    const {id} = useParams()
    const location = useLocation()
    const history = useHistory()
    const {loading, stepLoading, stepValues} = useSelector((state) => state.career)
    const dispatch = useDispatch()
    const onSubmit = (values) => {
        const payload = {
            ...values,
            applicationId: Number(id),
            applicationDate: values?.applicationDate.toLocaleDateString('en-US'),
            confidentialityAgreementDate: values?.confidentialityAgreementDate.toLocaleDateString('en-US'),
        }
        dispatch(updateThirdStep(payload))
    }
    const onPrev = () => {
        history.push(careerRoutes.stepFn(id, stepsPath.secondStep), {
            activeStep: 1
        })
    }
    useEffect(() => {
        if(id){
            dispatch(getThirdStep(id))
        }
    },[id, dispatch])
    const mappedValues = useMemo(() => {
        if (stepValues?.agreeStatement) {
            return {
                ...stepValues,
                applicationDate: new Date(stepValues.applicationDate),
                confidentialityAgreementDate: new Date(stepValues.confidentialityAgreementDate),
            }

        } else {
            return thirdStepValues
        }

    }, [stepValues])
    return (
        <>
            {
                stepLoading ?
                    <div className={'text-center'}>
                        <Spinner animation="border"/>
                    </div>
                    :
                    <>
                        <Stepper activeStep={location.state?.activeStep} steps={STEPS}/>
                        <h3>Corporal Punishment Policy</h3>
                        <Formik
                            validationSchema={thirdStepSchema}
                            initialValues={{...mappedValues}}
                            onSubmit={onSubmit}
                            render={({handleSubmit, values, setFieldValue, dirty, submitCount}) => (
                                <>
                                    <Form onSubmit={handleSubmit}>
                                        <div className={'mb-5 spacing_sm_bottom'}>
                                            <div className={'d-flex'}>
                                                <Field
                                                    defaultChecked={values.agreeStatement}
                                                    onChange={(e) => {
                                                        setFieldValue('agreeStatement', e.target.checked)
                                                    }}
                                                    name={'agreeStatement'}
                                                    type={'checkbox'}
                                                    className={'checkbox'}
                                                    component={FormCheck}

                                                />
                                                <p className={'ml-3'}>
                                                    The Company, in considering my application for employment, may verify
                                                    the information set forth on this application and obtain additional
                                                    information relating to my background. I authorized all persons,
                                                    schools, companies, corporations, Credit bureaus end law enforcement
                                                    agencies to supply any information concerning my background. I have
                                                    read, understand, and agree to this statement.

                                                </p>
                                            </div>
                                            <FormikError name="agreeStatement"/>
                                            <Field
                                                name={'agreeStatementInitial'}
                                                label={'Please Initial Here'}
                                                placeholder={'Enter'}
                                                component={LabeledFormControl}
                                            />
                                            <FormikError name="agreeStatementInitial"/>
                                        </div>
                                        <div className={'mb-5 spacing_sm_bottom'}>
                                            <div className={'d-flex'}>
                                                <Field
                                                    defaultChecked={values.applicationCorrect}
                                                    onChange={(e) => {
                                                        setFieldValue('applicationCorrect', e.target.checked)
                                                    }}
                                                    name={'applicationCorrect'}
                                                    type={'checkbox'}
                                                    className={'checkbox'}
                                                    component={FormCheck}

                                                />
                                                <p className={'ml-3'}>
                                                    I certify that the information on this application is correct and I
                                                    understand that any misrepresentation of any information will result in
                                                    my disqualification from consideration for employment or, if employed,
                                                    my dismissal. I understand that this application is not a contact,
                                                    offer, or promise of employment and that if hired I will be able to
                                                    resign at any time for any reason. Likewise, the company can terminate
                                                    my employment at any time with or without cause. I futhark understand
                                                    the no one other than the Executive Director the authority to enter into
                                                    an employment contact or agreement with me, and that my at-will
                                                    employment can be changed only by a written agreement signed by the
                                                    Executive Director.
                                                </p>
                                            </div>
                                            <FormikError name="applicationCorrect"/>
                                            <Field
                                                name={'applicationCorrectInitial'}
                                                label={'Please Initial Here'}
                                                placeholder={'Enter'}
                                                component={LabeledFormControl}
                                            />
                                            <FormikError name="applicationCorrectInitial"/>
                                        </div>
                                        <div className={'mb-5 spacing_sm_bottom'}>
                                            <div className={'d-flex'}>
                                                <Field
                                                    defaultChecked={values.applicationGood}
                                                    onChange={(e) => {
                                                        setFieldValue('applicationGood', e.target.checked)
                                                    }}
                                                    name={'applicationGood'}
                                                    type={'checkbox'}
                                                    className={'checkbox'}
                                                    component={FormCheck}
                                                />
                                                <p className={'ml-3'}>
                                                    I understand that this application is good for sixty (60) days from
                                                    today's date. If I still desire a position with the company after this
                                                    application expires, it will be my responsibility to fill out a new
                                                    application and file it with company. Otherwise, the company will not
                                                    consider me for employment after this application expires.
                                                </p>
                                            </div>
                                            <FormikError name="applicationGood"/>
                                            <Row>
                                                <Col sm>
                                                    <DatePicker
                                                        minDate={new Date()}
                                                        name={'applicationDate'}
                                                        label={'Date of Application'}
                                                        yearDropdownItemNumber={100}
                                                        scrollableYearDropdown
                                                        locale="en-US"
                                                        showYearDropdown
                                                        showMonthDropdown
                                                        placeholderText={'mm/dd/yyyy'}
                                                        selected={values.applicationDate}
                                                        onChange={(e) => {
                                                            setFieldValue('applicationDate', e)
                                                        }}
                                                    />
                                                    <FormikError name="applicationDate"/>
                                                </Col>
                                                <Col sm>
                                                    <Field
                                                        name={'socialSecurityCardSignature'}
                                                        label={'Signature as shown on Social Security Card'}
                                                        placeholder={'Enter'}
                                                        component={LabeledFormControl}
                                                    />
                                                    <FormikError name="socialSecurityCardSignature"/>
                                                </Col>
                                            </Row>
                                        </div>
                                        <h3>Confidentiality Agreement</h3>
                                        <div className={'my-5 spacing_sm_y'}>
                                            <p>
                                                The willful disclosure of information regarding clients in violation of
                                                confidentiality on part of any staff member or volunteer shall be grounds
                                                for termination of employment.
                                            </p>
                                            <p>All information recorded in files and any which may not be recorded but known
                                                to the staff person or volunteer will be treated as confidential and will be
                                                released only to the extent appropriate for the provision of necessary
                                                services and then only with consent of the client or her legal guardian.
                                                Consent must be given in writing. In emergency cases where the client is
                                                endagered or is considered dangerous to others, disclosure is permissible.
                                                When time allows, staff must clear this release of information with the
                                                Executive Director. In an emergemcy, staff may make a decision to report the
                                                information, then notify the Executive Director as soon as possible.</p>
                                            <p> All agency business must be treated as confidential by all staff members and
                                                volunteers. No client shall be subject to any publicity which betrays her
                                                anonymity and or reveals confidential information about them.</p>
                                            <p>
                                                I have read the above statement in regards to confidentiality.
                                                I undertsnad the policy and agree to abide by this policy.</p>
                                        </div>
                                        <Row className={'mb-4'}>
                                            <Col>
                                                <div className={'d-flex align-items-center'}>
                                                    <Field
                                                        defaultChecked={values.confidentialityAgreement}
                                                        onChange={(e) => {
                                                            setFieldValue('confidentialityAgreement', e.target.checked)
                                                        }}
                                                        name={'confidentialityAgreement'}
                                                        type={'checkbox'}
                                                        className={'checkbox'}
                                                        component={FormCheck}
                                                    />
                                                    <FormCheckLabel className={'ml-3'}>
                                                        I Agree with Confidentiality Agreement
                                                    </FormCheckLabel>
                                                </div>
                                                <FormikError name="confidentialityAgreement"/>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm>
                                                <Field
                                                    name={'confidentialityAgreementName'}
                                                    label={'Name'}
                                                    placeholder={'Enter'}
                                                    component={LabeledFormControl}
                                                />
                                                <FormikError name="confidentialityAgreementName"/>
                                            </Col>
                                            <Col sm>
                                                <DatePicker
                                                    minDate={new Date()}
                                                    name={'confidentialityAgreementDate'}
                                                    label={'Date'}
                                                    yearDropdownItemNumber={100}
                                                    scrollableYearDropdown
                                                    locale="en-US"
                                                    showYearDropdown
                                                    showMonthDropdown
                                                    placeholderText={'mm/dd/yyyy'}
                                                    selected={values.confidentialityAgreementDate}
                                                    onChange={(e) => {
                                                        setFieldValue('confidentialityAgreementDate', e)
                                                    }}
                                                />
                                                <FormikError name="confidentialityAgreementDate"/>
                                            </Col>
                                        </Row>
                                        <div className={'Step__footer'}>
                                            <Button
                                                onClick={onPrev}
                                                className={'btn-outline-primary Step__action-button'}>
                                                Back
                                            </Button>
                                            <LoadingActionButton loading={loading} text={'Next'}/>
                                        </div>
                                    </Form>
                                    <CustomPrompt when={dirty && submitCount === 0}/>
                                </>
                            )}
                        />

                    </>
            }

        </>
    );
};

