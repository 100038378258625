import React from 'react';
import DOMPurify from 'dompurify';
import ReactPlayer from 'react-player/lazy';
import Measure from 'react-measure';

import AuthImage from 'modules/core/components/AuthImage';
import { BreakpointContextConsumer } from 'modules/core/contexts/BreakpointContext';

import './styles.scss';

const ContentItem = ({
  text,
  image,
  videoUrl,
  type,
  mobileVideoHeight,
  desktopVideoHeight,
}) => {
  const [videoWidth, setVideoWidth] = React.useState(window.innerWidth);

  const handleResize = React.useCallback((contentRect) => {
    setVideoWidth(contentRect.bounds.width);
  }, []);

  return (
    <BreakpointContextConsumer>
      {({ isMobile, breakpoint, width }) => (
        <Measure bounds onResize={handleResize}>
          {({ measureRef }) => (
            <div className="LargePost__postItemRoot">
              <div className="LargePost__contents">
                {text && (
                  <div
                    className="LargePost__text"
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(text),
                    }}
                  ></div>
                )}
              </div>

              {image && (
                <AuthImage
                  controllerPath={type === 'training' ? 'trainings' : 'posts'}
                  hash={image}
                >
                  <img className="LargePost__itemImage" />
                </AuthImage>
              )}
              {videoUrl && (
                <div className="ContentItem__video" ref={measureRef}>
                  <ReactPlayer
                    url={videoUrl}
                    controls={true}
                    width="100%"
                    height={`${Number(videoWidth) * 0.55}px`}
                  />
                </div>
              )}
            </div>
          )}
        </Measure>
      )}
    </BreakpointContextConsumer>
  );
};

export default ContentItem;
