import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { mainRoutes } from './constants';
//pages
import MainPage from 'modules/main/pages/MainPage';

const HomeRoutes = () => {
  return (
    <Switch>
      <Route path={mainRoutes.root} component={MainPage} exact />
    </Switch>
  );
};

export default HomeRoutes;
