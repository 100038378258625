import React from 'react';
import { Button, Spinner } from 'react-bootstrap';

import ApiClient from 'modules/core/utils/ApiClient';
import history from 'modules/core/utils/history';
import { loginRoutes } from 'modules/login/routes/constants';
import Input from 'modules/core/components/Input';
import TitleDecor from 'modules/core/components/TitleDecor';
import Container from 'modules/core/components/Container';

import './styles.scss';

const UserNewPasswordPage = () => {
  const [password, setPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [submited, setSubmited] = React.useState(false);
  const [error, setError] = React.useState('');
  const [message, setMessage] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  const passwordInputHandler = React.useCallback(
    (e) => {
      setPassword(e.target.value);
    },
    [setPassword]
  );

  const confirmPasswordInputHandler = React.useCallback(
    (e) => {
      setConfirmPassword(e.target.value);
    },
    [setConfirmPassword]
  );

  const loginSubmitHandler = React.useCallback(
    async (e) => {
      if (loading) return;
      e.preventDefault();
      try {
        setLoading(true);
        const params = new URLSearchParams(history.location.search);
        const id = params.get('id');
        const token = params.get('token');
        console.log(`[ResetPasswordRedirect] id: ${id} token: ${token}`);

        const response = await ApiClient.resetPassword({
          id: Number(id),
          token,
          password,
          confirmPassword,
        });
        if (response.errors.length > 0) {
          setError(response.errors[0].message);
        } else {
          setMessage('Password successfully changed.');
          setTimeout(() => {
            history.push(loginRoutes.root);
          }, 6000);
        }
      } catch (err) {
        console.log(err.response);
        setError(
          Object.values(err.response.data.errors).join(' ') ||
            'Password not valid.'
        );
      }
      setLoading(false);
      setSubmited(true);
    },
    [password, confirmPassword, loading]
  );

  return (
    <Container
      className="UserResetPasswordPage__container"
      paddingHorizontalDesktop={'250px'}
      paddingHorizontalMobile={'18px'}
    >
      <div className="UserResetPasswordPage__root">
        <div
          className={
            'UserResetPasswordPage__content' + (submited ? '-submited' : '')
          }
        >
          <TitleDecor content="Change Password" />
          <Input
            type="password"
            id="fyc_admin_pass"
            placeholder=" "
            label="Password"
            submited={submited}
            value={password}
            onChange={passwordInputHandler}
            isInvalid={submited && password.length < 5}
            errorFeedback="Password is not valid."
            rootClass="LoginPage__input"
          />
          <Input
            type="password"
            id="fyc_admin_confirm_pass"
            placeholder=" "
            label="Repeat Password"
            submited={submited}
            value={confirmPassword}
            onChange={confirmPasswordInputHandler}
            isInvalid={submited && confirmPassword !== password}
            errorFeedback="Passwords do not match."
            rootClass="LoginPage__input"
          />
          {error && (
            <h1 className="UserResetPasswordPage__serverMessage">{error}</h1>
          )}
          {message && (
            <h1 className="UserResetPasswordPage__serverMessage">{message}</h1>
          )}
          {message && !error && (
            <h1 className="UserResetPasswordPage__serverMessage">
              Redirecting to login page in a few seconds..
            </h1>
          )}

          <Button
            type="sumbit"
            size="lg"
            onClick={loginSubmitHandler}
            disabled={loading}
          >
            Save
            {loading && (
              <Spinner
                animation="border"
                size="sm"
                variant="secondary"
                className="buttonSpinner"
              />
            )}
          </Button>
        </div>
      </div>
    </Container>
  );
};

export default UserNewPasswordPage;
