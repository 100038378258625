import ApiClient from "../../utils/ApiClient";
import {toast} from "react-toastify";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {
    resetMedicationLog, setMedicationsCurrentPage,
    setOneTimeLogId, setOneTimeLogsCurrentPage, setShowMedicationLogModal,
    setShowOneTimeModal,
    setShowUpdateOneTimeModal
} from "../reducers/residentsReducer";


export const updateMedicationEvent = createAsyncThunk('residents/updateMedicationEvent', async (data, {dispatch}) => {
    const {stDate, endDate, residentId, id, value} = data
    try {
        const response = await ApiClient.updateMedicationsEvents(id, value)
        if (response.success) {
            toast.success('Event updated successfully')
            dispatch(getMedicationsEvents({id: residentId, stDate, endDate}))
        } else {
            toast.error(response.errors[0].message)
        }
    } catch (err) {
        toast.error(err)
    }

})
export const updateTimeMedsLog = createAsyncThunk('residents/updateOneTimeMedsLog', async (data, {dispatch}) => {
    try {
        const {residentId, ...rest} = data
        const response = await ApiClient.updateMedsLogOneTime(rest)
        if (response.success) {
            dispatch(setShowUpdateOneTimeModal(false))
            dispatch(setOneTimeLogId(0))
            dispatch(setOneTimeLogsCurrentPage(1))
            dispatch(getOneTimeMedications({
                id: data.residentId,
                currentPage: 1,
                pageSize: 10
            }))
        } else {
            toast.error(response.errors[0].message)
        }

    } catch (err) {
        toast.error(err)
    }
})
export const getOneTimeLogById = createAsyncThunk('residents/getOneTimeLog', async (id) => {
    try {
        const response = await ApiClient.getOneTimeLogById(id)
        if (response.success) {
            return response.value
        } else {
            toast.error(response.errors[0].message)
        }

    } catch (err) {
        toast.error(err)
    }
})
export const addOneTimeMedsLog = createAsyncThunk('residents/addOneTimeMedsLog', async (data, {dispatch}) => {
    try {
        const response = await ApiClient.createMedsLogOneTime(data)
        if (response.success) {
            dispatch(setShowOneTimeModal(false))
            dispatch(setOneTimeLogsCurrentPage(1))
            dispatch(getOneTimeMedications({
                id: data.residentId,
                currentPage: 1,
                pageSize: 10
            }))
        } else {
            toast.error(response.errors[0].message)
        }

    } catch (err) {
        toast.error(err)
    }
})
export const getOneTimeMedications = createAsyncThunk('residents/getOneTimeMedications', async (data) => {
    try {
        const {id, currentPage, pageSize} = data
        const response = await ApiClient.getMedicationsOneTime(id, currentPage, pageSize)
        if (response.success) {
            return {
                totalItems: response.value.totalItems,
                items: response.value.entities,
                currentPage
            }
        } else {
            toast.error(response.errors[0].message)
        }

    } catch (err) {
        toast.error(err)
    }
})
export const getMedicationsEvents = createAsyncThunk('residents/getMedicationsEvents', async (data) => {
    try {
        const {id, stDate, endDate} = data
        const response = await ApiClient.getMedicationsEvents(id, stDate, endDate)
        if (response.success) {
            return response.value.events
        } else {
            toast.error(response.errors[0].message)
        }
    } catch (err) {
        toast.error(err)
    }
})
export const getMedicationsLogs = createAsyncThunk('residents/getMedicationsLogs', async (data) => {
    try {
        const {id, isCurrent, currentPage, pageSize} = data
        const response = await ApiClient.getMedicationsLogs(id, isCurrent, currentPage, pageSize)
        if (response.success) {
            return {
                totalItems: response.value.totalItems,
                items: response.value.entities,
                currentPage
            }
        } else {
            toast.error(response.errors[0].message)
        }

    } catch (err) {
        toast.error(err)
    }
})
export const createMedicationLog = createAsyncThunk('residents/createMedicationLog', async (data, {dispatch}) => {
    try {
        const response = await ApiClient.createMedicationLog(data)
        if (response.success) {
            dispatch(setShowMedicationLogModal(false))
            dispatch(resetMedicationLog())
            dispatch(setMedicationsCurrentPage(1))
            dispatch(getMedicationsLogs({
                id: data.residentId,
                isCurrent: true,
                currentPage: 1,
                pageSize: 10
            }))
        } else {
            toast.error(response.errors[0].message)
        }
    } catch (err) {
        toast.error(err)
    }
})
export const getResidentMedicationLog = createAsyncThunk('residents/getMedicationLog', async (id) => {
    try {
        const response = await ApiClient.getResidentMedicationLog(id)
        if (response.success) {
            return response.value

        } else {
            toast.error(response.errors[0].message)
        }

    } catch (err) {
        toast.error(err)
    }

})
export const deleteMedicationLog = createAsyncThunk('residents/deleteMedicationLog', async ({
                                                                                                id,
                                                                                                isCurrent,
                                                                                                residentId
                                                                                            }, {dispatch, getState}) => {
    try {
        const {residents} = await getState()
        const response = await ApiClient.deleteMedicationLog(id)
        if (response.success) {
            if(residents.medicationsLogs.currentPage > 1){
                dispatch(setMedicationsCurrentPage(1))
            }else {
                dispatch(getMedicationsLogs({
                    id: residentId,
                    isCurrent: isCurrent,
                    currentPage: 1,
                    pageSize: 10
                }))
            }


        } else {
            toast.error(response.errors[0].message)
        }

    } catch (err) {
        toast.error(err)
    }
})
export const deleteOneTimeMedsLog = createAsyncThunk('residents/deleteOneTimeMedsLog', async ({
                                                                                                  residentId,
                                                                                                  id
                                                                                              }, {
                                                                                                  dispatch,
                                                                                                  getState
                                                                                              }) => {
    try {
        const {residents} = await getState()
        const response = await ApiClient.deleteMedicationLogOneTime(id)
        if (response.success) {
            if (residents.medications.currentPage > 1) {
                dispatch(setOneTimeLogsCurrentPage(1))
            } else {
                dispatch(getOneTimeMedications({
                    id: residentId,
                    currentPage: 1,
                    pageSize: 10
                }))
            }


        } else {
            toast.error(response.errors[0].message)
        }

    } catch (err) {
        toast.error(err)
    }
})
