export const STEPS = [
    {
        name: 'Application'
    },
    {
        name: 'Obtaining and acknowledging the Forstter Youth Housing Staff Handbook'
    },
    {
        name: 'Corporal Punishment Policy'
    },
    {
        name: 'Abuse/Neglect Reporting Policy'
    },
    {
        name: 'Health Assessment Form',
        highlights: '(if you do not have a health assessment done by a doctor, make an appointment).'
    },
    {
        name: 'Current TB skin test'
    },
    {
        name: 'Driving license (copy)'
    },
    {
        name: 'First aid/CPR (If you do not have current first aid/CPR, check one of the following.',
    },
]
export const stepsPath = {
    firstStep: 'first-step',
    secondStep: 'second-step',
    thirdStep: 'third-step',
    fourthStep: 'fourth-step',
    fifthStep: 'fifth-step',
    sixStep: 'six-step',
    sevenStep: 'seven-step',
    eightStep: 'eight-step',
    finish: 'finish'
}

export const pageNumbers = [
    'first-step',
    'second-step',
    'third-step',
    'fourth-step',
    'fifth-step',
    'six-step',
    'seven-step',
    'eight-step'
]
