import React from 'react';

import './styles.scss';

const CareerBlockQuote = () => {
  return (
    <div className="CareerBlockQuote__root">
      <div className="CareerBlockQuote__inner">
        <p>
          The only way to do great work, is
          <br /> to love what you do
          <span className="CareerBlockQuote__dot"></span>
          <span className="CareerBlockQuote__drop"></span>{' '}
        </p>
      </div>
    </div>
  );
};

export default CareerBlockQuote;
