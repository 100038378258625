import {
  SET_ACTIVATED_FACILITIES_LIST,
  SET_DEACTIVATED_FACILITIES_LIST,
  SET_SHORT_FACILITIES_LIST,
} from 'modules/core/store/actions/facilityActions';
import { LOGOUT } from 'modules/auth/store/actions/authActions';

const initialState = {
  activatedFacilities: [],
  activatedTotalItemsCount: 9999,

  deactivatedFacilities: [],
  deactivatedTotalItemsCount: 9999,

  shortFacilities: [],
  shortTotalItemsCount: 9999,
};

const residentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACTIVATED_FACILITIES_LIST:
      return {
        ...state,
        activatedFacilities: action.facilities,
        activatedTotalItemsCount: action.totalItemsCount,
      };
    case SET_DEACTIVATED_FACILITIES_LIST:
      return {
        ...state,
        deactivatedFacilities: action.facilities,
        deactivatedTotalItemsCount: action.totalItemsCount,
      };
    case SET_SHORT_FACILITIES_LIST:
      return {
        ...state,
        shortFacilities: action.facilities,
        shortTotalItemsCount: action.totalItemsCount,
      };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default residentsReducer;
