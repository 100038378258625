import React, {useCallback} from 'react';
import './styles.scss'

const Stepper = ({activeStep, steps}) => {
    const getClassName = useCallback((step, index) => {
        return step === index ? 'step_active' : step > index ? 'step_visited' : ''
    }, [])
    return (
        <div className={'stepper-container'}>
            <span className={'step-title'}>
                {`${activeStep + 1}/${steps.length} ${steps[activeStep].name}`}
                {steps[activeStep]?.highlights ?
                    <span className={'step-title-orange'}>{` ${steps[activeStep]?.highlights}`}</span>
                    : null
                }
            </span>
            <div className={'step-items'}>
                {
                    steps.map((_, index) => (
                        <div key={index} className={`step ${getClassName(activeStep, index)}`}/>
                    ))
                }

            </div>

        </div>
    );
};

export default Stepper;
