import React from 'react';
import PropTypes from 'prop-types';

import { BreakpointContextConsumer } from 'modules/core/contexts/BreakpointContext';

import './styles.scss';

const LargeImage = ({ src, className, imgClassName = '', children }) => {
  return (
    <BreakpointContextConsumer>
      {({ isMobile }) => (
        <div className={'Landscape__root ' + className}>
          <img
            src={src}
            alt="happy kid"
            className={'Landscape__image ' + imgClassName}
          />
          {children}
        </div>
      )}
    </BreakpointContextConsumer>
  );
};

LargeImage.propTypes = {
  src: PropTypes.string.isRequired,
  className: PropTypes.string,
  imgClassName: PropTypes.string,
};

export default LargeImage;
