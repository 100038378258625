import React from 'react';

import useBreakpoint from '../hooks/useBreakpoint';

const { Provider, Consumer } = React.createContext();

const BreakpointContext = (props) => {
  const breakpointState = useBreakpoint();
  return <Provider value={breakpointState}>{props.children}</Provider>;
};

export { BreakpointContext, Consumer as BreakpointContextConsumer };
