import {
    SET_CAREER_LOADING,
    SET_APPLICANTS,
    SET_DATA_STEP_LOADING,
    SET_STEP_VALUES,
    SET_JOB_POSITIONS_SHORT,
    SET_SHOW_ALERTER,
    SET_ALERTER_LOADING,
    SET_ERRORS,
    SET_JOB_APPLICATIONS_LOADING,
    SET_JOB_APPLICATIONS_ERRORS, SET_JOB_APPLICATIONS, HIDE_JOB_APPLICATION
} from "../actions/careerActions";
import {LOGOUT} from "../../../auth/store/actions/authActions";

const initialState = {
    loading: false,
    stepLoading: false,
    jobApplications: [],
    stepValues: null,
    jobPositions: [],
    showAlerter: false,
    loadingAlerter: false,
    errors: [],
    getJobApplications:{
        items: [],
        loading: false,
        errors: [],
        totalItems: 0
    },
}

const careerReducer = (state = initialState,  action) => {
    switch (action.type){
        case SET_CAREER_LOADING :
            return {
                ...state,
                loading: action.payload
            }
        case SET_APPLICANTS :
            return {
                ...state,
                jobApplications: action.payload
            }
        case SET_DATA_STEP_LOADING :
            return {
                ...state,
                stepLoading: action.payload
            }
        case SET_STEP_VALUES :
            return {
                ...state,
                stepValues: action.payload
            }
        case SET_JOB_POSITIONS_SHORT :
            return {
                ...state,
                jobPositions: action.payload
            }
        case SET_SHOW_ALERTER :
            return {
                ...state,
                showAlerter: action.payload
            }
        case SET_ALERTER_LOADING:
            return {
                ...state,
                loadingAlerter: action.payload
            }
        case SET_ERRORS :
            return {
                ...state,
                errors: action.payload
            }
        case SET_JOB_APPLICATIONS_LOADING :
            return {
                ...state,
                getJobApplications: {
                    ...state.getJobApplications,
                    loading: action.payload
                }
            }
        case SET_JOB_APPLICATIONS_ERRORS :
            return {
                ...state,
                getJobApplications: {
                    ...state.getJobApplications,
                    errors: action.payload
                }
            }
        case SET_JOB_APPLICATIONS :
            return {
                ...state,
                getJobApplications: {
                    ...state.getJobApplications,
                    items: action.payload.entities,
                    totalItems: action.payload.totalItems
                }
            }
        case HIDE_JOB_APPLICATION :
            return  {
                ...state,
                jobApplications: state.jobApplications.filter(it => it.applicationId !== action.payload )
            }
        case LOGOUT :
            return initialState
        default :
            return state
    }
}
export default careerReducer
