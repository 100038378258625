import React, {useState} from 'react';
import {Link} from 'react-router-dom';

import useParams from 'modules/core/hooks/wrappedUseParams';
import ApiClient from 'modules/core/utils/ApiClient';
import Container from 'modules/core/components/Container';
import WithLoading from 'modules/core/components/WithLoading';

import './styles.scss';
import {Button, OverlayTrigger, Tooltip} from "react-bootstrap";
import useAuth from "../../../auth/hooks/useAuth";
import {useDispatch} from "react-redux";
import {createJobApplicationsRequest} from "../../../core/store/actions/careerActions";

const CareerViewPositionPage = () => {
    const {id} = useParams();
    const [position, setPosition] = React.useState();
    const [show, setShow] = useState(false)
    const {isAuthenticated} = useAuth()
    const dispatch = useDispatch()

    const renderTooltip = (props) => (
        <Tooltip id="apply-tooltip" {...props}>
            You will be redirected on login page
        </Tooltip>
    );

    React.useEffect(() => {
        if (id && !position)
            (async () => {
                try {
                    setPosition(await ApiClient.getJobPosition(id));
                } catch (err) {
                    console.log(err, err.data);
                }
            })();
    }, [id, position]);

    return (
        <Container className="CareerViewPositionPage__root">
            <div className="CareerViewPositionPage__container">
                <WithLoading loading={!position}>
                    <div className="CareerViewPositionPage__top">
                        <div className="CareerViewPositionPage__left">
                            <h2>{position?.name}</h2>
                            <span>{position?.shortDescription}</span>
                            <p>
                                {position?.experience} <span> years of experience</span>
                            </p>
                        </div>
                        <div className="CareerViewPositionPage__right">
                            <OverlayTrigger
                                onToggle={() => {
                                    if (!isAuthenticated) {
                                        setShow((prev) => !prev)

                                    }
                                }}
                                trigger={'hover'}
                                defaultShow={false}
                                show={show}
                                overlay={renderTooltip()} placement={'auto'}>
                                <Button
                                    onClick={() => {
                                        dispatch(createJobApplicationsRequest(Number(id), position?.name))
                                    }}
                                >

                                    Apply
                                </Button>
                            </OverlayTrigger>
                        </div>
                    </div>
                    <div className="CareerViewPositionPage__description">
                        <div className="CareerViewPositionPage__row">
                            <h3>Position</h3>
                            <p>{position?.description}</p>
                        </div>
                        <div className="CareerViewPositionPage__row">
                            <h3>Required skills</h3>
                            <p>{position?.requiredSkills}</p>
                        </div>
                    </div>
                </WithLoading>
            </div>
        </Container>
    );
};

export default CareerViewPositionPage;
