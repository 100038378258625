import {
  LOGIN,
  LOGIN_STARTED,
  LOGIN_ERROR,
  LOGOUT,
  SIGNED_UP,
  SIGN_UP_STARTED,
} from 'modules/auth/store/actions/authActions';
import localStorageService from 'modules/core/utils/localStorageService';

const initialState = {
  tokenData: null,
  loading: false,
  isAuthenticated: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case SIGN_UP_STARTED:
    case LOGIN_STARTED:
      return { loading: true };
    case LOGIN:
      localStorageService.storeTokenData(action.tokenData);
      return {
        ...state,
        tokenData: action.tokenData,
        loading: false,
        isAuthenticated: true,
      };
    case SIGNED_UP:
      return {
        ...state,
        message: action.message,
        loading: false,
      };
    case LOGIN_ERROR:
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        error: action.error,
      };
    case LOGOUT:
      localStorageService.clearTokenData();

      return {
        tokenData: null,
        loading: false,
        isAuthenticated: false,
        error: '',
      };
    default:
      return state;
  }
};

export default authReducer;
