import React from 'react';

import masterCardIcon from 'assets/icons/masterCard.svg';
import visaIcon from 'assets/icons/visa.svg';
import cartes_bancaires from 'assets/icons/cartes-bancaires.svg';

import amex from 'assets/icons/card/amex.svg';
import jcb from 'assets/icons/card/jcb.svg';
import unionpay from 'assets/icons/card/unionpay.svg';
import discover from 'assets/icons/card/discover.svg';
import diners_club from 'assets/icons/card/diners.svg';
import './styles.scss';

const brandImages = new Map();
brandImages.set('cartes_bancaires', cartes_bancaires);
brandImages.set('amex', amex);
brandImages.set('jcb', jcb);
brandImages.set('unionpay', unionpay);
brandImages.set('discover', discover);
brandImages.set('diners_club', diners_club);
brandImages.set('mastercard', masterCardIcon);
brandImages.set('visa', visaIcon);

const CreditCard = ({ brand, last4, isDefault }) => {
  return (
    <>
      <div className="CreditCard__brand">
        <img src={brandImages.get(brand)} alt="brand" />
      </div>
      <div className="CreditCard__numbers">
        <p>**** **** **** {last4}</p>
      </div>
    </>
  );
};

export default CreditCard;
