import ApiClient from "../../utils/ApiClient";
import {toast} from "react-toastify";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {setShowIsTurnOfModal, setShowIsTurnOnModal} from "../reducers/trackerReducer";

export const getWorkingTimeByWeek = createAsyncThunk('tracker/workingTimeByWeek', async ({userId,startDate}) => {
    try {
        const result = await ApiClient.getWorkingTimeByWeek(userId,startDate)
        if(result.success){
            return result.value
        }else {
            toast.error(result.errors[0].message)
        }
    }catch (err){
        toast.error(err)
    }
})

export const getWorkingTimeByMonth = createAsyncThunk('tracker/workingTimeByMonth', async ({userId,startDate}) => {
    try {
        const result = await ApiClient.getWorkingTimeByMonth(userId,startDate)
        if(result.success){
            return result.value
        }else {
            toast.error(result.errors[0].message)
        }
    }catch (err){
        toast.error(err)
    }
})
export const updatePricePerHour = createAsyncThunk('tracker/updatePrice', async ({userId, pricePerHour}) => {
    try {
        const result = await ApiClient.updatePricePerHour({userId, pricePerHour})
        if(result.success){
            toast.success(result.messages[0].message)
        }else {
            toast.error(result.errors[0].message)
            return result.errors
        }
    }catch (err){
        toast.error(err)
    }
})

export const getCurrentTimeInformation = createAsyncThunk('users/getTimeInformation', async (_,thunkApi) => {
    try{
        const response = await ApiClient.getCurrentTimeInformation()
        if(response.success){
            if(!response.value.isTracked){
                thunkApi.dispatch(setShowIsTurnOnModal(true))
            }
            return response.value

        }else {
            toast.error(response.errors[0].message)
        }

    }catch (err){
        toast.error(err)
    }
})

export const startTimer = createAsyncThunk('users/startTimer', async ({onStart},{dispatch}) => {
    try{
        const response = await ApiClient.startTimer()
        if(response.success){
            dispatch(setShowIsTurnOnModal(false))
            onStart()
            toast.success(response.messages[0].message)

        }else {
            toast.error(response.errors[0].message)
        }

    }catch (err){
        toast.error(err)
    }
})

export const stopTimer = createAsyncThunk('users/stopTimer', async ({onPause}, {dispatch}) => {
    try{
        const response = await ApiClient.stopTimer()
        if(response.success){
            dispatch(setShowIsTurnOfModal(false))
            onPause()
            toast.success(response.messages[0].message)

        }else {
            toast.error(response.errors[0].message)
        }

    }catch (err){
        toast.error(err)
    }
})
