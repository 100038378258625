import React from 'react';
import { Spinner } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import throttle from 'lodash.throttle';

import { fetchPostsWithItems } from 'modules/core/store/actions/postsActions';
import useBreakpoint from 'modules/core/hooks/useBreakpoint';
import Container from 'modules/core/components/Container';
import LargePost from 'modules/posts/components/LargePost';

import './styles.scss';

const POST_HEIGHT = 800;

const PostsPage = () => {
  const { posts, loading } = useSelector((state) => ({
    posts: state.posts.posts,
    loading: state.posts.loading,
  }));

  const { isMobile, breakpoint } = useBreakpoint();
  const [perWidth, setPerWidth] = React.useState(isMobile ? 1 : 2);
  const [pageSize, setPageSize] = React.useState(
    (perWidth * (window.innerHeight / POST_HEIGHT)) ^ 0
  );

  const dispatch = useDispatch();

  React.useEffect(() => {
    const _perWidth = isMobile ? 1 : 2;
    setPageSize((perWidth * (window.innerHeight / POST_HEIGHT)) ^ 0);
    setPerWidth(_perWidth);
  }, [isMobile, breakpoint, perWidth]);

  React.useEffect(() => {
    if (posts.length < pageSize) {
      dispatch(fetchPostsWithItems(pageSize));
    }
  }, [pageSize, posts, dispatch]);

  React.useEffect(() => {
    window.onscroll = throttle(() => {
      if (
        window.pageYOffset >=
        document.documentElement.scrollHeight -
          document.documentElement.clientHeight -
          POST_HEIGHT
      ) {
        dispatch(fetchPostsWithItems(pageSize));
      }
    }, 100);
  }, [pageSize, dispatch]);

  return (
    <Container className="PostsPage__root" paddingHorizontalDesktop={'210px'}>
      {posts &&
        posts.map((post, i) => (
          <div className="PostsPage__posts" key={post.id}>
            <LargePost post={post} />
            {i !== posts.length - 1 && <div className="PostsPage__separator" />}
          </div>
        ))}
      {loading && (
        <div className="PostsPage__spinnerContainer">
          <Spinner animation="border" />
        </div>
      )}
    </Container>
  );
};

export default PostsPage;
