import React from 'react';

import './styles.scss';

const CheckBox = ({
  onChange,
  checked: _checked,
  children,
  controlled,
  disabled,
  variant,
  className,
  raiseChangeOnChildrenClick = false,
}) => {
  const [checked, setChecked] = React.useState(!!_checked);

  const handleCheckboxClick = React.useCallback(() => {
    if (disabled) return;
    setChecked(!checked);
    if (onChange) onChange(!checked);
  }, [checked, onChange, disabled]);

  const showSvg = React.useMemo(
    () => (controlled ? _checked : checked),
    [controlled, _checked, checked]
  );

  return (
    <div className={`CheckBox__root ${className}`}>
      <div
        className={
          (variant === 'flat' ? 'CheckBox__box--flat' : 'CheckBox__box') +
          (disabled && variant === 'flat' ? ' Checkbox__disabled' : '')
        }
        onClick={handleCheckboxClick}
      >
        <svg
          width="10"
          height="8"
          viewBox="0 0 10 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{
            opacity: showSvg ? 1 : 0,
            transform: showSvg ? 'none' : 'rotate(30deg)',
            width: showSvg ? 'auto' : '7px',
            height: showSvg ? 'auto' : '7px',
          }}
        >
          <path
            d="M8.49373 0.758435C8.83831 0.413855 9.39698 0.413855 9.74156 0.758435C10.0861 1.10302 10.0861 1.66169 9.74156 2.00627L4.44745 7.30039C4.10287 7.64497 3.54419 7.64497 3.19961 7.30039L0.258435 4.35921C-0.0861451 4.01463 -0.0861451 3.45596 0.258435 3.11138C0.603015 2.7668 1.16169 2.7668 1.50627 3.11138L3.82353 5.42863L8.49373 0.758435Z"
            fill="black"
          />
        </svg>
      </div>
      <div
        className="CheckBox__children"
        onClick={raiseChangeOnChildrenClick ? handleCheckboxClick : undefined}
      >
        {children}
      </div>
    </div>
  );
};

CheckBox.propTypes = {};

export default CheckBox;
