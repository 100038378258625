import {
  EDIT_POST,
  SET_POSTS,
  SET_DELETED_POSTS,
  SET_POSTS_LOADING,
  DELETE_POST,
  DELETE_POST_FOREVER,
  RESTORE_POST,
  CREATE_POST,
} from 'modules/core/store/actions/postsActions';

const emptyInitialState = {
  deleted: [],
  posts: [],
  loading: false,
  postsTotalItems: 9999,
  deletedPostsTotalItems: 9999,
};

const postsReducer = (state = emptyInitialState, action) => {
  switch (action.type) {
    case SET_POSTS:
      return {
        ...state,
        posts: [...(action.clear ? [] : state.posts), ...action.data],
        postsTotalItems: action.postsTotalItems,
      };
    case SET_DELETED_POSTS:
      return {
        ...state,
        deleted: [...state.deleted, ...action.data],
        deletedPostsTotalItems: action.deletedPostsTotalItems,
      };
    case EDIT_POST:
      return {
        ...state,
        posts: state.posts.map((post) => {
          if (post.id === action.id) {
            console.log(action.post);
            return action.post;
          }
          return post;
        }),
      };
    case DELETE_POST:
      return {
        ...state,
        posts: [],
        deleted: [],
        postsTotalItems: 9999,
        deletedPostsTotalItems: 9999,
      };
    case DELETE_POST_FOREVER:
      return {
        ...state,
        deleted: [],
        deletedPostsTotalItems: 9999,
      };
    case SET_POSTS_LOADING:
      return {
        ...state,
        loading: action.value,
      };
    case RESTORE_POST:
      return {
        ...state,
        deleted: [],
        posts: [],
        postsTotalItems: 9999,
        deletedPostsTotalItems: 9999,
      };
    case CREATE_POST:
      return {
        ...state,
        posts: [],
        postsTotalItems: 9999,
      };
    default:
      return state;
  }
};

export default postsReducer;
